import React, { Component } from 'react';
import Router from './ProductsPage.Router'


import { AssignmentModal } from '../../shared'

import styles from './ProductsPage.css'

class ProductsPage extends Component {
  render() {
    // const props = {...this.props}
    // const prop = {...props.comp.props}
    return (
      <div className={styles.app}>
        <Router />
      </div>
    );
  }
}

export default ProductsPage;
