import React, { Component } from 'react';
import { connect } from 'react-redux'
import * as cookies from 'js-cookie';
import * as jwt from 'jsonwebtoken';

import {
  Selector,
  Button,
  Header,
  Body,
  Input,
  Form,
  Span,
  FileUpload,
  Icon,
} from '../../../../../common'

// Actions
import { createCommunityMember } from '../../../../../../actions/community'

import logo from '../../../../assets/Muvor.png'

import styles from './Paywall.css'
import { UTILS } from '../../../../../../lib';

class AddMember extends Component {
  constructor(props) {
    super(props)
    this.state = {
      payModal: {},
      name: '',
      contact: '',
      deposit: 0,
      photo: [],
      me: cookies.get('me') && JSON.parse(cookies.get('me')) ? JSON.parse(cookies.get('me')) : undefined 
    }
  }

  componentDidMount() {
    sessionStorage.setItem('deposit', '0')
  }

  onChange = deposit => {
    if ( deposit >= 0) {
      this.setState({ deposit })
      sessionStorage.setItem('deposit', deposit)
    }
  }

  onSubmit(e) {
    e.preventDefault()
    const {
      photo,
      name,
      contact,
      deposit,
      me
    } = this.state
    let formData = new FormData();
    photo.map(p => {
      formData.append("file", p)
    })
    const data = {
      name,
      contact,
      deposit
    }
    formData.append("data", JSON.stringify(data))
    // console.log('onSubmit', data)
    this.props.createCommunityMember(formData)
  }

  renderPhotoItem(photo) {
    console.log(photo)
    let pic = URL.createObjectURL(photo)
    return (
      <div>
        <img className="image"
          src={pic} />
        <Icon
          small
          button
          cancel
          onClick={() => this.setState({photo:[]})}
        />
      </div>
    )
  }

  render() {
    const {
      deposit,
      name,
      contact
    } = this.state
    const {
      superDisplay,
      employeesDisplay
    } = this.props
    const invalid = (
      !contact
    )

    return (
      <div>
        <Body className={styles.body}>
          <img src={logo} className={styles.logo}/>
          <Span className={styles.title}> Muvor Coin: Community Token </Span>
          <Form
            className="form"
            onSubmit={this.onSubmit.bind(this)}
          >
            <br />

            <label>
              Pseudonym:
            </label>
            <Input
              placeholder="..."
              value={name}
              onChange={name => this.setState({ name })}
            />
            
            <br />

            <label>
              Contact (Email):
            </label>
            <Input
              placeholder="..."
              value={contact}
              onChange={contact => this.setState({ contact })}
            />

            <br />

            <label>
              Coin Photo (You can change this later):
            </label>
            
            <div className={styles.photoList}>
            {
              this.state.photo.length ? (
              this.state.photo.map(this.renderPhotoItem.bind(this))
              ) : (
                <span>
                  None
                </span>
                )
              }
              </div>
              <FileUpload
                id="add-photo"
                wide
                large
                confirm
                title="PHOTOS"
                accept={'image/jpeg,image/png'}
                inactive={this.props.loading}
                onUpload={photo => {var a=this.state.photo.slice();a.push(photo);this.setState({photo:a})}}
                progress={this.props.progress}
              />
            <br />

            <label>
              $M̶VRX (Recommended):
            </label>
            <div className={styles.ua}>
              <Input className={styles.users} type='number' value={this.state.deposit} placeholder='0' onChange={this.onChange}/>
              <h5>{UTILS.formatUSD(this.state.deposit*100)}</h5>
            </div>
            <br />

            <div className={styles.buttons}>
              <Button
                large
                cancel
                link="/home/community"
                title="CANCEL"
              />
              <Button
                large
                type="submit"
                title="Fork Yourself!"
                inactive={invalid}
                onClick={this.onSubmit.bind(this)}
              />
            </div>
          </Form>
        </Body>
      </div>
    );
  }
}

const mapStateToProps = ({ main }) => {
  const {
    loading
  } = main

  return {
    loading,
  }
}

const actions = {
  createCommunityMember
}

export default connect(mapStateToProps, actions)(AddMember)
