import React, { Component } from 'react';
import styles from '../Home.css';

class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {  }
  }
  render() { 
    return ( 
      <div className={styles.app}> 
        <div id='services' className='text-center'>
          <div className='container'>
            <div className='section-title'>
              <h2>Our Services</h2>
            </div>
            <div className='service'>
              {this.props.data
                ? this.props.data.map((d, i) => (
                    <div key={`${d.name}-${i}`} className='rows'>
                      {' '}
                      <a href={d.site}><i className={d.icon}></i></a>
                      <div className='service-desc'>
                        <h3>{d.name}</h3>
                        <p>{d.text}</p>
                      </div>
                    </div>
                  ))
                : 'loading'}
            </div>
          </div>
        </div>
      </div>
     );
  }
}
 
export default Services;