import React, { Component } from 'react';

export default class Job extends Component {
  render() {
    let job = JSON.parse(sessionStorage.getItem('job'))
    console.log(job)
    return (
        <div>
          <div className="cell">
            <article className="article">
              <h1 className="article-title">{job.position}</h1>
              <div className="article-body">
                <p>
                  {job.description}
                </p>
              </div>
            </article>
          </div>
        </div>
    )
  }
}