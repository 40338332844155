import React, { Component } from 'react';

import { connect } from 'react-redux'
import styles from './ImportsPage.css'
import { FormModal, Header } from '../../common';
import ImportModal from './ImportModal/ImportModal';
import { Import } from '../../../actions/editor';

class ImportsPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      importModal: {},
    }
  }

  render() {
    return (
      <div className={styles.app}>
        {/* Modal push to importee compo */}
        <ImportModal
          content={this.state.importModal}
          onClickAway={() => {this.setState({importModal: null});window.history.back()}}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ main }) => {
  const {
    loading,
    progress
  } = main

  return {
    loading,
    progress
  }
}

const actions = {
}

export default connect(mapStateToProps, actions)(ImportsPage)
