import React, { Component } from 'react';
import MuvorLogo from '../../assets/MuvorMain.png';
import styles from '../Home.css';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {  }
  }
  render() { 
    return ( 
      <header id='header' className={styles.app}>
        <div className='intro'>
          <div className='overlay'>
            <div className='intro-text'>
              <img src={MuvorLogo} alt=''/>
              <h1>
                {this.props.data ? this.props.data.title : 'Loading'}
              </h1>
              <p>{this.props.data ? this.props.data.paragraph : 'Loading'}</p>
            </div>
          </div>
        </div>
      </header>
     );
  }
}
 
export default Header;