import { api } from '../config'

export default {
  getAll() {
    const url = '/assignments'
    return api.get(url)
  },

  post(data) {
    const url = '/assignments'
    return api.post(url, data)
  },

  postNoteById(data) {
    const url = `/assignments/notes`
    return api.post(url, data)
  },

  deleteById(id) {
    const url = `/assignments/${id}`
    return api.delete(url, id)
  }
}
