import React from 'react';
import {
Route,
Switch
} from 'react-router-dom'

// Components
import PersonalInfo from "./PersonalInfo/PersonalInfo";
import Education from "./Education/Education";
import FamilyInfo from "./FamilyInfo/FamilyInfo";
import WorkExperience from "./WorkExperience/WorkExperience";
import EmployeeHome from "./EmployeeHome/EmployeeHome";
import LeaveApplicationEmp from "./LeaveApplicationEmp/LeaveApplicationEmp";
import NotFound from '../../../NotFound/NotFound'


const HrDashboardRouter = props => (
<Switch>
    {/* <Route
        exact
        path="/hr/employee/info"
        component={props => <EmployeeInfo data={this.state.EmpInfo} onBack={this.handleBack}/>}
        /> */}
    <Route
        exact
        path="/hr/employee/info/personal-info"
        component={PersonalInfo}
    />
    <Route
        exact
        path="/hr/employee/info/education"
        component={Education}
    />
    <Route
        exact
        path="/hr/employee/info/family-info"
        component={FamilyInfo}
    />
    <Route
        exact
        path="/hr/employee/info/work-experience"
        component={WorkExperience}
    />
    {/* <Route
        path="/employee/personal-info"
        exact
        component={PersonalInfoF}
    /> */}
    <Route
        exact
        path="/hr/employee/personal-info"
        component={PersonalInfo}
    />
    <Route
        exact
        path="/hr/employee/education"
        component={Education}
    />
    <Route
        exact
        path="/hr/employee/home"
        component={EmployeeHome}
    />
    <Route
        exact
        path="/hr/employee/family-info"
        component={FamilyInfo}
    />
    <Route
        exact
        path="/hr/employee/work-experience"
        component={WorkExperience}
    />
    <Route
        exact
        path="/hr/employee/leave-application-emp"
        component={LeaveApplicationEmp}
    />

    {/* <Route
        exact
        path="/employee"
        component={() => (
        <Redirect
            to={
            "/employee/" +
            this.props.data["_id"] +
            "/personal-info"
            }
        />
        )}
    /> */}

    <Route
        component={NotFound}
    />
</Switch>
)


export default HrDashboardRouter
             