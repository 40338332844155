import React, { Component } from 'react';
import Calculator from "./component/Calculator";
import "./SimpleCalculator.css";

class SimpleCalculator extends Component {
    constructor(props, ...rest) {
        super(props, ...rest);
        this.state = {};
    }

    render() {
        return (
            <div>
                <Calculator />
            </div>
        );
    }
}


export default SimpleCalculator;