import React, { Component, useState } from 'react';
import { connect } from 'react-redux'

import Router from './LandingPage.Router';
import Header from './components/Header';
import Footer from './components/Footer';
import { getCart, createGuest } from '../../actions/community';

import styles from './LandingPage.css'
import style from 'react-awesome-modal/lib/style';

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.me = sessionStorage.getItem('my-community-data') ? JSON.parse(sessionStorage.getItem('my-community-data')) : '';
  }

  // componentDidMount() {
  //   if (!!this.me) {
  //     this.props.getCart()
  //   } else {
  //     this.props.createGuest()
  //   }
  // }

  render() {
    return (
      <div className={styles.app}>
        <Header cart={this.props.cart} guest={this.props.guest}/>
        <div className="router">
          <Router />
        </div>
        <div>
        <Footer />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ community }) => {
  const {
      cart,
      guest
    } = community
  return {
      cart,
      guest
  }
}

const actions = {
  getCart, 
  createGuest
}

export default connect(mapStateToProps, actions)(LandingPage)