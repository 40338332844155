import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom'

// Components
import CommunityView from './CommunityView/CommunityView'
import AddMember from './AddMember/AddMember'
import MemberView from './MemberView/MemberView'
import NotFound from '../../../NotFound/NotFound'
import Paywall from './AddMember/Paywall/Paywall';
import VerifyPage from './VerifyPage/VerifyPage';

const CommunityPageRouter = props => (
  <Switch>
    <Route
      exact
      path="/community"
      render={() => <CommunityView
        props={props}
      />
      }
    />

    <Route
      exact
      path="/community/add"
      render={() => <AddMember
        props={props}
      />
      }
    />

    <Route
      exact
      path="/community/:id"
      render={() => <MemberView
        props={props}
      />
      }
    />

    <Route
      path="/community/add/verify"
      render={() => <VerifyPage
          content={props.verify}
          onClickAway={() => window.location.replace('/community/add')} />
        }
      />

    <Route
      path="/community/add/paywall"
      render={() => <Paywall />
      }
    />

    <Route
      component={NotFound}
    />
  </Switch>
)


export default CommunityPageRouter
