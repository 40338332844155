import React, { Component } from 'react';
import Router from './VideoPage.Router';
import styles from './VideoPage.css'

class VideoPage extends Component {
    constructor (props) {
      super (props) 
      this.state = {
        // clientId: randomID()
      }
    }
    
    render() {
      // cookies.set('valhalla', this.state.clientId)
      // sock(this.state.clientId)
      return (
        <div className={styles.app}>
            <Router />
        </div>
      );
    }
  }
  
  export default VideoPage;