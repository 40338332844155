import React, { Component } from 'react';
import Router from './ProjectsPage.Router';
import styles from './ProjectsPage.css';

class Projects extends Component {
  constructor(props, ...rest) {
    super(props, ...rest);
    this.state = {};
  }

  render() {
    return (
      <div className={styles.body}>
        <Router />
      </div>
    );
  }
}


export default Projects;