// Router
import { push } from 'react-router-redux'

// Types
import { productTypes } from "./types"

// Services
import { products } from '../services'

// Helpers
import { updateItemWithArray, updateArrayWithItem } from '../lib'

import * as cookies from 'js-cookie'

const {
    GET_PRODUCTS,
    GET_PRODUCTS_FAILURE,
    GET_PRODUCTS_SUCCESS,

    GET_GENDER,
    GET_GENDER_FAILURE,
    GET_GENDER_SUCCESS,

    GET_LENGTH,
    GET_LENGTH_FAILURE,
    GET_LENGTH_SUCCESS,

    GET_SIZE,
    GET_SIZE_FAILURE,
    GET_SIZE_SUCCESS,

    ADD_QUANTITIES,
    ADD_QUANTITIES_SUCCESS,
    ADD_QUANTITIES_FAILURE,

    SUB_QUANTITIES,
    SUB_QUANTITIES_SUCCESS,
    SUB_QUANTITIES_FAILURE,

    CREATE_PRODUCT,
    CREATE_PRODUCT_FAILURE,
    CREATE_PRODUCT_SUCCESS,
  
    PATCH_PRODUCT,
    PATCH_PRODUCT_FAILURE,
  
    DELETE_PRODUCT,
    DELETE_PRODUCT_FAILURE,
    DELETE_PRODUCT_SUCCESS,
    
    SET_CHECKED_PRODUCTS,
    FILTER_PRODUCTS,
    FILTER_SUCCESS,
    FILTER_FAILURE,
    
} = productTypes

const me = cookies.get('me') ? JSON.parse(cookies.get('me')) : {}
const { id, company } = me
const uid=id,cid=company;

export const addQuantities = () => dispatch => {
  dispatch({
    type: ADD_QUANTITIES,
  })
  products.getProducts()
    .then(res => onAddQuantitesSuccess({dispatch, res}))
    .catch(err => onAddQuantitiesFailure({dispatch, err}))
}

function onAddQuantitesSuccess({dispatch, res}) {
  console.log('addQuantites success');
  dispatch({
    type: ADD_QUANTITIES_SUCCESS,
    payload: res,
  })
}

function onAddQuantitiesFailure({dispatch, err}) {
  console.log('addQuantities failure', err);
  const payload = err.message || 'addQuantities Failed'
  window.alert(payload)
  dispatch({
    type: ADD_QUANTITIES_FAILURE,
    payload: err,
  })
}

export const subQuantities = () => dispatch => {
  dispatch({
    type: SUB_QUANTITIES,
  })
  products.getProducts()
    .then(res => onSubQuantitesSuccess({dispatch, res}))
    .catch(err => onSubQuantitiesFailure({dispatch, err}))
}

function onSubQuantitesSuccess({dispatch, res}) {
  console.log('subQuantites success');
  dispatch({
    type: SUB_QUANTITIES_SUCCESS,
    payload: res,
  })
}

function onSubQuantitiesFailure({dispatch, err}) {
  console.log('addQuantities failure', err);
  const payload = err.message || 'subQuantities Failed'
  window.alert(payload)
  dispatch({
    type: SUB_QUANTITIES_FAILURE,
    payload: err,
  })
}


export const getProducts = () => dispatch => {
  dispatch({
    type: GET_PRODUCTS,
  })
  products.getProducts(cid,uid)
    .then(res => onGetProductsSuccess({dispatch, res}))
    .catch(err => onGetProductsFailure({dispatch, err}))
}

export const onGetProductsSuccesss = (id) => dispatch => {
    dispatch({
      type: GET_PRODUCTS_SUCCESS,
    })
    products.getProducts(id)
      .then(res => onGetProductsSuccess({dispatch, res}))
      .catch(err => onGetProductsFailure({dispatch, err}))
  }
  
  function onGetProductsSuccess({dispatch, res}) {
    console.log('getProducts success');
    dispatch({
      type: GET_PRODUCTS_SUCCESS,
      payload: res.data,
    })
  }
  
  function onGetProductsFailure({dispatch, err}) {
    console.log('getProducts failure', err);
    const payload = err.message || 'getProducts Failed'
    window.alert(payload)
    dispatch({
      type: GET_PRODUCTS_FAILURE,
      payload,
    })
  }
  
  // export const getGender = () => dispatch => {
  //   dispatch({
  //     type: GET_GENDER,
  //   })
  //   products.getGender()
  //     .then(res => onGetGenderSuccess({dispatch, res}))
  //     .catch(err => onGetGenderFailure({dispatch, err}))
  // }
  
  // function onGetGenderSuccess({dispatch, res}) {
  //   console.log('getGender success');
  //   dispatch({
  //     type: GET_GENDER_SUCCESS,
  //     payload: res,
  //   })
  // }
  
  // function onGetGenderFailure({dispatch, err}) {
  //   console.log('getGender failure', err);
  //   const payload = err.message || 'getGender Failed'
  //   window.alert(payload)
  //   dispatch({
  //     type: GET_GENDER_FAILURE,
  //     payload,
  //   })
  // }

  // export const getLength = () => dispatch => {
  //   dispatch({
  //     type: GET_LENGTH,
  //   })
  //   products.getLength()
  //     .then(res => onGetLengthSuccess({dispatch, res}))
  //     .catch(err => onGetLengthFailure({dispatch, err}))
  // }
  
  // function onGetLengthSuccess({dispatch, res}) {
  //   console.log('getLength success');
  //   dispatch({
  //     type: GET_LENGTH_SUCCESS,
  //     payload: res,
  //   })
  // }
  
  // function onGetLengthFailure({dispatch, err}) {
  //   console.log('getLength failure', err);
  //   const payload = err.message || 'getLength Failed'
  //   window.alert(payload)
  //   dispatch({
  //     type: GET_LENGTH_FAILURE,
  //     payload,
  //   })
  // }

  // export const getSize = () => dispatch => {
  //   dispatch({
  //     type: GET_SIZE,
  //   })
  //   products.getSize()
  //     .then(res => onGetSizeSuccess({dispatch, res}))
  //     .catch(err => onGetSizeFailure({dispatch, err}))
  // }
  
  // function onGetSizeSuccess({dispatch, res}) {
  //   console.log('getSize success');
  //   dispatch({
  //     type: GET_SIZE_SUCCESS,
  //     payload: res,
  //   })
  // }
  
  // function onGetSizeFailure({dispatch, err}) {
  //   console.log('getSize failure', err);
  //   const payload = err.message || 'getSize Failed'
  //   window.alert(payload)
  //   dispatch({
  //     type: GET_SIZE_FAILURE,
  //     payload,
  //   })
  // }
  
  export const bulkPatchProducts = update => (dispatch, getState) => {
    dispatch({
      type: GET_PRODUCTS,
    })
    products.bulkPatch(update)
      .then(res => onBulkPatchProductsSuccess({dispatch, getState, res}))
      .catch(err => onBulkPatchProductsFailure({dispatch, err}))
  }
  
  function onBulkPatchProductsSuccess({dispatch, getState, res}) {
    console.log('BulkPatchProducts success');
    const { products, productsDisplay } = getState().product
  
    const updateProduct = product => updateItemWithArray(product, res)
  
    dispatch({
      type: GET_PRODUCTS_SUCCESS,
      payload: products.map(updateProduct),
    })
    dispatch({
      type: FILTER_PRODUCTS,
      payload: productsDisplay.map(updateProduct),
    })
  }
  
  function onBulkPatchProductsFailure({dispatch, err}) {
    console.log('BulkPatchProducts failure', err);
    const payload = err.message || 'BulkPatchProducts Failed'
    window.alert(payload)
    dispatch({
      type: GET_PRODUCTS_FAILURE,
      payload,
    })
  }
  
  export const createProduct = data => (dispatch, getState) => {
    dispatch({
      type: CREATE_PRODUCT,
    })
    products.post(data)
      .then(res => onCreateProductSuccess({dispatch, getState, res}))
      .catch(err => onCreateProductFailure({dispatch, err}))
  }
  
  function onCreateProductSuccess({dispatch, getState, res}) {
    console.log('createProduct success');
    // const { products } = getState()
    dispatch({
      type: CREATE_PRODUCT_SUCCESS,
      payload: res
    })
    dispatch(push('/products'))
  }
  
  function onCreateProductFailure({dispatch, err}) {
    console.log('createProduct failure', err);
    const payload = err.message || 'CreateProduct Failed'
    window.alert(payload)
    dispatch({
      type: CREATE_PRODUCT_FAILURE,
      payload,
    })
  }
  
  export const patchProductById = (id, update) => (dispatch, getState) => {
    dispatch({
      type: PATCH_PRODUCT,
    })
    products.patchById(id, update)
      .then(res => onPatchProductSuccess({dispatch, getState, res}))
      .catch(err => onPatchProductFailure({dispatch, err}))
  }
  
  export const patchProductStatus = update => (dispatch, getState) => {
    dispatch({
      type: PATCH_PRODUCT,
    })
    products.patchStatus(update)
      .then(res => onPatchProductSuccess({dispatch, getState, res}))
      .catch(err => onPatchProductFailure({dispatch, err}))
  }
  
  function onPatchProductSuccess({dispatch, getState, res}) {
    console.log('patchProduct success');
    let { products, productsDisplay } = getState().products
    dispatch({
      type: GET_PRODUCTS_SUCCESS,
      payload: updateArrayWithItem(products.products, res)
    })
    dispatch({
      type: FILTER_PRODUCTS,
      payload: updateArrayWithItem(productsDisplay.products, res),
    })
  }
  
  function onPatchProductFailure({dispatch, err}) {
    console.log('patchProduct failure', err)
    const payload = err.message || 'PatchProduct Failed'
    window.alert(payload)
    dispatch({
      type: PATCH_PRODUCT_FAILURE,
      payload,
    })
  }
  
  export const deleteProductById = id => (dispatch, getState) => {
    dispatch({
      type: DELETE_PRODUCT,
    })
    products.deleteById(id)
      .then(res => onDeleteProductSuccess({dispatch, getState}))
      .catch(err => onDeleteProductFailure({dispatch, err}))
  }
  
  // TODO: REFACTOR - HOT RELOAD
  function onDeleteProductSuccess({dispatch, getState}) {
    console.log('DeleteProduct success');
    const product = getState().products.products.products
    // const products = product.filter(el => el.id !== product.id)
  
    dispatch({
      type: DELETE_PRODUCT_SUCCESS,
      payload: product,
    })
  }
  
  function onDeleteProductFailure({dispatch, err}) {
    console.log('DeleteProduct failure', err)
    const payload = err.message || 'DeleteProduct Failed'
    window.alert(payload)
    dispatch({
      type: DELETE_PRODUCT_FAILURE,
      payload,
    })
  }
  
  // export const filterProductsByStatus = () => (dispatch, getState) => {
  //   const { barcode } = getState().products.barcode
  //   const { products } = getState().products 
  //   const payload = barcode ? (
  //     products.filter(el => el.barcode === barcode)
  //   ) : (
  //     [...products]
  //   )
  //   dispatch({
  //     type: FILTER_PRODUCTS,
  //     payload
  //   })
  // }

  export const filterProductsByStatus = (search, searchType) => (dispatch, getState) => {
    console.log(search)
    dispatch({
      type: FILTER_PRODUCTS,
    })
    products.filterProductsByStatus(search)
      .then(res => onFilterSuccess({dispatch, getState, res}))
      .catch(err => onFilterFailure({dispatch, err})) 
  }

  function onFilterSuccess({dispatch, getState, res}) {
    console.log('search success');
    let { productsDisplay } = getState().product
    dispatch({
      type: FILTER_SUCCESS,
      payload: (productsDisplay, res),
    })
  }
  
  function onFilterFailure({dispatch, err}) {
    console.log('search failure', err);
    const payload = err.message || 'search failed'
    window.alert(payload)
    dispatch({
      type: FILTER_FAILURE,
      payload,
    })
  }

  export const checkProduct = ({id}) => (dispatch, getState) => {
    const { checkedProducts } = getState().product
    let payload = []
    if (checkedProducts.includes(id)) {
      payload = checkedProducts.filter(el => el !== id)
    } else {
      payload = [...checkedProducts, id]
    }
  
    dispatch({
      type: SET_CHECKED_PRODUCTS,
      payload,
    })
  }