import React from 'react';
import cn from 'classnames'

import TabsSelector from './TabsSelector/TabsSelector'

import styles from './SwitchTable.css'

const SwitchTable = ({ 
  tabs, 
  centered,
  selected, 
  className,
  onClick 
}) => {
  const miscClasses = {
    centered
  }
  const classNames = cn(
    'switch-table', 
    styles.switchContainer,
    { ...miscClasses },
    className
  )
  return (
    <div className={classNames}>
      {/* floats above the main */}
      <TabsSelector
        tabs={tabs}
        selected={selected.value}
        onClick={onClick}
      />
      <div className="main">
        {selected.renderComponent()}
      </div>
    </div>
  )
}

export default SwitchTable
