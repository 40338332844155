// TEAM FLOW
export const CREATE_SUPERVISOR = 'create_supervisor'
export const CREATE_SUPERVISOR_SUCCESS = 'create_supervisor_success'
export const CREATE_SUPERVISOR_FAILURE = 'create_supervisor_failure'

export const SET_SUPERVISOR = 'set_supervisor'

export const GET_SUPERVISOR = 'get_supervisor'
export const GET_SUPERVISOR_SUCCESS = 'get_supervisor_success'
export const GET_SUPERVISOR_FAILURE = 'get_supervisor_failure'

export const GET_SUPERVISORS = 'get_supervisors'
export const GET_SUPERVISORS_SUCCESS = 'get_supervisors_success'
export const GET_SUPERVISORS_FAILURE = 'get_supervisors_failure'

export const PATCH_SUPERVISOR = 'patch_supervisor'
export const PATCH_SUPERVISOR_SUCCESS = 'patch_supervisor_success'
export const PATCH_SUPERVISOR_FAILURE = 'patch_supervisor_failure'

export const CREATE_NOTE = 'create_note'
export const CREATE_NOTE_SUCCESS = 'create_note_success'
export const CREATE_NOTE_FAILURE = 'create_note_failure'

export const DELETE_SUPERVISOR = 'delete_supervisor'
export const DELETE_SUPERVISOR_SUCCESS = 'delete_supervisor_success'
export const DELETE_SUPERVISOR_FAILURE = 'delete_supervisor_failure'

export const CLEAR_ERRORS = 'supervisor_clear_errors'
export const CLEAR_STATE = 'supervisor_clear_state'
