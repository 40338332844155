import React, { Component } from 'react';
import { connect } from 'react-redux';
import { removeFromCart } from '../../../../actions/community';
import { formatCurrencyOptions } from '../../../../lib';

import {
  Selector,
  Button,
  Header,
  Body,
  Input,
  Form,
  FormModal,
  FileUpload,
  Icon,
  Table,
} from '../../../common'
import styles from './CartRow.css'

// one for each item
class CartRow extends Component {
  constructor(props) {
    super(props);
    this.state = {  }
  }

  removeFrom (item) {
    this.props.removeFromCart(item)
  }

  renderIcons(item) {
    return (
      <td className="icons">
        <Icon
          small
          cancel
          onClick={() => this.removeFrom(item)}
        />
      </td>
    )
  }

  render() {
    const { item } = this.props
    return (
      <tr className={styles.row}>
        {
        // <td className="checkbox">
        //   <Input
        //     type="checkbox"
        //     className="checkbox"
        //     value={checked}
        //     onChange={() => this.setState{ itemChecked }}
        //   />
        // </td>
  }
        <td>
          {item.name}
        </td>
        <td className="photos">
          {item.photo && item.photo.length ? (
            item.photo.map(this.renderPhotoLink.bind(this))
          ) : (
            'No Photos'
          )}
        </td>

        <td className="photos">
          {item.document && item.document.length ? (
            item.document.map(this.renderPhotoLink.bind(this))
          ) : (
            'No Documents'
          )}
        </td>
        <td>
          {item.description}
        </td>
        <td>
          {item.price.toLocaleString(...formatCurrencyOptions)}
        </td>
        <td>
          {item.quantity}
        </td>
        {this.renderIcons(item)}
      </tr>
    )
  }
}
const mapStateToProps = ({ community }) => {
  const {
      cart,
      guest
    } = community
  return {
      cart,
      guest
  }
}

const actions = {
  removeFromCart
}

export default connect(mapStateToProps, actions)(CartRow)