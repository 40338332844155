import React, { Component } from 'react';
import { connect } from 'react-redux'
import validator from 'validator'
import * as cookies from 'js-cookie';

import {
  Selector,
  Button,
  Header,
  Body,
  Input,
  Form,
} from '../../../common'

// Actions
import {
  getEmployees,
} from '../../../../actions/main'

import {
  getSupervisors,
} from '../../../../actions/supervisors'

import {
  createAccount,
} from '../../../../actions/accounts'

import styles from './AddAccount.css'
import { hasAuthTokenAsync } from '../../../../lib';

class AddAccount extends Component {
  constructor(props) {
    super(props)
    this.state = {
      company: '',
      user: '',
      accessToken: '',
      item: '',
      institutionId: '',
      institution: '',
      accountName: '',
      accountSubtype: '',
      me: cookies.get('me') && JSON.parse(cookies.get('me')) ? JSON.parse(cookies.get('me')) : undefined 
    }
  }



  onSubmit(e) {
    e.preventDefault()
    const {
      accessToken,
      item,
      institutionId,
      institution,
      accountName,
      accountSubtype,
      me
    } = this.state
    const data = {
      company: me.company,
      user: me.id,
      paymentMethod: this.props.payment,
      accessToken,
      item,
      institutionId,
      institution,
      accountName,
      accountType: this.props.payment.type,
      accountSubtype
    }
    // console.log('onSubmit', data)
    this.props.createAccount(data)
  }

  componentDidMount() {
    hasAuthTokenAsync()
      .then(() => {
        // this.props.getSelf()
      })
      .catch(console.log)
  }

  render() {
    const {
      paymentMethod,
      accessToken,
      item,
      institutionId,
      institution,
      accountName,
      accountType,
      accountSubtype,
    } = this.state

    const {
      onViewModal
    } = this.props

    const invalid = (
      !paymentMethod
      || !accountName
      || !accountType
    )

    return (
      <div>
        <Header>
          <h3>Add a New Payment Method</h3>
        </Header>
        <Body className={styles.body}>
          <Form
            className="form"
            onSubmit={this.onSubmit.bind(this)}
          >
            <br />
            <label>
              Account Name:
            </label>
            <Input
              placeholder="Bank Account 1"
              value={accountName}
              onChange={accountName => this.setState({ accountName })}
            />
            <br />
            <label>
              Institution:
            </label>
            <Input
              placeholder="U.S. Bank, Fidelity Investments, J.P. Morgan/Chase...."
              value={institution}
              onChange={institution => this.setState({ institution })}
            />
            <br />
            {this.props.payment.type}
            <Button
              alt
              icon="plus"
              title="ADD PAYMENT METHOD"
              onClick={onViewModal}
            />

            <br />

            <div className="buttons">
              <Button
                large
                cancel
                link="/team"
                title="CANCEL"
              />
              <Button
                large
                type="submit"
                title="CREATE ACCOUNT"
                inactive={invalid}
                onClick={this.onSubmit.bind(this)}
              />
            </div>
          </Form>
        </Body>
      </div>
    );
  }
}

const mapStateToProps = ({ main, supervisors }) => {
  const {
    loading,
    employeesDisplay
  } = main
  
  const {
    supervisorsDisplay
  } = supervisors

  return {
    loading,
    employeesDisplay,
    superDisplay: supervisorsDisplay
  }
}

const actions = {
  createAccount,
  getEmployees,
  getSupervisors
}

export default connect(mapStateToProps, actions)(AddAccount)
