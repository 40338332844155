import { api } from '../config'

export default {
  getAll() {
    const url = '/accounts'
    return api.get(url)
  },

  post(data, id) {
    const url = `/accounts/${id}`
    return api.post(url, data)
  },

  fundAccount(aid, cid, data) {
    const url = `/fund/${aid}/${cid}`
    return api.put(url, data)
  },

  getById(cid) {
    const url = `/accounts/${cid}`
    return api.get(url)
  },

  patchById(aid, cid, update) {
    const url = `/accounts/${aid}/${cid}`
    return api.put(url, update)
  },


  deleteById(id) {
    const url = `/accounts/${id}`
    return api.delete(url, id)
  }
}
