// TEAM FLOW
export const CREATE_TEAM_MEMBER = 'team_create_member'
export const CREATE_TEAM_MEMBER_SUCCESS = 'team_create_member_success'
export const CREATE_TEAM_MEMBER_FAILURE = 'team_create_member_failure'

export const PATCH_TEAM_MEMBER = 'team_patch_member'
export const PATCH_TEAM_MEMBER_SUCCESS = 'team_patch_member_success'
export const PATCH_TEAM_MEMBER_FAILURE = 'team_patch_member_failure'

export const GET_TEAMS = 'get_teams'
export const GET_TEAMS_SUCCESS = 'get_teams_success'
export const GET_TEAMS_FAILURE = 'get_teams_failure'

export const SET_TEAM_MEMBER = 'team_set_member'
export const GET_TEAM_MEMBER = 'team_get_member'
export const GET_TEAM_MEMBER_SUCCESS = 'team_get_member_success'
export const GET_TEAM_MEMBER_FAILURE = 'team_get_member_failure'

export const DELETE_TEAM= 'delete_team'
export const DELETE_TEAM_SUCCESS = 'delete_team_success'
export const DELETE_TEAM_FAILURE = 'delete_team_failure'

export const GET_EXPENSES = 'team_get_expenses'
export const GET_EXPENSES_SUCCESS = 'team_get_expenses_success'
export const GET_EXPENSES_FAILURE = 'team_get_expenses_failure'

export const CREATE_EXPENSE = 'team_create_expense'
export const CREATE_EXPENSE_SUCCESS = 'team_create_expense_success'
export const CREATE_EXPENSE_FAILURE = 'team_create_expense_failure'

export const CLEAR_STATE = 'team_clear_state'
export const CLEAR_ERRORS = 'team_clear_errors'
