const { NODE_ENV } = process.env

const isProd = true;

// const prod = 'myoyovh7m1ecpxqpimifwzvii.muvor.xyz'
const prod = 'api.muvor.com'
// const local = '127.0.0.1' 
const local = '192.168.0.199'

// const uri = `https://${prod}`
const uri = isProd ?`https://${prod}` : `http://${local}:9000`

export default uri
