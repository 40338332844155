// React
import React, { Component } from 'react';

// Redux
import { connect } from 'react-redux'

// JWT
import * as jwt from 'jsonwebtoken'

// Cookies
import * as cookies from 'js-cookie'

// 3rd Party Libraries
import qs from 'query-string'
import {
  passwordChanged,
  passwordConfirmChanged,
  createPassword,
  accessChanged,
} from '../../../actions/auth'

// Components
import {
  Form,
  Input,
  ErrorMessage,
  FloatBox,
  Button,
}  from '../../common'

// Styles
import styles from './CreatePassword.css'

class CreatePassword extends Component { 
  constructor(props) {
    super(props);
    this.state = {}
  }
  componentDidMount() {
  }
  handleSubmit(e) {
    e.preventDefault();
    const {
      password, 
      accessCode,
      match: { params }
    } = this.props;
    const {
      userId,
      token
    } = params;
    const decoded = jwt.verify(token, this.props.accessCode + '-' + userId);
    if (toString(decoded._id) === toString(userId)) {
      // clear local storage so there is no conflicting token
      localStorage.clear();
      sessionStorage.clear();
      cookies.remove('token');
      cookies.remove('me');
      const data = {
        userId,
        token,
        password,
        accessCode
      }
      this.props.createPassword({ data });
    }
  }

  render() {
    const {
      match: { params },
      error,
      accessCode,
      password,
      passwordConfirm,
      loading,
    } = this.props
    const {
      token
    } = params
    const inactive = (
      ! accessCode
      || !password
      || !passwordConfirm
      || password.length < 6
      || loading
      || (password.trim() !== passwordConfirm.trim())
    )
    return (
      <div className={styles.container}>
        <FloatBox>
          <h2>Create Password</h2>
          <p>Create your password to verify your account</p>
          <Form className="body" onSubmit={this.handleSubmit.bind(this)}>
            <Input
              wide
              placeholder="Access Code"
              value={accessCode}
              type="text"
              onChange={this.props.accessChanged.bind(this)}
            />
            <Input
              wide
              placeholder="Password"
              value={password}
              type="password"
              onChange={this.props.passwordChanged.bind(this)}
            />
            <Input
              wide
              placeholder="Confirm Password"
              value={passwordConfirm}
              type="password"
              onChange={this.props.passwordConfirmChanged.bind(this)}
            />
            <Button
              type="submit"
              className="button"
              title="Verify"
              inactive={inactive}
            />
            <ErrorMessage error={error}/>
          </Form>
        </FloatBox>
      </div>
    );
  }
}

const mapStateToProps = ({auth}) => {
  const {
    accessCode,
    passwordConfirm,
    password,
    error,
    loading,
  } = auth

  return {
    accessCode,
    passwordConfirm,
    password,
    error,
    loading,
  }
}

const actions = {
  accessChanged,
  passwordConfirmChanged,
  passwordChanged,
  createPassword,
}

export default connect(mapStateToProps, actions)(CreatePassword)
