// Router
import { push } from 'react-router-redux'

// Types
import { stripeTypes } from './types'

// Cookies
import * as cookies from 'js-cookie'
import * as jwt from 'jsonwebtoken'

// Services
import { stripes } from '../services'

const {
  CREATE_STRIPE,
  CREATE_STRIPE_FAILURE,
  CREATE_STRIPE_SUCCESS,
  SET_STRIPE,
  GET_STRIPE,
  GET_STRIPE_FAILURE,
  GET_STRIPE_SUCCESS,
  GET_PLAN,
  GET_PLAN_FAILURE,
  GET_PLAN_SUCCESS,
  PATCH_STRIPE,
  PATCH_STRIPE_FAILURE,
  PATCH_STRIPE_SUCCESS,
  CLEAR_ERRORS,
  CLEAR_STATE,
} = stripeTypes

export const createStripe = data => {
  stripes.charge(data)
    .then(res => onCreateStripeSuccess({res}))
    .catch(err => onCreateStripeFailure({err}))
}

function onCreateStripeSuccess({res}) {
  console.log('createStripe success');
  return [res]
}

function onCreateStripeFailure({err}) {
  console.log('createStripe failure', err);
  const payload = err.message || 'CreateStripe Failed'
  window.alert(payload)
  return payload
}

export const setStripe = payload => {
  return {
    type: SET_STRIPE,
    payload,
  }
}

export const getStripe = () => dispatch => {
  dispatch({
    type: GET_STRIPE,
  })
  stripes.getAll()
    .then(res => onGetStripeSuccess({dispatch, res}))
    .catch(err => onGetStripeFailure({dispatch, err}))
}

function onGetStripeSuccess({dispatch, res}) {
  const { stripe } = res
  dispatch({
    type: GET_STRIPE_SUCCESS,
    payload: res,
  })
}

function onGetStripeFailure({dispatch, err}) {
  console.log('getStripe failure', err);
  const payload = err.message || 'GetStripe Failed'
  window.alert(payload)
  dispatch({
    type: GET_STRIPE_FAILURE,
    payload,
  })
  dispatch(push('/login'))
}

// Synchronous
export const getPlan = () => dispatch => {
  dispatch({
    type: GET_PLAN,
  })
  Promise.resolve()
    .then(res => onGetPlanSuccess({dispatch, res}))
    .catch(err => onGetPlanFailure({dispatch, err}))
}

function onGetPlanSuccess({dispatch, res}) {
  console.log('getPlan success');
  // let plan = sessionStorage.getItem('plan')
  // let selectedPlan = jwt.verify(plan, 'W4KUU^1337#4(&35')
  // let plan = JSON.stringify(cookies.get('plan') ? cookies.get('plan') : [] ).replace(/\\/g, "").replace(/^\"?|\"?$/g, "")
  // let selectedPlan = plan ? JSON.parse(plan) : plan
  dispatch({
    type: GET_PLAN_SUCCESS,
    payload: res,
  })
}

function onGetPlanFailure({dispatch, err}) {
  console.log('getStripe failure', err);
  const payload = err.message || 'GetStripe Failed'
  window.alert(payload)
  dispatch({
    type: GET_PLAN_FAILURE,
    payload,
  })
  dispatch(push('/login'))
}

export const patchStripeById = (id, update) => (dispatch, getState) => {
  dispatch({
    type: PATCH_STRIPE,
  })
  stripes.patchById(id, update)
    .then(res => onPatchStripeSuccess({dispatch, res}))
    .catch(err => onPatchStripeFailure({dispatch, err}))
}

function onPatchStripeSuccess({dispatch, res}) {
  console.log('patchStripe success');
  dispatch({
    type: PATCH_STRIPE_SUCCESS,
    payload: res.stripe,
  })
}

function onPatchStripeFailure({dispatch, err}) {
  console.log('patchStripe failure', err)
  const payload = err.message || 'PatchStripe Failed'
  window.alert(payload)
  dispatch({
    type: PATCH_STRIPE_FAILURE,
    payload,
  })
}

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  }
}

export const clearState = () => {
  return {
    type: CLEAR_STATE,
  }
}