import React, { Component } from 'react';
import pdf from '../mvrxwp-draft.pdf'
import styles from './pdfReader.css'

class pdfReader extends Component {
  constructor(props) {
    super(props);
    this.state = {  }
  }
  render() { 
    return ( 
        <iframe className={styles.pdf} src={pdf} />
     );
  }
}
 
export default pdfReader;