import React, { Component } from 'react';
import Router from './CalculatorPage.Router';
import CalcNav from './CalcNav/CalcNav'

import styles from './CalculatorPage.css'

class CalculatorPage extends Component {
  render() {
    return (
      <div className={styles.app}>
        <CalcNav />
          <div className="main">
            <Router />
        </div>
      </div>
    );
  }
}

export default CalculatorPage;
