const expenseStatusOptions = [
  'Gas',
  'Parking',
  'Printing',
  'Lunch',
  'Entertainment',
  'Travel',
  'Dinner',
]

export default expenseStatusOptions
