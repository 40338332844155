import React, { Component } from 'react';
import { connect } from 'react-redux'
import * as jwt from 'jsonwebtoken'
import * as cookies from 'js-cookie'
import { 
  Button,
  Form,
  Header,
  Input,
  FloatBox
} from '../../../common'
import Utils from '../../../../lib/utils';
import ModelValidations from '../../../../lib/model-validations';
import FormValidationErrors from '../../../../lib/form-validation-errors';
import FormSubmitErrors from '../../../../lib/form-submit-errors';
import { createCompany } from '../../../../actions/companys'

// Styles
import styles from './RegisteryMain.css'
import axios from '../../../../config/api';
import { api } from '../../../../config';

class RegisteryMain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      company: '',
      name: '',
      email: '',
      password: '',
      passwordConfirm: '',

      validation: {
        company: {
          valid: false,
          touched: false,
          message: 'Company Name is required'
        },
        name: {
          valid: false,
          touched: false,
          message: 'Full Name is required'
        },
        email: {
          valid: false,
          touched: false,
          message: ModelValidations.email.regex.message
        },
        password: {
          valid: false,
          touched: false,
          message: ModelValidations.password.minLength.message
        },
        passwordConfirm: {
          valid: false,
          touched: false,
          message: 'Password confirm does not match.'
        },
        formValid: false
      }
    };
  }

  componentDidMount() {
    Utils.focusFirstInput();
  }

  get initalState() {
    return {
      company: '',
      name: '',
      email: '',
      password: '',
      passwordConfirm: ''
    }
  }

  get invalid() {
    const {
      // company,
      name,
      email = (ModelValidations.email.regex.value).test(email),
      password,
      passwordConfirm
    } = this.state

    return !(
      // company.length > 0
      name.length > 0
      && !this.props.loading
      && email
      && password.length >= ModelValidations.password.minLength.value
      && passwordConfirm === this.state.password
    )
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.invalid) {
      return
    }
    
    const {
      company = encodeURIComponent(this.state.company),
      name = encodeURIComponent(this.state.name),
      email = encodeURIComponent(this.state.email),
      password = encodeURIComponent(this.state.password),
      // passwordConfirm = encodeURIComponent(this.state.passwordConfirm)
    } = this.state

    sessionStorage.setItem('domain', company)
    const cliLat = localStorage.getItem('cli_lat')
    const cliLong = localStorage.getItem('cli_long')
    // const cliCoords = localStorage.getItem('cli_coords')
    const cliGPU = localStorage.getItem('cli_gpu')
    const cliRAM = localStorage.getItem('cli_ram')

    const games = { cliLat, cliLong, cliGPU, cliRAM/**, cliCoords */}
    // window.alert(sessionStorage.getItem('plan'))
    // window.alert(jwt.verify(sessionStorage.getItem('plan'), 'W3<34!!_M_^09_H4Q!7%!', { algorithm: ['HS512'] }))

    const data = {
      stripe_id: sessionStorage.getItem('stripe_id') ? JSON.parse(sessionStorage.getItem('stripe_id')) : '',
      amount: sessionStorage.getItem('plan'),
      quantity: sessionStorage.getItem('quantity'),
      company,
      name,
      email,
      password,
      games
    }
    this.props.createCompany(jwt.sign(data, 'M_^09XW4kUU!}}+', { algorithm: 'HS512' }));
  }

  render() {
    const {
      validation,
      company, 
      name,
      email,
      password,
      passwordConfirm
    } = this.state

    return (
      <div className={styles.container}>
        <FloatBox>
          <h2>Register</h2>
          <Form 
            className="form"
            onSubmit={this.onSubmit.bind(this)}>
          {
          // <h4>Sign Up {this.props.company.name && `- ${this.props.company.name}`}</h4>
          }
            <Input 
              placeholder="Company Name"
              value={company}
              onChange={company => this.setState({ company })} />
            <Input 
              placeholder="Full Name"
              value={name}
              onChange={name => this.setState({ name })} />
            <Input
              placeholder="Email" 
              value={email}
              onChange={email => this.setState({ email })} />
            <Input
              type="password"
              placeholder="Password"
              value={password}
              onChange={password => this.setState({ password })} />
            <Input 
              type="password" 
              placeholder="Confirm Password" 
              autoComplete="off" 
              value={passwordConfirm}
              onChange={passwordConfirm => this.setState({ passwordConfirm })} />
            <Button
              className={styles.btnReg}
              type="submit" 
              title="REGISTER"
              inactive={this.invalid || this.props.loading}
              onClick={this.onSubmit.bind(this)} />
          </Form>
            <FormValidationErrors validation={validation} />
            <FormSubmitErrors errors={this.state.errors} />
        </FloatBox>
      </div>
    );
  }
}

const mapStateToProps = ({ companys }) => {
  const {
    company,
    name,
    email,
    password,
    error,
    loading, 
  } = companys

    return {
      company,
      name,
      email,
      password,
      error,
      loading, 
  }
}

const actions = {
  createCompany
}

export default connect(mapStateToProps, actions)(RegisteryMain);
