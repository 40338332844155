// TEAM FLOW
export const CREATE_COMPANY = 'create_company'
export const CREATE_COMPANY_SUCCESS = 'create_company_success'
export const CREATE_COMPANY_FAILURE = 'create_company_failure'

export const SET_COMPANY = 'set_company'

export const GET_COMPANY = 'get_company'
export const GET_COMPANY_SUCCESS = 'get_company_success'
export const GET_COMPANY_FAILURE = 'get_company_failure'

export const PATCH_COMPANY = 'patch_company'
export const PATCH_COMPANY_SUCCESS = 'patch_company_success'
export const PATCH_COMPANY_FAILURE = 'patch_company_failure'

export const CLEAR_ERRORS = 'company_clear_errors'
export const CLEAR_STATE = 'company_clear_state'
