export const LIST = 'LIST_PROJECTS';
export const GET = 'GET_PROJECT';

export const CREATE_PROJECT = 'create_project';
export const CREATE_PROJECT_FAILURE = 'create_project_failure';
export const CREATE_PROJECT_SUCCESS = 'create_project_success';
export const SET_PROJECT = 'set_project';
export const GET_PROJECTS = 'get_projects';
export const GET_PROJECTS_FAILURE = 'get_projects_failure';
export const GET_PROJECTS_SUCCESS = 'get_projects_success';
export const GET_PROJECT = 'get_project';
export const GET_PROJECT_FAILURE = 'get_project_failure';
export const GET_PROJECT_SUCCESS = 'get_project_success';
export const PATCH_PROJECT = 'patch_project';
export const PATCH_PROJECT_FAILURE = 'patch_project_failure';
export const PATCH_PROJECT_SUCCESS = 'patch_project_success';
export const DELETE_PROJECT = 'delete_project';
export const DELETE_PROJECT_FAILURE = 'delete_project_failure';
export const DELETE_PROJECT_SUCCESS = 'delete_project_success';
export const CREATE_NOTE = 'create_note';
export const CREATE_NOTE_FAILURE = 'create_note_failure';
export const CREATE_NOTE_SUCCESS = 'create_note_success';
export const CLEAR_ERRORS = 'clear_errors';
export const CLEAR_STATE = 'clear_state';
