import React, { Component } from 'react';
import { connect } from 'react-redux'
import moment from 'moment'

import {
  formatCurrencyOptions,
  designStatusOptions,
  stageTypeOptions,
  hasAuthTokenAsync
} from '../../../../../lib'

// Components
import {
  Tr,
  Span,
  Icon,
  Input,
  Selector,
  ErrorMessage,
  Button
} from '../../../../common'

// Actions
import {
  getProducts,
  filterProductsByStatus,
  addQuantities,
  subQuantities,
  deleteProductById,
  patchProductById,
} from '../../../../../actions/products'

import {
  createAssignment,
} from '../../../../../actions/main'

import styles from './ProductRow.css'

class ProductRow extends Component {
  constructor(props) {
    super(props)
    this.state = this.initialState
  }

  get initialState() {
    const {
      search,
      quantity,
      note,
      cost
    } = this.props

    return {
      editing: false,
      assignmentModal: null,
      searchType: '',
      search,
      quantity,
      note,
      cost
    }
  }

  get formIsInvalid() {
    const {
      quantity,
      note,
      cost
    } = this.state

    return !(
      quantity
      && note
      && cost
    )
  }

  setEditState(editing) {
    this.setState({
      ...this.initialState,
      editing,
    })
  }

  save() {
    var {
      quantity,
      cost,
      note,
    } = this.state

    const regex = "^-?\\d*(\\.\\d+)?$"
    var validUpdate = true

    if (quantity === undefined || quantity.match(regex)){
      if (quantity === "") {
        quantity = undefined
      }
      parseInt(quantity)
      validUpdate = true
    } else {
      window.alert("error: Invalid value found") 
      return validUpdate = false
    }

    if (cost === undefined || cost.match(regex)){
      if (cost === "") {
        cost = undefined
      }
      parseInt(cost) 
      validUpdate = true
    } else {
      window.alert("error: Invalid value found")
      return validUpdate = false
    }

    const update = {
      quantity,
      cost,
      note,
    }

    const id = this.props.product._id

    if (validUpdate === true) {
      this.props.patchProductById(id, update)
    } else {
      return
    }

    this.setState({ editing: false })
  }

  delete() {
    const message = `Are you sure you want to delete this product?`
    const confirmed = window.confirm(message)
    if (!confirmed) {
      return
    }
    const id = this.props.product._id
    this.props.deleteProductById(id)

    console.log(this.props.product._id)
  }

  renderIcons() {
    const { editing } = this.state
    const { isAdmin } = this.props
    if (isAdmin) {
      return null
    } else if (editing) {
      return (
        <td className="icons">
          <Icon
            small
            cancel
            onClick={() => this.setEditState(false)}
          />
          <Icon
            small
            save
            onClick={() => this.save()}
          />
        </td>
      )
    } else {
      return (
        <td className="icons">
          <Icon
            small
            edit
            onClick={() => this.setEditState(true)}
          />
          <Icon
            small
            cancel
            onClick={() => this.delete()}
          />
        </td>
      )
    }
  }

  setAssignmentStatus(status) {
    const { products } = this.props
    const { userId } = products
    const productId = products.id
    this.props.patchAssignmentStatus({ userId, productId, status })
  }

  renderPhotoLink(photo, index) {
    return (
      <a
        className="photo-link"
        href={photo.url}
        target="_blank"
        rel="noopener noreferrer"
        key={photo.id}
      >
        Photo {index + 1}
      </a>
    )
  }


  render(products) {
    const { 
      searchType,
      editing,
      note,
      quantity,
      cost
      } = this.state
    
    const {
      product
    } = this.props

      // <td>
      //   {product.length} 
      // </td>     
      // <td>
      //     {product.size}
      // </td>
      // <td>
      //   {product.color}
      // </td>
      // <td>
      //   {product.gender}
      // </td>
    const link = `/products/${product._id}`
    return (
      <Tr 
        className={styles.row}
        to={link}
        key={product.id}
      >
        <td className="photo">
          <img src={product.photo} />
        </td>
        <td className="bold">
          {product.products}
        </td>
        <td>
          <Span
            editing={editing}
            value={cost}
            placeholder={product.cost}
            onChange={cost => this.setState({ cost })}
          >
            {product.cost}
          </Span>
        </td>
        <td className="bold">
          {product.barcode}
        </td>
        <td>
          <Span
            editing={editing}
            value={quantity}
            placeholder={product.quantity}
            onChange={quantity => this.setState({ quantity })}
          >
          {product.quantity}
          </Span>
        </td>
{
        // <td className="photos">
        //   {photos && photos.length ? (
        //     photos.map(this.renderPhotoLink.bind(this))
        //   ) : (
        //     'No Photos'
        //   )}
        // </td>
}
        <td>
          <Span
            editing={editing}
            value={note}
            placeholder={product.note}
            onChange={note => this.setState({ note })}
          >
            {product.note}
          </Span>
        </td>
        {
        // <td>
        //   {isadmin ? (
        //     <Selector
        //       options={designStatusOptions}
        //       selected={status}
        //       onSelect={this.setExpenseStatus.bind(this)}
        //     />
        //   ) : (
        //     status
        //   )}
        // </td>
        }
        <td className="detailsCell">
          <Button
            title="DETAILS"
            link={link}
          />
        </td>
      </Tr>
    )
  }
}

const mapStateToProps = ({ main }) => {
  const {
    assignments
  } = main

  return {
    assignments
  }
}

const actions = {
  filterProductsByStatus,
  createAssignment,
  addQuantities,
  subQuantities,
  patchProductById,
  deleteProductById
}

export default connect(mapStateToProps, actions)(ProductRow);
