import { companyTypes } from '../actions/types'

const {
  CREATE_COMPANY,
  CREATE_COMPANY_FAILURE,
  CREATE_COMPANY_SUCCESS,
  SET_COMPANY,
  GET_COMPANY,
  GET_COMPANY_FAILURE,
  GET_COMPANY_SUCCESS,
  PATCH_COMPANY,
  PATCH_COMPANY_FAILURE,
  PATCH_COMPANY_SUCCESS,
  CLEAR_ERRORS,
  CLEAR_STATE,
} = companyTypes

const INITIAL_STATE = {
  company: null,
  name: '',
  email: '',
  password: '',
  passwordConfirm: '',
  error: '',
  loading: false,
}

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action
  switch (type) {
    case CREATE_COMPANY:
      return {
        ...state,
        loading: true,
      }
    case CREATE_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case CREATE_COMPANY_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      }
    case SET_COMPANY:
      return {
        ...state,
        company: payload,
      }
    case GET_COMPANY:
      return {
        ...state,
        loading: true,
      }
    case GET_COMPANY_SUCCESS:
      return {
        ...state,
        company: payload,
        loading: false,
      }
    case GET_COMPANY_FAILURE:
      return {
        ...state,
        company: null,
        error: payload,
        loading: false,
      }
    case PATCH_COMPANY:
      return {
        ...state,
        loading: true,
      }
    case PATCH_COMPANY_SUCCESS:
      return {
        ...state,
        company: payload,
        loading: false,
      }
    case PATCH_COMPANY_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      }
    case CLEAR_STATE:
      return {
        ...INITIAL_STATE,
      }
    case CLEAR_ERRORS:
      return {
        ...state,
        error: '',
        loading: false,
      }
    default:
      return state
  }
}
