import React, { Component } from 'react';
import h from '../../../shared/ChatPop/helpers'


class RecordPage extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    shareScreen() {
        const {
            screen,
        } = this.state
        h.shareScreen().then((stream) => {
            h.toggleShareIcons(true);
    
            //disable the video toggle btns while sharing screen. This is to ensure clicking on the btn does not interfere with the screen sharing
            //It will be enabled was user stopped sharing screen
            h.toggleVideoBtnDisabled(true);
    
            //save my screen stream
            screen = stream;
    
            //share the new stream with all partners
            this.broadcastNewTracks(stream, 'video', false);
    
            //When the stop sharing button shown by the browser is clicked
            screen.getVideoTracks()[0].addEventListener('ended', () => {
                this.stopSharingScreen();
            });
        }).catch((e) => {
            console.error(e);
        });
    }

    //When user clicks the 'Share screen' button
    shareScreen = (e) => {
        const {
            screen,
        } = this.state
        e.preventDefault();

        if (screen && screen.getVideoTracks().length && screen.getVideoTracks()[0].readyState != 'ended') {
            this.stopSharingScreen();
        }

        else {
            this.shareScreen();
        }
    };

    stopSharingScreen = () => {
        const {
            screen,
            myStream
        } = this.state
        //enable video toggle btn
        h.toggleVideoBtnDisabled(false);
    
        return new Promise( (res, rej) => {
            if (screen.getTracks().length) {screen.getTracks().forEach(track => track.stop())} else return '';
    
            res();
        }).then(() => {
            h.toggleShareIcons(false);
            this.broadcastNewTracks(myStream, 'video');
        }).catch((e) => {
            console.error(e);
        });
    }
    
    broadcastNewTracks = (stream, type, mirrorMode = true) => {
        const {
            pc
        } = this.state
        h.setLocalStream(stream, mirrorMode);
    
        let track = type == 'audio' ? stream.getAudioTracks()[0] : stream.getVideoTracks()[0];
    
        for (let p in pc) {
            let pName = pc[p];
    
            if (typeof pc[pName] == 'object') {
                h.replaceTrack(track, pc[pName]);
            }
        }
    }
    
    getAndSetUserStream = () => {
        const {
            myStream
        } = this.state
        h.getUserFullMedia().then((stream) => {
            //save my stream
            myStream = stream;
    
            h.setLocalStream(stream);
        }).catch((e) => {
            console.error(`stream error: ${e}`);
        });
    }
    
    startRecording = ( stream ) => {
        const {
            mediaRecorder,
            recordedStream,
            username
        } = this.state
        mediaRecorder = new MediaStream( stream, {
            mimeType: 'video/webm;codecs=vp9'
        } );
    
        mediaRecorder.start( 1000 );
        this.toggleRecordingIcons( true );
    
        mediaRecorder.ondataavailable = function ( e ) {
            recordedStream.push( e.data );
        };
    
        mediaRecorder.onstop = function () {
            this.toggleRecordingIcons( false );
    
            h.saveRecordedStream( recordedStream, username );
    
            setTimeout( () => {
                recordedStream = [];
            }, 3000 );
        };
    
        mediaRecorder.onerror = function ( e ) {
            console.error( e );
        };
    }
    
    
    //When record button is clicked onclikc
    record = ( e ) => {
        const {
            mediaRecorder
        } = this.state
        /**
         * Ask user what they want to record.
         * Get the stream based on selection and start recording
         */
        if ( !mediaRecorder || mediaRecorder.state == 'inactive' ) {
            h.toggleModal( 'recording-options-modal', true );
        }

        else if ( mediaRecorder.state == 'paused' ) {
            mediaRecorder.resume();
        }

        else if ( mediaRecorder.state == 'recording' ) {
            mediaRecorder.stop();
        }
    };
    
    
    //When user choose to record screen 
    recordScreen = () => {
        const {
            screen
        } = this.state
        h.toggleModal( 'recording-options-modal', false );

        if ( screen && screen.getVideoTracks().length ) {
            this.startRecording( screen );
        }

        else {
            h.shareScreen().then( ( screenStream ) => {
                this.startRecording( screenStream );
            } ).catch( () => { } );
        }
    };
    
    
    //When user choose to record own video onclikc
    recordVideo = () => {
        const {
            myStream
        } = this.state
        h.toggleModal( 'recording-options-modal', false );

        if ( myStream && myStream.getTracks().length ) {
            this.startRecording( myStream );
        }

        else {
            h.getUserFullMedia().then( ( videoStream ) => {
                this.startRecording( videoStream );
            } ).catch( () => { } );
        }
    };


    render() {
        return (
            <div>
                <div className="custom-modal" id='recording-options-modal'>
                    <div className="custom-modal-content">
                        <div className="row text-center">
                            <div className="col-md-6 mb-2">
                                <span className="record-option" id='record-video'>Record video</span>
                            </div>
                            <div className="col-md-6 mb-2">
                                <span className="record-option" id='record-screen'>Record screen</span>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-md-12 text-center">
                                <button className="btn btn-outline-danger" id='closeModal'>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default RecordPage;