import { supervisorsTypes } from '../actions/types'

const {
  CREATE_SUPERVISOR,
  CREATE_SUPERVISOR_FAILURE,
  CREATE_SUPERVISOR_SUCCESS,
  SET_SUPERVISOR,
  GET_SUPERVISOR,
  GET_SUPERVISOR_FAILURE,
  GET_SUPERVISOR_SUCCESS,
  GET_SUPERVISORS,
  GET_SUPERVISORS_FAILURE,
  GET_SUPERVISORS_SUCCESS,
  PATCH_SUPERVISOR,
  PATCH_SUPERVISOR_FAILURE,
  PATCH_SUPERVISOR_SUCCESS,
  DELETE_SUPERVISOR,
  DELETE_SUPERVISOR_FAILURE,
  DELETE_SUPERVISOR_SUCCESS,
  CREATE_NOTE,
  CREATE_NOTE_SUCCESS,
  CREATE_NOTE_FAILURE,
  CLEAR_ERRORS,
  CLEAR_STATE,
} = supervisorsTypes

const INITIAL_STATE = {
  supervisor: [],
  supervisors: [],
  supervisorsDisplay: [],
  error: '',
  loading: false,
}

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action
  switch (type) {
    case CREATE_SUPERVISOR:
      return {
        ...state,
        loading: true,
      }
    case CREATE_SUPERVISOR_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case CREATE_SUPERVISOR_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      }
    case SET_SUPERVISOR:
      return {
        ...state,
        supervisor: payload,
      }
    case GET_SUPERVISOR:
      return {
        ...state,
        loading: true,
      }
    case GET_SUPERVISOR_SUCCESS:
      return {
        ...state,
        supervisors: payload,
        supervisorsDisplay: payload,
        loading: false,
      }
    case GET_SUPERVISOR_FAILURE:
      return {
        ...state,
        supervisors: null,
        supervisorsDisplay: null,
        error: payload,
        loading: false,
      }
    case GET_SUPERVISORS:
      return {
        ...state,
        loading: true,
      }
    case GET_SUPERVISORS_SUCCESS:
      return {
        ...state,
        supervisors: payload,
        supervisorsDisplay: payload,
        loading: false,
      }
    case GET_SUPERVISORS_FAILURE:
      return {
        ...state,
        supervisors: null,
        supervisorsDisplay: null,
        error: payload,
        loading: false,
      }
    case PATCH_SUPERVISOR:
      return {
        ...state,
        loading: true,
      }
    case PATCH_SUPERVISOR_SUCCESS:
      return {
        ...state,
        supervisors: payload,
        supervisorsDisplay: payload,
        loading: false,
      }
    case PATCH_SUPERVISOR_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      }
    case DELETE_SUPERVISOR:
      return {
        ...state,
        loading: true,
      }
    case DELETE_SUPERVISOR_SUCCESS:
      return {
        ...state,
        supervisors: payload,
        supervisorsDisplay: payload,
        loading: false,
      }
    case DELETE_SUPERVISOR_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      }
    case CREATE_NOTE:
      return {
        ...state,
        loading: true,
      }
    case CREATE_NOTE_SUCCESS:
      return {
        ...state,
        supervisors: payload,
        supervisorsDisplay: payload,
        loading: false,
      }
    case CREATE_NOTE_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      }
    case CLEAR_STATE:
      return {
        ...INITIAL_STATE,
      }
    case CLEAR_ERRORS:
      return {
        ...state,
        error: '',
        loading: false,
      }
    default:
      return state
  }
}
