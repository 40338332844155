// Router
import { push } from 'react-router-redux'

// Types
import { communityTypes, expensesTypes } from './types'

// Services
import { auth, communitys, community } from '../services'

import * as cookies from 'js-cookie';

const {
  VERIFY_EMAIL,
  VERIFY_SUCCESS,
  VERIFY_FAILURE,
  INIT_COMMUNITYS,
  INIT_SUCCESS,
  INIT_FAILURE,
  CREATE_COMMUNITY_MEMBER,
  CREATE_COMMUNITY_MEMBER_FAILURE,
  CREATE_COMMUNITY_MEMBER_SUCCESS,
  GET_COMMUNITY,
  GET_COMMUNITY_SUCCESS,
  GET_COMMUNITY_FAILURE,
  SET_COMMUNITY_MEMBER,
  GET_COMMUNITY_MEMBER,
  GET_COMMUNITY_MEMBER_FAILURE,
  GET_COMMUNITY_MEMBER_SUCCESS,
  PATCH_COMMUNITY_MEMBER,
  PATCH_COMMUNITY_MEMBER_FAILURE,
  PATCH_COMMUNITY_MEMBER_SUCCESS,
  CLEAR_ERRORS,
  CLEAR_STATE,
  GET_TIMELINE,
  GET_TIMELINE_SUCCESS,
  GET_TIMELINE_FAILURE,
  GET_OFFERINGS,
  GET_OFFERINGS_SUCCESS,
  GET_OFFERINGS_FAILURE,
  CREATE_OFFERING,
  CREATE_OFFERING_SUCCESS,
  CREATE_OFFERING_FAILURE,
  ADD_TO_CART,  
  ADD_TO_CART_SUCCESS,
  ADD_TO_CART_FAILURE,  
  GET_CART,
  GET_CART_SUCCESS,
  GET_CART_FAILURE,
  REMOVE_FROM_CART,  
  REMOVE_FROM_CART_SUCCESS,
  REMOVE_FROM_CART_FAILURE,  
  CREATE_GUEST,
  CREATE_GUEST_SUCCESS,
  CREATE_GUEST_FAILURE,
  GET_GUEST,
  GET_GUEST_SUCCESS,
  GET_GUEST_FAILURE,
  REMOVE_GUEST,
  REMOVE_GUEST_SUCCESS,
  REMOVE_GUEST_FAILURE,
  GET_OFFERING,
  GET_OFFERING_SUCCESS,
  GET_OFFERING_FAILURE,
  PATCH_OFFERING,
  PATCH_OFFERING_SUCCESS,
  PATCH_OFFERING_FAILURE,
  DELETE_OFFERING,
  DELETE_OFFERING_SUCCESS,
  DELETE_OFFERING_FAILURE,
} = communityTypes

const company = JSON.parse(sessionStorage.getItem("company"))
const me = sessionStorage.getItem('my-community-data') ? JSON.parse(sessionStorage.getItem('my-community-data')) : '';

export const addToCart = payload => (dispatch, getState) => {
  dispatch({
    type: ADD_TO_CART,
  })
  communitys.addTo(payload)
    .then(res => addToCartSuccess({dispatch, getState, res}))
    .catch(err => addToCartFailure({dispatch, err}))
}

function addToCartSuccess({dispatch, getState, res}) {
  dispatch({
    type: ADD_TO_CART_SUCCESS,
    payload: res.data,
  })
  dispatch(push(`/home/community/${res.data._id}`))
  window.location.reload()
}

function addToCartFailure({dispatch, err}) {
  const payload = err.message || 'addToCart Failed'
  window.alert(payload)
  dispatch({
    type: ADD_TO_CART_FAILURE,
    payload,
  })
}

export const getCart = () => dispatch => {
  dispatch({
    type: GET_CART,
  })
  communitys.getCart()
    .then(res => getCartSuccess({dispatch, res}))
    .catch(err => getCartFailure({dispatch, err}))
}

function getCartSuccess({dispatch, res}) {
  console.log('getCart success');
  dispatch({
    type: GET_CART_SUCCESS,
    payload: res.data
  })
}

function getCartFailure({dispatch, err}) {
  console.log('getCart failure', err);
  const payload = err.message || 'getCart Failed'
  window.alert(payload)
  dispatch(push('/community'))
  dispatch({
    type: GET_CART_FAILURE,
    payload,
  })
}

export const removeFromCart = data => dispatch => {
  dispatch({
    type: REMOVE_FROM_CART,
  })
  console.log(data)
  communitys.removeFromCart(data)
    .then(res => removeFromCartSuccess({dispatch, res}))
    .catch(err => removeFromCartFailure({dispatch, err}))
}

function removeFromCartSuccess({dispatch, res}) {
  dispatch({
    type: REMOVE_FROM_CART_SUCCESS,
    payload: res
  })
}

function removeFromCartFailure({dispatch, err}) {
  const message = err.message || 'Create Password Failed'
  window.alert(message)
  dispatch({
    type: REMOVE_FROM_CART_FAILURE,
    payload: message,
  })
}


export const createGuest = () => (dispatch, getState) => {
  dispatch({
    type: CREATE_GUEST,
  })
  communitys.createGuest()
    .then(res => createGuestSuccess({dispatch, getState, res}))
    .catch(err => createGuestFailure({dispatch, err}))
}

function createGuestSuccess({dispatch, getState, res}) {
  sessionStorage.setItem('my-community-data', JSON.stringify(res.data))
  dispatch({
    type: CREATE_GUEST_SUCCESS,
    payload: res.data,
  })
}

function createGuestFailure({dispatch, err}) {
  const payload = err.message || 'createGuest Failed'
  window.alert(payload)
  dispatch({
    type: CREATE_GUEST_FAILURE,
    payload,
  })
}

export const getGuest = (payload) => dispatch => {
  dispatch({
    type: GET_GUEST,
  })
  communitys.getGuest(payload)
    .then(res => getGuestSuccess({dispatch, res}))
    .catch(err => getGuestFailure({dispatch, err}))
}

function getGuestSuccess({dispatch, res}) {
  console.log('getGuest success');
  dispatch({
    type: GET_GUEST_SUCCESS,
    payload: res.data
  })
}

function getGuestFailure({dispatch, err}) {
  console.log('getGuest failure', err);
  const payload = err.message || 'getGuest Failed'
  window.alert(payload)
  dispatch({
    type: GET_GUEST_FAILURE,
    payload,
  })
}

export const removeGuest = data => dispatch => {
  dispatch({
    type: REMOVE_GUEST,
  })
  communitys.removeGuest(data)
    .then(res => removeGuestSuccess({dispatch, res}))
    .catch(err => removeGuestFailure({dispatch, err}))
}

function removeGuestSuccess({dispatch, res}) {
  dispatch({
    type: REMOVE_GUEST_SUCCESS,
    payload: res
  })
}

function removeGuestFailure({dispatch, err}) {
  const message = err.message || 'removeGuest Failed'
  window.alert(message)
  dispatch({
    type: REMOVE_GUEST_FAILURE,
    payload: message,
  })
}


export const createCommunityMember = payload => (dispatch, getState) => {
  dispatch({
    type: CREATE_COMMUNITY_MEMBER,
  })
  auth.communitySignup(payload)
    .then(res => onCreateCommunityMemberSuccess({dispatch, getState, res}))
    .catch(err => onCreateCommunityMemberFailure({dispatch, err}))
}

function onCreateCommunityMemberSuccess({dispatch, getState, res}) {
  sessionStorage.setItem('select_community', JSON.stringify(res.data))
  sessionStorage.setItem('my-community-data', JSON.stringify(res.data))
  dispatch({
    type: CREATE_COMMUNITY_MEMBER_SUCCESS,
    payload: res.data,
  })
  dispatch(push(`/community/${res.data._id}`))
}

function onCreateCommunityMemberFailure({dispatch, err}) {
  const payload = err.message || 'CreateCommunityMember Failed'
  window.alert(payload)
  dispatch({
    type: CREATE_COMMUNITY_MEMBER_FAILURE,
    payload,
  })
}

export const verifyEmail = data => dispatch => {
  dispatch({
    type: VERIFY_EMAIL,
  })
  auth.verifyEmail(data)
    .then(res => onVerifySuccess({dispatch, res}))
    .catch(err => onVerifyFailure({dispatch, err}))
}

function onVerifySuccess({dispatch, res}) {
  // set new token for Session Storage
  sessionStorage.setItem('token', JSON.stringify(res))
  dispatch({
    type: VERIFY_SUCCESS,
    payload: res
  })
}

function onVerifyFailure({dispatch, err}) {
  const message = err.message || 'Create Password Failed'
  window.alert(message)
  dispatch({
    type: VERIFY_FAILURE,
    payload: message,
  })
}

export const getToken = () => {

  return sessionStorage.getItem('token')
} 

export const init = () => dispatch => {
  dispatch({
    type: INIT_COMMUNITYS,
  })
  auth.init()
    .then(res => onInitSuccess({dispatch, res}))
    .catch(err => onInitFailure({dispatch, err}))
}

function onInitSuccess({dispatch, res}) {
  dispatch({
    type: INIT_SUCCESS,
  })
  // set new token for Session Storage
  console.log(res)
}

function onInitFailure({dispatch, err}) {
  const message = 'Create Password Failed'
  window.alert(message)
  dispatch({
    type: INIT_FAILURE,
    payload: message,
  })
}

export const setMember = payload => dispatch => {
  const { communitys } = payload
  sessionStorage.setItem('select_community', JSON.stringify(payload))
  dispatch({
    type: SET_COMMUNITY_MEMBER,
    payload,
  })
  dispatch(push(`/home/community/${payload._id}`))
}

export const getCommunity = () => dispatch => {
  dispatch({
    type: GET_COMMUNITY,
  })
  community.getAll()
    .then(res => onGetCommunitySuccess({dispatch, res}))
    .catch(err => onGetCommunityFailure({dispatch, err}))
}

function onGetCommunitySuccess({dispatch, res}) {
  console.log('GetCommunitys success');
  dispatch({
    type: GET_COMMUNITY_SUCCESS,
    payload: res.data
  })
}

function onGetCommunityFailure({dispatch, err}) {
  console.log('GetCommunitys failure', err);
  const payload = err.message || 'GetCommunitys Failed'
  window.alert(payload)
  dispatch({
    type: GET_COMMUNITY_FAILURE,
    payload,
  })
}

export const getTimeline = () => dispatch => {
  dispatch({
    type: GET_TIMELINE,
  })
  communitys.getTimeline()
    .then(res => onGetTimelineSuccess({dispatch, res}))
    .catch(err => onGetTimelineFailure({dispatch, err}))
}

function onGetTimelineSuccess({dispatch, res}) {
  console.log('GetTimeline success');
  dispatch({
    type: GET_TIMELINE_SUCCESS,
    payload: res.data
  })
}

function onGetTimelineFailure({dispatch, err}) {
  console.log('GetTimeline failure', err);
  const payload = err.message || 'GetTimeline Failed'
  window.alert(payload)
  dispatch({
    type: GET_TIMELINE_FAILURE,
    payload,
  })
}

export const getOfferings = () => dispatch => {
  dispatch({
    type: GET_OFFERINGS,
  })
  communitys.getOfferings()
    .then(res => onGetOfferingsSuccess({dispatch, res}))
    .catch(err => onGetOfferingsFailure({dispatch, err}))
}

function onGetOfferingsSuccess({dispatch, res}) {
  console.log('GetOfferings success');
  dispatch({
    type: GET_OFFERINGS_SUCCESS,
    payload: res.data
  })
}

function onGetOfferingsFailure({dispatch, err}) {
  console.log('GetOfferings failure', err);
  const payload = err.message || 'GetOffering Failed'
  window.alert(payload)
  dispatch({
    type: GET_OFFERINGS_FAILURE,
    payload,
  })
}

export const createOffering = payload => (dispatch, getState) => {
  dispatch({
    type: CREATE_OFFERING,
  })
  communitys.createOffering(payload)
    .then(res => onCreateCommunityOfferingSuccess({dispatch, getState, res}))
    .catch(err => onCreateCommunityOfferingFailure({dispatch, err}))
}

function onCreateCommunityOfferingSuccess({dispatch, getState, res}) {
  dispatch({
    type: CREATE_OFFERING_SUCCESS,
    payload: res.data,
  })
  dispatch(push(`/home/community/${res.data.coin}`))
  window.location.reload()
}

function onCreateCommunityOfferingFailure({dispatch, err}) {
  const payload = err.message || 'Offering Failed'
  window.alert(payload)
  dispatch({
    type: CREATE_OFFERING_FAILURE,
    payload,
  })
}

export const getMemberById = member => (dispatch, getState) => {
  dispatch({
    type: GET_COMMUNITY_MEMBER,
  })
  communitys.getById(member)
    .then(res => onGetMemberSuccess({dispatch, res}))
    .catch(err => onGetMemberFailure({dispatch, err}))
}

function onGetMemberSuccess({dispatch, res}) {
  res.data.map(d => {
    dispatch({
      type: GET_COMMUNITY_MEMBER_SUCCESS,
      payload: d,
    })
  })
}

function onGetMemberFailure({dispatch, err}) {
  dispatch(push('/community'))
  const payload = err.message || 'GetMember Failed'
  window.alert(payload)
  dispatch({
    type: GET_COMMUNITY_MEMBER_FAILURE,
    payload,
  })
}

export const patchMemberById = (id, update) => (dispatch, getState) => {
  dispatch({
    type: PATCH_COMMUNITY_MEMBER,
  })
  communitys.patchById(id, update)
    .then(res => onPatchMemberSuccess({dispatch, res}))
    .catch(err => onPatchMemberFailure({dispatch, err}))
}

function onPatchMemberSuccess({dispatch, res}) {
  dispatch({
    type: PATCH_COMMUNITY_MEMBER_SUCCESS,
    payload: res.user,
  })
}

function onPatchMemberFailure({dispatch, err}) {
  const payload = err.message || 'PatchMember Failed'
  window.alert(payload)
  dispatch({
    type: PATCH_COMMUNITY_MEMBER_FAILURE,
    payload,
  })
}

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  }
}
