import React, { Component } from 'react'
import Invoice from './Invoice/Invoice'

import './Invoice/Invoice.module.scss'

class InvoicePage extends Component {
  render() {
    return (
      <div className="App">
        <Invoice />
      </div>
    )
  }
}

export default InvoicePage
