import { api } from '../config'

export default {
  getAll(cid,uid) {
    const url = `${cid}/supervisors/${uid}`
    return api.get(url)
  },

  post(data) {
    const url = '/supervisors'
    return api.post(url, data)
  },

  getById(id) {
    const url = `/supervisors/${id}`
    return api.get(url)
  },

  patchById(id, update) {
    const url = `/supervisors/${id}`
    return api.patch(url, update)
  },
}
