import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom'

// Components
import SimpleCalculator from './SimpleCalculator/SimpleCalculator'
import ScientificCalculator from './ScientificCalculator/ScientificCalculator'
import NotFound from '../../NotFound/NotFound'


const CalculatorPageRouter = props => (
  <Switch>
 
    <Route
      exact
      path="/calculator/simple"
      component={SimpleCalculator}
    />    
    
    <Route
      exact
      path="/calculator/scientific"
      component={ScientificCalculator}
    />

    <Route
      component={NotFound}
    />
  </Switch>
)


export default CalculatorPageRouter
