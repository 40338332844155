const types = {
  basic: "Basic",
  standard: "Standard",
  premium: "Premium",
  vip: "VIP"
}

const initialState = {
  cycles: {
    'Monthly': {
      unit: 'user',
      plans: {
        'Basic': {
          features: {
            type: types.basic,
            edit: "Inventory and User Mangement",
            graphs: 3,
            access: "All users have access to Muvor Ledger",
            benefit: "Everyday low price",
            price: 20
          }
        },
        'Standard': {
          features: {
            type: types.standard,
            edit: "Messaging, Voice and Video calls",
            graphs: 5,
            access: "+ Human Resources Dashboard",
            benefit: "17% Savings",
            price: 50
          }
        },
        'Premium': {
          features: {
            type: types.premium,
            edit: "Share and Sign Documents",
            graphs: 10,
            access: "+ Additional Storage",
            benefit: "28% Savings",
            price: 100
          }
        },
        'VIP': {
          features: {
            type: types.vip,
            edit: "Coming Soon!",
            graphs:"",
            access:"",
            benefit: "",
            price: 200
          }
        }
      },
      summary: `Simple ${"\u2022"} Green ${"\u2022"} Secure`
    },
    'Yearly': {
      unit: 'user',
      plans: {
        'Basic': {
          features: {
            type: types.basic,
            edit: "Inventory and User Mangement",
            graphs: 3,
            access: "All users have access to Muvor Ledger",
            benefit: "Everyday low price",
            price: 200
          }
        },
        'Standard': {
          features: {
            type: types.standard,
            edit: "Messaging, Voice and Video calls",
            graphs: 5,
            access: "+ Human Resources Dashboard",
            benefit: "17% Savings",
            price: 500
          }
        },
        'Premium': {
          features: {
            type: types.premium,
            edit: "Share and Sign Documents",
            graphs: 10,
            access: "+ Additional Storage",
            benefit: "28% Savings",
            price: 1000
          }
        },
        'VIP': {
          features: {
            type: types.vip,
            edit: "Coming Soon!",
            graphs:"",
            access:"",
            benefit: "",
            price: 2000
          }
        }
      },
      summary: `Simple ${"\u2022"} Green ${"\u2022"} Secure`
    },
   'Pay-As-You-Go': {
      unit: '',
      plans: {
        // 'Basic': {
        //   features: {
        //     type: types.basic,
        //     edit: "Inventory and User Mangement",
        //     graphs: 5,
        //     access: "All users have access to Muvor Ledger",
        //     benefit: "Everyday low price",
        //     price: 50
        //   }
        // },
        // 'Standard': {
        //   features: {
        //     type: types.standard,
        //     edit: "Messaging, Voice and Video calls",
        //     graphs: 3,
        //     access: "+ Human Resources Dashboard",
        //     benefit: "17% Savings",
        //     price: 150
        //   }
        // },
        // 'Premium': {
        //   features: {
        //     type: types.premium,
        //     edit: "Share and Sign Documents",
        //     graphs: 2,
        //     access: "+ Additional Storage",
        //     benefit: "28% Savings",
        //     price: 500
        //   }
        // },
        // 'VIP': {
        //   features: {
        //     type: types.vip,
        //     edit: "Coming Soon!",
        //     graphs:"",
        //     access:"",
        //     benefit: "",
        //     price: 2000
        //   }
        // }
      },
      summary: 'Coming Soon!'
    }
  },
  featurable: [
    {
      name: 'type',
      formatter: (value) => `${value}`,
      class: 'type'
    },
    { 
      name: 'edit', 
      formatter: (value) => `${value}`,
      class: 'content-after-included'
    },
    {
      name: 'graphs',
      formatter: (value) => `Access to ${value} graphs`,
      class: 'content-after-turnaround'
    },
    {
      name: 'access',
      formatter: (value) => `${value}`,
      class: 'content-after-support'
    },
    {
      name: 'benefit', 
      formatter: (value) => `${value}`,
    },
    {
      name: 'price',
      formatter: (value, unit) => `$${value}${unit ? '/' + unit : ''}`,
      class: 'price'
    }
  ]
}

export default initialState;