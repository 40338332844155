import React, { Component } from 'react';
import Router from './HrDashboard.Router';

import * as cookies from 'js-cookie'

import styles from './HrDashboard.css'

class HrDashboard extends Component {
  constructor (props) {
    super (props) 
    this.state = {
      // clientId: randomID()
    }
  }

  componentDidMount() {

  }
  render() {
    // cookies.set('valhalla', this.state.clientId)
    const me = cookies.get('me') && JSON.parse(cookies.get('me')) ? JSON.parse(cookies.get('me')) : undefined 
    return (
      <Router/>
    );
  }
}

export default HrDashboard;

