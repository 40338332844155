import React from 'react';
import {
  Route,
  Switch,
  Redirect
} from 'react-router-dom'

// Components
import RecordPage from './MessagerPage/RecordPage/RecordPage'
import NotFound from '../NotFound/NotFound'
import VideoPage from './MessagerPage/VideoPage/VideoPage';
import useChatRoom from './MessagerPage/ChatPage/useChatRoom';


const MessagerPageRouter = props => (
  <Switch>
   <Route
      path="/chats/video"
      component={VideoPage}
    /> 

    <Route
      path="/chats/record"
      component={RecordPage}
    />    

    <Route
      path="/chats"
      component={useChatRoom}
    />    

    <Route
      component={NotFound}
    />
  </Switch>
)

export default MessagerPageRouter
