import React from 'react';

import { storiesOf, action, linkTo, addDecorator } from '@storybook/react';
import StopIcon from '@mui/icons-material/Stop';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import ColorPicker from '../components/shared/ColorPicker/ColorPicker'

storiesOf('ColorPicker', module)
  .add('default option', () => {
    return (
      <div style={{margin: 40}}>
        <ColorPicker />
      </div>
    )
  })
  .add('custom color and item per row', () => {
    const props = {
      availableColors: ['black', 'blue', 'green', 'red'],
      itemPerRow: 3
    }
    return (
      <div style={{margin: 40}}>
        <ColorPicker {...props} />
      </div>
    )
  })
  .add('custom font icon', () => {
    const props = {
      iconComponent: <StopIcon />,
      selectedIconComponent: <CheckBoxIcon />
    }
    return (
      <div style={{margin: 40}}>
        <ColorPicker {...props} />
      </div>
    )
  })