import React, { Component } from 'react';

// Router
import Router from './Login.Router'

// Styles
import styles from './Login.css'

class Login extends Component {
  render() {
    return (
      <div className={styles.app}>
        <Router />
      </div>
    )
  }
}

export default Login
