import React, { Component } from 'react';
import { connect } from 'react-redux'

import Multistep from '../../shared/Multistep'

import { getStripe,  getPlan } from '../../../actions/stripe'

import * as cookies from 'js-cookie'
import Router from './RegisterMain.router';

// Styles
import styles from './RegisterMain.css'

class RegisterMain extends Component {
  constructor(props){
    super(props)
      this.state = {
      }
  } 

  get initalState() {
    return {

    }
  }

  componentWillMount() {
    
  }

  // static getDerivedStateFromProps(props, state) {
  //   return {selectedPlan: props.favcol };
  // }

  // componentWillUpdate() {
  //   this.props.getPlan(selectedPlan => this.setState({selectedPlan: selectedPlan}))
  // }
  render() {
    return (
      <div className={styles.app}>
        <div className="router">
          <Router />
        </div>
      </div>
    );
  }
}


const mapStateToProps = ({ stripe }) => {
  const {

  } = stripe

  return {

  }
}

const actions = {

}

export default connect(mapStateToProps, actions)(RegisterMain);