import React, { Component } from 'react';
import styles from './ProfileCard.css';
import BusinessCard from './components/BusinessCard';
import { FloatBox, Form } from '../../../common';

class ProfileCard extends Component {
  constructor(props) {
      super(props);
      this.state = { 
        isNightMode: false 
      };
  }

  changeMode = () => {
    this.setState((prevState) => {
      return {isNightMode: !prevState.isNightMode};
    });
  }

  render() {
    return (
      <div>
        <FloatBox className={styles.float}>
          <BusinessCard />
        </FloatBox>
      </div>
    );
  }
}

export default ProfileCard;
