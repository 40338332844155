import React from 'react';
import {
  Route,
  Switch,
  Redirect
} from 'react-router-dom'

// Components
import NotFound from '../NotFound/NotFound'
import LoginMain from './LoginMain/LoginMain'
import ResetPassword from './ResetPassword/ResetPassword'
import CreatePassword from './CreatePassword/CreatePassword'
import ClaimAccount from './ClaimAccount/ClaimAccount'

import restricted from '../restricted'
import RegisterMain from './RegisterMain/RegisterMain';

const LoginRouter = props => (
  <Switch>
    <Route
      exact
      path="/login"
      component={LoginMain}
    />

    <Route
      path="/login/reset-password"
      component={ResetPassword}
    />

    <Route
      path="/login/claim-account"
      component={ClaimAccount}
    />
    
    <Route
      path="/login/register"
      component={RegisterMain}
    />

    <Route
      path="/login/create-password/:userId/:token"
      component={CreatePassword}
    />

    <Route
      component={NotFound}
    />
  </Switch>
)


export default LoginRouter
