// React
import React, { Component } from 'react';

// Redux
import { connect } from 'react-redux'
import {
  emailChanged,
  resetPassword,
} from '../../../actions/auth'

// Components
import {
  ErrorMessage,
  FloatBox,
  Button,
  Form,
  Link,
  Input,
}  from '../../common'

// Styles
import styles from './ResetPassword.css'

class ResetPassword extends Component {
  handleSubmit(e) {
    e.preventDefault()
    const data = { email: this.props.email }
    this.props.resetPassword(data)
  }

  render() {
    const {
      error,
      email,
      emailChanged,
      loading,
    } = this.props

    return (
      <div className={styles.container}>
        <FloatBox>
          <h2>Resend Verification Email</h2>
          <p>Request an email to reset your password</p>
          <Form className="body" onSubmit={e => this.handleSubmit(e)}>
            (May be Case-Sesnsitive)
            <Input
              wide
              id="email"
              name="email"
              placeholder="user@gmail.com"
              value={email}
              type="email"
              onChange={emailChanged}
            />
            <Button
              type="submit"
              className="button"
              title="Submit"
              inactive={loading}
            />
            <Link to="/login">Back</Link>
            <ErrorMessage error={error}/>
          </Form>
        </FloatBox>
      </div>
    );
  }
}

const mapStateToProps = ({auth}) => {
  const {
    email,
    error,
    loading,
  } = auth

  return {
    email,
    error,
    loading,
  }
}

const actions = {
  resetPassword,
  emailChanged,
}

export default connect(mapStateToProps, actions)(ResetPassword)