import React, { Component } from 'react';
import Router from './ReportsPage.Router';

import styles from './ReportsPage.css'

class ReportsPage extends Component {
  render() {
    return (
      <div className={styles.app}>
        <Router />
      </div>
    );
  }
}

export default ReportsPage;
