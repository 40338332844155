// React
import React, { Component } from 'react';

import TitleBar from './TitleBar/TitleBar'
import NavBar from './NavBar/NavBar'

import 'react-spinner/react-spinner.css'
import styles from './TopNav.css'
import SideBar from '../AgendaPage/SideBar/SideBar';
import { render } from 'react-dom';
import { formatDate } from '../../../lib/dateHelper'


export default class TopNav extends Component {
  viewModal({ rep, day }) {
    // const date = formatDate(new Date(this.year, this.month, day))
    // const visitModal = {
    //   rep,
    //   date,
    // }
    // this.setState({ visitModal, rep: null, day: null})
  }
  
  closeSideBar() {
    this.setState({ rep: null, day: null })
  }
  render() {
    return (
      <div className={styles.topNav}>
        <NavBar />
        {/* SideBar */}
        <SideBar
          // rep={this.state.rep}
          // day={this.state.day}
          // month={this.state.month}
          // year={this.state.year}
          // flipped={this.state.sideBarFlipped}
          visible={0
            // true
            // this.props.rep && this.props.day
            // this.props
          }
          onClickAway={this.closeSideBar.bind(this)}
          onViewModal={this.viewModal.bind(this)}
        />
      </div>
    );
  }
}
