import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom'

// Components
import SupervisorsView from './SupervisorsView/SupervisorsView'
import SupervisorView from './SupervisorView/SupervisorView'
import AddSupervisor from './AddSupervisor/AddSupervisor'
import NotFound from '../../NotFound/NotFound'

const SupervisorsPageRouter = props => (
  <Switch>
    <Route
      exact
      path="/supervisors"
      component={SupervisorsView}
    />

    <Route
      exact
      path="/supervisors/add"
      component={AddSupervisor}
    />

    <Route
      exact
      path="/supervisors/:supervisorId"
      component={SupervisorView}
    />

    <Route
      component={NotFound}
    />
  </Switch>
)


export default SupervisorsPageRouter
