import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom'

// Components
import EmployeesView from './EmployeesView/EmployeesView'
import EmployeeView from './EmployeeView/EmployeeView'
import AddEmployee from './AddEmployee/AddEmployee'
import NotFound from '../../NotFound/NotFound'

const EmployeesPageRouter = props => (
  <Switch>
    <Route
      exact
      path="/employees"
      component={EmployeesView}
    />

    <Route
      exact
      path="/employees/add"
      component={AddEmployee}
    />

    <Route
      exact
      path="/employees/:employeeId"
      component={EmployeeView}
    />

    <Route
      component={NotFound}
    />
  </Switch>
)


export default EmployeesPageRouter
