// Router
import { push } from 'react-router-redux'

// Types
import { supervisorsTypes } from './types'

// Services
import { supervisors, visits } from '../services'

//cookies
import * as cookies from 'js-cookie';

const {
  CREATE_SUPERVISOR,
  CREATE_SUPERVISOR_FAILURE,
  CREATE_SUPERVISOR_SUCCESS,
  SET_SUPERVISOR,
  GET_SUPERVISOR,
  GET_SUPERVISOR_FAILURE,
  GET_SUPERVISOR_SUCCESS,
  GET_SUPERVISORS,
  GET_SUPERVISORS_FAILURE,
  GET_SUPERVISORS_SUCCESS,
  PATCH_SUPERVISOR,
  PATCH_SUPERVISOR_FAILURE,
  CREATE_NOTE,
  CREATE_NOTE_SUCCESS,
  CREATE_NOTE_FAILURE,
  PATCH_SUPERVISOR_SUCCESS,
  DELETE_SUPERVISOR,
  DELETE_SUPERVISOR_FAILURE,
  DELETE_SUPERVISOR_SUCCESS,
  CLEAR_ERRORS,
  CLEAR_STATE,
} = supervisorsTypes

const me = cookies.get('me') ? JSON.parse(cookies.get('me')) : {}
const { id, company } = me
const uid=id,cid=company;

export const createSupervisor = payload => dispatch => {
  dispatch({
    type: CREATE_SUPERVISOR,
  })
  supervisors.post(payload)
    .then(res => onCreateSupervisorSuccess({dispatch, res}))
    .catch(err => onCreateSupervisorFailure({dispatch, err}))
}

function onCreateSupervisorSuccess({dispatch, res}) {
  console.log('createSupervisor success');
  dispatch({
    type: CREATE_SUPERVISOR_SUCCESS,
  })
  dispatch(push('/supervisors'))
}

function onCreateSupervisorFailure({dispatch, err}) {
  console.log('createSupervisor failure', err);
  const payload = err.message || 'CreateSupervisor Failed'
  window.alert(payload)
  dispatch({
    type: CREATE_SUPERVISOR_FAILURE,
    payload,
  })
}

export const setSupervisor = payload => {
  localStorage.setItem('supervisor', JSON.stringify(payload))
  return {
    type: SET_SUPERVISOR,
    payload,
  }
}

export const getSupervisors = () => dispatch => {
  dispatch({
    type: GET_SUPERVISORS,
  })
  supervisors.getAll(cid,uid)
    .then(res => onGetSupervisorsSuccess({dispatch, res}))
    .catch(err => onGetSupervisorsFailure({dispatch, err}))
}

function onGetSupervisorsSuccess({dispatch, res}) {
  console.log('getSupervisor success');
  dispatch({
    type: GET_SUPERVISORS_SUCCESS,
    payload: res.data,
  })
}

function onGetSupervisorsFailure({dispatch, err}) {
  console.log('getSupervisor failure', err);
  const payload = err.message || 'GetSupervisor Failed'
  window.alert(payload)
  dispatch({
    type: GET_SUPERVISORS_FAILURE,
    payload,
  })
  dispatch(push('/supervisors'))
}

export const getSupervisorById = supervisorId => (dispatch, getState) => {
  // check if a supervisor has been set yet
  const { supervisor } = getState().supervisors
  if (supervisor && supervisor.id !== supervisorId) {
    // clear it if it is not the same one
    dispatch({
      type: CLEAR_STATE,
    })
  }

  dispatch({
    type: GET_SUPERVISOR,
  })
  supervisors.getById(supervisorId)
    .then(res => onGetSupervisorSuccess({dispatch, res}))
    .catch(err => onGetSupervisorFailure({dispatch, err}))
}

function onGetSupervisorSuccess({dispatch, res}) {
  console.log('getSupervisor success');
  dispatch({
    type: GET_SUPERVISOR_SUCCESS,
    payload: res.supervisor,
  })
}

function onGetSupervisorFailure({dispatch, err}) {
  console.log('getSupervisor failure', err);
  const payload = err.message || 'GetSupervisor Failed'
  window.alert(payload)
  dispatch({
    type: GET_SUPERVISOR_FAILURE,
    payload,
  })
  dispatch(push('/supervisors'))
}

export const patchSupervisorById = (id, update) => (dispatch, getState) => {
  dispatch({
    type: PATCH_SUPERVISOR,
  })
  supervisors.patchById(id, update)
    .then(res => onPatchSupervisorSuccess({dispatch, res}))
    .catch(err => onPatchSupervisorFailure({dispatch, err}))
}

function onPatchSupervisorSuccess({dispatch, res}) {
  console.log('getSupervisor success');
  dispatch({
    type: PATCH_SUPERVISOR_SUCCESS,
    payload: res.supervisor,
  })
}

function onPatchSupervisorFailure({dispatch, err}) {
  console.log('patchSupervisor failure', err);
  const payload = err.message || 'PatchSupervisor Failed'
  window.alert(payload)
  dispatch({
    type: PATCH_SUPERVISOR_FAILURE,
    payload,
  })
}

export const createNote = data => (dispatch, getState) => {
  dispatch({
    type: CREATE_NOTE,
  })

  const {
    visitId,
    text,
  } = data

  visits.postNoteById(visitId, { text })
    .then(res => onCreateNoteSuccess({dispatch, getState, res}))
    .catch(err => onCreateNoteFailure({dispatch, err}))
}

function onCreateNoteSuccess({dispatch, getState, res}) {
  console.log('createNote success');
  const { note } = res
  // update the visits array on the supervisor

  const { supervisor } = getState().supervisors
  const { visits } = supervisor
  const visit = visits.find(el => el.id === note.visitId)
  visit.notes = [...visit.notes, note]

  dispatch({
    type: CREATE_NOTE_SUCCESS,
    payload: { ...supervisor },
  })
}

function onCreateNoteFailure({dispatch, err}) {
  console.log('createNote failure', err);
  dispatch({
    type: CREATE_NOTE_FAILURE,
    payload: err.message || 'CreateNote Failed',
  })
}

export const deleteSupervisorById = id => (dispatch, getState) => {
  dispatch({
    type: DELETE_SUPERVISOR,
  })
  supervisors.deleteById(id)
    .then(res => onDeleteSupervisorSuccess({dispatch, getState}))
    .catch(err => onDeleteSupervisorFailure({dispatch, err}))
}

// TODO: REFACTOR - HOT RELOAD
function onDeleteSupervisorSuccess({dispatch, getState}) {
  console.log('DeleteSupervisor success');
  const supervisor = getState().supervisors
  // const supervisors = supervisor.filter(el => el.id !== supervisor.id)

  dispatch({
    type: DELETE_SUPERVISOR_SUCCESS,
    payload: supervisor,
  })
}

function onDeleteSupervisorFailure({dispatch, err}) {
  console.log('DeleteSupervisor failure', err)
  const payload = err.message || 'DeleteSupervisor Failed'
  window.alert(payload)
  dispatch({
    type: DELETE_SUPERVISOR_FAILURE,
    payload,
  })
}


export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  }
}

export const clearState = () => {
  return {
    type: CLEAR_STATE,
  }
}
