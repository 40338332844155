import React, { Component } from 'react';

import { Button, FloatBox, Input } from '../../../../common';

import * as cookies from 'js-cookie'

import './MainWindow.css'

class MainWindow extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            friendID: ''
         }
    }
    render() { 
        const { friendID } = this.state
        const { callHandler } = this.props

        /**
         * Start the call with or without video
         * @param {Boolean} video
         */
        const callWithVideo = (video) => {
            const config = { audio: true, video };
            return () => friendID && callHandler(true, friendID, config);
        };

        return (
            <FloatBox className="container main-window">
                <div>
                <h3>
                    Hi, your ID is
                    <Input
                        type="text"
                        className="txt-clientId"
                        value={this.props.clientId}
                    />
                </h3>
                <h4>Get started by calling a friend below</h4>
                </div>
                <div>
                <Input
                    type="text"
                    className="txt-clientId"
                    placeholder="Your friend ID"
                    value={friendID}
                    onChange={friendID => this.setState({ friendID })}
                />
                <div>
                    <Button
                    type="button"
                    className="btn-action fa fa-video-camera"
                    onClick={callWithVideo(true)}
                    />
                    <Button
                    type="button"
                    className="btn-action fa fa-phone"
                    onClick={callWithVideo(false)}
                    />
                </div>
                </div>
            </FloatBox>
        );
    }
}
 
export default MainWindow;