import React, { Component } from 'react';
import { connect } from 'react-redux'
import * as cookies from 'js-cookie';
import * as jwt from 'jsonwebtoken';

import {
  Selector,
  Button,
  Header,
  Body,
  Input,
  Form,
  Span,
  FileUpload,
  Icon,
} from '../../../../common'

import ModelValidations from '../../../../../lib/model-validations';

// Actions
import { createCommunityMember, verifyEmail, init } from '../../../../../actions/community'

import logo from '../../../assets/Muvor.png'

import styles from './AddMember.css'

class AddMember extends Component {
  constructor(props) {
    super(props)
    this.state = {
      accessToken: null,
      payModal: {},
      name: '',
      email: '',
      deposit: 0,
      me: cookies.get('me') && JSON.parse(cookies.get('me')) ? JSON.parse(cookies.get('me')) : undefined 
    }
  }

  componentDidMount() {
    // this.props.init()
    sessionStorage.setItem('deposit', '0')
  }

  onChange = deposit => {
    if ( deposit >= 0) {
      this.setState({ deposit })
      sessionStorage.setItem('deposit', deposit)
    }
  }
  
  generateAccessCode(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!%^&()<>?/';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    // result = result.match(/.{1,5}/g).join('-')
    return result;
  }

  onSubmit(e) {
    e.preventDefault()
    const {
      name,
      email,
      deposit,
      me
    } = this.state
    // console.log('onSubmit', data)
    const accessCode = this.generateAccessCode(16);
    const access = {
      contact: email,
      accessCode
    }
    const data = {
      accessCode,
      name,
      email,
      deposit
    }
    sessionStorage.setItem('formDatum', JSON.stringify(data))
    this.props.verifyEmail(access)
    window.location.replace('/community/add/verify')
  }

  get invalid() {
    const {
      name,
      email
    } = this.state

    return !(
      name.length > 0
      && email.length > 0
    )
  }

  render() {
    const {
      deposit,
      name,
      email
    } = this.state
    const {
      superDisplay,
      employeesDisplay
    } = this.props

    const employeeOptions = [
      {
        key: '',
        value: '',
        display: 'unassigned',
      },
      // ...employeesDisplay.map(e => ({
      //   key: e._id,
      //   value: e._id,
      //   display: e.name,
      // })),
    ]
    
    const superOptions = [
      {
        key: '',
        value: '',
        display: 'unassigned',
      },
      // ...superDisplay.map(s => ({
      //   key: s._id,
      //   value: s._id,
      //   display: s.name,
      // })),
    ]
    // if (this.state.deposit > 0) {
    //   return (
    //     <Paymodal
    //       content={this.state.payModal}
    //       onClickAway={() => {this.setState({payModal: null});window.location.replace('/home/community/add')}}
    //     />
    //   )
    // } else {
      return (
        <div>
          <Body className={styles.body}>
            <img src={logo} className={styles.logo}/>
            <Span className={styles.title}> Muvor Coin: Community Token </Span>
            <Form
              className="form" >
              <br />

              <label>
                Pseudonym:
              </label>
              <Input
                placeholder="..."
                value={name}
                onChange={name => this.setState({ name })}
              />
              
              <br />

              <label>
                Contact (Email):
              </label>
              <Input
                placeholder="..."
                value={email}
                onChange={email => this.setState({ email })}
              />

              <br />
{ 
              // <label>
              //   Coin Photo (You can change this later):
              // </label>
              
              // <div className={styles.photoList}>
              // {
              //   this.state.photo.length ? (
              //   this.state.photo.map(this.renderPhotoItem.bind(this))
              //   ) : (
              //     <span>
              //       None
              //     </span>
              //     )
              //   }
              //   </div>
              //   <FileUpload
              //     id="add-photo"
              //     wide
              //     large
              //     confirm
              //     title="PHOTOS"
              //     accept={'image/jpeg,image/png'}
              //     inactive={this.props.loading}
              //     onUpload={photo => {var a=this.state.photo.slice();a.push(photo);this.setState({photo:a})}}
              //     progress={this.props.progress}
              //   />
              // <br />
               
              // <label>
              //   $MVRX (Recommended):
              // </label>
              // <div className={styles.ua}>
              //   <Input className={styles.users} type='number' value={this.state.deposit} placeholder='0' onChange={this.onChange}/>
              //   <h5>{UTILS.formatUSD(this.state.deposit*100)}</h5>
              // </div>
              // <br />  
 }
              <div className={styles.buttons}>
                <Button
                  large
                  cancel
                  link="/community"
                  title="CANCEL"
                />
                <Button
                  large
                  type="submit"
                  title="Fork Yourself!"
                  inactive={this.invalid || this.props.loading}
                  onClick={this.onSubmit.bind(this)}
                />
              </div>
            </Form>
          </Body>
        </div>
      );
    }
  // }
}

const mapStateToProps = ({ main }) => {
  const {
    loading
  } = main

  return {
    loading,
  }
}

const actions = {
  createCommunityMember,
  verifyEmail,
  init
}

export default connect(mapStateToProps, actions)(AddMember)
