import React, {Component} from 'react';
import { connect } from 'react-redux'
import logo from './../assets/logo.png';
import emailLogo from './../assets/email.png';
import mobileLogo from './../assets/phone-call.png';
import githubLogo from './../assets/github.png';
import ContactInfo from './ContactInfo';
import Logo from './Logo';
import Name from './Name';
import WorkInfo from './WorkInfo';

// Actions
import {
  getSelf,
  patchSelf,
} from '../../../../../actions/auth'

import * as cookies from 'js-cookie';

import styles from '../ProfileCard.css';


function formatName(user) {
  return user.firstName + ' ' + user.lastName;
}

class BusinessCard extends Component {

  render() {
    const user = cookies.get('me') && JSON.parse(cookies.get('me')) ? JSON.parse(cookies.get('me')) : undefined 
    console.log(user)
    return (
      <div className={styles.card}>
        <div className="Business-card">
          <div className="Logo-container">
            <Logo logo={logo}/>
          </div>
          <div className="Basic-info">
            <Name name={user.name}/>
            <WorkInfo position={user.role}/>
            <div className="Contact-info">
              <ContactInfo logo={emailLogo} value={user.email} type="E-mail" />
              <ContactInfo logo={githubLogo} value={user.github} type="GitHub" />
              <ContactInfo logo={mobileLogo} value={user.mobile} type="Mobile" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({auth}) => {
  const {
    me,
    loading,
  } = auth
  return {
    loading,
    me,
  }
}

const actions = {
  patchSelf,
  getSelf,
}

export default connect(mapStateToProps, actions)(BusinessCard)