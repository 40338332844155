import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as cookies from 'js-cookie';
import {   
  getProjects,
  deleteProjectById
} from '../../../../actions/projects';
import { 
  ActionBox, 
  Button, 
  SearchBar, 
  Table 
} from '../../../common';

import ProjectRow from './ProjectRow';
import styles from '../ProjectsPage.css';


class Projects extends Component {
  constructor (props) {
    super (props)
    this.state = {
      name: '',
      creationDate: ''
    } 
  }
  componentDidMount() {
    this.props.getProjects()
  }

  searchByName(name) {
    const creationDate = ''
    this.setState({ name, creationDate })
    this.props.filterEmployeesByName(name)
  }

  searchByCreation(creationDate) {
    const name = ''
    this.setState({ name, creationDate })
    this.props.filterEmployeesByDob(creationDate)
  }

  renderTableHead() {
    const { onDelete, onRestore, timesheets } = this.props;

    return (
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
          </tr>
        </thead>
    );
  }

  renderTableBody() {
    const { projects } = this.props;
    // let filteredProducts = projects.filter (project => {
    //     return project.name.indexOf(this.state.search) !== -1
    //   }
    // )
    if (!projects) {
      return (
        <tbody>
          <tr>
            <td colSpan="9" className="empty">
              No Projects Found
            </td>
          </tr>
        </tbody>
      )
    } else {
      return (
        <tbody>
          {
          Array.isArray(projects) && projects.map (p => (
            <ProjectRow project={ p } /* onDelete={ deleteTimesheet } onRestore={ restoreTimesheet } *//>
          ))
          }
        </tbody>
      )
    }
  }

  render() {
    const { projects } = this.props;
    const { name, creationDate } = this.state;

    return (
      <div className={styles.app}>
        <div className="body">
          <ActionBox>
            <div className="main">
              <SearchBar
                label="Search By Employee"
                placeholder="First or Last Name..."
                value={name}
                onChange={this.searchByName.bind(this)}
              />
              <SearchBar
                label="Search By Creation Date"
                type="date"
                value={creationDate}
                onChange={this.searchByCreation.bind(this)}
              />
              <Button
                search
                icon="search"
                title="SEARCH"
              />
            </div>
            <div className="action">
              <Button
                link="/projects/add"
                icon="plus"
                title="NEW PROJECT"
              />
            </div>
          </ActionBox>
          </div>
          <Table bordered striped hover>
          {/* onclick render empty timesheet obj*/}
          {this.renderTableHead()}
          {this.renderTableBody()}
        </Table>
      </div>
    );
  }
}

const mapStateToProps = ({ projects }) => {
  const {
    projectsDisplay
  } = projects

  return {
    projects: projectsDisplay
  }
}

const actions = {
  getProjects,
  deleteProjectById
}

export default connect(mapStateToProps, actions)(Projects);
