// SOCKET ACTIONS
export const CONNECTION = 'connection'
export const CONNECTION_SUCCESS = 'connection_success'
export const CONNECTION_FAILURE = 'connection_failure'

export const SOCKET_SET = 'socket_set'
export const SOCKET_CLEAR = 'socket_clear'

// Messaging
export const SOCKET_STATUS = 'socket_status::changed';

export const SOCKET_LOAD = 'socket_load';
export const SOCKET_SEND = 'socket_send';
export const SOCKET_RECEIVE = 'socket_receive';
export const SOCKET_ADD = 'socket_add';

export const SOCKETS_REMOVE = 'sockets_remove';

export const SOCKETS_AWAY = 'sockets_away';
export const SOCKET_ROOM_ADD = 'sockets_room_add';
export const SOCKET_SET_AUDIO = 'sockets_set_audio';
export const SOCKET_SET_VIDEO = 'sockets_set_video';

export const MVRX = 'mvrx'

export const MVRX_SUCCESS = 'mvrx_success'

export const MVRX_FAILURE = 'mvrx_success'


