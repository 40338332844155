export const LIST = 'LIST_TIMESHEETS';
export const GET = 'GET_TIMESHEET';

// timesheet
export const CREATE_TIMESHEET = 'create_timesheet'
export const CREATE_TIMESHEET_SUCCESS = 'create_timesheet_success'
export const CREATE_TIMESHEET_FAILURE = 'create_timesheet_failure'

export const SET_TIMESHEET = 'set_timesheet'

export const GET_TIMESHEET = 'get_timesheet'
export const GET_TIMESHEET_SUCCESS = 'get_timesheet_success'
export const GET_TIMESHEET_FAILURE = 'get_timesheet_failure'

//current tracking time entry item
export const TIME_ENTRY_INPUT__PULL               = 'TIME_ENTRY_INPUT__PULL'
export const TIME_ENTRY_INPUT__START              = 'TIME_ENTRY_INPUT__START'
export const TIME_ENTRY_INPUT__STOP               = 'TIME_ENTRY_INPUT__STOP'
export const TIME_ENTRY_INPUT__CHANGE_START_TIME  = 'TIME_ENTRY_INPUT__CHANGE_START_TIME'
export const TIME_ENTRY_INPUT__CHANGE_TEXT        = 'TIME_ENTRY_INPUT__CHANGE_TEXT'
export const TIME_ENTRY_INPUT__REMOVE             = 'TIME_ENTRY_INPUT__REMOVE'
export const TIME_ENTRY_INPUT__ASSIGN_TAG         = 'TIME_ENTRY_INPUT__ASSIGN_TAG'
export const TIME_ENTRY_INPUT__ASSIGN_TAG_ID      = 'TIME_ENTRY_INPUT__ASSIGN_TAG_ID'

//time entries
export const TIME_ENTRIES_FETCH_LIST  = 'TIME_ENTRIES_FETCH_LIST'
export const FETCH_LIST_SUCCESS  = 'FETCH_LIST_SUCCESS'
export const FETCH_LIST_FAILURE  = 'FETCH_LIST_FAILURE'
export const TIME_ENTRIES_REMOVE      = 'TIME_ENTRIES_REMOVE'
export const TIME_ENTRIES__ASSIGN_TAG = 'TIME_ENTRIES__ASSIGN_TAG'
export const TIME_ENTRIES__ASSIGN_TAG_ID = 'TIME_ENTRIES__ASSIGN_TAG_ID'

//report
export const REPORT_FETCH = 'REPORT_FETCH'

//tags
export const FETCH_LIST = 'FETCH_LIST'