// React
import React, { Component } from 'react'
import NavBar from './NavBar/NavBar'

import 'react-spinner/react-spinner.css'
import styles from './TopNav.css'

class TopNav extends Component {
  constructor(props) {
    super(props);
    this.state = {  }
  }
  render() { 
    return ( 
      <div className={styles.topNav}>
        <NavBar />
      </div>
     );
  }
}
 
export default TopNav
