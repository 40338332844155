import React from 'react';
import {
  Route,
  Switch,
  Navigate
} from 'react-router-dom'

// Components
import Role from "../Role/Role";
import RoleForm from "../Role/RoleForm/RoleForm";
import Position from "../Position/Position";
import Department from "../Department/Department";
import AdminPortal from "./AdminPortal/AdminPortal";
import AdminProjectBid from "./AdminProjectBid/AdminProjectBid";
import NotFound from '../../../NotFound/NotFound'


const HrDashboardRouter = props => (
  <Switch>
    <Route exact path="/hr/admin/role" component={Role} />
    {/* <Route path="/admin/role/form" exact component={RoleForm} /> */}
    <Route
        path="/hr/admin/position"
        exact
        component={Position}
    />
    <Route
        path="/hr/admin/department"
        exact
        component={Department}
    />
        <Route
        path="/hr/admin/portal-master"
        exact
        component={AdminPortal}
    />
        <Route
        path="/hr/admin/project-bid"
        exact
        component={AdminProjectBid}
    />

    <Route
      component={NotFound}
    />
  </Switch>
)

export default HrDashboardRouter
