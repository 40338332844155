import React, { Component } from 'react';
import { connect } from 'react-redux'
import qs from 'query-string'

// Components
import {
  Header,
  ButtonGroup,
} from '../../../common'

import RepDashboard from '../RepDashboard/RepDashboard'
import AdminDashboard from '../AdminDashboard/AdminDashboard'

import styles from './DashboardView.css'

class DashboardView extends Component {
  componentDidMount() {
    if (!this.params.view) {
      this.graphView = 'day'
    }
  }

  get params() {
    const params = qs.parse(this.props.location.search)
    return params
  }

  set graphView(view) {
    const newParams = qs.stringify({...this.params, view})
    this.props.history.push({
      search: newParams,
    })
  }

  render() {
    const buttonOptions = [
      { option: 'Day',   value: 'day'   },
      { option: 'Week',  value: 'week'  },
      { option: 'Month', value: 'month' },
      { option: 'Year',  value: 'year'  },
    ]

    const { me, isAdmin } = this.props
    
    return (
      <div  className={styles.body}>
        <Header>
          {isAdmin ? (
            <h2>
              Administrator Dashboard
            </h2>
          ) : (
            <h2>
              Sales Dashboard
            </h2>
          )
          // : (
          //   <h2>
          //     Welcome
          //   </h2>
          // )
        
        }

          <ButtonGroup
            className={styles.bg}
            options={buttonOptions}
            onPress={view => this.graphView = view}
            selected={this.params.view}
          />
        </Header>

        {!me ? (
          null
        ) : isAdmin ? (
          <AdminDashboard
            view={this.params.view}
          />
        ) : (
          <RepDashboard
            view={this.params.view}
          />
        ) 
      }
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const {
    isAdmin,
    me,
  } = auth

  return {
    isAdmin,
    me,
  }
}

export default connect(mapStateToProps, null)(DashboardView);
