import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Projects from "./projects/Projects";
import ProjectsDetail from './projects/ProjectsDetail';
import AddProject from './projects/AddProject/AddProject';

const ProjectsPageRouter = comp => (
  <Switch>
    <Route 
      exact
      path="/projects" 
      component={ Projects } 
    />
    
    <Route 
      exact
      path="/projects/add" 
      component={ AddProject } 
    />
    
    <Route 
      exact
      path="/projects/:_id" 
      component={ ProjectsDetail } 
    />
  </Switch>
);

export default ProjectsPageRouter;
