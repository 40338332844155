import React, { Component } from 'react';
import { connect } from 'react-redux'
import * as cookies from 'js-cookie'
import validator from 'validator'
import { 
  CardCVCElement, 
  CardExpiryElement, 
  CardNumberElement, 
  Elements, 
  StripeProvider 
} from 'react-stripe-elements';

import {
  Selector,
  Button,
  Header,
  Body,
  Input,
  Form,
  FormModal,
  FileUpload,
  Icon,
} from '../../../../common'

import styles from './PaymentPage.css'


class PaymentPage extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      type: 'card',
      card: 0,
      exp: '',
      cvc: 0,
      country: 'US',
      currency: 'usd',
      accountHolder: '',
      accountType: 'individual',
      routingNumber: 0,
      accountNumber: 0,
     }
  }

  // get inactive() {
  //   const rep = this.state.import > 0 ? this.state.import : 0
  //   return !rep 
  // }

  get options() {
    return [
      {
        value: '',
        display: 'Select',
      },
      {
        value: 'card',
        display: 'Card',
      },,
      {
        value: 'bank',
        display: 'Bank Account',
      },
    ]
  }

  submit(e) {
    e.preventDefault()
    const {
      type,
      card,
      exp,
      cvc,
      country,
      currency,
      accountHolder,
      accountType,
      routingNumber,
      accountNumber
    } = this.state

    if (type==='card') {
      const data = {
        type,
        card,
        exp,
        cvc,
      }
      this.props.onSubmit(data)
      this.props.onClickAway()
    }
    if (type==='bank') {
      const data = {
        type,
        country,
        currency,
        accountHolder,
        accountType,
        routingNumber,
        accountNumber
      }
      this.props.onSubmit(data)
      this.props.onClickAway()
    }
  }

  render() {
    const {
      content,
      onClickAway,
      loading,
      progress,
      onSubmit,
    } = this.props
    const {
      type,
      card,
      exp,
      cvc,
      accountHolder,
      routingNumber,
      accountNumber
    } = this.state

    return (
      <FormModal
        onClickAway={onClickAway}
        visible={!!content}
        className={styles.modal}
      >

        <label>
          Type:
        </label>
        <Selector
          wide
          value={this.state.type}
          onSelect={i => this.setState({ type: i })}
          options={this.options}
        /> 
        <br />
        {type==='card' ? (
          <div>
            <label>
              Card details
            </label>
            <Input
              placeholder="XXXX XXXX XXXX XXXX"
              value={card}
              onChange={card => this.setState({ card })}
              maxlength="16"
            />

            <br />

            <label>
              Expiration date
            </label>
            <Input
              placeholder="MM/YYYY"
              value={exp}
              onChange={exp => this.setState({ exp })}
              maxlength="7"
            />

            <br />
            <label>
              CVC
            </label>
            <Input
              placeholder="123"
              value={cvc}
              onChange={cvc => this.setState({ cvc })}
              maxlength="3"
            />
          </div>  
        ) : type==='bank' && (
          <div>
            <label>
              Account Holder Name:
            </label>
            <Input
              placeholder="Full Name"
              value={accountHolder}
              onChange={accountHolder => this.setState({ accountHolder })}
            />

            <br />

            <label>
              Routing Number:
            </label>
            <Input
              placeholder="9 digits"
              value={routingNumber}
              onChange={routingNumber => this.setState({ routingNumber })}
              maxlength="9"
            />

            <br />
            <label>
              Account Number:
            </label>
            <Input
              placeholder="account #"
              value={accountNumber}
              onChange={accountNumber => this.setState({ accountNumber })}
              maxlength="20"
            />
          </div>
        )}
        <br />
        <div className="buttons">
          <Button
            large
            cancel
            type="button"
            title="Cancel"
            onClick={onClickAway}
          />
          <Button
            large
            title="Create"
            inactive={this.inactive}
            onClick={this.submit.bind(this)}
          />
        </div>
      </FormModal>
    )
  }
}
 
const mapStateToProps = ({ main }) => {
  const {
    loading,
    progress
  } = main

  return {
    loading,
    progress
  }
}

const actions = {
}

export default connect(mapStateToProps, actions)(PaymentPage)
