import React from 'react';
import cn from 'classnames'

// Components
import {
  Icon,
} from '../../../../../../common'

import styles from './Day.css'

const Day = ({day, reps, modal, onClick, today, schedule}) => {
  const className = cn(styles.day, { outside: !day, today })
  console.log(schedule)
  if (!day) {
    return (
      <div className={className}/>
    )
  } else {
    return (
      <div className={className} onClick={modal}>
        <Icon>
          {day}
        </Icon>
        {reps.map(rep => (
          <span
            key={rep.id}
            className="rep-name"
            onClick={() => onClick(rep)}
          >
            {rep.nameDisplay || 'Rep'}
          </span>
        ))}
      </div>
    )
  }
}

export default Day;
