// MAIN FLOW
export const GET_VISITS = 'main_get_visits'
export const GET_VISITS_SUCCESS = 'main_get_visits_success'
export const GET_VISITS_FAILURE = 'main_get_visits_failure'

export const GET_SUPERVISORS = 'main_get_supervisors'
export const GET_SUPERVISORS_SUCCESS = 'main_get_supervisors_success'
export const GET_SUPERVISORS_FAILURE = 'main_get_supervisors_failure'
export const FILTER_SUPERVISORS = 'main_filter_supervisors'

export const GET_TEAM_MEMBERS = 'main_get_team_members'
export const GET_TEAM_MEMBERS_SUCCESS = 'main_get_team_members_success'
export const GET_TEAM_MEMBERS_FAILURE = 'main_get_team_members_failure'
export const FILTER_TEAM_MEMBERS = 'main_filter_team_members'

export const GET_USER = 'main_get_user'
export const GET_EMPLOYEES = 'main_get_employees'
export const GET_EMPLOYEES_SUCCESS = 'main_get_employees_success'
export const GET_EMPLOYEES_FAILURE = 'main_get_employees_failure'
export const FILTER_EMPLOYEES = 'main_filter_employees'

export const GET_REPS = 'main_get_reps'
export const GET_REPS_SUCCESS = 'main_get_reps_success'
export const GET_REPS_FAILURE = 'main_get_reps_failure'

export const CREATE_VISIT = 'main_create_visit'
export const CREATE_VISIT_SUCCESS = 'main_create_visit_success'
export const CREATE_VISIT_FAILURE = 'main_create_visit_failure'

export const GET_ASSIGNMENTS = 'main_get_assignments'
export const GET_ASSIGNMENTS_SUCCESS = 'main_get_assignments_success'
export const GET_ASSIGNMENTS_FAILURE = 'main_get_assignments_failure'

export const CREATE_ASSIGNMENT = 'main_create_assignment'
export const CREATE_ASSIGNMENT_SUCCESS = 'main_create_assignment_success'
export const CREATE_ASSIGNMENT_FAILURE = 'main_create_assignment_failure'

export const DELETE_ASSIGNMENT = 'delete_assignment'
export const DELETE_ASSIGNMENT_SUCCESS = 'delete_assignment_success'
export const DELETE_ASSIGNMENT_FAILURE = 'delete_assignment_failure'

export const UPLOAD_FILE = 'main_upload_file'
export const UPLOAD_FILE_PROGRESS = 'main_upload_file_progress'
export const UPLOAD_FILE_SUCCESS = 'main_upload_file_success'
export const UPLOAD_FILE_FAILURE = 'main_upload_file_failure'

export const SET_PHOTOS = 'main_set_photos'

export const CLEAR_PHOTOS = 'main_clear_photos'
export const CLEAR_STATE = 'main_clear_state'

export const CLEAR_ERRORS = 'main_clear_errors'
