import React, { Component } from 'react';
import Router from './EmployeesPage.Router'

import styles from './EmployeesPage.css'

class EmployeesPage extends Component {
  render() {
    return (
      <div className={styles.app}>
        <Router />
      </div>
    );
  }
}

export default EmployeesPage;
