import React, { Component } from 'react';
import { Icon, Button } from '../../../../common';
import styles from '../Timesheet.css'
import { connect } from 'react-redux';
import { setTimesheet } from '../../../../../actions/timesheets';

class TimesheetRow extends Component {
  
  handleClick = (event) => {
    const { timesheet, onDelete, onRestore } = this.props;

    if (timesheet.deleted) {
      onRestore(timesheet);
    } else {
      onDelete(timesheet);
    }

    event.stopPropagation();
  };

  // showDetail = () => {
  //   const { history, timesheet } = this.props;

  //   if (timesheet.deleted) {
  //     console.log('You cannot edit a deleted timesheet.');
  //     return;
  //   }

  //   history.push(`/reports/timesheet/${timesheet._id}`);
  // };

  renderIcons() {
    const { editing } = this.state
    const { isAdmin } = this.props
    if (editing) {
      return (
        <td className="icons">
          <Icon
            small
            cancel
            onClick={() => this.setEditState(false)}
          />
          <Icon
            small
            save
            onClick={() => this.save()}
          />
        </td>
      )
    } else {
      return (
        <td className="icons">
          <Icon
            small
            edit
            onClick={() => this.setEditState(true)}
          />
          <Icon
            small
            cancel
            onClick={() => this.delete()}
          />
        </td>
      )
    }
  }

  render() {
    const { timesheet } = this.props;

    console.log(timesheet)

    return (
      <tr>
        <td>{timesheet.startDate}</td>
        <td>{timesheet.time}</td>
        <td>{timesheet.payout}</td>
        <td>{timesheet.notes}</td>
        <td className={styles.detailsCell}>
          <Button
            title="DETAILS"
            link={`/reports/timesheet/${timesheet._id}`}
            onClick={() => this.props.setTimesheet(timesheet)}
          />
        </td>
      </tr>
    );
  }
}

const mapStateToProps = ({ timesheets, main }) => {
  const {
    timesheet
  } = timesheets

  const {
  } = main

  return {
    timesheet
  }
}

const actions = {
  setTimesheet
}

export default connect(mapStateToProps, actions)(TimesheetRow);