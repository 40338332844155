import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Timesheets from './timesheets/Timesheets';
import TimesheetsDetail from './timesheets/TimesheetsDetail';

class TimesTestRouter extends Component {
  render() {
    return (
      <Switch>
        <Route 
          path="/reports/timesheet/timesheets" 
          component={ Timesheets } 
        />
        
        <Route 
          exact
          path="/reports/timesheet/:id" 
          component={ TimesheetsDetail } 
        />
      </Switch>
    );
  }
}

export default TimesTestRouter;
