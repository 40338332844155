import React, { Component } from 'react';
import Router from './SupervisorsPage.Router'

import styles from './SupervisorsPage.css'

class SupervisorsPage extends Component {
  render() {
    return (
      <div>
        <div className={styles.app}>
          <Router />
        </div>
      </div>
    );
  }
}

export default SupervisorsPage;
