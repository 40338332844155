import { contactsTypes } from '../actions/types'

const {
  GET_CONTACTS,
  GET_CONTACTS_SUCCESS,
  GET_CONTACTS_FAILURE,
  FILTER_CONTACTS,
  CREATE_CONTACT,
  CREATE_CONTACT_FAILURE,
  CREATE_CONTACT_SUCCESS,
  SET_CONTACT,
  GET_CONTACT,
  GET_CONTACT_FAILURE,
  GET_CONTACT_SUCCESS,
  PATCH_CONTACT,
  PATCH_CONTACT_FAILURE,
  PATCH_CONTACT_SUCCESS,
  CREATE_NOTE,
  CREATE_NOTE_SUCCESS,
  CREATE_NOTE_FAILURE,
  DELETE_CONTACT,
  DELETE_CONTACT_FAILURE,
  DELETE_CONTACT_SUCCESS,
  CLEAR_ERRORS,
  CLEAR_STATE,
} = contactsTypes

const INITIAL_STATE = {
  contacts: [],
  contactsDisplay: [],
  error: '',
  loading: false,
}

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action
  switch (type) {
    case GET_CONTACTS:
      return {
        ...state,
        loading: true,
      }
    case GET_CONTACTS_SUCCESS:
      return {
        ...state,
        contacts: payload,
        contactsDisplay: payload,
        loading: false,
      }
    case GET_CONTACTS_FAILURE:
      return {
        ...state,
        contacts: [],
        contactsDisplay: [],
        error: payload,
        loading: false,
      }
    case FILTER_CONTACTS:
      return {
        ...state,
        contactsDisplay: payload,
      }
    case CREATE_CONTACT:
      return {
        ...state,
        loading: true,
      }
    case CREATE_CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case CREATE_CONTACT_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      }
    case SET_CONTACT:
      return {
        ...state,
        contact: payload,
      }
    case GET_CONTACT:
      return {
        ...state,
        loading: true,
      }
    case GET_CONTACT_SUCCESS:
      return {
        ...state,
        contact: payload,
        loading: false,
      }
    case GET_CONTACT_FAILURE:
      return {
        ...state,
        contact: null,
        error: payload,
        loading: false,
      }
      case DELETE_CONTACT:
      return {
        ...state,
        loading: true,
      }
    case DELETE_CONTACT_SUCCESS:
      return {
        ...state,
        contact: payload,
        contactDisplay: payload,
        loading: false,
      }
    case DELETE_CONTACT_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      }
    case PATCH_CONTACT:
      return {
        ...state,
        loading: true,
      }
    case PATCH_CONTACT_SUCCESS:
      return {
        ...state,
        contact: payload,
        loading: false,
      }
    case PATCH_CONTACT_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      }
    case CREATE_NOTE:
      return {
        ...state,
        loading: true,
      }
    case CREATE_NOTE_SUCCESS:
      return {
        ...state,
        contact: payload,
        loading: false,
      }
    case CREATE_NOTE_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      }
    case CLEAR_STATE:
      return {
        ...INITIAL_STATE,
      }
    case CLEAR_ERRORS:
      return {
        ...state,
        error: '',
        loading: false,
      }
    default:
      return state
  }
}
