import React, { Component } from "react";
import "./Lifecycle.css";

class Lifecycle extends Component {
  constructor (props) {
    super (props)
    this.state = {
      category: {},
      tasks: [
        { name: "Learn", category: "progress", bgcolor: "yellow" },
        { name: "React", category: "progress", bgcolor: "pink" },
        { name: "Vue", category: "complete", bgcolor: "skyblue" },
        { name: "Extra", category: "complete", bgcolor: "skyblue" }
      ]
    };
  }

  onDragStart = (ev, id) => {
    console.log("dragstart:", id);
    ev.dataTransfer.setData("id", id);
  };

  onDragOver = ev => {
    ev.preventDefault();
  };

  onDrop = (ev, cat) => {
    let id = ev.dataTransfer.getData("id");

    let tasks = this.state.tasks.filter(task => {
      if (task.name == id) {
        task.category = cat;
      }
      return task;
    });

    this.setState({
      ...this.state,
      tasks
    });
  };

  groupBy (list, keyGetter) {
    const map = new Map();
    list.forEach(l => {
      const key = keyGetter(l);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [l]);
      } else {
        collection.push(l)
      }
    })
    return map
  }

  render() {
    const {
      tasks,
      category
    } = this.state

    const groups = this.groupBy(tasks, task => task.category)
    const grpArr = Array.from(groups)
    grpArr.forEach(group => {
      group[1].map(g => {
        category[g.category] = { group }
        category[group[0]].group.push(
          <div
            key={g}
            onDragStart={e => this.onDragStart(e, g.name)}
            draggable
            className="draggable"
            style={{ backgroundColor: g.bgcolor }}
          >
            {g.name}
          </div>
        );
      })
    });
    console.log(category)
    return (
      <div className="container-drag">
        { grpArr.map(group => {
          
            return (
              <div
                className="tasks"
                onDragOver={e => this.onDragOver(e)}
                onDrop={e => this.onDrop(e, group[0])}
              >
                <span className="task-header">{group[0]}</span>
                {group.slice(2) || ''}
              </div>
            )
          })   
        }
      </div>
    );
  }
}

export default Lifecycle
