import io from 'socket.io-client';

// const socket = io('ws://192.168.0.199:9999', { withCredentials: true, path: "/tower" });
const socket = io(`https://api.muvor.com`, { withCredentials: true, path: "/tower" });

// const chat = io('ws://192.168.0.199:9999', { withCredentials: true, path: "/tower-0" });
const chat = io(`https://api.muvor.com`, { withCredentials: true, path: "/tower-0" });

// const live = io('ws://192.168.0.199:9999', { withCredentials: true, path: "/tower-1" });
const live = io(`https://api.muvor.com`, { withCredentials: true, path: "/tower-1" });


export { socket, chat, live };
