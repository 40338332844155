import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as timesheet from '../../../../../actions/timesheets';
import * as employee from '../../../../../actions/main';
import SheetView from './SheetView/SheetView';

class TimesheetsDetail extends Component {

  componentDidMount() {
    const { listEmployees } = this.props;
    listEmployees();
  }

  handleSave = (timesheet) => {
    const { history, createTimesheet, updateTimesheet } = this.props;

    const save = timesheet._id ? updateTimesheet(timesheet) : createTimesheet(timesheet);
    save.then(() => {
      history.push('/employees/timesheets');
    });
  };

  render() {
    const { employees, timesheet } = this.props;

    return (
      <div>
        <h1>Timesheet Detail</h1>
        <SheetView
          timesheet={ timesheet }
          employees={ employees }
          handleSave={ this.handleSave }
        />
      </div>
    );
  }
}

// TimesheetsDetail.propTypes = {
//   employees: PropTypes.arrayOf(PropTypes.object),
//   timesheet: PropTypes.object,
//   createTimesheet: PropTypes.func,
//   updateTimesheet: PropTypes.func
// };

const mapStateToProps = (state, props) => {
  const { match } = props;
  const { _id } = match.params;

  return {
    // employees: state.employees.data,
    // timesheet: state.timesheets.data.find(timesheet => timesheet._id === _id)
  };
};

const mapDispatchToProps = {
  createTimesheet: timesheet.createTimesheet,
  updateTimesheet: timesheet.updateTimesheet,
  listEmployees: employee.getEmployees
};

export default connect(mapStateToProps, mapDispatchToProps)(TimesheetsDetail);
