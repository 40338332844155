import { api } from '../config'

export default {
  getAll(cid,uid) {
    const url = `${cid}/employees/${uid}`
    return api.get(url)
  },

  post(data) {
    const url = '/employees'
    return api.post(url, data)
  },

  getById(id) {
    const url = `/employees/${id}`
    return api.get(url)
  },

  patchById(id, update) {
    const url = `/employees/${id}`
    return api.put(url, update)
  },

  postNoteById(id, update) {
    const url = `/employees/${id}/notes`
    return api.post(url, update)
  },
}
