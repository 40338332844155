import React, { Component } from 'react';

class Graph extends Component {
    componentDidMount() {
        const mvrx = [
            {x: 0, y: 0},
            {x: 50, y: 10},
            {x: 100, y: 20},
            {x: 150, y: 30},
            {x: 200, y: 10},
            {x: 250, y: 40},
            {x: 300, y: 10},
            {x: 350, y: 30},
            {x: 400, y: 20},
            {x: 450, y: 10},
            {x: 500, y: 0}
        ];

        const stopArr = this.stop(mvrx, 100, 1);

        this.setState({
            stops: stopArr
        });
    }

    stop = (pointArr, totPoints, mode) => {
        let stops = [];
        pointArr.forEach((p, i) => {
            let numPoints = totPoints + i;
            let relMean = p.y / mode;
            let hexVal = `#${Math.floor(relMean * 255).toString(16).padStart(2, '0').repeat(3)}`;
            stops.push(<stop offset={`${p.x / 5}%`} stopColor={hexVal} key={i} />);
        });
        return stops;
    }

    render() {
        const squareSize = 6;
        const squareSpacing = 0;
        const squareHeight = squareSize * 4; // Adjusted height

        let squares = [];
        let numSquares = 0;

        if (this.state && this.state.stops) {
            numSquares = this.state.stops.length;
        }

        for (let i = 0; i < numSquares; i++) {
            const grayscaleValue = Math.floor(i * (255 / numSquares));
            const grayscaleColor = `rgb(${grayscaleValue}, ${grayscaleValue}, ${grayscaleValue})`;

            // Original square
            squares.push(<rect x={i * squareSize} y="246" width={squareSize} height={squareSize} fill={grayscaleColor} key={i} />);

            // Additional square below with gradient
            squares.push(
                <rect
                    x={i * squareSize}
                    y={246 + squareSize} 
                    width={squareSize}
                    height={squareHeight}
                    fill={`url(#gradient-${i})`}
                    key={`grad-${i}`}
                />
            );

            // Gradient definition
            squares.push(
                <defs key={`def-${i}`}>
                    <linearGradient id={`gradient-${i}`} gradientTransform="rotate(90)">
                        <stop offset="0%" stopColor={grayscaleColor} />
                        <stop offset="100%" stopColor="white" />
                    </linearGradient>
                </defs>
            );
        }

        return (
            <svg width="500" height="500" viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
                <filter id="displacementFilter">
                    <feDisplacementMap in2="turbulence" in="SourceGraphic" scale="50" xChannelSelector="R" yChannelSelector="G" />
                </filter>
                <defs>
                    <linearGradient id="myGradient">
                        {this.state && this.state.stops}
                    </linearGradient>
                </defs>
                {squares}
            </svg>
        );
    }
}

export default Graph;
