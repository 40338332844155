// Router
import { push } from 'react-router-redux'

// Cookies
import * as cookies from 'js-cookie'

// Types
import { authTypes } from './types'

// Services
import { auth, users } from '../services'

const {
  EMAIL_CHANGED,
  ACCESS_CHANGED,
  USERNAME_CHANGED,
  PASSWORD_CHANGED,
  CONFIRM_PASSWORD_CHANGED,
  LOGIN,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  GET_SELF,
  GET_SELF_FAILURE,
  GET_SELF_SUCCESS,
  PATCH_SELF,
  PATCH_SELF_FAILURE,
  PATCH_SELF_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  CREATE_PASSWORD,
  CLAIM_ACCOUNT,
  CREATE_PASSWORD_FAILURE,
  CREATE_PASSWORD_SUCCESS,
  CLEAR_ERRORS,
} = authTypes

const me = cookies.get('me') ? JSON.parse(cookies.get('me')) : {}

// Forms
export const usernameChanged = payload => {
  return {
    type: USERNAME_CHANGED,
    payload,
  }
}

export const emailChanged = payload => {
  return {
    type: EMAIL_CHANGED,
    payload,
  }
}

export const accessChanged = payload => {
  return {
    type: ACCESS_CHANGED,
    payload,
  }
}

export const passwordChanged = payload => {
  return {
    type: PASSWORD_CHANGED,
    payload,
  }
}

export const passwordConfirmChanged = payload => {
  return {
    type: CONFIRM_PASSWORD_CHANGED,
    payload,
  }
}

export const login = payload => {
  return dispatch => {
    dispatch({
      type: LOGIN,
    })
    auth.login(payload)
      .then(res => onLoginSuccess({dispatch, res}))
      .catch(res => onLoginFailure({dispatch, res}))
  }
}

function onLoginSuccess({dispatch, res}) {
  console.log('login success');
  const { token, user } = res.data
  console.log(token)
  cookies.set('token', token, { expires: 14 });
  cookies.set('me', JSON.stringify(user), { expires: 14 });
    dispatch({
    type: LOGIN_SUCCESS,
  })
  dispatch(push('/dashboard'))
}

function onLoginFailure({dispatch, res}) {
  console.log('login failure', res);
  dispatch({
    type: LOGIN_FAILURE,
    payload: 'Invalid Credentials',
  })
}

// Contacts
export const getSelf = () => dispatch => {
  dispatch({
    type: GET_SELF,
    payload: me
  })
}

export const getSelfs = () => dispatch => {
  dispatch({
    type: GET_SELF_SUCCESS,
  })
  Promise.resolve()
    .then(res => onGetSelfSuccess({dispatch, res}))
    .catch(err => onGetSelfFailure({dispatch, err}))
}

function onGetSelfSuccess({dispatch, res}) {
  console.log('getSelf success');
  // const { user } = res
  dispatch({
    type: GET_SELF_SUCCESS,
    payload: me,
  })
}

function onGetSelfFailure({dispatch, err}) {
  const message = err.message || 'Get Self Failed'
  console.log('getSelf failure', message);
  window.alert(message)
  dispatch({
    type: GET_SELF_FAILURE,
    payload: message,
  })
}

export const resetPassword = ({ email }) => dispatch => {
  dispatch({
    type: RESET_PASSWORD,
  })
  auth.resetPassword({ email })
    .then(res => onResetPasswordSuccess({dispatch, res}))
    .catch(err => onResetPasswordFailure({dispatch, err}))
}

function onResetPasswordSuccess({dispatch, res}) {
  console.log('resetPassword success');
  dispatch({
    type: RESET_PASSWORD_SUCCESS,
  })
  dispatch(push('/login'))
}

function onResetPasswordFailure({dispatch, err}) {
  const message = err.message || 'Reset Password Failed'
  console.log('resetPassword failure', message);
  window.alert(message)
  dispatch({
    type: RESET_PASSWORD_FAILURE,
    payload: message,
  })
}

export const createPassword = data => dispatch => {
  dispatch({
    type: CREATE_PASSWORD,
  })
  auth.createPassword(data)
    .then(res => onCreatePasswordSuccess({dispatch, res}))
    .catch(err => onCreatePasswordFailure({dispatch, err}))
}

export const claimAccount = data => dispatch => {
  dispatch({
    type: CLAIM_ACCOUNT,
  })
  auth.claimAccount(data)
    .then(res => onCreatePasswordSuccess({dispatch, res}))
    .catch(err => onCreatePasswordFailure({dispatch, err}))
}

function onCreatePasswordSuccess({dispatch, res}) {
  console.log('createPassword success');
  dispatch({
    type: CREATE_PASSWORD_SUCCESS,
  })

  // set new token for Session Storage
  cookies.set('token', res.token)

  dispatch(push('/'))
}

function onCreatePasswordFailure({dispatch, err}) {
  const message = err.message || 'Create Password Failed'
  console.log('createPassword failure', message);
  window.alert(message)
  dispatch({
    type: CREATE_PASSWORD_FAILURE,
    payload: message,
  })
}

export const patchSelf = update => dispatch => {
  dispatch({
    type: PATCH_SELF,
  })
  users.patchSelf(update)
    .then(res => onPatchSelfSuccess({dispatch, res}))
    .catch(err => onPatchSelfFailure({dispatch, err}))
}

function onPatchSelfSuccess({dispatch, res}) {
  console.log('patchSelf success');
  const { user } = res
  dispatch({
    type: PATCH_SELF_SUCCESS,
    payload: user,
  })
}

function onPatchSelfFailure({dispatch, err}) {
  const message = err.message || 'Patch Self Failed'
  console.log('patchSelf failure', message);
  window.alert(message)
  dispatch({
    type: PATCH_SELF_FAILURE,
    payload: message,
  })
}

export const logout = () => dispatch => {
  if (window.confirm('Log out?')) {
    localStorage.clear()
    sessionStorage.clear()
    cookies.remove('token');
    cookies.remove('me');
    dispatch(push('/login'))
  }
}

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  }
}
