import React, { Component } from 'react';

import {
  Table,
  Header,
  ActionBox,
  Button,
  Selector,
  SearchBar,
} from '../../../common'

import styles from './ScriptsView.css'

class ScriptsView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filterValue: 'All',
      searchValue: 'Any',
    }
  }

  renderTableHead() {
    return (
      <thead>
        <tr>
          <th>
            Status
          </th>
          <th>
            Date
          </th>
          <th>
            Supervisor
          </th>
          <th>
            Employee
          </th>
          <th>
            DOB
          </th>
          <th>
            Medication
          </th>
          <th>
            Rep
          </th>
          <th />
        </tr>
      </thead>
    )
  }

  renderTableBody() {
    return (
      <tbody>
      </tbody>
    )
  }

  renderTable() {
    return (
      <Table>
        {this.renderTableHead()}
        {this.renderTableBody()}
      </Table>
    )
  }

  render() {
    const {
      filterValue,
      searchValue,
    } = this.state

    const filterOptions = [
      'All',
      'Review',
      'Received',
      'Processed',
    ]

    const searchOptions = [
      'Any',
      'Name',
      'Email',
    ]

    return (
      <div className={styles.app}>
        <Header>
          <h2>
            Select a Script
          </h2>
        </Header>
        <div className="body">
          <ActionBox>
          
            <div className="main">
            <div className="column">
              <Button
                cancel
                title="RX"
              />
              <Button
                cancel
                title="HC"
              />
            </div>
            
              <Selector
                label="View Status"
                options={filterOptions}
                selected={filterValue}
                onSelect={filterValue => this.setState({ filterValue })}
              />

              <SearchBar
                options={searchOptions}
                selected={searchValue}
                onSelect={searchValue => this.setState({ searchValue })}
                label="Search"
                placeholder="First or Last Name..."
              />
              <Button
                search
                icon="search"
                title="SEARCH"
              />
            </div>
            <div className="action">
              <Button
                icon="plus"
                title="ADD A NEW SCRIPT"
              />
            </div>
          </ActionBox>
          {this.renderTable()}
        </div>
      </div>
    );
  }
}

export default ScriptsView;
