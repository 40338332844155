const expenseStatusOptions = [
  {
    value: 'approved',
    display: 'Approved',
  },
  {
    value: 'pending',
    display: 'Pending',
  },
  {
    value: 'denied',
    display: 'Denied',
  },
]

export default expenseStatusOptions
