import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom'

// Components
import ProductView from './ProductView/ProductView'
import AddProduct from './AddProduct/AddProduct'
import NotFound from '../../NotFound/NotFound'
import AssignmentModal from '../../shared'
import LifecycleView from './LifecycleView/LifecycleView';

const ProductsPageRouter = comp => (
  <Switch>    
    <Route
      exact
      path="/products"
      render={() => <ProductView
        comp={comp}
        />
      }
    /> 

    <Route
      exact
      path="/products/add"
      render={() => <AddProduct
        comp={comp}
        />
      }
    /> 

    <Route
      exact
      path="/products/:productId"
      render={() => <LifecycleView
        comp={comp}
        />
      }
    /> 

     <Route
    //   exact
    //   path="/products/check-in"
    //   component={onInModel}
    />

    <Route
      // exact
      // path="/products/check-out"
      // component={AssignmentModal}
      
    />

    <Route
      component={NotFound}
    />
  </Switch>
)


export default ProductsPageRouter
