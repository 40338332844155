import React, { Component } from 'react';
import Router from './CommunityPage.Router'

import styles from './CommunityPage.css'
import { Button, FormModal, Span } from '../../../common';

class CommunityPage extends Component {
  constructor (props) {
    super (props)
    this.state = {
      verify: {}
    }
    const me = sessionStorage.getItem('my-community-data')
  }
  render() {
    const {
      verify
    } = this.state
    const receipt = sessionStorage.getItem('receipt') ? JSON.parse(sessionStorage.getItem('receipt')) : null
    if (!!receipt) {
      const {
      } = this.props
      const {
        details,
        items,
        description
      } = receipt
      return (
        <FormModal
          onClickAway={() => { sessionStorage.removeItem('receipt'); window.location.reload() }}
          visible={!!{}}
          className={styles.modal}
        >
          <p>{description}</p>
          {items.map(i => <div>{i.item}</div>)}
          <Span>{details}</Span>
          <br />
          <div className="buttons">
            <Button
              large
              cancel
              type="button"
              title="Close"
              onClick={() => { sessionStorage.removeItem('receipt'); window.location.reload() }}
            />
          </div>
        </FormModal>
      )
    }
    return (
      <div className={styles.app}>
        <Router props={verify}/>
      </div>
    );
  }
}

export default CommunityPage;
