import React, { Component } from 'react';
import { connect } from 'react-redux'
import moment from 'moment'

import { hasAuthTokenAsync } from '../../../../lib'

// 3rd Party Components
import FontAwesome from 'react-fontawesome'

// Components
import {
  Tr,
  Header,
  ActionBox,
  Button,
  SearchBar,
  Selector,
  Table,
} from '../../../common'

// Actions
import {
  getTeamMembers,
  filterTeamMembers,
} from '../../../../actions/main'

import {
  setMember,
  getTeam
} from '../../../../actions/team'

import styles from './AccountsView.css'

class AccountsView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchType: '',
      search: '',
    }
  }

  componentDidMount() {
    hasAuthTokenAsync()
      .then(() => {
        this.props.getTeam()
      })
      .catch(console.log)
  }

  filterUsers(search) {
    const { searchType } = this.state
    this.props.filterTeamMembers(search, searchType)
    this.setState({ search, searchType })
  }

  viewMember(e, user) {
    e.stopPropagation()
    this.props.setMember(user)
  }

  renderTableHead() {
    return (
      <thead>
        <tr>
          <th>
            Name
          </th>
          <th>
            Superviors
          </th>
          <th>
            Reps
          </th>
          <th>
            Date Added
          </th>
          <th />
        </tr>
      </thead>
    )
  }

  renderTableBody() {
    const { teams } = this.props
    console.log(teams)
    return (
      <tbody>
        {
        // teams.map(this.renderTableRow.bind(this))
        }
      </tbody>
    )
  }

  renderTableRow(teams) {
    const link = `/bank/${teams._id}`
    const date = moment(teams.created_at).format('MM/DD/YYYY')
    return (
      <Tr
        to={link}
        key={teams.id}
      >
        <td className="bold">
          {teams.name}
        </td>
        <td>
          {<Selector
            className={styles.selector}
            table
            options={teams.supervisors.map(s => s) || 'None'}
          />}
        </td>
        <td>
          {<Selector
            className={styles.selector}
            table
            options={teams.employees.map(e => e) || 'None'}
          />}
        </td>
        <td>
          <FontAwesome style={{ marginRight: '10px' }} name="calendar" />
          {date}
        </td>
        <td className={styles.detailsCell}>
          <Button
            title="DETAILS"
            link={link}
            // onClick={e => this.viewMember(e, teams)}
            // inactive
          />
        </td>
      </Tr>
    )
  }

  renderTable() {
    return (
      <Table className={styles.table} hoverable>
        {this.renderTableHead()}
        {this.renderTableBody()}
      </Table>
    )
  }

  get placeholderText() {
    switch (this.state.searchType) {
      case 'name':
        return 'Search By First or Last Name...'
      case 'email':
        return 'Search By Email...'
      default:
        return 'Search By Name or Email...'
    }
  }

  get searchOptions() {
    return [
      {
        value: '',
        display: 'Any',
      },
      {
        value: 'name',
        display: 'Name',
      },
      {
        value: 'email',
        display: 'Email',
      },
    ]
  }


  render() {
    const { searchType } = this.state

    return (
      <div className={styles.app}>
        <div className="body">
          <ActionBox>
            <div className="main">
              <SearchBar
                options={this.searchOptions}
                selected={searchType}
                onSelect={searchType => this.setState({ searchType })}
                label="Search"
                placeholder={this.placeholderText}
                value={this.state.search}
                onChange={this.filterUsers.bind(this)}
              />
            </div>
            <div className="action">
              <Button
                link='/bank/add'
                icon="plus"
                title="ADD ACCOUNT"
              />
            </div>
          </ActionBox>
          {this.renderTable()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ accounts, auth }) => {
  const {
    authDisplay,
    plaid,
    error,
  } = accounts

  const {
    isAdmin,
  } = auth

  return {
    auth: authDisplay,
    plaid,
    error,
    isAdmin,
  }
}

const actions = { 
  // logout, 
  // getAccounts, 
  // addAccount 
}

export default connect(mapStateToProps, actions)(AccountsView);
