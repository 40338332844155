import React, { Component, createRef } from "react";
import _ from 'lodash';
import { TextField } from "@material-ui/core";
import { Span, Button, FloatBox, Input, Body, Tr, Overlay, Icon, Selector } from "../../../common";
import styles from './ChatPage.css'
import PeerConnection from "../VideoPage/VideoPage/PeerConnection";
import LiveWindow from "../VideoPage/LiveWindow/LiveWindow";

const NEW_MESSAGE_EVENT = "new-message-event";

class ChatPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newMessage: '',
      clientId: '',
      liveWindow: '',
      liveSrc: null,
    };
    this.config = null;
    this.messageContainerRef = createRef(null);
    this.peopleContainerRef = createRef(null);
    this.roomContainerRef = createRef(null);
    this.liveVideo = createRef(null); //([])
  }

  componentDidMount() {
    this.props.socket.on('init', ({ id: clientId }) => {
      document.title = `${clientId} - Muvor Live`;
      this.setState({ clientId });
    })
    .on('request', ({ from: callFrom }) => {
      this.setState({ callModal: 'active', callFrom });
    })
    .on('call', (data) => {
      if (data.sdp) {
        this.pc.setRemoteDescription(data.sdp)
        if (data.sdp.type === 'offer') {
          this.pc.createAnswer(data.sdp);
        }
      } else this.pc.addIceCandidate(data.candidate);
    })
    .on('end', this.endLive.bind(this, false))
    .emit('init');
    this.props.messages.map(m => m.scrollIntoView({behavior: "smooth"}))
  }

  componentDidUpdate() {
    if (this.liveVideo.current && this.props.liveSrc) this.liveVideo.current.srcObject = this.props.liveSrc;
    if (this.messageContainerRef.current) {
      this.messageContainerRef.current.scrollTop = this.messageContainerRef.current.scrollHeight;
    }
    if (this.peopleContainerRef.current) {
      this.peopleContainerRef.current.scrollTop = this.peopleContainerRef.current.scrollHeight;
    }
    if (this.roomContainerRef.current) {
      this.roomContainerRef.current.scrollTop = this.roomContainerRef.current.scrollHeight;
    }
  }

  endLive(isStarter) {
    const confirm = window.confirm("End Live Stream?")
    if (confirm === true && _.isFunction(this.pc.stop)) {
      this.pc.end(isStarter);
      this.pc = {};
      this.config = null;
      this.setState({
        liveWindow: '',
        liveSrc: null
      });
    }
  }
  
  render() { 
    const {
      newMessage,
      clientId,
      liveWindow,
      liveSrc,
    } = this.state
    const {
      messages,
      socket,
      people,
      rooms,
      user,
      me
    } = this.props

    const sendMessage = (messageBody) => {
      const message = { senderId: socket.id, messageBody };
      socket.emit(NEW_MESSAGE_EVENT, message, (response) => {
        console.log(response); // ok
      });
    };
    
    const handleNewMessageChange = event => {
      this.setState({ newMessage: event.target.value })
    };

    const handleSupport = event => {
    };
    
    /**
    * Start live with or without video
    * @param {Boolean} video
    */
    const handleGoLive = (isMe) => {
      this.pc = new PeerConnection();
      this.config = { audio: true, video: true };
      this.pc.on('liveStream', (src) => {
          const newState = { liveWindow: 'active', liveSrc: src };
          if (!isMe) newState.liveModal = '';
          this.setState(newState);
        }).go(isMe, this.config);
    };

    const handleSendMessage = () => {
      if (newMessage !== "") {
        messages.push({ senderId: socket.id, messageBody: newMessage, isOwner: true })
        this.setState({ newMessage: "" })
        sendMessage(newMessage);
      }
    };
  
    const handleKeyUp = event => {
      if (event.key === "Enter"){
        if (newMessage !== "") {
          messages.push({ senderId: socket.id, messageBody: newMessage, isOwner: true })
          this.setState({ newMessage: "" })
          sendMessage(newMessage);
        }
      }
    }

    // const handleClick = () => {
    //   const wrapper = this.wrapperRef.current;
    //   wrapper.classList.toggle('is-nav-open')
    // }
  
    return ( 
      <div className="nav">
          <Body className={styles.container}>
          {liveSrc === null ? <Button
            // live || !me
            // disabled={!newMessage}
            title="Go Live!"
            onClick={() => handleGoLive(true)}
            className={styles.liveButton}
            danger
          />
          : <Button
            // live || !me
            // disabled={!newMessage}
            title="End Live"
            onClick={() => this.endLive(true)}
            className={styles.liveButton}
            danger
          /> }
          <Button
            // supportChat
            disabled={!newMessage}
            onClick={handleSupport}
            className={styles.supportButton}
            cancel
          >
            Support
          </Button>
          {!_.isEmpty(this.config) && (
          <LiveWindow
            className={styles.liveWindow}
            status={liveWindow}
            liveSrc={liveSrc}
            config={this.config}
            mediaDevice={this.pc.mediaDevice}
            endLive={this.endLiveHandler}
          /> )}
            <FloatBox className={liveSrc === null ? styles.wings : styles.liveWings}>
              <div ref={this.peopleContainerRef} className={styles.peopleContainer}>
                <h3>PEOPLE</h3>
                <ul className={styles.ol}>
                  {/* <Icon className="nav__icon" type="menu-fold" onClick={() => handleClick()} /> */}
                  {Array.isArray(people) && people.map((peep, i) => {console.log(peep)})}
                </ul>
              </div>
            </FloatBox>
            <FloatBox className={liveSrc === null ? styles.paper : styles.livePaper}>
              <div ref={this.messageContainerRef} className={styles.messageContainer}>
                <ul className={styles.ol}>
                  {Array.isArray(messages) && messages.map((message, i) => (
                    <li className={styles.li} key={i} >
                      <Span className={message.senderId === socket.id ? styles.owner : styles.guest}>{message.messageBody}</Span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className={styles.action}>
                <TextField
                  className={styles.messageInput}
                  id="message"
                  label="Message"
                  placeholder="enter message here"
                  variant="outlined"
                  value={newMessage}
                  onChange={handleNewMessageChange}
                  onKeyUp={handleKeyUp}
                />
                <Button
                  disabled={!newMessage}
                  onClick={handleSendMessage}
                  className={styles.sendButton}
                >
                  Send
                </Button>
              </div>
            </FloatBox>
            <FloatBox className={liveSrc === null ? styles.wings : styles.liveWings}>
              <h3>ROOMS</h3>
              <ul className={styles.ol}>
                {/* <Icon className="nav__icon" type="menu-fold" onClick={() => handleClick()} /> */}
                {/* {Array.isArray(rooms) && rooms.map((room, i) => {
                  <li className={styles.li} key={i}>
                    <Span className={room.to === me.name ? styles.dormant : styles.alerted}>
                      {room.name}
                    </Span>
                  </li>
                })} */}
              </ul>
            </FloatBox>
          </Body>
        </div>
    );
  }
}
 
export default ChatPage;