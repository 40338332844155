import React, { Component } from "react";
import "./DashboardAdmin.css";
import { Route, Link } from "react-router-dom";
import { Switch } from "react-router";
import { Redirect } from "react-router-dom";

import Router from './DashboardAdmin.router'

import NavBar from "../NavBar/NavBar";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsersCog,
  faUsers,
  faChair,
  faBuilding,
  faDollarSign,
  faTasks,
  faHome,
  faCalendar,
  faListAlt
} from "@fortawesome/free-solid-svg-icons";
import AdminDashboard from "../UserDashboards/Admin/AdminDashboard";


class DashboardAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      redirect: true,
      checked: true
    }
  };
  handleChange=(checked)=> {
    console.log("switch");
    // var sidebarV = this.refs.sidebar;
    // var sidebarV = React.findDOMNode( this.refs.sidebar);
    // sidebarV.style.disply="none";
    
    if(this.state.checked==true){ 
       // document.getElementById("sidebar").setAttribute("style", "display:none")
      document.getElementById("sidebar").setAttribute("class", "display-none");
    }
    // document.getElementById("sidebar").setAttribute("style", "display:block");
    else{document.getElementById("sidebar").setAttribute("class", "display-block");}   
    this.setState({ checked });
  }

  render() {
    return (
      <div id="outer-main-div">
        <div id="outer-nav">
          <NavBar loginInfo={this.props.data} checked={this.state.checked} handleChange={this.handleChange} onLogout={this.props.onLogout}/>
        </div>

        <div id="main-non-nav">
          <div id="sidebar">
            <div id="sidebar-top-content" />
            <div id="main-title">
              <FontAwesomeIcon icon={faUsersCog} className="sidebar-icon" />
              Admin
            </div>
            <ul className="navbar-ul">
              <li>
                <Link to="/hr/dashboard">
                  <FontAwesomeIcon icon={faHome} className="sidebar-icon" /> 
                  Dashboard
                </Link> 
              </li>
              <li>
                <Link to="/dashboard">
                  <FontAwesomeIcon icon={faDollarSign} className="sidebar-icon" /> 
                  Sales Home
                </Link> 
              </li>
              <li>
                <Link to="/hr/employee">
                  <FontAwesomeIcon icon={faListAlt} className="sidebar-icon" /> 
                  Employees 
                </Link> 
              </li>
              <li>
                <Link to="/hr/dashboard/agenda">
                  <FontAwesomeIcon icon={faCalendar} className="sidebar-icon" /> 
                  Agenda 
                </Link> 
              </li>
              <li>
                <Link to="/hr/admin/role">
                  <FontAwesomeIcon icon={faUsers} className="sidebar-icon" /> 
                  Role 
                </Link> 
              </li>
              <li>
                <Link to="/hr/admin/position">
                  <FontAwesomeIcon icon={faChair} className="sidebar-icon" /> 
                  Position 
                </Link> 
              </li>
              <li>
                <Link to="/hr/admin/department">
                  <FontAwesomeIcon
                    icon={faBuilding}
                    className="sidebar-icon"
                  /> 
                  Department 
                </Link> 
              </li>
              <li>
                <Link to="/hr/admin/project-bid">
                  <FontAwesomeIcon
                    icon={faDollarSign}
                    className="sidebar-icon"
                  /> 
                  Project Bidding 
                </Link> 
              </li>
              <li>
                <Link to="/hr/admin/portal-master">
                  <FontAwesomeIcon icon={faTasks} className="sidebar-icon" /> 
                  Portal Master 
                </Link> 
              </li>
              
            </ul>
          </div>
          {/* <div id="sidebar-top-content" /> */}
          <div id="main-area">
            <div id="sidebar-top-content" />
            { window.location=='http://localhost:3000/hr/admin' ? <AdminDashboard /> : <Router /> }
          </div>
        </div>
      </div>
    );
  }
}

export default DashboardAdmin;
