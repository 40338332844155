import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom'

// Components
import TeamView from './TeamView/TeamView'
import AddMember from './AddMember/AddMember'
import MemberView from './MemberView/MemberView'
import NotFound from '../../NotFound/NotFound'

const TeamPageRouter = props => (
  <Switch>
    <Route
      exact
      path="/team"
      render={() => <TeamView
        props={props}
      />
      }
    />

    <Route
      exact
      path="/team/add"
      render={() => <AddMember
        props={props}
      />
      }
    />

    <Route
      exact
      path="/team/:memberId"
      render={() => <MemberView
        props={props}
      />
      }
    />

    <Route
      component={NotFound}
    />
  </Switch>
)


export default TeamPageRouter
