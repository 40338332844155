// Redux
import { combineReducers } from 'redux'

// Redux Router
import { routerReducer } from 'react-router-redux'

// Redux Form
import { reducer as formReducer } from 'redux-form'

// Reducers
import auth from './AuthReducer'
import accounts from './AccountsReducer'
import main from './MainReducer'
import supervisors from './SupervisorsReducer'
import employees from './EmployeesReducer'
import expense from './ExpenseReducer'
import team from './TeamReducer'
import contacts from './ContactsReducer'
import community from './CommunityReducer'
import products from './ProductsReducer'
import sockets from './SocketsReducer'
import companys from './CompanysReducer'
import stripe from './StripeReducer'
import timeEntryInput from './TimeInputReducer'
import Layout from './Layout'
import ThemeOptions from './ThemeOptions'
import DisplayResult from "./ResultReducer";
import DisplayInput from "./InputReducer";
import projects from './ProjectsReducer';
import timesheets from './TimesheetReducer';
import cashier from './CashierReducer';

const reducers = {
  auth,
  accounts,
  main,
  supervisors,
  employees,
  expense,
  team,
  contacts,
  community,
  products,
  sockets,
  companys,
  stripe,
  timeEntryInput,
  ThemeOptions,
  Layout,
  displayResult: DisplayResult,
  displayInput: DisplayInput,
  form: formReducer,
  projects,
  timesheets: timesheets,
  cashier,
  router: routerReducer
}

export default combineReducers(reducers)
