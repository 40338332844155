import React, { Component } from 'react';
import CycleNav from './CycleNav';
import FeatureTable from './FeatureTable';
import FeatureSummary from './FeatureSummary';
import ChartNotes from './ChartNotes';
import { Button } from '../../../common';

class PricingChart extends Component {
  constructor(props){
    super(props)
    this.state={
      data: {}
    }
  }
  render() {
    const activeCycle = this.props.data.cycles[this.props.activeCycle];

    return (
      <div id="pricing-chart" className="max-width flex-column">
        <CycleNav cycles={this.props.data.cycles} handleCycleClick={this.props.handleCycleClick} activeCycle={ this.props.activeCycle } />
        <main className="flex-column">
          <div id="plans" className="padding-1 bottom-inner-shadow">
            <FeatureTable featurable={this.props.data.featurable.slice(0,4)} activeCycle={activeCycle} />
          </div>
          <FeatureSummary summary={ activeCycle.summary }/>
          <div id="pricing" className="tb-padding-1 tb-inner-shadow">
            <FeatureTable featurable={this.props.data.featurable.slice(4)} activeCycle={activeCycle} columnHeaders={false} />
          </div>
          <Button
            wide
            trial
            type="submit"
            className="button"
            title="Start Free Trial!"
          />
        </main>

        <ChartNotes />        
      </div>
    );
  }
}

export default PricingChart;
