import React, { Component } from 'react';
import { push } from 'react-router-redux'
import { connect } from 'react-redux'
import moment from 'moment'
import * as cookies from 'js-cookie';

import { formatDate } from '../../../../lib/dateHelper'

import { hasAuthTokenAsync } from '../../../../lib'

// 3rd Party Components
import FontAwesome from 'react-fontawesome'

import ProductTable from '../ProductTable/ProductTable'

// Components
import {
  Tr,
  Icon,
  Input,
  Header,
  ActionBox,
  Button,
  SearchBar,
  Table,
} from '../../../common'

// Actions
import {
  getProducts,
  filterProductsByStatus,
  addQuantities,
  subQuantities,
} from '../../../../actions/products'

// Assignment
import {
  AssignmentModal
} from '../../../shared'

import {
  createAssignment,
} from '../../../../actions/main'

import styles from './ProductView.css'

class ProductView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      assignmentModal: null,
      searchType: '',
      search: '',
      quantity: null,
    }
  }

  componentDidMount() {
    hasAuthTokenAsync()
      .then(() => {
        this.props.getProducts()
      })
      .catch(console.error)
  }

  filterProducts(search) {
    const { searchType } = this.state
    this.props.filterProductsByStatus(search, searchType)
    this.setState({ search, searchType })
    console.log(this.state)
  }

  viewProduct(e, products) {
    e.stopPropagation()
    this.props.setMember(products)
  }

  viewModal({ assignment, day }) {
    const date = formatDate(new Date(this.year, this.month, day))
    const assignmentModal = {
      assignment,
      date,
    }
    this.setState({ assignmentModal, assignment: null, day: null})
  }

  handleClick(e) {
    e.preventDefault()
    
  }

  handleChange(e) {
    this.setState({search: e.target.value})

  }

  setEditState(editing) {
    this.setState({
      ...this.initialState,
      editing,
    })
  }

  addQuan(){
    const {quantity} = this.state
    this.props.addQuantities(quantity)
    this.setState({ quantity })
  }

  subQuan(){
    const {quantity} = this.state
    this.props.subQuantities(quantity)
    this.setState({ quantity })
  }


  get placeholderText() {
    switch (this.state.searchType) {
      case 'name':
        return 'Search By First or Last Name...'
      case 'email':
        return 'Search By Email...'
      default:
        return 'Search By Name or Email...'
    }
  }

  get searchOptions() {
    return [
      {
        value: '',
        display: 'Any',
      },
      {
        value: 'name',
        display: 'Name',
      },
      {
        value: 'email',
        display: 'Email',
      },
    ]
  }

  render() {
    const { searchType } = this.state
    const { products } = this.props
    return (
      <div className={styles.app}>
        <div className="body">
          <ActionBox>
            <div className="main">
              <SearchBar
                options={this.searchOptions}
                selected={searchType}
                onSelect={searchType => this.setState({ searchType })}
                label="Search"
                placeholder={this.placeholderText}
                value={this.state.search}
                onChange={search => this.setState({ search })}
              />
            </div>
            <div className="action">
              <Button
                link='/products/add'
                icon="plus"
                title="ADD A NEW PRODUCT"
              />
            </div>
          </ActionBox>
          <ProductTable products={products} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ products, main }) => {
  const {
    productsDisplay
  } = products

  const {
    assignments
  } = main

  return {
    products: productsDisplay,
    assignments
  }
}

const actions = {
  getProducts,
  filterProductsByStatus,
  createAssignment,
  addQuantities,
  subQuantities
}

export default connect(mapStateToProps, actions)(ProductView);
