import React, { Component } from 'react';
import Router from './ContactsPage.Router'

import styles from './ContactsPage.css'

class ContactsPage extends Component {
  render() {
    console.log(this.props)
    // const contacts = this.props.comp.props
    return (
      <div className={styles.app}>
        <Router />
      </div>
    );
  }
}

export default ContactsPage;
