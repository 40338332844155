import { api } from '../config'
//import { user } from '../test-data'

// Private Methods
function getUsersByRole(role) {
  const url = `/users?roles=${role}`
  return api.get(url)
}

// Public Methods
export default {
  post(data) {
    const url = '/users'
    return api.post(url, data)
  },

  getReps(cid,uid) {
    const url = `${cid}/reps/${uid}`
    return api.get(url)
  },

  getById(id) {
    const url = `/users/${id}`
    return api.get(url)
  },

  patchById(id, update) {
    const url = `/users/${id}`
    return api.patch(url, update)
  },

  // getSelf(id) {
  //   const url = `/me/${id}`
  //   return api.get(url)
  // },

  patchSelf(data) {
    const url = '/users/me'
    return api.patch(url, data)
  },
}
