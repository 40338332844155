import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './LiveWindow.css'

const getButtonClass = (icon, enabled) => classnames(`btn-action fa ${icon}`, { disable: !enabled });

function LiveWindow({ liveSrc, config, mediaDevice, status, endLive }) {
  const liveVideo = useRef(null);
  const [video, setVideo] = useState(config.video);
  const [audio, setAudio] = useState(config.audio);

  useEffect(() => {
    if (liveVideo.current && liveSrc) liveVideo.current.srcObject = liveSrc;
  });

  useEffect(() => {
    if (mediaDevice) {
      mediaDevice.toggle('Video', video);
      mediaDevice.toggle('Audio', audio);
    }
  });

  const onMicrophone = stream => {
    console.log(stream)
    let audioContext = new AudioContext();
    let analyser = audioContext.createAnalyser();
    let microphone = audioContext.createMediaStreamSource(stream);
    let javascriptNode = audioContext.createScriptProcessor(4096, 1, 1);
  
    analyser.smoothingTimeConstant = 0.8;
    analyser.fftSize = 1024;
  
    microphone.connect(analyser);
    analyser.connect(javascriptNode);
    javascriptNode.connect(audioContext.destination);
    javascriptNode.onaudioprocess(event => {
        var array = new Uint8Array(analyser.frequencyBinCount);
        analyser.getByteFrequencyData(array);
        var values = 0;
  
        var length = array.length;
        for (var i = 0; i < length; i++) {
          values += (array[i]);
        }
  
        var average = values / length;
  
      console.log(Math.round(average))
    })
  }

  /**
   * Turn on/off a media device
   * @param {String} deviceType - Type of the device eg: Video, Audio
   */
  const toggleMediaDevice = (deviceType) => {
    if (deviceType === 'video') {
      setVideo(!video);
      mediaDevice.toggle('Video');
    }
    if (deviceType === 'audio') {
      setAudio(!audio);
      mediaDevice.toggle('Audio');
    }
  };


  return (
    <div className={classnames('live-window', status)}>
      { // array of peers in the room 
        // peerVideo.current.map(peer => 
        // && onMicrophone(peer) && //peerVideo (full screen))
      }
      <video id="liveVideo" ref={liveVideo} autoPlay muted />
      <div className="video-control">
        <button
          key="btnVideo"
          type="button"
          className={getButtonClass('fa-video-camera', video)}
          onClick={() => toggleMediaDevice('video')}
        />
        <button
          key="btnAudio"
          type="button"
          className={getButtonClass('fa-microphone', audio)}
          onClick={() => toggleMediaDevice('audio')}
        />
      </div>
    </div>
  );
}

// LiveWindow.propTypes = {
//   status: PropTypes.string.isRequired,
//   liveSrc: PropTypes.object, // eslint-disable-line
//   config: PropTypes.shape({
//     audio: PropTypes.bool.isRequired,
//     video: PropTypes.bool.isRequired
//   }).isRequired,
//   mediaDevice: PropTypes.object, // eslint-disable-line
//   endLive: PropTypes.func.isRequired
// };

export default LiveWindow;
