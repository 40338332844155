import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ProjectsView from './ProjectsView/ProjectsView';
import { patchProjectById, createProject } from '../../../../actions/projects';


class ProjectsDetail extends React.Component {
  handleSave = (values) => {
    const { onCreate, onUpdate, history } = this.props;

    const result = values._id ? onUpdate(values) : onCreate(values);
    result.then(() => {
      history.push('/projects');
    });
  };

  render() {
    return (
      <div>
        <h1>Projects Detail</h1>
        <ProjectsView
          project={this.props.project}
          actions={this.props.actions}
          handleSave={this.handleSave}
        />
      </div>
    );
  }
}

// ProjectsDetail.propTypes = {
//   project: PropTypes.object.isRequired,
//   history: PropTypes.object,
//   onCreate: PropTypes.func,
//   onUpdate: PropTypes.func,
// };

// ProjectsDetail.defaultProps = {
//   project: {}
// };

const mapStateToProps = ({ main, projects }) => {
  const {
    history
  } = main
  const {
    projectsDisplay
  } = projects
  return {
    history,
    projects: projectsDisplay
  }
}

const actions = {
  patchProjectById,
  createProject
}

export default connect(mapStateToProps, actions)(ProjectsDetail);
