// Router
import { push } from 'react-router-redux'
import { uploads } from '../services'

// Types
import { editorTypes } from './types'

// Services
// import { editor } from '../services'

const {
    LOAD_NOTE,
    UPDATE_NOTE,
    CREATE_NOTE,
    CREATE_IMPORT,
    CREATE_IMPORT_SUCCESS,
    CREATE_IMPORT_FAILURE
} = editorTypes

export function loadNote() {
 return dispatch => {
  fetch("http://localhost:3000/api/v1/notes")
   .then(response => response.json())
   .then(json => dispatch({ type: LOAD_NOTE, payload: json })
 )}
}

export function createNote(noteContent) {
 return dispatch => {
  fetch("http://localhost:3000/api/v1/notes", {
   method: "post",
   headers: { "Content-Type": "application/json", "Accepts": "application/json" },
   body: JSON.stringify({ content: noteContent })
  })
   .then(response => response.json())
   .then(json => {
    dispatch({ type: CREATE_NOTE, newNote: json })
   })
 }
}

export function updateNote(note_id, note_content) {
 return dispatch => {
  fetch(`http://localhost:3000/api/v1/notes/${note_id}`, {
   method: "PATCH",
   headers: { "Content-Type": "application/json", Accepts: "application/json" },
   body: JSON.stringify({ content: note_content })
  })
   .then(response => response.json())
   .then(json =>
    dispatch({ type: UPDATE_NOTE, updated_note: json })
   );
 };
}

export const Import = (data, link) => (dispatch, getState) => {
  dispatch({
    type: CREATE_IMPORT,
  })
  uploads.post(data, link)
    .then(res => ImportSuccess({dispatch, getState, res}))
    .catch(err => ImportFailure({dispatch, err}))
}

function ImportSuccess({dispatch, getState, res}) {
  console.log('createImport success');
  const { imports } = getState()
  dispatch({
    type: CREATE_IMPORT_SUCCESS,
    payload: [res, imports]
  })
  dispatch(push('/imports'))
}

function ImportFailure({dispatch, err}) {
  console.log('createImport failure', err);
  const payload = err.message || 'CreateImport Failed'
  window.alert(payload)
  dispatch({
    type: CREATE_IMPORT_FAILURE,
    payload,
  })
}