import React, { Component } from 'react';
import { connect } from 'react-redux';
import { removeFromCart } from '../../../actions/community';
import { formatCurrencyOptions } from '../../../lib';

import {
  Selector,
  Button,
  Header,
  Body,
  Input,
  Form,
  FormModal,
  FileUpload,
  Icon,
  Table,
} from '../../common'
import CartRow from './CartRow/CartRow';

import styles from './CartModal.css'
import { CashierModal } from '..';

class CartModal extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      cashierModal: null,
     }
  }
  renderTableHead() {
    return (
      <thead>
        <tr>
          <th>
            Name
          </th>
          <th>
            Photos
          </th>
          <th>
            Documents
          </th>
          <th>
            Description
          </th>
          <th>
            Price
          </th>
          <th>
            Quantity
          </th>
          <th />
        </tr>
      </thead>
    )
  }

  renderTableBody() {
    const { cart } = this.props
    if (cart.items && !cart.items.length) {
      return (
        <tbody>
          <tr>
            <td colSpan="7" className="empty">
              No Items Found
            </td>
          </tr>
        </tbody>
      )
    } else {
      return (
        <tbody>
          {cart.items && cart.items.map(i => (
            <CartRow
              key={i.id}
              item={i}
            />
          ))}
        </tbody>
      )
    }
  }

  render() {
    const { onClickAway, content, cart } = this.props
    if (!!this.state.cashierModal) {
      return ( 
        <CashierModal
          cart={cart}
          isme={this.props.me}
          content={this.state.cashierModal}
          onClickAway={() => this.setState({cashierModal: null})}
        />
      )
    }
    return (
      <FormModal
        onClickAway={onClickAway}
        visible={!!content}
        className={styles.modal}
      >
        <Table className={styles.table}>
          {this.renderTableHead()}
          {this.renderTableBody()}
        </Table>  
        <Button 
          styles={styles.button}
          title='Checkout'
          onClick={() => this.setState({ cashierModal: {} })}
          module
        />        
      </FormModal>
    )
  }
}
 
export default CartModal;
