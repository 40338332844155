import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom'
import logo from '../../../../assets/images/MuvorWithoutSmall.png';
import styles from '../LandingPage.css'

import React, { Component } from 'react';
import { connect } from 'react-redux';
import CartModal from '../../shared/CartModal/CartModal';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cartModal: null
    };
    this.me = sessionStorage.getItem('my-community-data') ? JSON.parse(sessionStorage.getItem('my-community-data')) : ''
  }

  renderCartItems(items) {
    return (
      <div>
        {items}
      </div>
    )
  }

  render() {
    const {
      cart
    } = this.props

    if (!!this.state.cartModal) {
      return (
        <CartModal 
          cart={cart} 
          // me={this.me}
          content={this.state.cartModal}
          onClickAway={() => this.setState({ cartModal: null })}
        />
      )
    }
    return (
      <div className={styles.app}>
        <div className="header">
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
            {/* <Navbar.Brand href="/home"><img width="200" height="5" className="d-inline-block align-top" src={logo} alt="home"></img></Navbar.Brand>        */}
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto">
              </Nav>
              <Nav>
                <Nav.Link href="/">Home</Nav.Link>
                <Nav.Link href="/home/community">Community</Nav.Link>
                <Nav.Link href="/home/pricing">Pricing</Nav.Link>
                <Nav.Link href="/home/careers">Careers</Nav.Link>
                {cart && cart.items && <Nav.Link onClick={() => this.setState({ cartModal: {} })}>Cart</Nav.Link>}
                <Nav.Link href="/login">Login/Signup</Nav.Link>
                <Nav.Link eventKey={2} href="/chats/home">
                  Contact/Chat
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>
    );
  }
}

export default Home;