import React, { Component } from 'react';
import { connect } from 'react-redux'
import validator from 'validator'
import * as cookies from 'js-cookie';

import {
  Selector,
  Button,
  Header,
  Body,
  Input,
  Form,
} from '../../../common'

// Actions
import {
  createTeamMember,
} from '../../../../actions/team'

import {
  getEmployees,
} from '../../../../actions/main'

import {
  getSupervisors,
} from '../../../../actions/supervisors'

import styles from './AddMember.css'
import { hasAuthTokenAsync } from '../../../../lib';

class AddMember extends Component {
  constructor(props) {
    super(props)
    this.state = {
      supervisors: [],
      employees: [],
      name: '',
      me: cookies.get('me') && JSON.parse(cookies.get('me')) ? JSON.parse(cookies.get('me')) : undefined 
    }
  }

  onSubmit(e) {
    e.preventDefault()
    const {
      supervisors,
      employees,
      name,
      me
    } = this.state
    const data = {
      company: me.company,
      supervisors,
      employees,
      name,
    }
    // console.log('onSubmit', data)
    this.props.createTeamMember(data)
  }

  componentDidMount() {
    hasAuthTokenAsync()
      .then(() => {
        // this.props.getSelf()
        this.props.getEmployees()
        this.props.getSupervisors()
      })
      .catch(console.log)
  }

  render() {
    const {
      supervisors,
      employees,
      name,
    } = this.state

    const {
      superDisplay,
      employeesDisplay
    } = this.props
    const invalid = (
      !supervisors
      || !employees
    )

    const employeeOptions = [
      {
        key: '',
        value: '',
        display: 'unassigned',
      },
      ...employeesDisplay.map(e => ({
        key: e._id,
        value: e._id,
        display: e.name,
      })),
    ]
    
    const superOptions = [
      {
        key: '',
        value: '',
        display: 'unassigned',
      },
      ...superDisplay.map(s => ({
        key: s._id,
        value: s._id,
        display: s.name,
      })),
    ]

    return (
      <div>
        <Header>
          <h3>Add a New Team Member</h3>
        </Header>
        <Body className={styles.body}>
          <Form
            className="form"
            onSubmit={this.onSubmit.bind(this)}
          >
            <br />

            <label>
              Team Name (optional):
            </label>
            <Input
              placeholder="..."
              value={name}
              onChange={name => this.setState({ name })}
            />

            <br />

            <label>
              Supervisors:
            </label>
            {this.state.supervisors.map(s => <div>{s}</div> )}
            <Selector
              wide
              selected={supervisors}
              options={superOptions}
              onSelect={i => {var a=this.state.supervisors.slice();a.push(i);this.setState({supervisors:a})}}
            />            
            
            <br />

            <label>
              Employees:
            </label>
            {this.state.employees.map(e => <div>{e}</div> )}
            <Selector
              wide
              selected={employees}
              options={employeeOptions}
              onSelect={i => {var a=this.state.employees.slice();a.push(i);this.setState({employees:a})}}
            />

            <br />

            <div className="buttons">
              <Button
                large
                cancel
                link="/team"
                title="CANCEL"
              />
              <Button
                large
                type="submit"
                title="CREATE TEAM"
                inactive={invalid}
                onClick={this.onSubmit.bind(this)}
              />
            </div>
          </Form>
        </Body>
      </div>
    );
  }
}

const mapStateToProps = ({ main, supervisors }) => {
  const {
    loading,
    employeesDisplay
  } = main
  
  const {
    supervisorsDisplay
  } = supervisors

  return {
    loading,
    employeesDisplay,
    superDisplay: supervisorsDisplay
  }
}

const actions = {
  createTeamMember,
  getEmployees,
  getSupervisors
}

export default connect(mapStateToProps, actions)(AddMember)
