import React, { Component } from 'react';
import { connect } from 'react-redux'

import qs from 'query-string'
import * as cookies from 'js-cookie';
// Helpers
import { hasAuthTokenAsync, getDateObject, CookieBanner } from '../../lib'
import { formatDate } from '../../lib/dateHelper'


// Components
import Router from './Main.Router'
import TopNav from './TopNav/TopNav'
import SideNavi from '../shared/SideNav/SideNav'
import SideBar from './AgendaPage/SideBar/SideBar'


// Actions
import {
  getSelf,
} from '../../actions/auth'

import {
  getReps,
  getVisits,
  getTeamMembers,
  getEmployees,
  getSupervisors,
} from '../../actions/main'

import {
  connector,
} from '../../actions/sockets'

import styles from './Main.css'

class Main extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedRepId: '',
      visitModal: null,
      rep: null,
      day: null,
      sideBarFlipped: false,
    }
  }

  componentDidMount() {
    hasAuthTokenAsync()
      .then(() => {
        // this.props.connector()
        if (!this.params.month || !this.params.year) {
          console.log('setting current date')
          this.setToCurrentDate()
        }
      })
      .catch(console.log)
    }

  get params() {
    const params = qs.parse(this.props.location.search)
    return params
  }

  get month() {
    return Number(this.params.month)
  }

  get year() {
    return Number(this.params.year)
  }

  get selectedRepId() {
    return Number(this.params.rep) || 0
  }

  setParams(newParams) {
    const search = qs.stringify({...this.params, ...newParams})
    this.props.history.push({
      search,
    })
  }

  setToCurrentDate() {
    const { month, year } = getDateObject()
    this.setParams({month, year})
  }

  setMonth(newParams) {
    this.setParams(newParams)
  }

  setRep(rep) {
    this.setParams({ rep: rep || null })
  }

  viewRep({rep, day}) {
    const dayOfWeek = (new Date(this.year, this.month, day)).getDay()
    const sideBarFlipped = dayOfWeek > 3
    this.setState({ rep, day, sideBarFlipped })
  }

  viewModal({ rep, day }) {
    const date = formatDate(new Date(this.year, this.month, day))
    const visitModal = {
      rep,
      date,
    }
    this.setState({ visitModal, rep: null, day: null })
  }

  closeSideBar() {
    this.setState({ rep: null, day: null })
  }

  render() {
    const {
      selectedRepId,
      month,
      year,
      connection
    } = this.props
    // console.log(connection)
    // const connect = {
    //   ...connection.data
    // }
    // const props = connect[0]
    // localStorage.setItem('company', props)
    return (
      <div className={styles.app}>
        <TopNav />
          <div className="main">
            <Router 
              history={this.props.history}
              location={this.props.location}
            />
        </div>
        <CookieBanner />
      </div>
    );
  }
}

const mapStateToProps = ({ sockets }) => {
  const {
    connection
  } = sockets

  return {
    connection
  }
}

const actions = {
  getReps,
  // getVisits,
  connector,
  getTeamMembers,
  getSupervisors,
  getEmployees,
}

export default connect(mapStateToProps, actions)(Main)
