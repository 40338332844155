import React, { Component } from 'react';
import { connect } from 'react-redux'

import moment from 'moment'

// Components
import {
  Span,
  Input,
  Button,
  Selector,
  FormModal,
} from '../../common'

// actions 
import { getReps } from '../../../actions/main';
import { getSelf } from '../../../actions/auth';
import { getSupervisors } from '../../../actions/supervisors';

import styles from './VisitModal.css'
import { hasAuthTokenAsync } from '../../../lib';

class VisitModal extends Component {
  constructor(props) {
    super(props)
    this.state = this.initialState
  }
  componentDidMount() {
    console.log("mounting")
    hasAuthTokenAsync()
    .then(() => {
      this.props.getReps()
      this.props.getSelf()
      this.props.getSupervisors()
    })
  }
  get initialState() {
    const date = moment().add(1, 'day').format('YYYY-MM-DD')
    const initialState = {
      rep: '',
      date,
      time: '10:00',
      supervisor: '',
    }
    return initialState
  }

  static getDerivedStateFromProps(newProps) {
    return newProps.content || null
  }

  get inactive() {
    const {
      date,
      time,
      supervisor,
    } = this.state
    const rep = this.props.isadmin ? this.state.rep : this.props.me
    return !rep || !date || !time || !supervisor
  }

  submit(e) {
    e.preventDefault()
    const {
      date,
      time,
      supervisor,
    } = this.state

    const rep = this.props.isadmin ? this.state.rep : this.props.me

    // combine date and time
    const dateTime = moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm').toISOString()
    const data = {
      repId: rep.id || rep,
      dateTime,
      supervisorId: supervisor.id || supervisor,
    }

    this.props.onSubmit(data)
    this.props.onClickAway()
  }

  renderSelectByType(type) {
    const { content } = this.props
    let staticOption = content && content[type]

    let defaultOption
    let dataSource = []

    switch(type) {
      case 'Super' || 'Admin':
        defaultOption = 'Select Supervisor'
        dataSource = Array.isArray(this.props.supervisors) && this.props.supervisors
        break
      case 'Rep':
        if (!this.props.isadmin) {
          staticOption = this.props.me
        }
        defaultOption = 'Select Rep'
        dataSource = Array.isArray(this.props.reps) && this.props.reps
        break
      default:
        // unknown type
        return null
    }
    const options = [
      // {
      //   key: 0,
      //   value: '',
      //   display: defaultOption,
      // },
      // // FIXME: datasource is selections to visit in modal (reps | superv)
      // ...dataSource.map(data => ({
      //   key: data.id,
      //   value: data.id,
      //   display: data.name,
      // })),
    ]

    if (staticOption) {
      return (
        <div>
          <Span>
            {staticOption.nameDisplay || type}
          </Span>
        </div>
      )
    } else {
      return (
        <Selector
          selected={this.state[type]}
          options={options}
          onSelect={value => this.setState({ [type]: value })}
        />
      )
    }
  }

  render() {
    const {
      content,
      onClickAway,
      me
    } = this.props

    console.log(this.props)

    const { date, time } = this.state
    return (
            <FormModal
              title="Schedule New Visit"
              onClickAway={onClickAway}
              visible={!!content}
              onSubmit={this.submit.bind(this)}
              className={styles.modal}
            >
              {/* Rep */}
              <label>
                Rep
              </label>
              { me && me.name }

              <br />

              {/* Date */}
              <label>
                Date
              </label>
              <Input
                type="date"
                value={date}
                onChange={date => this.setState({ date })}
              />

              <br />

              {/* Time */}
              <label>
                Time
              </label>
              <Input
                type="time"
                value={time}
                onChange={time => this.setState({ time })}
              />

              <br />

              {/* Supervisor */}
              <label>
                Supervisor
              </label>
              { this.renderSelectByType(`Super`) }

              <div className="buttons">
                <Button
                  large
                  cancel
                  type="button"
                  title="Cancel"
                  onClick={onClickAway}
                />
                <Button
                  large
                  inactive={this.inactive}
                  type="submit"
                  title="Schedule"
                />
              </div>
            </FormModal>
    )
  }
}

const mapStateToProps = ({ auth, main, supervisors }) => {
  const {
    reps
  } = main
  const {
    supervisorsDisplay,
  } = supervisors
  const {
    // me,
    isadmin,
  } = auth

  return {
    // me,
    isadmin,
    reps,
    supervisors: supervisorsDisplay,
  }
}

const actions = {
  getReps,
  getSelf,
  getSupervisors
}

export default connect(mapStateToProps, actions)(VisitModal);
