import { productTypes } from '../actions/types'

const {
  GET_PRODUCTS,
  GET_PRODUCTS_FAILURE,
  GET_PRODUCTS_SUCCESS,

  GET_GENDER,
  GET_GENDER_FAILURE,
  GET_GENDER_SUCCESS,

  GET_LENGTH,
  GET_LENGTH_FAILURE,
  GET_LENGTH_SUCCESS,

  GET_SIZE,
  GET_SIZE_FAILURE,
  GET_SIZE_SUCCESS,

  CREATE_PRODUCT,
  CREATE_PRODUCT_FAILURE,
  CREATE_PRODUCT_SUCCESS,

  EXPORT_PRODUCTS,
  EXPORT_SUCCESS,
  EXPORT_FAILURE,
  
  IMPORT_PRODUCTS,
  IMPORT_SUCCESS,
  IMPORT_FAILURE,

  ADD_QUANTITIES,
  ADD_QUANTITIES_SUCCESS,
  ADD_QUANTITIES_FAILURE,

  SUB_QUANTITIES,
  SUB_QUANTITIES_SUCCESS,
  SUB_QUANTITIES_FAILURE,

  PATCH_PRODUCT,
  PATCH_PRODUCT_FAILURE,
  PATCH_PRODUCT_SUCCESS,

  DELETE_PRODUCT,
  DELETE_PRODUCT_FAILURE,
  DELETE_PRODUCT_SUCCESS,

  UPLOAD_FILE,
  UPLOAD_FILE_PROGRESS,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,

  FILTER_PRODUCTS,
  FILTER_SUCCESS,
  FILTER_FAILURE
} = productTypes

const INITIAL_STATE = {
    products: [],
    productsDisplay: [],

    imports: [],
    importsDisplay: [],

    genders: [],
    gendersDisplay: [],

    lengths: [],
    lengthsDisplay: [],

    barcode: '',

    sizes: [],
    sizesDisplay: [],
  
    checkedProducts: [],
  
    photos: [],
    progress: 0,
  
    error: '',
    loading: false,
  }


export default (state = INITIAL_STATE, action) => {
    const {type, payload} = action
    switch (type) {
      case GET_PRODUCTS:
        return {
          ...state,
          loading: true,
        }
      case GET_PRODUCTS_SUCCESS:
        return {
          ...state,
          products: payload,
          productsDisplay: payload,
          loading: false,
        }
      case GET_PRODUCTS_FAILURE:
        return {
          ...state,
          products: [],
          productsDisplay: [],
          error: payload,
          loading: false,
        }

        case GET_GENDER:
        return {
          ...state,
          loading: true,
        }
      case GET_GENDER_SUCCESS:
        return {
          ...state,
          genders: payload,
          gendersDisplay: payload,
          loading: false,
        }
      case GET_GENDER_FAILURE:
        return {
          ...state,
          genders: [],
          gendersDisplay: [],
          error: payload,
          loading: false,
        }

        case GET_LENGTH:
        return {
          ...state,
          loading: true,
        }
      case GET_LENGTH_SUCCESS:
        return {
          ...state,
          lengths: payload,
          lengthsDisplay: payload,
          loading: false,
        }
      case GET_LENGTH_FAILURE:
        return {
          ...state,
          lengths: [],
          lengthsDisplay: [],
          error: payload,
          loading: false,
        }

        case GET_SIZE:
        return {
          ...state,
          loading: true,
        }
      case GET_SIZE_SUCCESS:
        return {
          ...state,
          sizes: payload,
          sizesDisplay: payload,
          loading: false,
        }
      case GET_SIZE_FAILURE:
        return {
          ...state,
          sizes: [],
          sizesDisplay: [],
          error: payload,
          loading: false,
        }

      case CREATE_PRODUCT:
        return {
          ...state,
          loading: true,
        }
      case CREATE_PRODUCT_SUCCESS:
        return {
          ...state,
          products: payload,
          productsDisplay: payload,
          loading: false,
        }
      case CREATE_PRODUCT_FAILURE:
        return {
          ...state,
          error: payload,
          loading: false,
        }

        case IMPORT_PRODUCTS:
        return {
          ...state,
          loading: true,
        }
      case IMPORT_SUCCESS:
        return {
          ...state,
          imports: payload,
          importsDisplay: payload,
          loading: false,
        }
      case IMPORT_FAILURE:
        return {
          ...state,
          error: payload,
          loading: false,
        }

        case EXPORT_PRODUCTS:
        return {
          ...state,
          loading: true,
        }
      case EXPORT_SUCCESS:
        return {
          ...state,
          exports: payload,
          exportsDisplay: payload,
          loading: false,
        }
      case EXPORT_FAILURE:
        return {
          ...state,
          exports: [],
          exportsDisplay: [],
          error: payload,
          loading: false,
        }

      case PATCH_PRODUCT:
        return {
          ...state,
          loading: true,
        }

      case PATCH_PRODUCT_SUCCESS:
        return {
          ...state,
          products: payload,
          productsDisplay: payload,
          loading: false,
        }
      case PATCH_PRODUCT_FAILURE:
        return {
          ...state,
          error: payload,
          loading: false,
        }
      case ADD_QUANTITIES:
        return {
          ...state,
          loading: true,
        }
        case ADD_QUANTITIES_SUCCESS:
        return {
          ...state,
          products: payload,
          productsDisplay: payload,
          loading: false,
        }
      case ADD_QUANTITIES_FAILURE:
        return {
          ...state,
          error: payload,
          loading: false,
        }

      case SUB_QUANTITIES:
        return {
          ...state,
          loading: true,
        }
      case SUB_QUANTITIES_SUCCESS:
        return {
          ...state,
          products: payload,
          productsDisplay: payload,
          loading: false,
        }
      case SUB_QUANTITIES_FAILURE:
        return {
          ...state,
          error: payload,
          loading: false,
        }
  
      case DELETE_PRODUCT:
        return {
          ...state,
          loading: true,
        }
      case DELETE_PRODUCT_SUCCESS:
        return {
          ...state,
          products: payload,
          productsDisplay: payload,
          loading: false,
        }
      case DELETE_PRODUCT_FAILURE:
        return {
          ...state,
          error: payload,
          loading: false,
        }
      case FILTER_PRODUCTS:  
        return {
          ...state,
          productsDisplay: payload,
          barcode: payload,
          loading: false
        }

      case FILTER_SUCCESS:
        return {
          ...state,
          barcode: payload,
          productsDisplay: payload,
          loading: false,
        }

    case FILTER_FAILURE:
        return {
          ...state,
          error: payload,
          loading: false,
        }

      case UPLOAD_FILE:
        return {
          ...state,
          progress: 0,
          loading: true,
        }
      case UPLOAD_FILE_PROGRESS:
        return {
          ...state,
          progress: payload,
        }
      case UPLOAD_FILE_SUCCESS:
        return {
          ...state,
          progress: 0,
          photos: [...state.photos, payload],
          loading: false,
        }
      case UPLOAD_FILE_FAILURE:
        return {
          ...state,
          progress: 0,
          loading: false,
        }
        default:
        return state     
    }
}