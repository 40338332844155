import React from "react";
import Job from "./Job";

export default function JobCard(props) {
    let job = props.job;

    const showCard = (job) => {
        console.log(job)
        // history.push(`/home/community/${job.id}`)
        window.location.href=`/home/careers/${job.id}`
        sessionStorage.setItem('job', JSON.stringify(job))
        // history.push('/home/community/commm')
    }

    return (
        <div onClick={() => showCard(job)} className={job.featured ? "job-card featured-job" : "job-card"}>
            <div className="details">
                <div className="primary-content">
                    <div className="row">
                        {
                            // <p className={'company-name'}>{job.company}</p>
                        }
                        {job.new && <div className="highlight new">NEW! </div>}
                        {job.featured && <div className="highlight featured">FEATURED</div>}
                    </div>
                    <h2 className={'position'}>{job.position}</h2>
                    <div className="secondary">
                        <p>{job.postedAt}</p>
                        <p>{job.contract}</p>
                        <p>{job.location}</p>
                    </div>
                </div>
                <ul className="tags">
                    {job.languages.map((language) => <li onClick={(e) => props.toggleLanguageFilter(language)}
                                                         key={language}>{language}</li>)}
                    {job.tools.map((tool) => <li onClick={(e) => props.toggleToolFilter(tool)} key={tool}>{tool}</li>)}
                </ul>
            </div>

        </div>
    )
}