import React, { Component } from 'react';
import Router from './ProfilePage.Router'

import styles from './ProfilePage.css'

class ProfilePage extends Component {
  render() {
    return (
      <div className={styles.app}>
        <Router />
      </div>
    );
  }
}

export default ProfilePage;
