import React, { Component } from 'react';
import emailjs from 'emailjs-com';
import styles from '../Home.css';
import { Button } from '../../../common';

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      name: '',
      email: '',
      message: ''
     }
  }
  render() { 
    const {
      name,
      email,
      message
    } = this.state
    const handleChange = (e) => {
      const { name, value } = e.target
      this.setState((prevState) => ({ ...prevState, [name]: value }))
    }
    const clearState = () => this.setState({ ...this.state })
  
    const handleSubmit = (e) => {
      e.preventDefault()
      console.log(name, email, message)
      emailjs
        .sendForm(
          'YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', e.target, 'YOUR_USER_ID'
        )
        .then(
          (result) => {
            console.log(result.text)
            clearState()
          },
          (error) => {
            console.log(error.text)
          }
        )
    }
    return ( 
      <div>
        <div id='contact' className={styles.app}>
          <div className='contact-forms'>
            <div className='container'>
              <div className='section-title'>
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name='sentMessage' validate onSubmit={handleSubmit}>
                <div className='forms'>
                  <div className='form-group'>
                    <input
                      type='text'
                      id='name'
                      name='name'
                      className='form-control'
                      placeholder='Name'
                      required
                      onChange={handleChange}
                    />
                    <p className='help-block text-danger'></p>
                  </div>
                  <div className='form-group'>
                    <input
                      type='email'
                      id='email'
                      name='email'
                      className='form-control'
                      placeholder='Email'
                      required
                      onChange={handleChange}
                    />
                    <p className='help-block text-danger'></p>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder='Message'
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <div id='success'></div>
                <Button className='form-button' type='submit' title='Send Message' />
              </form>
            </div>
            <div className='contact-info'>
              <div className='contact-item'>
                <h3>Contact Info</h3>
                <p>
                  <span>
                    <i className='fa fa-map-marker'></i> Address
                  </span>
                  {this.props.data ? this.props.data.address : 'loading'}
                </p>
              </div>
              <div className='contact-item'>
                <p>
                  <span>
                    <i className='fa fa-phone'></i> Phone
                  </span>{' '}
                  {this.props.data ? this.props.data.phone : 'loading'}
                </p>
              </div>
              <div className='contact-item'>
                <p>
                  <span>
                    <i className='fa fa-envelope-o'></i> Email
                  </span>{' '}
                  {this.props.data ? this.props.data.email : 'loading'}
                </p>
              </div>
            </div>
          </div>
          <div className='social'>
            <ul>
              <li>
                <a href={this.props.data ? this.props.data.facebook : '/'}>
                  <i className='fa fa-facebook'></i>
                </a>
              </li>
              <li>
                <a href={this.props.data ? this.props.data.twitter : '/'}>
                  <i className='fa fa-twitter'></i>
                </a>
              </li>
              <li>
                <a href={this.props.data ? this.props.data.youtube : '/'}>
                  <i className='fa fa-youtube'></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
     );
  }
}
 
export default Contact;
