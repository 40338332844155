import React, { Component } from 'react';
import { connect } from 'react-redux'

import PaymentPage from './AddAccount/PaymentPage/PaymentPage';
import { 
  getTransactions, 
  createAccount, 
  deleteAccountById
 } from '../../../actions/accounts';

import Router from './BankPage.Router'
import styles from './BankPage.css'

class BankPage extends Component {
    constructor(props) {
      super(props);
      this.state = { 
        onViewModal: null,
        paymentMethod: null,
        payment: {},
       }
    }
    
  render() {
    return (
      <div className={styles.app}>
        <Router 
          payment={this.state.payment}
          onViewModal={() => this.setState({ paymentMethod: {}})}/>
        {/* Modal create &stripe payment method  */}
        <PaymentPage
          content={this.state.paymentMethod}
          onClickAway={() => this.setState({ paymentMethod: null })}
          onSubmit={(e) => this.setState({ payment: e })}
        />  
      </div>
    );
  }
}

const mapStateToProps = ({ accounts, auth }) => {
  const {
    accountsDisplay,
    transactions,
    loading,
    user,
    error,
  } = accounts

  const {
    isAdmin,
  } = auth

  return {
    accounts: accountsDisplay || {},
    transactions,
    loading,
    user,
    error,
    isAdmin,
  }
}

const actions = { 
  getTransactions, 
  createAccount, 
  deleteAccountById 
}
export default connect(mapStateToProps, actions)(BankPage);
