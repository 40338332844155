/* eslint-disable no-undef */
/* eslint-disable no-new-object */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import parse from 'html-react-parser';

import styles from './CashierModal.css';
import { Button, FormModal, Input, Selector, Span } from '../../common';
import {  
  getDrawerById,
  createTransaction,
  checkout
} from '../../../actions/cashier';
import { getCart } from '../../../actions/community';
import { getAccountById } from '../../../actions/accounts';

import { SwitchTable } from '..';
import { formatCurrencyOptions } from '../../../lib';

class CashierModal extends Component {
  constructor(props) {
    super(props);
    this.tabOptions = [
      {
        value: 'purchase',
        display: 'Purchase',
        renderComponent: () => this.renderPurchase(),
      },
      {
        value: 'drawer',
        display: 'Register',
        renderComponent: () => this.renderRegister(),
      }
    ]
    this.state = {
      paymentMethod: [],
      tab: this.tabOptions[0],
      change: null,
      price: 0.00,
      payment: 0.00,
      type: 'cash',
      card: 0,
      exp: '',
      cvc: 0,
      country: 'US',
      currency: 'usd',
      accountHolder: '',
      accountType: 'individual',
      routingNumber: 0,
      accountNumber: 0,
      description: ''
    }
    this.rgx = /card_([0-9a-z]+)/ || /pm_([0-9a-z]+)/ || /src_([0-9a-z]+)/ || /tok_([0-9a-z]+)/
    this.me = sessionStorage.getItem('my-community-data') ? JSON.parse(sessionStorage.getItem('my-community-data')) : ''
  }

  get options() {
    return [
      {
        value: '',
        display: 'Select',
      },
      {
        value: 'cash',
        display: 'One-Time Cash',
      },
      {
        value: 'bank',
        display: 'One-Time Bank (Coming Soon!)',
      },
      {
        value: 'card',
        display: 'One-Time Card',
      }
    ]
  }

  componentDidMount() {
    this.props.getDrawerById(this.me._id)
    this.me.account && this.props.getAccountById()
  }
  
  formatTransaction (bundleData, header) {
    const currencyFormatter = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 });
    const quantityFormatter = new Intl.NumberFormat('en-US');
    const quantityNames = {
      2000: 'Twenty-dollar bills',
      1000: 'Ten-dollar bills',
      500: 'Five-dollar bills',
      100: 'One-dollar bills',
      50: 'Fifty-cent coins',
      25: 'Quarters',
      10: 'Dimes',
      5: 'Nickels',
      1: 'Pennies'
    };
  
    const total = currencyFormatter.format((bundleData.total/100));
    let output = `<h5>${header} $${total}</h5>`;
    output += `<div style="margin:5vh auto;display:grid;grid-template-columns:25vh 25vh;grid-template-rows:4vh 4vh 4vh 4vh;column-gap:2vh;row-gap:1vh;">`;
    _(quantityNames).forEach((name, bill) => {
      const q = quantityFormatter.format(bundleData.quantities[bill]);
      output += `<div><strong>${name}:</strong> ${q}</div>`;
    });
    output += '</div>';
  
    return parse(output);
  };

  // trigger the transaction
  handleClick(e) {
    e.preventDefault()
    const payment = this.state.payment;
    const price = this.state.price;
    const data = { payment, price };
    this.props.createTransaction(data)
  }

  submit(e) {
    e.preventDefault()
    const {
      account
    } = this.props
    const {
      type,
      card,
      exp,
      cvc,
      country,
      currency,
      accountHolder,
      accountType,
      routingNumber,
      accountNumber,
      name,
      contact,
      description
    } = this.state
    if (type==='card') {
      const data = {
        name,
        guest: this.me._id,
        type,
        source: {
          number: card,
          exp_month: exp.split('/')[0],
          exp_year: exp.split('/')[1],
          cvc
        },
        contact,
        description
      }
      this.props.checkout(data)
      this.props.onClickAway()
    }
    if (type==='bank') {
      const data = {
        guest: this.me._id,
        type,
        country,
        currency,
        accountHolder,
        accountType,
        routingNumber,
        accountNumber
      }
      this.props.checkout(data)
      this.props.onClickAway()
    }
    if (type==='balance') {
      const balance = account[0].balance
      const data = {
        user: this.me._id,
        source: { balance },
        account,
        description,
        contact
      }
      this.props.checkout(data)
      this.props.onClickAway()
    }
    if (this.rgx.test(type)) {
      const data = {
        user: this.me._id,
        account,
        description,
        source: type,
        contact
      }
      this.props.checkout(data)
      this.props.onClickAway()
    }
  }

  renderNewCard() {
    const {
      card,
      exp,
      cvc,
      name,
      contact,
      description
    } = this.state
    const {
      onClickAway
    } = this.props
    return (
      <div>
        <label>
          Name on Card
        </label>
        <Input
          placeholder="John Smith II"
          value={name}
          onChange={name => this.setState({ name })}
        />

        <label>
          Contact Email
        </label>
        <Input
          placeholder="email@address.com"
          value={contact}
          onChange={contact => this.setState({ contact })}
        />

        <label>
          Card details
        </label>
        <Input
          placeholder="XXXX XXXX XXXX XXXX"
          value={card}
          onChange={card => this.setState({ card })}
          maxlength="16"
        />

        <label>
          Expiration date
        </label>
        <Input
          placeholder="MM/YYYY"
          value={exp}
          onChange={exp => this.setState({ exp })}
          maxlength="7"
        />

        <label>
          CVC
        </label>
        <Input
          placeholder="123"
          value={cvc}
          onChange={cvc => this.setState({ cvc })}
          maxlength="3"
        />
        <label>
          Description
        </label>
        <Input
          placeholder="Add a note"
          value={description}
          onChange={description => this.setState({ description })}
        />
        <br />
        <div className="buttons">
          <Button
            large
            cancel
            type="button"
            title="Cancel"
            onClick={onClickAway}
          />
          <Button
            large
            title="One-Time Charge"
            inactive={this.inactive}
            onClick={this.submit.bind(this)}
          />
          <br />
        </div>
      </div> 
    )
  }

  renderNewBank() {
    const {
      onClickAway
    } = this.props
    const {
      country,
      currency,
      accountHolder,
      accountType,
      routingNumber,
      accountNumber
    } = this.state
    return (
      <div>
        <label>
          Account Holder Name:
        </label>
        <Input
          placeholder="Full Name"
          value={accountHolder}
          onChange={accountHolder => this.setState({ accountHolder })}
        />

        <br />

        <label>
          Routing Number:
        </label>
        <Input
          placeholder="9 digits"
          value={routingNumber}
          onChange={routingNumber => this.setState({ routingNumber })}
          maxlength="9"
        />

        <br />
        <label>
          Account Number:
        </label>
        <Input
          placeholder="account #"
          value={accountNumber}
          onChange={accountNumber => this.setState({ accountNumber })}
          maxlength="20"
        />
        <br />
        <div className="buttons">
          <Button
            large
            cancel
            type="button"
            title="Cancel"
            onClick={onClickAway}
          />
          <Button
            large
            title="Add"
            inactive={this.inactive}
            onClick={this.submit.bind(this)}
          />
          <br />
        </div>
      </div>
    )
  }

  renderBalance() {
    const {
      onClickAway,
      account,
      cart
    } = this.props
    const {
      description
    } = this.state
    return (
      <div>
        <label>
          Balance
        </label>
        {account[0].balance.toLocaleString(...formatCurrencyOptions)}

        <br />

        <label>
          Description:
        </label>
        <Input
          placeholder="Add a note"
          value={description}
          onChange={description => this.setState({ description })}
        />
        <br />
        <div className="buttons">
          <Button
            large
            cancel
            type="button"
            title="Cancel"
            onClick={onClickAway}
          />
          <Button
            large
            title="Purchase"
            inactive={this.inactive}
            onClick={this.submit.bind(this)}
          />
          <br />
        </div>
      </div>
    )
  }

  renderCashierTab() {
    const {
      change
    } = this.props
    const {
      payment,
      price
    } = this.state
    return ( 
      <div className={styles.form}>
        <br />
        <h4> Perform a Transaction: </h4>
        <div className={styles.formGroup}>
          <label> Payment: </label>
          <Input
            type="number"
            value={payment}
            onChange={payment => this.setState({ payment })}
          />
        </div>   
        <div className={styles.formGroup}>
          <Button onClick={this.handleClick.bind(this)} title="Submit" />
        </div>
      {!!change && this.formatTransaction(change, 'Success: Your change is')}
      </div> 
    )
  }

  renderCart() {
    const {
      cart,
      onClickAway
    } = this.props
    const {
      description
    } = this.state
    const {
      account,
      details,
      items
    } = cart
    return (
      <div>
        <h3>{account}</h3>
        <Span>{items.map(i => <Span><div>{i.item}</div><div>{i.price.toLocaleString(...formatCurrencyOptions)}</div></Span> )}</Span>
        <Span>{details}</Span>
        <label>
          Description
        </label>
        <Input
          placeholder="Add a note"
          value={description}
          onChange={description => this.setState({ description })}
        />
        <br />
        <div className="buttons">
          <Button
            large
            cancel
            type="button"
            title="Cancel"
            onClick={onClickAway}
          />
          <Button
            large
            title="Purchase"
            onClick={this.submit.bind(this)}
          />
          <br />
        </div>
      </div>
    )
  }

  renderPurchase() {
    const {
      type
    } = this.state
    const options = this.props.account && this.props.account[0] ? this.options.concat(this.props.account[0].paymentMethod).concat([       
      {
        value: 'balance',
        display: 'Balance',
      }
    ])
    : this.me.account ? this.options.concat(   
      [       
        {
          value: 'balance',
          display: 'Balance',
        }
      ]) 
    : this.options
    return (
      <div>
        <Selector
          wide
          value={this.state.type}
          onSelect={i => this.setState({ type: i })}
          options={options}
        /> 
                
        {this.rgx.test(type) && this.renderCart()}
        {type==='cash' && this.renderCashierTab()}
        {type==='card' && this.renderNewCard()}
        {type==='bank' && this.renderNewBank()}
        {type==='balance' && this.renderBalance()}
      </div>
    )
  }

  renderRegister() {
    const {
      drawer
    } = this.props
    return (
      <div>
        {this.formatTransaction(drawer, 'Your Register Contains:')}
      </div>
    )
  }

  render() {
    const { onClickAway, content, drawer, cart } = this.props
    const { type, tab } = this.state
    const total = cart.total+(cart.total*.06)
    return (
      <FormModal
        onClickAway={onClickAway}
        visible={!!content}
        className={styles.modal}
      >
        {this.props.isme 
        && <SwitchTable
            centered
            tabs={this.tabOptions}
            selected={tab}
            onClick={tab => this.setState({ tab })}
          />
        }
        {tab.value === 'purchase' && <label> {`Price: ${total.toLocaleString(...formatCurrencyOptions)}`} </label>}
        {!this.props.isme && this.renderPurchase()}
      </FormModal>
    )
  }
}

const mapStateToProps = ({ cashier, community, accounts }) => {
  const {
    drawer,
    change,
    receipt
  } = cashier
 const {
  } = community
  const {
    account
  } = accounts

  return {
    drawer,
    change,
    receipt,
    account
  }
}

const actions = {
  getDrawerById,
  createTransaction,
  getAccountById,
  checkout
}

export default connect(mapStateToProps, actions)(CashierModal);
