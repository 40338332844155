import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom'

// Components
import ContactsView from './ContactsView/ContactsView'
import ContactView from './ContactView/ContactView'
import ContactModal from './ContactModal/ContactModal'
import NotFound from '../../NotFound/NotFound'

const ContactsPageRouter = props => (
  <Switch>
    <Route
      exact
      path="/contacts"
      render={() => <ContactsView
        props={props}
        />
      }
    />

    <Route
      exact
      path="/contacts/add"
      render={() => <ContactModal
        props={props}
        />
      }
    />

    <Route
      exact
      path="/contacts/:id"
      render={() => <ContactView
        props={props}
        />
      }
    />

    <Route
      component={NotFound}
    />
  </Switch>
)


export default ContactsPageRouter
