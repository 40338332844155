// Router
import { push } from 'react-router-redux'

// Types
import { projectsTypes } from './types'

// Services
import { projects } from '../services'

//cookies
import * as cookies from 'js-cookie';

const {
  CREATE_PROJECT,
  CREATE_PROJECT_FAILURE,
  CREATE_PROJECT_SUCCESS,
  SET_PROJECT,
  GET_PROJECTS,
  GET_PROJECTS_FAILURE,
  GET_PROJECTS_SUCCESS,
  GET_PROJECT,
  GET_PROJECT_FAILURE,
  GET_PROJECT_SUCCESS,
  PATCH_PROJECT,
  PATCH_PROJECT_FAILURE,
  PATCH_PROJECT_SUCCESS,
  DELETE_PROJECT,
  DELETE_PROJECT_FAILURE,
  DELETE_PROJECT_SUCCESS,
  CREATE_NOTE,
  CREATE_NOTE_FAILURE,
  CREATE_NOTE_SUCCESS,
  CLEAR_ERRORS,
  CLEAR_STATE,
} = projectsTypes

const me = cookies.get('me') ? JSON.parse(cookies.get('me')) : {}
const { id, company } = me
const uid=id,cid=company;
/*
Get tag if existed or create new tag then assign to entry by calling a callback
@param integer uid user id
@param string tagName tag name
@param string tagColor tag color
@param function assignFn callback to call to assign tag to entry
*/
const assignProjectTime = (dispatch, uid, tagName, tagColor, assignFn) => {
  //use existed tag
  //tag exist so return error            
}

export const createProject = payload => dispatch => {
  dispatch({
    type: CREATE_PROJECT,
  })
  projects.post(payload)
    .then(res => onCreateProjectSuccess({dispatch, res}))
    .catch(err => onCreateProjectFailure({dispatch, err}))
}

function onCreateProjectSuccess({dispatch, res}) {
  dispatch({
    type: CREATE_PROJECT_SUCCESS,
  })
  dispatch(push('/projects'))
}

function onCreateProjectFailure({dispatch, err}) {
  const payload = err.message || 'CreateProject Failed'
  window.alert(payload)
  dispatch({
    type: CREATE_PROJECT_FAILURE,
    payload,
  })
}

export const getProjects = () => dispatch => {
  dispatch({
    type: GET_PROJECTS,
  })
  projects.getAll(cid,uid)
    .then(res => onGetProjectsSuccess({dispatch, res}))
    .catch(err => onGetProjectsFailure({dispatch, err}))
}

function onGetProjectsSuccess({dispatch, res}) {
  console.log('getProjects success');
  dispatch({
    type: GET_PROJECTS_SUCCESS,
    payload: res.data,
  })
}

function onGetProjectsFailure({dispatch, err}) {
  console.log('getProjects failure', err);
  dispatch({
    type: GET_PROJECTS_FAILURE,
    payload: err.message || 'Get Projects Failed',
  })
}

export const getProjectById = projectId => (dispatch, getState) => {
  // check if a project has been set yet
  const { project } = getState().projects
  if (project && project.id !== projectId) {
    // clear it if it is not the same one
    dispatch({
      type: CLEAR_STATE,
    })
  }

  dispatch({
    type: GET_PROJECT,
  })
  projects.getById(projectId)
    .then(res => onGetProjectSuccess({dispatch, res}))
    .catch(err => onGetProjectFailure({dispatch, err}))
}

function onGetProjectSuccess({dispatch, res}) {
  dispatch({
    type: GET_PROJECT_SUCCESS,
    payload: res.project,
  })
}

function onGetProjectFailure({dispatch, err}) {
  const payload = err.message || 'GetProject Failed'
  window.alert(payload)
  dispatch({
    type: GET_PROJECT_FAILURE,
    payload,
  })
  dispatch(push('/projects'))
}

export const patchProjectById = (id, update) => (dispatch, getState) => {
  dispatch({
    type: PATCH_PROJECT,
  })
  projects.patchById(id, update)
    .then(res => onPatchProjectSuccess({dispatch, res}))
    .catch(err => onPatchProjectFailure({dispatch, err}))
}

function onPatchProjectSuccess({dispatch, res}) {
  dispatch({
    type: PATCH_PROJECT_SUCCESS,
    payload: res.project,
  })
}

function onPatchProjectFailure({dispatch, err}) {
  const payload = err.message || 'PatchProject Failed'
  window.alert(payload)
  dispatch({
    type: PATCH_PROJECT_FAILURE,
    payload,
  })
}

export const createProjectNote = data => (dispatch, getState) => {
  dispatch({
    type: CREATE_NOTE,
  })

  const {
    projectId,
    text,
  } = data

  projects.postNoteById(projectId, { text })
    .then(res => onCreateProjectNoteSuccess({dispatch, getState, res}))
    .catch(err => onCreateProjectNoteFailure({dispatch, err}))
}

function onCreateProjectNoteSuccess({dispatch, getState, res}) {
  const { note } = res

  // update the visits array on the project
  const { project } = getState().projects
  const notes = [note, ...project.notes]
  project.notes = notes

  dispatch({
    type: CREATE_NOTE_SUCCESS,
    payload: { ...project },
  })
}

function onCreateProjectNoteFailure({dispatch, err}) {
  dispatch({
    type: CREATE_NOTE_FAILURE,
    payload: err.message || 'CreateProjectNote Failed',
  })
}

export const deleteProjectById = id => (dispatch, getState) => {
  dispatch({
    type: DELETE_PROJECT,
  })
  projects.deleteById(id)
    .then(res => onDeleteProjectSuccess({dispatch, getState}))
    .catch(err => onDeleteProjectFailure({dispatch, err}))
}

// TODO: REFACTOR - HOT RELOAD
function onDeleteProjectSuccess({dispatch, getState}) {
  console.log('DeleteProject success');
  const project = getState().projects
  // const projects = project.filter(el => el.id !== project.id)

  dispatch({
    type: DELETE_PROJECT_SUCCESS,
    payload: project,
  })
}

function onDeleteProjectFailure({dispatch, err}) {
  console.log('DeleteProject failure', err)
  const payload = err.message || 'DeleteProject Failed'
  window.alert(payload)
  dispatch({
    type: DELETE_PROJECT_FAILURE,
    payload,
  })
}

export const setProject = payload => {
  return {
    type: SET_PROJECT,
    payload,
  }
}

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  }
}

export const clearState = () => {
  return {
    type: CLEAR_STATE,
  }
}

