import React, { Component } from 'react';
import { connect } from 'react-redux'

// Components
import {
  SwitchTable,
  AccountsModal
} from '../../../../shared'

import {
  Input,
  Icon,
  Header,
  Body,
  Span,
  Button,
  FormModal,
} from '../../../../common'
import DashboardTab from './DashboardTab/DashboardTab'
import TimelineTab from './TimelineTab/TimelineTab'
import OfferingsTab from './OfferingsTab/OfferingsTab'
import PortfolioTab from './PortfolioTab/PortfolioTab'

// Actions
import {
  getMemberById,
  patchMemberById
} from '../../../../../actions/community'

import styles from './MemberView.css'

class MemberView extends Component {
  constructor(props) {
    super(props)
    this.tabOptions = [
      {
        value: 'dashboard',
        display: 'Dashboard',
        renderComponent: () => this.renderDashboardTab(),
      },
      {
        value: 'offerings',
        display: 'Offerings',
        renderComponent: () => this.renderOfferingsTab(),
      },
      {
        value: 'portfolio',
        display: 'Portfolio',
        renderComponent: () => this.renderDashboardTab(),
      },
      {
        value: 'timeline',
        display: 'Timeline',
        renderComponent: () => this.renderTimelineTab(),
      }
    ]
    this.member = sessionStorage.getItem('select_community') ? JSON.parse(sessionStorage.getItem('select_community')) : null
    this.me = sessionStorage.getItem('my-community-data') ? JSON.parse(sessionStorage.getItem('my-community-data')) : null
    this.isme = this.me && this.member && (this.me.company === this.member.mvrxt || this.me._id === this.member._id)
    this.state = this.initialState
  }

  get initialState() {
    const { community } = this.props
    const accountsModal = null
    const receiptModal = null
    const {
      name,
      group,
      contact,
    } = community
    return {
      tab: this.tabOptions[0],
      editing: false,
      name,
      group,
      contact,
      accountsModal,
      receiptModal
    }
  }

  static getDerivedStateFromProps(nextProps) {
    return nextProps.user || null
  }

  componentDidMount() {
    this.member && this.props.getMemberById(this.member)
  }

  setEditState(editing) {
    this.setState({ ...this.initialState, editing })
  }

  save() {
    const id = this.props.user.id
    const {
      name,
      contact,
      group,
      email,
    } = this.state

    const update = {
      name,
      contact,
      group,
      email,
    }

    this.props.patchMemberById(id, update)

    this.setEditState(false)
  }

  exchange() {
    const message = `Are you sure you want to exchange this coin?`
    const confirmed = window.confirm(message)
    if (!confirmed) {
      return
    }
    const id = this.props.team._id
    this.props.exchangeCoinById(id)

    console.log(this.props.team._id)
  }

  renderSwitchTable() {
    const { tab } = this.state
    return (
      <SwitchTable
        className={styles.tabOptions}
        tabs={this.tabOptions}
        selected={tab}
        onClick={tab => this.setState({ tab })}
      />
    )
  }

  renderDashboardTab() {
    return (
      <DashboardTab 
        me={this.me}
        isme={this.isme}
        coin={this.member}
      />
    )
  }

  renderOfferingsTab() {
    return (
      <OfferingsTab 
        me={this.me}
        isme={this.isme}
        coin={this.member}
      />
    )
  }

  renderTimelineTab() {
    return (
      <TimelineTab 
        me={this.isme}
      />
    )
  }
  
  renderPortfolioTab() {
    return (
      <PortfolioTab 
        me={this.isme}
      />
    )
  }

  renderContactInfo() {
    const {
      editing,
      firstName,
      lastName,
      username,
      group,
      email,
    } = this.state
    const { 
      community,
      isme
    } = this.props
    const {
      name,
      contact,
      logo
    } = community
    return (
      <div className={styles.logoInfo}>
        <img src={logo} />
        <div className={styles.contactInfo}>
          <div className={styles.viewInfo}>
            Name:
            <Span
              editing={editing}
              value={name}
              placeholder="Full Name"
              onChange={name => this.setState({ name })}
            >
              {name}
            </Span>
          </div>
          <div className={styles.viewInfo}>
            Group:
            <Span
              editing={editing}
              value={group}
              placeholder="Group Name"
              onChange={group => this.setState({ group })}
            >
              {group || 'None'}
            </Span>
          </div>
          <div className={styles.viewInfo}>
            Username:
            <Span
              editing={editing}
              value={username}
              placeholder="Username"
              onChange={username => this.setState({ username })}
            >
              {username || 'None'}
            </Span>
          </div>
          <div className={styles.viewInfo}>
            Email:
            <Span
              editing={editing}
              value={email}
              type="email"
              placeholder="user@gmail.com"
              onChange={email => this.setState({ email })}
            >
              {contact || 'None'}
            </Span>
          </div>
          {this.isme && <div className={styles.buttons}>
            <Button 
              large 
              hover 
              row 
              onClick={() => this.setState({ accountsModal: {} })} 
              title="Fund Account"
            />
          </div>
          }
        </div>
      </div>
    )
  }

  render() {
    const { community } = this.props
    if (!community) {
      return null
    }
    
    const {
      editing,
    } = this.state
    
    const {
      name,
    } = community
  
    if (!!this.state.accountsModal) {
      return ( 
        <AccountsModal
          isme={this.isme}
          me={this.me}
          content={this.state.accountsModal}
          onClickAway={() => this.setState({accountsModal: null})}
        />
      )
    }

    return (
      <div>
        <Header className={styles.header}>
          <h2>
            {this.isme && (
              !editing ? (
                <div>
                  <Icon
                    edit
                    onClick={() => this.setEditState(true)}
                  />
                  <Icon
                    exchange
                    onClick={() => this.exchange()}
                  />
                </div>
              ) : (
                <div>
                  <Icon
                    cancel
                    onClick={() => this.setEditState(false)}
                  />
                  <Icon
                    save
                    onClick={() => this.save()}
                  />
                </div>
              )
            )}
          </h2>
        </Header>
        <Body className={styles.body}>

          {this.renderContactInfo()}

          <div className="switch-buffer" />

          {this.renderSwitchTable()}
        </Body>
      </div>
    );
  }
}

const mapStateToProps = ({ community }) => {
  const {
    communitysDisplay,
    error,
  } = community

  return {
    community: communitysDisplay || {},
    error,
  }
}

const actions = {
  patchMemberById,
  getMemberById
}

export default connect(mapStateToProps, actions)(MemberView);
