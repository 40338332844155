import { mainTypes, teamTypes } from '../actions/types'

const {
  GET_ASSIGNMENTS,
  GET_ASSIGNMENTS_SUCCESS,
  GET_ASSIGNMENTS_FAILURE,
  CREATE_ASSIGNMENT,
  CREATE_ASSIGNMENT_SUCCESS,
  CREATE_ASSIGNMENT_FAILURE,
  DELETE_ASSIGNMENT,
  DELETE_ASSIGNMENT_FAILURE,
  DELETE_ASSIGNMENT_SUCCESS,
  GET_VISITS,
  GET_VISITS_SUCCESS,
  GET_VISITS_FAILURE,
  GET_SUPERVISORS,
  GET_SUPERVISORS_SUCCESS,
  GET_SUPERVISORS_FAILURE,
  FILTER_SUPERVISORS,
  GET_TEAM_MEMBERS,
  GET_TEAM_MEMBERS_SUCCESS,
  GET_TEAM_MEMBERS_FAILURE,
  FILTER_TEAM_MEMBERS,
  GET_USER,
  GET_EMPLOYEES,
  GET_EMPLOYEES_SUCCESS,
  GET_EMPLOYEES_FAILURE,
  FILTER_EMPLOYEES,
  GET_REPS,
  GET_REPS_SUCCESS,
  GET_REPS_FAILURE,
  CREATE_VISIT,
  CREATE_VISIT_SUCCESS,
  CREATE_VISIT_FAILURE,
  CLEAR_ERRORS,
} = mainTypes

const {
  CREATE_TEAM_MEMBER_SUCCESS,
} = teamTypes

const INITIAL_STATE = {
  reps: [],
  visits: [],

  assignments: [],
  assignmentsDisplay: [],

  supervisors: [],
  supervisorsDisplay: [],

  admins: [],
  adminsDisplay: [],

  employees: [],
  employeesDisplay: [],

  error: '',
  loading: false,
}

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action
  switch (type) {
    case GET_ASSIGNMENTS:
      return {
        ...state,
        loading: true,
      }
    case GET_ASSIGNMENTS_SUCCESS:
      return {
        ...state,
        assignments: payload,
        assignmentsDisplay: payload,
        loading: false,
      }
    case GET_ASSIGNMENTS_FAILURE:
      return {
        ...state,
        assignments: [],
        assignmentsDisplay: [],
        error: payload,
        loading: false,
      }
      case CREATE_ASSIGNMENT:
        return {
          ...state,
          loading: true,
        }
      case CREATE_ASSIGNMENT_SUCCESS:
        return {
          ...state,
          assignments: payload,
          loading: false,
        }
      case CREATE_ASSIGNMENT_FAILURE:
        return {
          ...state,
          error: payload,
          loading: false,
        }
      case DELETE_ASSIGNMENT:
        return {
          ...state,
          loading: true,
        }
      case DELETE_ASSIGNMENT_SUCCESS:
        return {
          ...state,
          products: payload,
          productsDisplay: payload,
          loading: false,
        }
      case DELETE_ASSIGNMENT_FAILURE:
        return {
          ...state,
          error: payload,
          loading: false,
        }
    case GET_VISITS:
      return {
        ...state,
        loading: true,
      }
    case GET_VISITS_SUCCESS:
      return {
        ...state,
        visits: payload,
        loading: false,
      }
    case GET_VISITS_FAILURE:
      return {
        ...state,
        visits: [],
        error: payload,
        loading: false,
      }
    case GET_SUPERVISORS:
      return {
        ...state,
        loading: true,
      }
    case GET_SUPERVISORS_SUCCESS:
      return {
        ...state,
        supervisors: payload,
        supervisorsDisplay: payload,
        loading: false,
      }
    case FILTER_SUPERVISORS:
      return {
        ...state,
        supervisorsDisplay: payload,
      }
    case GET_SUPERVISORS_FAILURE:
      return {
        ...state,
        supervisors: [],
        supervisorsDisplay: [],
        error: payload,
        loading: false,
      }
    case GET_TEAM_MEMBERS:
      return {
        ...state,
        loading: true,
      }
    case GET_TEAM_MEMBERS_SUCCESS:
      return {
        ...state,
        admins: payload,
        adminsDisplay: payload,
        loading: false,
      }
    case GET_TEAM_MEMBERS_FAILURE:
      return {
        ...state,
        admins: [],
        adminsDisplay: [],
        error: payload,
        loading: false,
      }
    case FILTER_TEAM_MEMBERS:
      return {
        ...state,
        adminsDisplay: payload,
      }
    case CREATE_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        admins: payload,
      }
    case GET_REPS:
      return {
        ...state,
        loading: true,
      }
    case GET_REPS_SUCCESS:
      return {
        ...state,
        reps: payload,
        loading: false,
      }
    case GET_REPS_FAILURE:
      return {
        ...state,
        reps: [],
        error: payload,
        loading: false,
      }
    case GET_EMPLOYEES:
      return {
        ...state,
        loading: true,
      }
    case GET_EMPLOYEES_SUCCESS:
      console.log(payload)
      return {
        ...state,
        employees: payload,
        employeesDisplay: payload,
        loading: false,
      }
    case GET_EMPLOYEES_FAILURE:
      return {
        ...state,
        employees: [],
        employeesDisplay: [],
        error: payload,
        loading: false,
      }
    case FILTER_EMPLOYEES:
      return {
        ...state,
        employeesDisplay: payload,
      }
    case CREATE_VISIT:
      return {
        ...state,
        loading: true,
      }
    case CREATE_VISIT_SUCCESS:
      return {
        ...state,
        visits: payload,
        loading: false,
      }
    case CREATE_VISIT_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      }
    case CLEAR_ERRORS:
      return {
        ...state,
        error: '',
        loading: false,
      }
    default:
      return state
  }
}
