import React, { Component } from 'react';
import Router from './Timesheet.Router';
import styles from './Timesheet.css';

class TimeTracker extends Component {
  constructor(props, ...rest) {
    super(props, ...rest);
    this.state = {};
  }

  render() {
    return (
      <div className={styles.body}>
        <Router />
      </div>
    );
  }
}


export default TimeTracker;