import React, { Component } from 'react';
import { connect } from 'react-redux'

import { hasAuthTokenAsync } from '../../../../lib'

import {
  Icon,
  Table,
  Header,
  ActionBox,
  Button,
  Selector,
  SearchBar,
} from '../../../common'

import {
  getContacts,
  filterContacts,
  filterContactsByRep,
  setContact,
  deleteContactById
} from '../../../../actions/contacts'

import styles from './ContactsView.css'
import { AddressLine, AddressModal } from '../../../shared';

class ContactsView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filterValue: '',
      searchType: '',
      search: '',
    }
  }

  componentDidMount() {
    hasAuthTokenAsync()
      .then(() => {
        this.props.getContacts()
      })
      .catch(console.error)
  }

  filterContacts(search) {
    const { searchType, filterValue } = this.state
    this.setState({ search })
    filterValue==='rep' ? this.props.filterContactsByRep(search, searchType) : this.props.filterContacts(search, searchType)
  }

  handleChange(e) {
    this.setState({value: e.target.value})
  }

  renderTableHead() { 
    return (
      <thead>
        <tr>
          <th>
            Contact Name
          </th>
          <th>
            Email
          </th>
          <th>
            Position
          </th>
          <th>
            Phone
          </th>
          <th>
            Address
          </th>
          <th />
        </tr>
      </thead>
    )
  }

  delete() {
    const message = `Are you sure you want to delete ${this.props.contacts.id}?`
    const confirmed = window.confirm(message)
    if (!confirmed) {
      return
    }

    const id = this.props.contacts.id

    this.props.deleteContactById(id)
  }

  renderIcons(){
    return (
        <Icon
          small
          cancel
          onClick={() => this.delete()}
        />
    )
  }

  renderTableBody() {
    const { contacts } = this.props 
    console.log(contacts)
    if (!Array.isArray(contacts)) {
      return (
        <tbody>
          <tr>
            <td colSpan="9" className="empty">
              No Receipts Found
            </td>
          </tr>
        </tbody>
      )
    } else {
      return (
        <tbody>
          { contacts.map(this.renderTableRow.bind(this)) }
        </tbody>
      )
    }
  }

  renderTableRow(contact) {
    const { rep } = contact
    const { address } = contact
    return (
      <tr key={contact._id}>
        <td className="bold">
          {contact.name}
        </td>
        <td>
          {contact.email}
        </td>
        <td>
          {contact.position}
        </td>
        <td>
          {contact.phone}
        </td>
        <td>
          {
            <AddressLine /> 
          // contact.address
          }
        </td>
        <td className={styles.detailsCell}>
          <Button
            title="DETAILS"
            link={`/contacts/${contact._id}`}
            onClick={() => this.props.setContact(contact)}
          />
        </td>
      </tr>
    )
  }
  
  renderTable() {
    return (
      <Table className={styles.table}>
        {this.renderTableHead()}
        {this.renderTableBody()}
      </Table>
    )
  }

  render() {
    const {
      filterValue,
      searchType,
      search,
    } = this.state

    const {
      employees,
      onViewModal,
    } = this.props

    const filterOptions = [
      {
        value: '',
        display: 'All',
      },
      {
        value: 'rep',
        display: 'Rep'
      }
    ]

    const searchOptions = [
      {
        value: '',
        display: 'Any',
      },
      {
        value: 'name',
        display: 'Name',
      },
      {
        value: 'id',
        display: 'ID',
      },
    ]
    return (
      <div className={styles.app}>
        <div className="body">
          <ActionBox>
            <div className="main">
              {/* TODO: Filter Select */}
              <Selector
                label="Filter"
                selected={filterValue}
                options={filterOptions}
                onSelect={filterValue => this.setState({ filterValue })}
              />
              <SearchBar
                options={searchOptions}
                selected={searchType}
                onSelect={searchType => this.setState({ searchType })}
                label="Search"
                placeholder="Search..."
                value={this.state.search}
                onChange={this.filterContacts.bind(this)}
              />
            </div>
            <div className="action">
              <Button
                icon="plus"
                title="ADD A NEW CONTACT"
                link="/contacts/add"
              />
            </div>
          </ActionBox>
          {this.renderTable()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ main, contacts }) => {
  const {
    employees,
  } = main
  
  const {
    contactsDisplay,
  } = contacts

  return {
    contacts: contactsDisplay,
    employees,
  }
}

const actions = {
  setContact,
  getContacts,
  filterContacts,
  filterContactsByRep,
  deleteContactById
}

export default connect(mapStateToProps, actions)(ContactsView);
