import S3 from 'react-aws-s3';

export const s3photos = (photo, product) => {
    // prod
    // const config = {
    //   bucketName: 'muvor',
    //   dirName: 'images',
    //   region: 'us-east-2',
    //   accessKeyId: 'AKIAST5WVHKEC5V6VFHC',
    //   secretAccessKey: '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',
    //   s3Url: 'https://muvor.s3.amazonaws.com'
    // } 
    // dev
    const config = {
        bucketName: 'muvor',
        dirName: 'photos',
        region: 'us-east-2',
        accessKeyId: 'AKIAST5WVHKEC5V6VFHC',
        secretAccessKey: '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',
        s3Url: 'https://muvor.s3.amazonaws.com'
      } 
      return new Promise ((resolve, reject) => {
        const ReactS3Client = new S3(config)
        ReactS3Client
        .uploadFile(photo, product)
        .then(l => {
          resolve(l.location) 
        })
        .catch(err => reject(err))
      })
}

export const s3documents = (file, type) => {
    // prod
    // const config = {
    //   bucketName: 'muvor',
    //   dirName: 'files',
    //   region: 'us-east-2',
    //   accessKeyId: 'AKIAST5WVHKEC5V6VFHC',
    //   secretAccessKey: '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',
    //   s3Url: 'https://muvor.s3.amazonaws.com'
    // } 
    // dev
    const config = {
      bucketName: 'muvor',
      dirName: 'documents',
      region: 'us-east-2',
      accessKeyId: 'AKIAST5WVHKEC5V6VFHC',
      secretAccessKey: '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',
      s3Url: 'https://muvor.s3.amazonaws.com'
    } 
    return new Promise ((resolve, reject) => {
    const ReactS3Client = new S3(config);
      ReactS3Client
      .uploadFile(file, type)
      .then(l => {
        resolve(l.location) 
      })
      .catch(err => reject(err))
    })
}

export const s3deletePhoto = (photo) => {
  // prod
  // const config = {
  //   bucketName: 'muvor',
  //   dirName: 'images',
  //   region: 'us-east-2',
  //   accessKeyId: 'AKIAST5WVHKEC5V6VFHC',
  //   secretAccessKey: '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',
  //   s3Url: 'https://muvor.s3.amazonaws.com'
  // } 
  // dev
  const config = {
    bucketName: 'muvor',
    dirName: 'photos',
    region: 'us-east-2',
    accessKeyId: 'AKIAST5WVHKEC5V6VFHC',
    secretAccessKey: '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',
    s3Url: 'https://muvor.s3.amazonaws.com'
  } 
  return new Promise ((resolve, reject) => {
    const ReactS3Client = new S3(config)
    ReactS3Client
      .deleteFile(photo)
      .then(response => resolve(response.message))
      .catch(err => reject(console.error(err)))
  })
}


export const s3deleteDocument = (file) => {
  // prod
  // const config = {
  //   bucketName: 'muvor',
  //   dirName: 'files',
  //   region: 'us-east-2',
  //   accessKeyId: 'AKIAST5WVHKEC5V6VFHC',
  //   secretAccessKey: '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',
  //   s3Url: 'https://muvor.s3.amazonaws.com'
  // } 
  // dev
  const config = {
    bucketName: 'muvor',
    dirName: 'documents',
    region: 'us-east-2',
    accessKeyId: 'AKIAST5WVHKEC5V6VFHC',
    secretAccessKey: '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',
    s3Url: 'https://muvor.s3.amazonaws.com'
  } 
  return new Promise ((resolve, reject) => {
    const ReactS3Client = new S3(config)
    ReactS3Client
      .deleteFile(file)
      .then(response => resolve(response.message))
      .catch(err => reject(console.error(err)))
  })
}
