import React, { Component } from 'react';
import { Button } from '../../common';

import styles from '../LandingPage.css';

class SocialProof extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    onClick(e) {
        e.preventDefault()
        window.location.replace("https://wakuuenterprises.com")
    }
    paper(e) {
        e.preventDefault()
        window.location.replace("https://wakuuenterprises.com/mvrxwp-draft")
    }

    render() {
        return (
            <div className={styles.app}>
                <div className="social-proof"> 
                    <Button title="Muvor Apps" module />
                    <Button title="Whitepaper." onClick={this.paper} module />
                    <Button title="Wakuu Home" onClick={this.onClick} module />
                </div>
            </div>
        );
    }
}

export default SocialProof;
