import React, { Component } from 'react';
import { connect } from 'react-redux'

import { hasAuthTokenAsync } from '../../../../lib'

import {
  Span,
  Table,
  Header,
  ActionBox,
  Button,
  SearchBar,
  Selector,
} from '../../../common'

import {
  getUser,
  getEmployees,
  filterEmployeesByDob,
  filterEmployeesByName
} from '../../../../actions/main'

import {
  setEmployee,
} from '../../../../actions/employees'

import styles from './EmployeesView.css'

class EmployeesView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      dob: '',
    }
  }
  componentDidMount() {
    hasAuthTokenAsync()
      .then(() => {
        this.props.getEmployees()
      })
      .catch(console.error)
  }

  get roles() {
    return [
      {
        value: 'Employee',
        display: 'Employee',
      },
      {
        value: 'Super',
        display: 'Supervisor',
      },
      {
        value: 'Rep',
        display: 'Sales Rep',
      },
      {
        value: 'HR',
        display: 'Human Resouces',
      }
    ]
  }

  get titles() {
    return [
      {
        value: 'User',
        display: 'User',
      },
      {
        value: 'Representative',
        display: 'Rep',
      },
      {
        value: 'Supervisor',
        display: 'Supervisor',
      },
      {
        value: 'Guest',
        display: 'Guest',
      },
      {
        value: 'Administrator',
        display: 'Administrator',
      }
    ]
  }

  searchByName(name) {
    const dob = ''
    this.setState({ name, dob })
    this.props.filterEmployeesByName(name)
  }

  searchByDob(dob) {
    const name = ''
    this.setState({ name, dob })
    this.props.filterEmployeesByDob(dob)
  }

  handleChange(e) {
    this.setState({name: e.target.value})
  }

  renderTableHead() {
    return (
      <thead className={styles.head}>
        <tr>
          <th>
            Name
          </th>
          <th>
            Email
          </th>
          <th>
            Phone Number
          </th>
          <th>
            Address
          </th>
          <th>
            Position
          </th>
          <th>
            Title
          </th>
          <th>
            Supervisor
          </th>
          <th />
        </tr>
      </thead>
    )
  }

  renderTableBody() {
    if (!Array.isArray(this.props.employees)) {
      return (
        <tbody>
          <tr>
            <td colSpan="9" className="empty">
              No Receipts Found
            </td>
          </tr>
        </tbody>
      )
    } else {
      return (
        <tbody>
          {this.props.employees.map(this.renderTableRow.bind(this))}
        </tbody>
      )
    }
  }

  renderTableRow(employee) {
    // const address = employee.address ? employee.address.street1 : 'None'
    
    return (
      <tr className={styles.map}>
        <td>
            {employee.name}
        </td>

        <td className={styles.email}>
          {employee.email}
        </td>

        <td>
          <Span icon="phone">
            {employee.phone || 'None'}
          </Span>
        </td>

        <td>
          {employee.address}
        </td>

        <td className={styles.select}>
          <Selector
            table
            value={employee.role}
            onSelect={r => this.setState({ role: r })}
            options={this.roles}
            className={styles.select}
          />
        </td>
        
        <td className={styles.select}>
          <Selector
            table
            value={employee.title}
            onSelect={t => this.setState({ title: t })}
            options={this.titles}
            className={styles.select}
          />
        </td>

        <td className="bold">
          {employee.supervisor}
        </td>
        <td className={styles.detailsCell}>
          <Button
            title="DETAILS"
            link={`/employees/${employee._id}`}
            onClick={() => this.props.setEmployee(employee)}
          />
        </td>
      </tr>
    )
  }

  renderTable() {
    return (
      <Table>
        {this.renderTableHead()}
        {this.renderTableBody()}
      </Table>
    )
  }

  render() {
    const {
      name,
      dob,
    } = this.state

    return (
      <div className={styles.app}>
        <div className="body">
          <ActionBox>
            <div className="main">
              <SearchBar
                label="Search By Name"
                placeholder="First or Last Name..."
                value={name}
                onChange={this.searchByName.bind(this)}
              />
              <SearchBar
                label="Search By DOB"
                type="date"
                value={dob}
                onChange={this.searchByDob.bind(this)}
              />
            </div>
            <div className="action">
              <Button
                link="/employees/add"
                icon="plus"
                title="ADD A NEW EMPLOYEE"
              />
            </div>
          </ActionBox>
          {this.renderTable()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ main }) => {
  const {
    employees,
    loading,
    error,
  } = main

  return {
    employees,
    loading,
    error,
  }
}

const actions = {
  getEmployees,
  setEmployee,
  filterEmployeesByName,
  filterEmployeesByDob,
}

export default connect(mapStateToProps, actions)(EmployeesView);
