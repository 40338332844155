import React, { Component } from 'react';
import { connect } from 'react-redux'

import {
  Selector,
  Button,
  Header,
  Body,
  Input,
  Form,
  FormModal,
  FileUpload,
  Icon,
  Span,
  FloatBox,
} from '../../../../common'

// Actions
import { createCommunityMember } from '../../../../../actions/community'

import styles from './VerifyPage.css'

import logo from '../../../assets/Muvor.png'
import { s3photos } from '../../../../../lib/s3';

function randomName(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  // result = result.match(/.{1,5}/g).join('-')
  return result;
}

class VerifyPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      code: '',
      datum: JSON.parse(sessionStorage.getItem('formDatum')),
      picture: []
    }
  }

  get invalid() {
    const {
      code,
      datum
    } = this.state

    return !(
      code === datum.accessCode
    )
  }

  renderPhotoItem(picture) {
    let pic = URL.createObjectURL(picture)
    return (
      <div>
        <img className="image"
          src={pic} />
        <Icon
          small
          button
          cancel
          onClick={() => this.setState({picture:[]})}
        />
      </div>
    )
  }

  render() {
    const {
      code
    } = this.state

    const onClick = (e) => {
      e.preventDefault()
      const {
        datum,
        picture
      } = this.state;  
      const randName = randomName(8)
      if (picture.length > 0) {
        picture.map(p => {
          s3photos(p, randName)
          .then(l => { 
              datum.picture = l
            this.props.createCommunityMember(datum)
          })
        })
      } else {
        this.props.createCommunityMember(datum)
      }
      // FIXME: file does not retain its size.
      // picture.length > 0 
      // && picture.map(p => {
      //   const pict = Buffer.from(new Uint8Array(p))
      //   var blob = new Blob(pict, {'type': 'image/png'});
      //   var file = new File([blob], p.name, { type: p.type, lastModified: Date.now() });
      //   var url = URL.createObjectURL(file); 
      //   console.log(p)
      //   console.log(file)
      //   var a = this.state.picture.slice();
      //   a.push(file);
      //   this.setState({picture:a})
      // })
    }
    return (
      <Body className={styles.body}>
        <Form className={styles.modal}>
          <img src={logo} className={styles.logo}/>
          <Span className={styles.title}> Muvor Coin: Community Token </Span>
          <br />

          <br />

          <label>
            Coin Photo (You can change this later):
          </label>

          <div className={styles.photoList}>
          {
            this.state.picture.length ? (
            this.state.picture.map(this.renderPhotoItem.bind(this))
            ) : (
              <span>
                None
              </span>
              )
            }
            </div>
            <FileUpload
              id="add-photo"
              wide
              large
              confirm
              title="PHOTOS"
              accept={'image/jpeg,image/png'}
              inactive={this.props.loading}
              onUpload={picture => {var a=this.state.picture.slice();a.push(picture);this.setState({picture:a})}}
              progress={this.props.progress}
            />
          <br />

          <label>
            Verify Access Code:
          </label>
          <Input
            placeholder="..."
            value={code}
            onChange={code => this.setState({ code })}
          />
          
          <br />

          <Button className={styles.vBtn} inactive={this.invalid || this.props.loading} title="Verify" large confirm onClick={onClick}/>
        </Form>
      </Body>
    );
  }
}
 
const mapStateToProps = ({ main, community }) => {
  const {
    loading,
    progress,
    history
  } = main

  const {
    verify
  } = community

  return {
    verify,
    loading,
    progress,
    history
  }
}

const actions = {
  createCommunityMember
}

export default connect(mapStateToProps, actions)(VerifyPage)
