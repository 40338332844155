import React, { useState, useEffect, Component, useLayoutEffect } from 'react';
import { XYPlot, XAxis, YAxis, HorizontalGridLines, AreaSeries, LineSeries } from 'react-vis';
import styles from './lines.css';

class Lines extends Component {
  constructor(props) {
    super(props);
    this.state = { 
     }
  }

  render() { 
    return ( 
      <div className={styles.lines}>
        <XYPlot
          width={window.innerWidth*.85}
          height={300}
        >
          <AreaSeries
            data={[
              {
                x: 0,
                y: 10
              },
              {
                x: 1,
                y: 9.723154918605044
              },
              {
                x: 2,
                y: 9.601952974219888
              },
              {
                x: 3,
                y: 9.48185529012974
              },
              {
                x: 4,
                y: 9.083914113387285
              },
              {
                x: 5,
                y: 8.353995343937187
              },
              {
                x: 6,
                y: 8.107341484723765
              },
              {
                x: 7,
                y: 7.553715820576757
              },
              {
                x: 8,
                y: 7.983421207999468
              },
              {
                x: 9,
                y: 8.028177772992375
              },
              {
                x: 10,
                y: 7.801291868576951
              },
              {
                x: 11,
                y: 8.469669922346327
              },
              {
                x: 12,
                y: 7.660145217274051
              },
              {
                x: 13,
                y: 7.604447664872998
              },
              {
                x: 14,
                y: 7.3848143549471725
              },
              {
                x: 15,
                y: 7.751595310390125
              },
              {
                x: 16,
                y: 7.60668762887067
              },
              {
                x: 17,
                y: 8.139299993957847
              },
              {
                x: 18,
                y: 7.898036363617992
              },
              {
                x: 19,
                y: 8.335231247021806
              },
              {
                x: 20,
                y: 8.139720679835927
              }
            ]}
            opacity={0.25}
            stroke="transparent"
            style={{}}
          />
          <LineSeries
            curve={null}
            data={[
              {
                x: 0,
                y: 10
              },
              {
                x: 1,
                y: 9.723154918605044
              },
              {
                x: 2,
                y: 9.601952974219888
              },
              {
                x: 3,
                y: 9.48185529012974
              },
              {
                x: 4,
                y: 9.083914113387285
              },
              {
                x: 5,
                y: 8.353995343937187
              },
              {
                x: 6,
                y: 8.107341484723765
              },
              {
                x: 7,
                y: 7.553715820576757
              },
              {
                x: 8,
                y: 7.983421207999468
              },
              {
                x: 9,
                y: 8.028177772992375
              },
              {
                x: 10,
                y: 7.801291868576951
              },
              {
                x: 11,
                y: 8.469669922346327
              },
              {
                x: 12,
                y: 7.660145217274051
              },
              {
                x: 13,
                y: 7.604447664872998
              },
              {
                x: 14,
                y: 7.3848143549471725
              },
              {
                x: 15,
                y: 7.751595310390125
              },
              {
                x: 16,
                y: 7.60668762887067
              },
              {
                x: 17,
                y: 8.139299993957847
              },
              {
                x: 18,
                y: 7.898036363617992
              },
              {
                x: 19,
                y: 8.335231247021806
              },
              {
                x: 20,
                y: 8.139720679835927
              }
            ]}
            opacity={1}
            stroke="#12939a"
            strokeStyle="solid"
          />
          <XAxis />
          <YAxis />
        </XYPlot>
      </div>
    );
  }
}
 
export default Lines;