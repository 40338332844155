import React, { Component } from 'react';
import styles from '../Home.css';

class Features extends Component {
  constructor(props) {
    super(props);
    this.state = {  }
  }
  render() { 
    return ( 
      <div className={styles.app}>
        <div id='features'>
          <div className='container'>
            <h2>Suite of Applications</h2>
            {
            // <h3>
            //   Enterprise Resource Planning, is used to manage the data of organizations in a more efficient manner. 
            //   <strong> Muvor</strong> takes on large amounts of data, analyzes, and then displays in a User-Friendly way. 
            //   Organizations who rely on ERP systems can expect reliable storage, both On-Premises and In the Cloud. 
            //   Integrating Blockchain with our ERP systems allows you to have direct access to all of your data, at all times,
            //   knowing that built in cryptography provides all the security your company will need.
            // </h3>
            }
            <div className='feats'>
              {this.props.data
                ? this.props.data.map((d, i) => (
                    <div key={`${d.title}-${i}`} className='feat'>
                      {' '}
                      <i className={d.icon}></i>
                      <h3>{d.title}</h3>
                      <p>{d.text}</p>
                    </div>
                  ))
                : 'Loading...'}
            </div>
          </div>
        </div>
      </div>
     );
  }
}
 
export default Features;