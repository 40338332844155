import React, { Component } from "react";
import "./DashboardEmployee.css";
import { Route, Link } from "react-router-dom";
import cookies from 'js-cookie'
import NavBar from "../NavBar/NavBar";
import Router from './DashboardEmployee.router'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
 faUser,
faFileAlt,
faUniversity,
faBriefcase,
faMale,
faHome,
faPassport,
} from "@fortawesome/free-solid-svg-icons";
import EmployeeDashboard from "../UserDashboards/Employee/EmployeeDashboard";

class DashboardHR extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      redirect: true,
      checked: true,
      me: cookies.get('me') ? JSON.parse(cookies.get('me')) : {}
    }
  };

  handleChange=(checked)=> {
    console.log("switch");
    // var sidebarV = this.refs.sidebar;
    // var sidebarV = React.findDOMNode( this.refs.sidebar);
    // sidebarV.style.disply="none";
    
    if(this.state.checked==true){ 
       // document.getElementById("sidebar").setAttribute("style", "display:none")
      document.getElementById("sidebar").setAttribute("class", "display-none");
    }
    // document.getElementById("sidebar").setAttribute("style", "display:block");
    else{document.getElementById("sidebar").setAttribute("class", "display-block");}   
    this.setState({ checked });
  }

  render() {
    return (
      <div id="outer-main-div">
        <div id="outer-nav">
          {/* <NavBar loginInfo={this.props.data} /> */}
          <NavBar
            loginInfo={this.props.data}
            checked={this.state.checked}
            handleChange={this.handleChange}
            onLogout={this.props.onLogout}
          />
        </div>

        <div id="main-non-nav">
          <div id="sidebar">
            <div id="sidebar-top-content" />
            <div id="main-title" className="main-title-employee">
              <FontAwesomeIcon icon={faUsers} className="sidebar-icon" />
              Employee
            </div>
            <ul className="navbar-ul">
              <li>
                <Link
                  to={
                    "/hr/dashboard"
                    // this.props.data["_id"] +
                  }
                >
                  <FontAwesomeIcon
                    icon={faHome}
                    className="sidebar-icon"
                  />
                  Dashboard
                </Link>
              </li>
              { this.state.me.role === "Admin" &&
              <li>
                <Link
                  to={"/hr/admin"}
                >
                  <FontAwesomeIcon
                    icon={faPassport}
                    className="sidebar-icon"
                  />
                  Admin
                </Link>
              </li>
              }
              <li>
                <Link
                  to={
                    "/hr/employee/" +
                    // this.props.data["_id"] +
                    "personal-info"
                  }
                >
                  <FontAwesomeIcon
                    icon={faUser}
                    className="sidebar-icon"
                  />
                  Personal Information
                </Link>
              </li>
              <li>
                <Link
                  to={
                    "/hr/employee/education"
                    // this.props.data["_id"] + 
                  }
                >
                  <FontAwesomeIcon
                    icon={faUniversity}
                    className="sidebar-icon"
                  />
                  Education
                </Link>
              </li>
              <li>
                <Link to={
                    "/hr/employee/" + 
                    // this.props.data["_id"] + 
                    "family-info"
                  }>
                  <FontAwesomeIcon
                    icon={faMale}
                    className="sidebar-icon"
                  />
                  Dependents
                </Link>
              </li>
              <li>
                <Link to={
                    "/hr/employee/" + 
                    // this.props.data["_id"] + 
                    "work-experience"
                  }>
                  <FontAwesomeIcon
                    icon={faBriefcase}
                    className="sidebar-icon"
                  />
                  WorkExp
                </Link>
              </li>
              <li>
                <Link to={
                    "/hr/employee/" + 
                    // this.props.data["_id"] + 
                    "leave-application-emp"
                  }>
                  <FontAwesomeIcon
                    icon={faFileAlt}
                    className="sidebar-icon"
                  />
                  Leave Application
                </Link>
              </li>
            </ul>
          </div>
          {/* <div id="sidebar-top-content" /> */}
          <div id="main-area">
            <div id="sidebar-top-content" />
            { window.location=='http://localhost:3000/hr/employee' ? <EmployeeDashboard /> : <Router /> }
          </div>
        </div>
      </div>
    );
  }
}

export default DashboardHR;
