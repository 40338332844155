import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom'

// Components
import ProfileCard from './ProfileCard/ProfileCard'
import NotFound from '../../NotFound/NotFound'
import ProfilePage from './ProfilePage/ProfilePage';

const ProfilePageRouter = props => (
  <Switch>
    <Route
      path="/profile/me"
      render={() => <ProfilePage
        comp={props}
        />
      }
    /> 

    <Route
      path="/profile/card"
      render={() => <ProfileCard
        comp={props}
        />
      }
    /> 

    <Route
      component={NotFound}
    />
  </Switch>
)


export default ProfilePageRouter
