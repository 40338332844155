import { api } from '../config'

export default {
  getAll() {
    const url = '/drawer'
    return api.get(url)
  },

  post(data, id) {
    const url = `/drawer/${id}`
    return api.post(url, data)
  },

  getById(id) {
    const url = `/drawer/${id}`
    return api.get(url)
  },

  patchById(id, update) {
    const url = `/drawer/${id}`
    return api.put(url, update)
  },

  checkout (data) {
    const u = sessionStorage.getItem('my-community-data') ? JSON.parse(sessionStorage.getItem('my-community-data')) : ''
    const url = `/offerings/${u._id}/checkout`
    return api.put(url, data)
  },

  deleteById(id) {
    const url = `/drawer/${id}`
    return api.delete(url, id)
  }
}
