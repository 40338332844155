import { socketsTypes } from '../actions/types'

// import {
//   getInitialState, 
//   addMessageToState, 
//   setMessagesAwayStatus, 
//   updateConnection
// } from '../components/Main/MessagerPage/ChatPage/chat/states/connect/utils';

const {
  CONNECTION,
  CONNECTION_SUCCESS,
  CONNECTION_FAILURE,
  SOCKET_STATUS,
  SOCKET_ADD,
  SOCKETS_REMOVE,
  SOCKETS_AWAY,
  SOCKET_SET,
  SOCKET_CLEAR,
  SOCKET_ROOM_ADD,
  SOCKET_SET_VIDEO,
  SOCKET_SET_AUDIO,
  MVRX,
  MVRX_SUCCESS,
  MVRX_FAILURE
} = socketsTypes

const INITIAL_STATE = {
  socketsDisplay: null,
  sockets: null,
  messagesDisplay: null,
  messages: null,
  rooms: [],
  connection: [],
  mvrx: []
}

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action
  switch (type) {

    // case SOCKET_STATUS:
    //   return updateConnection( state, action.payload );

    // case SOCKET_ADD:
    //   return addMessageToState( state, action.payload );

    // case SOCKETS_REMOVE:
    //   return getInitialState();

    // case SOCKETS_AWAY:
    //   return setMessagesAwayStatus( state, action.payload );

    case SOCKET_SET:
      return {
        ...state,
        sockets: payload,
        socketsDisplay: payload,
      }
    case SOCKET_SET_VIDEO:
      return {
        ...state,
        video: payload
      }    
    case SOCKET_SET_AUDIO:
      return {
        ...state,
        audio: payload
      }  
    case SOCKET_ROOM_ADD:
      return {
        ...state,
        rooms: payload
      }
    case SOCKET_CLEAR:
      return {
        ...state,
        sockets: null,
        socketsDisplay: null,
      }
    case CONNECTION:
      return {
        ...state,
        loading: true,
      }
    case CONNECTION_SUCCESS:
      return {
        ...state,
        connection: payload,
        loading: false,
      }
    case CONNECTION_FAILURE:
      return {
        ...state,
        connection: [],
        error: payload,
        loading: false,
      }
    case MVRX:
      return {
        ...state,
        loading: true,
      }
    case MVRX_SUCCESS:
      return {
        ...state,
        mvrx: payload,
        loading: false,
      }
    case MVRX_FAILURE:
      return {
        ...state,
        mvrx: [],
        error: payload,
        loading: false,
      }
    default:
      return state
  }
}
