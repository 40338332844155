import React from 'react';

import {
  Table,
} from '../../../../common'

const ProductsTab = ({ className }) => {
  return (
    <div className={className}>
      <Table>
        <thead>
          <tr>
            <th>
              Product
            </th>
            <th>
              Details
            </th>
            <th>
              Written Date
            </th>
            <th>
              Supervisor
            </th>
            <th>
              Quantity
            </th>
            <th>
              Cost
            </th>
          </tr>
        </thead>
        <tbody>
        </tbody>
      </Table>
    </div>
  )
}

export default ProductsTab;
