import React, { Component } from 'react';
import { connect } from 'react-redux'
import { 
  Button,
  Table,
  Tr,
  Selector,
  FloatBox
} from '../../../common'

import { UTILS, DomManipulation } from '../../../../lib'

import * as jwt from 'jsonwebtoken'
import { getStripe } from '../../../../actions/stripe'
import * as cookies from 'js-cookie'
import TAFFY from '../taffy'


// Styles
import MuvorLogo from '../../../../../assets/images/MuvorLogo.png';

import styles from './TierMain.css'

class TierMain extends Component {
  constructor(props){
    super(props)
    this.state = {
      plan:[],
      stripe: []
    }
  }
  
  componentWillMount() {
    this.props.getStripe(stripe => this.setState({stripe: stripe}));
  }
  colorChange() {
    // var document = new DomManipulation("#plan")
    // document.onReady(() => {
      document.getElementById(() => {
        var r = Math.floor(Math.random() * 255);
        var g = Math.floor(Math.random() * 255);
        var b = Math.floor(Math.random() * 255);
        var color = "rgb("+r+","+g+","+b+")"
        (this).css("background-color", color)
      })
    // })
  }

  setBg = (plan) => {
    // const plan = document.getElementById("plan")
    const randomColor = Math.floor(Math.random()*16777215).toString(16);
    document.getElementById("planbg").style.backgroundColor = "#" + randomColor;
  }

  renderBody() {
    const { stripe, selectedPlan } = this.props
    let plans = Object.values(stripe)
    return (
      <div className={styles.plans}>
        {/* stripe test  */}
        {stripe.data && stripe.data.filter(p=>p.id!='bs-mo').map(this.renderPlans.bind(this))}
        {/* {plans.filter(p=>p.id!='bs-mo').map(this.renderPlans.bind(this))} */}
      </div>
    )
  }

  renderPlans(plans) {
    const handleClick = (e) => {
        e.preventDefault()
    } 

    const format = (plan) => {
      if(plan.product === "prod_H82ZDHYuMNGJ7S") {
        
      }
    }
    
    return (
      <div className={styles.plan} key={plans.id}>
      {/* TODO: id=`planbg{i}` onMouseOver={this.setBg} */}
        <button onClick={() => this.setState({ plan: plans })}>
          <p><b>{plans.nickname}</b></p>
          <p>{UTILS.formatUSD(plans.amount)}</p>
          <p>{plans.interval}</p>
        </button>
{
        // <img src={plan.img} />
}
        </div>
    )
  }

  render() {
    const plan = jwt.sign({plan: this.state.plan}, 'W3<34!!_M_^09_H4Q!7%!', { algorithm: 'HS512'});
    sessionStorage.setItem('plan', plan); 
    // let { selectedPlan } = this.props
    // selectedPlan = this.state.plan
      return (
        <div className={styles.container}>
          <FloatBox className={styles.floatbox}>
            <span className={styles.title}>
                <a href="/login"> 
                  <img src={MuvorLogo} width="150" height="25"/>
                </a>
            </span>
            <h2>Tier Selection</h2>
            {this.renderBody()}
            <div className={styles.space}></div>
            <p>Selected Plan:</p>

            <h4>{this.state.plan.nickname + '/' + this.state.plan.interval}</h4>

            {
            //   plans = plans.sort((a, b) => {
            //   /* Sort plans in ascending order of price (amount)
            //     * Ref: https://www.w3schools.com/js/js_array_sort.asp */
            //   return a.amount - b.amount;
            // }).map(plan => {
            //   /* Format plan price (amount) */
            //   let amount = UTILS.formatUSD(plan.amount)
            //   return {...plan, amount};
            // }).forEach(plan => {
            //   const filteredPlans = plans.filter(plan => {
            //     return plan.product === plan.id;
            //   });
            //   plan.plans = filteredPlans;
            //   return plans
            // })
            }
          <Button
            title="NEXT"
            link={'/login/register/pay'}
          />
          </FloatBox>
        </div>
      );
    }
  }
// }

const mapStateToProps = ({ stripe }) => {
  const {
    stripeDisplay,
    error,
    loading, 
  } = stripe

  return {
    stripe: stripeDisplay,
    error,
    loading, 
  }
}

const actions = {
  getStripe,
}

export default connect(mapStateToProps, actions)(TierMain);
