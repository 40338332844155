import { api } from '../config'

import * as cookies from 'js-cookie'

const company = JSON.parse(sessionStorage.getItem("company"))

export default {
  connector() {
    const me = company[1]
    const id = me.company
    const uid = me.id
    const url = `/${id}/company/${uid}`
    return api.get(url)
},

  post(data) {
    const url = '/company'
    return api.post(url, data)
  },

  getById(id) {
    const url = `/company/${id}`
    return api.get(url)
  },

  patchById(id, update) {
    const url = `/company/${id}`
    return api.patch(url, update)
  }
}
