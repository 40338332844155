import { projectsTypes } from '../actions/types'

const {
  CREATE_PROJECT,
  CREATE_PROJECT_FAILURE,
  CREATE_PROJECT_SUCCESS,
  SET_PROJECT,
  GET_PROJECT,
  GET_PROJECT_FAILURE,
  GET_PROJECT_SUCCESS,
  GET_PROJECTS,
  GET_PROJECTS_FAILURE,
  GET_PROJECTS_SUCCESS,
  PATCH_PROJECT,
  PATCH_PROJECT_FAILURE,
  PATCH_PROJECT_SUCCESS,
  DELETE_PROJECT,
  DELETE_PROJECT_FAILURE,
  DELETE_PROJECT_SUCCESS,
  CREATE_NOTE,
  CREATE_NOTE_FAILURE,
  CREATE_NOTE_SUCCESS,
  CLEAR_ERRORS,
  CLEAR_STATE,
} = projectsTypes

const INITIAL_STATE = {
  projects: [],
  projectsDisplay: [],
  error: '',
  loading: false,
}

export default (state = INITIAL_STATE, project) => {
  const {type, payload} = project
  switch (type) {
    case CREATE_PROJECT:
      return {
        ...state,
        loading: true,
      }
    case CREATE_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case CREATE_PROJECT_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      }
    case SET_PROJECT:
      return {
        ...state,
        project: payload,
      }
    case GET_PROJECT:
      return {
        ...state,
        loading: true,
      }
    case GET_PROJECT_SUCCESS:
      return {
        ...state,
        projects: payload,
        loading: false,
      }
    case GET_PROJECT_FAILURE:
      return {
        ...state,
        projects: null,
        error: payload,
        loading: false,
      }
    case GET_PROJECTS:
      return {
        ...state,
        loading: true,
      }
    case GET_PROJECTS_SUCCESS:
      return {
        ...state,
        projectsDisplay: payload,
        loading: false,
      }
    case GET_PROJECTS_FAILURE:
      return {
        ...state,
        projects: null,
        error: payload,
        loading: false,
      }
    case PATCH_PROJECT:
      return {
        ...state,
        loading: true,
      }
    case PATCH_PROJECT_SUCCESS:
      return {
        ...state,
        projects: payload,
        loading: false,
      }
    case PATCH_PROJECT_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      }
    case DELETE_PROJECT:
      return {
        ...state,
        loading: true,
      }
    case DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        products: payload,
        productsDisplay: payload,
        loading: false,
      }
    case DELETE_PROJECT_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      }
    case CREATE_NOTE:
      return {
        ...state,
        loading: true,
      }
    case CREATE_NOTE_FAILURE:
      return {
        ...state,
        projects: payload,
        loading: false,
      }
    case CREATE_NOTE_SUCCESS:
      return {
        ...state,
        error: payload,
        loading: false,
      }
    case CLEAR_STATE:
      return {
        ...INITIAL_STATE,
      }
    case CLEAR_ERRORS:
      return {
        ...state,
        error: '',
        loading: false,
      }
    default:
      return state
  }
}
