// Router
import { push } from 'react-router-redux'

// Types
import { mainTypes, supervisorsTypes } from './types'

// Services
import {
  visits,
  users,
  employees,
  assignments,
  uploads
} from '../services'
import * as cookies from 'js-cookie'

const {
  GET_ASSIGNMENTS,
  GET_ASSIGNMENTS_SUCCESS,
  GET_ASSIGNMENTS_FAILURE,
  CREATE_ASSIGNMENT,
  CREATE_ASSIGNMENT_SUCCESS,
  CREATE_ASSIGNMENT_FAILURE,
  DELETE_ASSIGNMENT,
  DELETE_ASSIGNMENT_FAILURE,
  DELETE_ASSIGNMENT_SUCCESS,
  GET_VISITS,
  GET_VISITS_SUCCESS,
  GET_VISITS_FAILURE,
  GET_SUPERVISORS,
  GET_SUPERVISORS_SUCCESS,
  GET_SUPERVISORS_FAILURE,
  FILTER_SUPERVISORS,
  GET_TEAM_MEMBERS,
  GET_TEAM_MEMBERS_SUCCESS,
  GET_TEAM_MEMBERS_FAILURE,
  FILTER_TEAM_MEMBERS,
  GET_USER,
  GET_EMPLOYEES,
  GET_EMPLOYEES_SUCCESS,
  GET_EMPLOYEES_FAILURE,
  FILTER_EMPLOYEES,
  GET_REPS,
  GET_REPS_SUCCESS,
  GET_REPS_FAILURE,
  CREATE_VISIT,
  CREATE_VISIT_SUCCESS,
  CREATE_VISIT_FAILURE,
  UPLOAD_FILE,
  UPLOAD_FILE_PROGRESS,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
  SET_PHOTOS,
  CLEAR_PHOTOS,
  CLEAR_STATE,
  CLEAR_ERRORS,
} = mainTypes

const {
  CREATE_NOTE_SUCCESS,
} = supervisorsTypes

const me = cookies.get('me') ? JSON.parse(cookies.get('me')) : {}
const { id, company } = me
const uid=id,cid=company;

export const getAssignments = () => dispatch => {
  dispatch({
    type: GET_ASSIGNMENTS,
  })
  assignments.getAll()
    .then(res => onGetAssignmentsSuccess({dispatch, res}))
    .catch(err => onGetAssignmentsFailure({dispatch, err}))
}

function onGetAssignmentsSuccess({dispatch, res}) {
  console.log('getAssignments success');
  dispatch({
    type: GET_ASSIGNMENTS_SUCCESS,
    payload: res,
  })
}

function onGetAssignmentsFailure({dispatch, err}) {
  console.log('getAssignments failure', err);
  dispatch({
    type: GET_ASSIGNMENTS_FAILURE,
    payload: err.message || 'Login Failed',
  })
}

export const createAssignment = data => (dispatch, getState) => {
  dispatch({
    type: CREATE_ASSIGNMENT,
  })
  assignments.post(data)
    .then(res => onCreateAssignmentSuccess({dispatch, getState, res}))
    .catch(err => onCreateAssignmentFailure({dispatch, err}))
}

function onCreateAssignmentSuccess({dispatch, getState, res}) {
  console.log('createAssignment success');
  // update agenda view
  const { assignments } = getState().main
  dispatch({
    type: CREATE_ASSIGNMENT_SUCCESS,
    payload: [res, ...assignments],
  })
  dispatch(push('/dashboard'))
}

//  update contact view
//   const { contact } = getState().contacts
//   if (contact && contact.id === assignment.contactId) {
//     contact.assignments = [ ...contact.assignments, assignment ]
//     dispatch({
//       type: CREATE_NOTE_SUCCESS,
//       payload: { ...contact },
//     })
//   }
// }

function onCreateAssignmentFailure({dispatch, err}) {
  console.log('createAssignment failure', err);
  dispatch({
    type: CREATE_ASSIGNMENT_FAILURE,
    payload: err.message || 'CreateAssignment Failed',
  })
}

export const deleteAssignmentById = id => (dispatch, getState) => {
  dispatch({
    type: DELETE_ASSIGNMENT,
  })
  assignments.deleteById(id)
    .then(res => onDeleteAssignmentSuccess({dispatch, getState, res}))
    .catch(err => onDeleteAssignmentFailure({dispatch, err}))
}

function onDeleteAssignmentSuccess({dispatch, getState, res}) {
  console.log('deleteAssignment success');

  dispatch({
    type: DELETE_ASSIGNMENT_SUCCESS,
    payload: [res, ...assignments],
  })
}

function onDeleteAssignmentFailure({dispatch, err}) {
  console.log('DeleteProduct failure', err)
  const payload = err.message || 'DeleteProduct Failed'
  window.alert(payload)
  dispatch({
    type: DELETE_ASSIGNMENT_FAILURE,
    payload,
  })
}

export const getVisits = () => dispatch => {
  dispatch({
    type: GET_VISITS,
  })
  visits.getAll()
    .then(res => onGetVisitsSuccess({dispatch, res}))
    .catch(err => onGetVisitsFailure({dispatch, err}))
}

function onGetVisitsSuccess({dispatch, res}) {
  console.log('getVisits success');
  dispatch({
    type: GET_VISITS_SUCCESS,
    payload: res.visits,
  })
}

function onGetVisitsFailure({dispatch, err}) {
  console.log('getVisits failure', err);
  dispatch({
    type: GET_VISITS_FAILURE,
    payload: err.message || 'Login Failed',
  })
}

// Supervisors
export const getSupervisors = () => dispatch => {
  dispatch({
    type: GET_SUPERVISORS,
  })
  users.getSupervisors()
    .then(res => onGetSupervisorsSuccess({dispatch, res}))
    .catch(err => onGetSupervisorsFailure({dispatch, err}))
}

function onGetSupervisorsSuccess({dispatch, res}) {
  console.log('getSupervisors success');
  dispatch({
    type: GET_SUPERVISORS_SUCCESS,
    payload: res.users,
  })
}

function onGetSupervisorsFailure({dispatch, err}) {
  console.log('getSupervisors failure', err);
  dispatch({
    type: GET_SUPERVISORS_FAILURE,
    payload: err.message || 'Get Supervisors Failed',
  })
}

export const filterSupervisors = (input, searchType = '') => (dispatch, getState) => {
  const search = input.toLowerCase().trim()
  const { supervisors } = getState().main
  const supervisorsDisplay = !search ? supervisors : supervisors.filter(supervisor => {
    const name = supervisor.nameDisplay.toLowerCase()
    return name.includes(search)
  })

  dispatch({
    type: FILTER_SUPERVISORS,
    payload: supervisorsDisplay,
  })
}

export const getTeamMembers = () => dispatch => {
  dispatch({
    type: GET_TEAM_MEMBERS,
  })
  users.getTeam()
    .then(res => onGetTeamMembersSuccess({dispatch, res}))
    .catch(err => onGetTeamMembersFailure({dispatch, err}))
}

function onGetTeamMembersSuccess({dispatch, res}) {
  console.log('getTeamMembers success');
  dispatch({
    type: GET_TEAM_MEMBERS_SUCCESS,
    payload: res.users,
  })
}

function onGetTeamMembersFailure({dispatch, err}) {
  console.log('getTeamMembers failure', err);
  dispatch({
    type: GET_TEAM_MEMBERS_FAILURE,
    payload: err.message || 'Get TeamMembers Failed',
  })
}

// export const filterEmployeesByName = input => (dispatch, getState) => {
//   const search = input.toLowerCase().trim()
//   const { employees } = getState().main
//   const employeesDisplay = !search ? employees : employees.filter(employee => {
//     const name = employee.name.toLowerCase()
//     return name.includes(search)
//   })

//   dispatch({
//     type: FILTER_EMPLOYEES,
//     payload: employeesDisplay,
//   })
// }

export const filterTeamMembers = (input, searchType = '') => (dispatch, getState) => {
  const search = input.toLowerCase().trim()
  const { teams } = getState().team
  console.log(teams)
  const adminsDisplay = !search ? teams : teams.filter(admin => {
    const name = admin.name.toLowerCase()
    // const email = admin.email.toLowerCase()

    const nameMatches = name.includes(search)
    // const emailMatches = email.includes(search)

    switch (searchType) {
      case 'name':
        return nameMatches
      // case 'email':
      //   return emailMatches
      default:
        return nameMatches //|| emailMatches
    }
  })

  dispatch({
    type: FILTER_TEAM_MEMBERS,
    payload: adminsDisplay,
  })
}

export const getEmployees = () => dispatch => {
  dispatch({
    type: GET_EMPLOYEES,
  })
  employees.getAll(cid,uid)
    .then(res => onGetEmployeesSuccess({dispatch, res}))
    .catch(err => onGetEmployeesFailure({dispatch, err}))
}

function onGetEmployeesSuccess({dispatch, res}) {
  console.log('getEmployees success');
  dispatch({
    type: GET_EMPLOYEES_SUCCESS,
    payload: res.data,
  })
}

function onGetEmployeesFailure({dispatch, err}) {
  console.log('getEmployees failure', err);
  const payload = err.message || 'Get Employees Failed'
  window.alert(payload)
  dispatch({
    type: GET_EMPLOYEES_FAILURE,
    payload,
  })
}

export const filterEmployeesByName = input => (dispatch, getState) => {
  const search = input.toLowerCase().trim()
  const { employees } = getState().main
  const employeesDisplay = !search ? employees : employees.filter(employee => {
    const name = employee.name.toLowerCase()
    return name.includes(search)
  })

  dispatch({
    type: FILTER_EMPLOYEES,
    payload: employeesDisplay,
  })
}

export const filterEmployeesByDob = dob => (dispatch, getState) => {
  const { employees } = getState().main
  const employeesDisplay = !dob ? employees : employees.filter(employee => {
    return employee.dob === dob
  })

  dispatch({
    type: FILTER_EMPLOYEES,
    payload: employeesDisplay,
  })
}

export const getReps = () => dispatch => {
  dispatch({
    type: GET_REPS,
  })
  users.getReps(cid,uid)
    .then(res => onGetRepsSuccess({dispatch, res}))
    .catch(err => onGetRepsFailure({dispatch, err}))
}

function onGetRepsSuccess({dispatch, res}) {
  console.log('getReps success');
  dispatch({
    type: GET_REPS_SUCCESS,
    payload: res.users,
  })
}

function onGetRepsFailure({dispatch, err}) {
  console.log('getReps failure', err);
  dispatch({
    type: GET_REPS_FAILURE,
    payload: err.message || 'Get Reps Failed',
  })
}

export const createVisit = data => (dispatch, getState) => {
  dispatch({
    type: CREATE_VISIT,
  })
  visits.post(data)
    .then(res => onCreateVisitSuccess({dispatch, getState, res}))
    .catch(err => onCreateVisitFailure({dispatch, err}))
}

function onCreateVisitSuccess({dispatch, getState, res}) {
  console.log('createVisit success');
  const { visit } = res

  // update agenda view
  const { visits } = getState().main
  dispatch({
    type: CREATE_VISIT_SUCCESS,
    payload: [ ...visits, visit ],
  })

  // update supervisor view
  const { supervisor } = getState().supervisors
  if (supervisor && supervisor.id === visit.supervisorId) {
    supervisor.visits = [ ...supervisor.visits, visit ]
    dispatch({
      type: CREATE_NOTE_SUCCESS,
      payload: { ...supervisor },
    })
  }
}

function onCreateVisitFailure({dispatch, err}) {
  console.log('createVisit failure', err);
  dispatch({
    type: CREATE_VISIT_FAILURE,
    payload: err.message || 'CreateVisit Failed',
  })
}

export const uploadPhoto = file => dispatch => {
  const url = window.URL.createObjectURL(file)
  console.log(url, '\n', file)
  dispatch({
    type: UPLOAD_FILE,
    payload: url,
  })

  // uploads.getSignedUrl({ file })
  //   .then(({ url, attachment }) => {
  //     const onProgress = e => dispatch(updateProgress(e))
  //     return uploads.uploadToS3({url, file, attachment, onProgress})
  //   })
  //   .then(res => onUploadFileSuccess({dispatch, res}))
  //   .catch(err => onUploadFileFailure({dispatch, err}))
}

const updateProgress = payload => {
  return {
    type: UPLOAD_FILE_PROGRESS,
    payload,
  }
}

function onUploadFileSuccess({dispatch, res}) {
  console.log('uploaded to s3', res)
  const payload = {
    ...res,
    name: 'Photo',
  }
  dispatch({
    type: UPLOAD_FILE_SUCCESS,
    payload,
  })
}

function onUploadFileFailure({dispatch, err}) {
  window.alert(err.message || 'Unknown error during file upload')
  dispatch({
    type: UPLOAD_FILE_FAILURE,
  })
}

export const removePhoto = photo => (dispatch, getState) => {
  const { photos } = getState().design
  const payload = photos.filter(el => el.id !== photo.id)
  dispatch({
    type: SET_PHOTOS,
    payload,
  })
}

export const clearPhotos = () => {
  return {
    type: CLEAR_PHOTOS,
  }
}

export const clearState = () => {
  return {
    type: CLEAR_STATE,
  }
}

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  }
}
