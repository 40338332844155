import React, { Component } from 'react';
import { connect } from 'react-redux'

import { hasAuthTokenAsync } from '../../../../lib'

import {
  Table,
  Header,
  ActionBox,
  Button,
  Selector,
  SearchBar,
} from '../../../common'

import {
  filterSupervisors,
} from '../../../../actions/main'

import {
  setSupervisor,
  getSupervisors
} from '../../../../actions/supervisors'

import styles from './SupervisorsView.css'

class SupervisorsView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filterValue: 0,
      searchType: '',
      search: '',
    }
  }

  componentDidMount() {
    hasAuthTokenAsync()
      .then(() => {
        this.props.getSupervisors()
      })
      .catch(console.log)
  }

  get options() {
    return [
      {
        value: '',
        display: 'Any',
      },
      {
        value: 'name',
        display: 'Name',
      },
      {
        value: 'email',
        display: 'Email',
      },
    ]
  }

  filterSupervisors(search) {
    const { searchType } = this.state

    this.setState({ search })
    this.props.filterSupervisors(search, searchType)
  }

  renderTableHead() {
    return (
      <thead>
        <tr>
          <th>
            Supervisor Name
          </th>
          <th>
            Specialty
          </th>
          <th>
            Group
          </th>
          <th>
            Rep
          </th>
          <th>
            City
          </th>
          <th/>
        </tr>
      </thead>
    )
  }

  renderTableBody() {
    const { supervisors } = this.props
    if (!Array.isArray(supervisors)) {
      return (
        <tbody>
          <tr>
            <td colSpan="9" className="empty">
              No Receipts Found
            </td>
          </tr>
        </tbody>
      )
    } else {
      return (
        <tbody>
          { supervisors.map(this.renderTableRow.bind(this)) }
        </tbody>
      )
    }
  }

  renderTableRow(supervisor) {
    const { rep, address } = this.props
    return (
      <tr key={supervisor.id}>
        <td className="bold">
          {supervisor.name}
        </td>
        <td>
          {supervisor.specialty || 'None'}
        </td>
        <td>
          {<Selector
            className={styles.selector}
            table
            options={supervisor.team.map(t => t) || 'None'}
          />}
        </td>
        <td>
          {supervisor.project || 'Unassigned'}
        </td>
        <td>
          {supervisor.address || 'None'}
        </td>
        <td className={styles.detailsCell}>
          <Button
            title="DETAILS"
            link={`/supervisors/${supervisor._id}`}
            onClick={() => this.props.setSupervisor(supervisor)}
          />
        </td>
      </tr>
    )
  }

  renderTable() {
    return (
      <Table className={styles.table}>
        {this.renderTableHead()}
        {this.renderTableBody()}
      </Table>
    )
  }

  render() {
    const {
      filterValue,
      searchType,
      search,
    } = this.state

    const {
      reps,
      supervisors
    } = this.props

    // const filterOptions = [
    //   {
    //     value: '',
    //     display: 'All',
    //   },
    //   ...reps.map(rep => ({
    //     value: rep.id,
    //     display: rep.nameDisplay,
    //   })),
    // ]

    const searchOptions = [
      {
        value: '',
        display: 'Any',
      },
      {
        value: 'name',
        display: 'Name',
      },
    ]

    return (
      <div className={styles.app}>
        <div className="body">
          <ActionBox>
            <div className="main">
              {/* TODO: Filter Select */}
              <Selector
                label="Filter"
                selected={filterValue}
                options={this.options}
                onSelect={filterValue => this.setState({ filterValue })}
              />
              <SearchBar
                options={searchOptions}
                selected={searchType}
                onSelect={searchType => this.setState({ searchType })}
                label="Search"
                placeholder="First or Last Name..."
                value={search}
                onChange={this.filterSupervisors.bind(this)}
              />
            </div>
            <div className="action">
              <Button
                link="/supervisors/add"
                icon="plus"
                title="ADD A NEW SUPERVISOR"
              />
            </div>
          </ActionBox>
          {this.renderTable()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ main, supervisors }) => {
  const {
    reps,
  } = main
  const {
    supervisorsDisplay,
    supervisor
  } = supervisors

  return {
    supervisors: supervisorsDisplay,
    supervisor,
    reps,
  }
}

const actions = {
  setSupervisor,
  getSupervisors,
  filterSupervisors,
}

export default connect(mapStateToProps, actions)(SupervisorsView);
