import React from 'react';
import {
  Route,
  Switch,
  Navigate
} from 'react-router-dom'

// Components
import CallModal from './CallModal/CallModal';
import CallWindow from './CallWindow/CallWindow';
import MainWindow from './MainWindow/MainWindow';
import VideoPage from './VideoPage/VideoPage';

const MessagerPageRouter = props => (
  <Switch>
    <Route
      path="/chats/video"
      component={VideoPage}
    />  
  </Switch>
)

export default MessagerPageRouter
