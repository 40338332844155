import React, { Component } from 'react';
import { connect } from 'react-redux'
import _ from 'lodash'
import validator from 'validator'

import {
  Selector,
  Button,
  Header,
  Body,
  Input,
  Form,
  FormModal,
  FileUpload,
  Icon,
} from '../../../../../../common'

// Actions
import { createOffering } from '../../../../../../../actions/community';

import styles from './OfferingsModal.css'
import { s3documents, s3photos } from '../../../../../../../lib/s3';

function generateAccessCode(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  // result = result.match(/.{1,5}/g).join('-')
  return result;
}

class OfferingsModal extends Component {
  constructor(props) {
    super(props);
    this.photos = []
    this.documents = []
    this.state = { 
      name: '',
      description: '',
      photos: [],
      docs: [],
      price: 0.00,
      payout: 0.00,
      expense: 0.00,
      metrics: []
     }
  }

  get inactive() {
    const rep = this.state.import > 0 ? this.state.import : 0
    return !rep 
  }

  get options() {
    return [
      {
        value: '',
        display: 'Select',
      },
      {
        value: 'contacts',
        display: 'Contacts',
      }
    ]
  }

  *docLinks(docs) {
    const file = new Object()
    Array.isArray(docs)
    && docs.forEach((d, j) => {
      window['doc'+j] = generateAccessCode(10)
      setInterval(
        s3documents(d, window['doc'+j])
        .then(doc => {
          if (j+1 < docs.length+1 || docs.length === 1) {
            this.documents.push(doc)
          }
          if (j+1 === docs.length) {
            return this.documents
          }
      }), 3000)
    }, this)
    yield this
  }

  *imgLinks(photos) {
    const img = new Object()
    Array.isArray(photos)
    && photos.forEach((p, i) => {
      window['photo'+i] = generateAccessCode(10)
      setInterval(
        s3photos(p, window['photo'+i])
        .then(pho => {
          if (i+1 < photos.length+1 || photos.length === 1) {
            this.photos.push(pho)
          }
          if (i+1 === photos.length) {
            return this.photos
          }
      }), 3000);
    }, this)
    yield this
  }

  async onSubmit(e) {
    e.preventDefault()
    const {
      type,
      docs,
      photos,
      name,
      description,
      price,
      expense
    } = this.state
    const {
      coin
    } = this.props
    const data = {
      coin: coin._id,
      name,
      description,
      price,
      payout: (price - expense)
    }
    if (photos.length > 0 && !docs.length > 0) {
      const imgArr = []
      photos.map((p, i) => {
        window['photo'+i] = generateAccessCode(10)
        setInterval(
          s3photos(p, window['photo'+i])
          .then(pho => {
            if (i+1 < photos.length+1 || photos.length === 1) {
              imgArr.push(pho)
            }
            if (i+1 === photos.length) {
              data.photos = imgArr
              this.props.createOffering(data)
            }
        }), 3000);
      })
    }
    if (docs.length > 0 && !photos.length > 0) {
      const docArr = []
      docs.map((d, i) => {
        window['doc'+i] = generateAccessCode(10)
        setInterval(
          s3documents(d, window['doc'+i])
          .then(doc => {
            if (i+1 < docs.length+1 || docs.length === 1) {
              docArr.push(doc)
            }
            if (i+1 === docs.length) {
              data.documents = docArr
              this.props.createOffering(data)
            }
        }), 3000);
      })
    }
    if (docs.length > 0 && photos.length > 0) {
      const timer = (docs.length + photos.length) * 2500
      const dl = this.docLinks(docs)
      const pl = this.imgLinks(photos)
      const {
        photos: phtos
      } = pl.next().value
      const {
        documents
      } = dl.next().value
      setTimeout(() => {
        if (Array.isArray(documents) && documents.length === docs.length 
        && Array.isArray(phtos) && phtos.length === photos.length) {
          data.documents = documents
          data.photos = phtos
          this.props.createOffering(data)
        }
      }, timer)
    }
    if (!photos.length > 0 && !docs.length > 0) {
      this.props.createOffering(data)
    }
  }
  
  renderPhotoItem(photo) {
    return (
      <div
        key={photo.name}
        className="photo"
      >
        <span className="photo">
          {photo.name}
        </span>
        <Icon
          small
          button
          cancel
          onClick={() => this.props.removePhoto(photo)}
        />
      </div>
    )
  }

  render() {
    const {
      content,
      onClickAway,
      loading,
      progress,
      onSubmit,
      type
    } = this.props

    const {
      name,
      description,
      photos,
      docs,
      price,
      payout,
      expense,
      metrics
    } = this.state

    return (
      <FormModal
        onClickAway={onClickAway}
        visible={!!content}
        className={styles.modal}
        onSubmit={this.onSubmit.bind(this)}
      >

        <label>
          Name:
        </label>
        <Input
          placeholder="Offering Name"
          value={name}
          onChange={name => this.setState({ name })}
        />

        <label>
          Description:
        </label>
        <Input
          placeholder="notes..."
          value={description}
          onChange={description => this.setState({ description })}
        />

        <br />  
        
        <label>
          Photos:
        </label>
        <div className={styles.photoList}>
        {
          photos.length ? (
          photos.map(this.renderPhotoItem.bind(this))
          ) : (
            <span>
              None
            </span>
            )
          }
        </div>
          <FileUpload
            id="add-photo"
            large
            confirm
            title="Select Photo"
            accept={'image/jpeg,image/png'}
            inactive={loading}
            onUpload={i => {var a=photos.slice();a.push(i);this.setState({photos:a})}}
            progress={progress}
          />

        <br />  

        <label>
          Documents:
        </label>
        <div className={styles.photoList}>
        {
          docs.length ? (
          docs.map(this.renderPhotoItem.bind(this))
          ) : (
            <span>
              None
            </span>
            )
          }
        </div>
        <FileUpload
          id="add-docs"
          large
          confirm
          title="Select Files"
          accept={'application/pdf,text/plain,text/csv,application/word,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/epub+zip,application/json,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheet.sheet'}
          inactive={loading}
          onUpload={i => {var a=docs.slice();a.push(i);this.setState({docs:a})}}
          progress={progress}
        />

        <br />  

        <label>
          Your Cost:
        </label>
        <Input
          type="number"
          value={expense}
          onChange={expense => this.setState({ expense })}
        />

        <label>
          Price:
        </label>
        <Input
          type="number"
          value={price}
          onChange={price => this.setState({ price })}
        />
        
        <br />  

        <div className="buttons">
          <Button
            large
            cancel
            type="button"
            title="Cancel"
            onClick={onClickAway}
          />
          <Button
            large
            type="submit"
            title="Create"
          />
        </div>
      </FormModal>
    )
  }
}
 
const mapStateToProps = ({ main }) => {
  const {
    loading,
    progress
  } = main

  return {
    loading,
    progress
  }
}

const actions = {
  createOffering
}

export default connect(mapStateToProps, actions)(OfferingsModal)
