import React from 'react';
import {
  Route,
  Switch,
  Redirect
} from 'react-router-dom'
import Home from './pages/Home';
import Pricing from './pages/Pricing/Pricing'
import CommunityPage from './pages/Community/CommunityPage'
import Career from './pages/Career/CareerPage'
import Job from './pages/Career/components/Job'

// Components

import NotFound from '../NotFound/NotFound'
import MemberView from './pages/Community/MemberView/MemberView';
import pdfReader from './components/pdfReader/pdfReader';
import HomeGPT from './pages/HomeGPT';
import ComingSoonPage from '../ComingSoon/ComingSoon';
// import Job from './pages/JobsBoard/Job';

const LandingPageRouter = props => (
  <Switch>
    <Route
      exact path="/home"
      component={Home}
    />
    
    {/* <Route
      exact path="/home"
      component={HomeGPT}
    /> */}
    
    <Route
      path="/home/pricing"
      component={Pricing}
    />

    <Route
      path="/home/community"
      component={CommunityPage}
    />
    
    <Route
      path="/home/whitepaper"
      component={pdfReader}
    />

    <Route
      exact
      path="/home/community/:id"
      component={MemberView}
    />

    <Route
      path="/home/careers/home"
      component={Career}
    />
    
    <Route
      path="/home/careers/:id"
      component={Job}
    />
    
    {/* <Route
      path="/home/job"
      component={Job}
    /> */}

    <Route
      component={NotFound}
    />
  </Switch>
)


export default LandingPageRouter
