import React from 'react';
import cn from 'classnames'

import {
  SummaryItem,
} from '../../../../../../common'

import styles from './DataRow.css'

const DataRow = ({ className, coin, view }) => {
  const {
    lastLogin,
    hours,
    bonus,
    totalBonus,
    revenue,
  } = coin || {}

  let weeklyHours, ytdBonus, tBonus, monthlyRevenue
  if (view === 'day') {
      weeklyHours=hours*1
      ytdBonus=bonus*1
      tBonus=totalBonus*1
      monthlyRevenue=revenue*1
  } else if (view === 'week') {
      weeklyHours=hours*7
      ytdBonus=bonus*7
      tBonus=totalBonus*7
      monthlyRevenue=revenue*7
  } else if (view === 'month') {
      weeklyHours=hours*30
      ytdBonus=bonus*30
      tBonus=totalBonus*30
      monthlyRevenue=revenue*30
  } else if (view === 'year') {
      weeklyHours=hours*365
      ytdBonus=bonus*365
      tBonus=totalBonus*365
      monthlyRevenue=revenue*365
  } else {
      weeklyHours=hours*1
      ytdBonus=bonus*1
      tBonus=totalBonus*1
      monthlyRevenue=revenue*1
  }


  const items = [
    {
      title: 'Bonus',
      content: tBonus || '...',
    },
    {
      title: 'Avg Monthly',
      content: monthlyRevenue || '...',
    },
    {
      title: 'Bonus Year-to-Date',
      content: ytdBonus || '...',
    },
    {
      title: 'Hours Online Weekly',
      content: weeklyHours || '...',
    },
    {
      title: 'Last Logged In',
      content: lastLogin || '...',
    },
  ]
  const classNames = cn('summary-row', styles.summary, className)

  return (
    <div className={classNames}>
      {items.map(({ title, content }) => (
        <SummaryItem
          key={title}
          title={title}
          content={content}
        />
      ))}
    </div>
  )
}

export default DataRow
