// TEAM FLOW
export const CREATE_DRAWER = 'create_drawer'
export const CREATE_DRAWER_SUCCESS = 'create_drawer_success'
export const CREATE_DRAWER_FAILURE = 'create_drawer_failure'

export const CREATE_TRANSACTION = 'create_transaction'
export const CREATE_TRANSACTION_SUCCESS = 'create_transaction_success'
export const CREATE_TRANSACTION_FAILURE = 'create_transaction_failure'

export const SET_DRAWER = 'set_drawer'

export const GET_DRAWER = 'get_drawer'
export const GET_DRAWER_SUCCESS = 'get_drawer_success'
export const GET_DRAWER_FAILURE = 'get_drawer_failure'

export const PATCH_DRAWER = 'patch_drawer'
export const PATCH_DRAWER_SUCCESS = 'patch_drawer_success'
export const PATCH_DRAWER_FAILURE = 'patch_drawer_failure'

export const CREATE_NOTE = 'create_drawer_note'
export const CREATE_NOTE_SUCCESS = 'create_drawer_note_success'
export const CREATE_NOTE_FAILURE = 'create_drawer_note_failure'

export const CHECKOUT = 'checkout'
export const CHECKOUT_SUCCESS = 'checkout_success'
export const CHECKOUT_FAILURE = 'checkout_failure'

export const CLEAR_ERRORS = 'drawer_clear_errors'
export const CLEAR_STATE = 'drawer_clear_state'
