import React, { Component } from 'react';
import { connect } from 'react-redux'
import * as cookies from 'js-cookie';
import SmoothScroll from 'smooth-scroll'
import { createChart } from 'lightweight-charts';

import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'dat.gui'

import Plot from 'react-plotly.js';

import socket from '../../Messager/MessagerPage/VideoPage/VideoPage/socket'
import Feature from '../components/Feature';
import SocialProof from '../components/SocialProof';

import Header from './Home/header'
import Features from './Home/features'
import About from './Home/about'
import Services from './Home/services'
import Testimonials from './Home/testimonials'
import Contact from './Home/contact'


import { mvrxPrice } from '../../../actions/sockets';
import { CookieBanner } from '../../../lib';
import jsonData from './Home/data/data.json'
import logo from '../assets/MuvorMain.png';
import styles from '../LandingPage.css'
// import dm from './dm.svg';
import Graph from './svg/DisplacementMap';


export const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
})

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: jsonData
        };
        this.me = sessionStorage.getItem('my-community-data') ? JSON.parse(sessionStorage.getItem('my-community-data')) : '';
    }

    componentDidMount() {
        // this.props.mvrxPrice()
    }


    render() {
        const {
            mvrx
        } = this.props
        const {
            data
        } = this.state
        // let lineSeries
        // const chartProps = {
        //     width: 500,
        //     height: 300,
        //     timeScale: {
        //         timeVisable: true,
        //         secondsVisable: false
        //     }
        // }
        // const chart = createChart(document.body, chartProps);
        // lineSeries = chart.addAreaSeries();
        // socket.on('mvrx', m => {
        //     return lineSeries.update(m)
        // })
        // console.log('mvrd', mvrx)
        // Texture Loader
        // const loader = new THREE.TextureLoader()
        // // const height = loader.load()
        // const texture = loader.load()
        // // const alpha = loader.load()
        // // Debug
        // const gui = new dat.GUI()
        // // Canvas
        // const canvas = document.querySelector('canvas.webgl')
        // // Scene
        // const scene = new THREE.Scene()
        // // Objects
        // const geometry = new THREE.PlaneGeometry(3, 3, 64, 64)
        // // Materials
        // const material = new THREE.MeshStandardMaterial({
        //     color: 'grey'
        // })
        // // material.color = new THREE.Color('red')
        // // material.map = texture
        // // Mesh
        // const plane = new THREE.Mesh(geometry,material)
        // scene.add(plane) 
        // plane.rotation.x = 181
        // gui.add(plane.rotation, 'x').min(0).max(600)
        // /**
        //  * Sizes
        //  */
        // const sizes = {
        //     width: window.innerWidth,
        //     height: window.innerHeight
        // }
        // window.addEventListener('resize', () => {
        //     // Update sizes
        //     sizes.width = window.innerWidth
        //     sizes.height = window.innerHeight

        //     // Update camera
        //     camera.aspect = sizes.width / sizes.height
        //     camera.updateProjectionMatrix()

        //     // Update renderer
        //     renderer.setSize(sizes.width, sizes.height)
        //     renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
        // })
        // // Lights
        // const pointLight = new THREE.PointLight(0xffffff, 1)
        // pointLight.position.x = 2
        // pointLight.position.y = 3
        // pointLight.position.z = 4
        // scene.add(pointLight)

        // gui.add(pointLight.position, 'x')
        // gui.add(pointLight.position, 'y')
        // gui.add(pointLight.position, 'z')

        // const col = {
        //     color: '#ff0066'
        // }
        // gui.add(col, 'color').onChange(() => {
        //     pointLight.color.set(col.color)
        // })

        // /**
        //  * Camera
        //  */
        // // Base camera
        // const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
        // camera.position.x = 0
        // camera.position.y = 0
        // camera.position.z = 3
        // scene.add(camera)

        // // Controls
        // const controls = new OrbitControls(camera, canvas)
        // controls.enableDamping = true

        // /**
        //  * Renderer
        //  */
        // const renderer = new THREE.WebGLRenderer({
        //     canvas: canvas
        // })
        // renderer.setSize(sizes.width, sizes.height)
        // renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

        // /**
        //  * Animate
        //  */

        // const clock = new THREE.Clock()

        // // callable
        // const tick = () =>
        // {

        //     // const elapsedTime = clock.getElapsedTime()

        //     // Update objects
        //     // sphere.rotation.y = .5 * elapsedTime

        //     // Update Orbital Controls
        //     controls.update()

        //     // Render
        //     renderer.render(scene, camera)

        //     // Call tick again on the next frame
        //     window.requestAnimationFrame(tick)
        // }
        // Generating random data..
        // console.log(mvrx)
        var a=[1,2,3], b=[2,4,6], c=[3,6,9];
        for(var j=50,i=0;i<50;i++,j--) {
            var a_ = i; 
            a.push(a_);
            
            var b_ = Math.random() * 50; 
            b.push(b_);
            
            var c_ = Math.random() * 50; 
            c.push(c_);
        //     // mvrx && mvrx.map(c_ => {
        //     //     c.push(c_);
        //     // })
        //     // var c = mvrx || [1]
        }
        // Plotting the mesh
        var mesh = [
            {
                opacity:0.8,
                color:'rgb(300,100,200)',
                type: 'surface',
                x: a,
                y: b,
                z: c,
            }
        ];

        // tick()
        return (
            <div className={styles.app}>
                    <Graph /> 
                <Header data={data.Header} />
                    {/* renderer */}
                    {/* <img src={dm} /> */}

                   {/* {<Plot data={mesh} layout={{width: 500, height: 500}}/>}
                    { <lineSeries /> } */}

                <Features data={data.Features} />
                <About data={data.About} />
                <Services data={data.Services} />
                {
                    // <Testimonials data={data.Testimonials} />
                }
                <Contact data={data.Contact} />

                <CookieBanner />
            </div>
        );
    }
}

const mapStateToProps = ({ sockets }) => {
    const {
        mvrx
    } = sockets
    return {
        mvrx
    }
}

const actions = {
    // mvrxPrice
}
  
export default connect(mapStateToProps, actions)(Home)