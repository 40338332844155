import React, { Component } from 'react';
import { connect } from 'react-redux'
import * as cookies from 'js-cookie'

import {
   lengthTypeOptions,
   genderTypeOptions,
   sizeTypeOptions, 
   productTypeOptions,
   s3
  } from '../../../../../lib'

import {
  Selector,
  Button,
  Header,
  Body,
  Input,
  Form,
  FileUpload,
  Icon,
  Span,
} from '../../../../common'

// Actions
import {
  clearPhotos,
  removePhoto,
  uploadPhoto,
  getEmployees,
  // getGender,
  // getLength,
  // getSize
} from '../../../../../actions/main'

import {
  createProject
} from '../../../../../actions/projects'
import {
  getProducts
} from '../../../../../actions/products'
import {
  getSupervisors
} from '../../../../../actions/supervisors'

import styles from './AddProject.css'

class AddProject extends Component {
  constructor(props) {
    super(props)
    this.state = {
      import: [],
      name: '',
      employes: [],
      product: [],
      supervisor: '',
      description: '',
      links: [''],
      notes: [''],
      photos: [],
      tasks: [''],
    }
  }

  componentDidMount() {
    this.props.clearPhotos()
    this.props.getSupervisors()
    this.props.getEmployees()
    this.props.getProducts()
  }

  componentWillUnmount() {
    this.props.clearPhotos()
  }

  onSubmit(e) {
    e.preventDefault()
    if (this.formIsInvalid) {
      return
    }
    const {
      name,
      employes,
      product,
      supervisor,
      description,
      photos,
      links,
      notes,
      tasks
    } = this.state
    const me = cookies.get('me') && JSON.parse(cookies.get('me')) ? JSON.parse(cookies.get('me')) : undefined 
    if (photos.length > 0) {
      s3.s3photos(photos[0], name)
      .then(l => {
        notes.push(l)
        const data = {
          company: me.company,
          name,
          employes,
          product,
          supervisor,
          description,
          links,
          notes,
          tasks
        }
        this.props.createProject(data)
      })
    } else {
      const data = {
        company: me.company,
          name,
          employes,
          product,
          supervisor,
          description,
          links,
          notes,
          tasks
      }
      this.props.createProject(data)
    }
  }

  get formIsInvalid() {
    const {
      name,
      employes,
      product,
      supervisor,
      description,
      links,
      notes,
      tasks
    } = this.state

    return !(
      name
      // &&length
      // && size
      // && color
      // && gender
      && employes
      // && note
    )
  }

  renderSelectByType(type) {
    const { employees, supervisors } = this.props
    const { employes } = this.state
    // let staticOption = content && content[type]

    let defaultOption
    let dataSource
    switch(type) {
      case 'supervisors' || 'Admin':
        defaultOption = 'Select Supervisor'
        dataSource = supervisors
        break
      case 'employes':
        // if (!this.props.isadmin) {
        //   staticOption = this.props.me
        // }
        defaultOption = 'Select Employee'
        dataSource = employees
        break
      default:
        // unknown type
        return null
    }
    const options = [
      {
        key: 0,
        value: '',
        display: defaultOption,
      },
      ...dataSource.map(data => ({
        key: data._id,
        value: data._id,
        display: data.name,
      })),
    ]

    return (
      <div>
        {this.state[type]}
        <Selector
          selected={dataSource}
          options={options}
          onSelect={e => this.state[type].push(e)}
        />
      </div>
    )
  }

  

  renderPhotoItem(photo) {
    return (
      <div
        key={photo.id}
        className="photo"
      >
        <span className="photo">
          {photo.name}
        </span>
        <Icon
          small
          button
          cancel
          onClick={() => this.props.removePhoto(photo)}
        />
      </div>
    )
  }

  renderSelect() {
    const {
      type
    } = this.state

    return (
      <Selector
        options={productTypeOptions}
        selected={type}
        onSelect={type => this.setState({ type })}
      />
    )
  }

  render() {
    const {
      name,
      employes,
      product,
      supervisor,
      description,
      photos,
      links,
      notes,
      tasks
    } = this.state

    const {
      employees,
      products,
      supervisors,
      progress,
      loading
    } = this.props

    const employOptions = [
      {
        key: '',
        value: '',
        display: 'Select Employees',
      },
      ...employees.map(e => ({
        key: e._id,
        value: JSON.stringify({id: e._id, name: e.name}),
        display: e.name,
      })),
    ]

    const superOptions = [
      {
        key: '',
        value: '',
        display: 'Select a Supervisor',
      },
      ...supervisors.map(s => ({
        key: s._id,
        value: JSON.stringify({id: s._id, name: s.name}),
        display: s.name,
      })),
    ]
    const prodOptions = [
      {
        key: '',
        value: '',
        display: 'Select Products',
      },
      ...products.map(p => ({
        key: p._id,
        value: JSON.stringify({id: p._id, name: p.products}),
        display: p.products,
      })),
    ]

    return (
      <div className={styles.body}>
        <Header>
          <h3>Add a New Project</h3>
        </Header>
        <Body className={styles.body}>
          <Form
            className="form"
            onSubmit={this.onSubmit.bind(this)}
          >

            <label>
              Name:
            </label>
            <Input
              value={name}
              onChange={name => this.setState({ name })}
            />

            <br />
              
            <label>
              Add Employees:
            </label>
            {employes.length > 0 && employes.map(e => <div key={e}>{JSON.parse(e).name}</div> )}
            <Selector
              wide
              selected={employes.name}
              options={employOptions}
              onSelect={i => {var a=employes.slice();a.push(i);this.setState({employes:a})}}
            />    
            
            <br />
              
            <label>
              Add Supervisors:
            </label>
            {supervisor && JSON.parse(supervisor).name}
            <Selector
              wide
              selected={supervisor.name}
              options={superOptions}
              onSelect={i => this.setState({supervisor:i})}
            />    
            
            <br />
              
            <label>
              Add Products:
            </label>
            {product.length > 0 && product.map(p => <div key={p}>{JSON.parse(p).name}</div> )}
            <Selector
              wide
              selected={product.name}
              options={prodOptions}
              onSelect={i => {var a=product.slice();a.push(i);this.setState({product:a})}}
            />    
            
            <br />

            <label>
              Description:
            </label>
            <Input
              value={description}
              onChange={description => this.setState({ description })}
            />

            <br />

            <label>
              Photos:
            </label>
              
              <div className={styles.photoList}>
                { photos.length ? (
                  photos.map(this.renderPhotoItem.bind(this))
                  ) : (
                    <span>
                      None
                    </span>
                  )
                }
              </div>
              <FileUpload
                id="add-photo"
                wide
                large
                confirm
                title="PHOTOS"
                accept={'image/jpeg,image/png'}
                inactive={loading}
                onUpload={photo => {var a=this.state.photo.slice();a.push(photo);this.setState({photo:a})}}
                progress={progress}
              />
            <br />

            <label>
              tasks:
            </label>
            {tasks.map(t => 
              <Input
                placeholder="Add tasks..."
                value={t}
                onChange={task => {var a=tasks.slice();a.push(task);this.setState({tasks:a})}}
              />
            )}

            
            <br />
            
            <label>
              notes:
            </label>
            {notes.map(n => 
              <Input
                placeholder="Add notes..."
                value={n}
                onChange={note => {var a=notes.slice();a.push(note);this.setState({notes:a})}}
              />
            )}

            <br />
            
            <label>
              links:
            </label>
            {links.map(l => 
              <Input
                placeholder="Add links..."
                value={l}
                onChange={link => {var a=links.slice();a.push(link);this.setState({links:a})}}
              />
            )}

            <br />

            <div className="buttons">
              <Button
                large
                cancel
                link="/dashboard"
                title="CANCEL"
              />
              <Button
                large
                type="submit"
                title="CREATE"
                inactive={this.formIsInvalid}
                onClick={this.onSubmit.bind(this)}
              />
            </div>
          </Form>
        </Body>
      </div>
    );
  }
}

const mapStateToProps = ({ projects, products, supervisors, main }) => {
  const {
    // gendersDisplay,
    // sizesDisplay,
    // lengthsDisplay,
    photos,
    progress,
    loading,
  } = projects
  const {
    employees
  } = main
  const {
    productsDisplay
  } = products
  const {
    supervisorsDisplay
  } = supervisors

  return {
    supervisors: supervisorsDisplay,
    products: productsDisplay,
    employees,
    photos,
    progress,
    loading,
  }
}

const actions = {
  clearPhotos,
  removePhoto,
  createProject,
  uploadPhoto,
  getSupervisors,
  getEmployees,
  getProducts
}

export default connect(mapStateToProps, actions)(AddProject)
