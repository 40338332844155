import { push } from 'react-router-redux'

import * as cookies from 'js-cookie'

import socket, { companys, mvrx } from '../services'

import socketClient from '../components/shared/socketclient';

// Constants
import { constants } from '../lib';

// Types
import { socketsTypes } from './types'

// Config
import { uri } from '../config'

// io functions
// import * as utils from '../components/shared/socketclient/utils';

const {
  CONNECTION,
  CONNECTION_SUCCESS,
  CONNECTION_FAILURE,
  SOCKET_STATUS,
  SOCKET_SEND,
  SOCKET_RECEIVE,
  SOCKET_ADD,
  SOCKETS_REMOVE,
  SOCKETS_AWAY,
  SOCKET_SET,
  SOCKET_CLEAR,
  SOCKET_ROOM_ADD,
  SOCKET_SET_VIDEO,
  SOCKET_SET_AUDIO,
  MVRX,
  MVRX_SUCCESS,
  MVRX_FAILURE
} = socketsTypes

export const mvrxPrice = () => (dispatch) => {
  dispatch({
    type: MVRX,
  })
  mvrx.getAll()
    .then(res => onGetPriceSuccess({dispatch, res}))
    .catch(err => onGetPriceFailure({dispatch, err}))
}

// function onGetPriceSuccess({dispatch, res}) {
//   dispatch({
//     type: MVRX_SUCCESS,
//     payload: res.data
//   })
// }

function onGetPriceSuccess({dispatch, res}) {
  const plot = []
  const {
    time,
    value
  } = res
  plot.push({time,value})
  dispatch({
    type: MVRX_SUCCESS,
    payload: plot
  })
  // localStorage.setItem('plot', JSON.stringify(plot))
}

function onGetPriceFailure({dispatch, err}) {
  const payload = err.message || 'GetPrice Failed'
  dispatch({
    type: MVRX_FAILURE,
    payload,
  })
  dispatch(push('/login'))
}

export const status = ( status, isConnected ) => ({
  type: SOCKET_STATUS,
  payload: { status, isConnected }
});

export const receive = message => ({
  type: SOCKET_RECEIVE,
  payload: message
});


export const add = message => ({
  type: SOCKET_ADD,
  payload: message
});

export const remove = () => ({
  type: SOCKETS_REMOVE
});

export const setAway = flag => ({
  type: SOCKETS_AWAY,
  payload: flag
});

export const addRoom = () => dispatch => {
  dispatch({
    type: SOCKET_ROOM_ADD,
  })
}

export const setAudio = audio => dispatch => { 
  dispatch({
    type: SOCKET_SET_AUDIO,
    audio
  })
}

export const setVideo = video => dispatch => { 
  dispatch({
    type: SOCKET_SET_VIDEO,
    video
  })
}

export const sendMessage = (message, user) => (dispatch, getState, api) => {
  message = {user, message};
  dispatch({
    type: SOCKET_SEND,
  });
  const socket = socketClient.getInstance()
  // socket.emit('message', message)
}

// export const receiveMessage = (message) => (dispatch, getState, api) => {

//   message = utils.processMessage (message);

//   dispatch (add (message));
// }

export const connectSocket = () => (dispatch, getState) => {
  const { socket } = getState().sockets
  if (socket != null) {
    return
  } else {
    const socket = socketClient.getInstance()

    dispatch({
      type: SOCKET_SET,
      payload: socket,
    })
  }
}

// Connect
export const connector = () => dispatch => {
  dispatch({
    type: CONNECTION,
  })
  companys.connector()
    .then(res => onConnectSuccess({dispatch, res}))
    .catch(err => onConnectFailure({dispatch, err}))
}

function onConnectSuccess({dispatch, res}) {
  console.log('success');
  sessionStorage.setItem("company", res)
  cookies.set("company", res)
  dispatch({
    type: CONNECTION_SUCCESS,
    payload: res,
  })
}

function onConnectFailure({dispatch, err}) {
  console.log('failure', err);
  dispatch({
    type: CONNECTION_FAILURE,
    payload: err.message || 'Get Contacts Failed',
  })
}

export const clearSocket = () => {
  return {
    type: SOCKET_CLEAR,
  }
}
