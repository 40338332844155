import React, { Component } from 'react';
import Map from './Map';

class Page extends Component {

	render() {
		return(
			<div style={{ margin: '100px' }}>
				<Map
					google={this.props.google}
					center={{lat: 34.0690, lng: 117.9751}}
					height='300px'
					zoom={15}
				/>
			</div>
		);
	}
}

export default Page;
