// React
import React, { Component } from 'react';

// Redux
import { connect } from 'react-redux'

// Components
import {
  Form,
  Input,
  ErrorMessage,
  FloatBox,
  Button,
}  from '../../common'

// Actions
import {
  logout,
  claimAccount,
  passwordChanged,
  passwordConfirmChanged,
  emailChanged,
} from '../../../actions/auth'

// Styles
import styles from './ClaimAccount.css'
import { api } from '../../../config';
import { init } from '../../../actions/community';

class ClaimAccount extends Component {
  componentDidMount() {

  }

  handleSubmit(e) {
    e.preventDefault()
    const { password } = this.props
    this.props.claimAccount({ password })
    // this.props.init()
    // const cliLat = localStorage.getItem('cli_lat')
    // const cliLong = localStorage.getItem('cli_long')
    // // const cliCoords = localStorage.getItem('cli_coords')
    // const cliGPU = localStorage.getItem('cli_gpu')
    // const cliRAM = localStorage.getItem('cli_ram')

    // // const games = { lat: cliLat, lon: cliLong, gpu: cliGPU, ram: cliRAM/**, cliCoords */ }
    // const games = JSON.stringify({ cliLat, cliLong, cliGPU, cliRAM })
    // api.post('/xchange_genesis', games)
    // .then(tof => tof === true && window.alert(tof))
    // .catch(err => console.error(err));
  }

  containsAt() {
    const find = emailChanged.toString().split("")
    const findAt = find.includes("@")
    const findSpace = find.includes(" ")
    if( findAt === true && findSpace === false) {
      return true
    }
  }

  render() {
    const {
      error,
      emailChanged,
      loading,
    } = this.props

    const inactive = (
      !emailChanged
      || this.containsAt() === false
      || loading
    )

    return (
      <div className={styles.container}>
        <FloatBox>
          <h2>Claim Account</h2>
          <p>Welcome! Enter your Email Address to claim your account.</p>
          <Form className="body" onSubmit={this.handleSubmit.bind(this)}>
            <Input
              wide
              placeholder="Email Address"
              value={emailChanged}
              type="password"
              onChange={this.props.emailChanged.bind(this)}
            />
            <div className="buttons">
    {
              // <Button
              //   type="button"
              //   cancel
              //   title="Log Out"
              //   onClick={() => this.props.logout()}
              // />
    }
              <Button
                type="submit"
                className="button"
                title="Verify"
                inactive={inactive}
              />
            </div>
            <ErrorMessage error={error}/>
          </Form>
        </FloatBox>
      </div>
    );
  }
}

const mapStateToProps = ({auth}) => {
  const {
    emailChanged,
    error,
    loading,
  } = auth

  return {
    emailChanged,
    error,
    loading,
  }
}

const actions = {
  emailChanged,
  claimAccount,
  init,
  logout,
}

export default connect(mapStateToProps, actions)(ClaimAccount)
