import React from 'react';
import {
  Route,
  Switch,
  Redirect
} from 'react-router-dom'

import RegisteryMain from './RegisteryMain/RegisteryMain'
import StripeMain from './StripeMain/StripeMain'
import TierMain from './TierMain/TierMain'
import CheckoutMain from './CheckoutMain/CheckoutMain'

// Components

import NotFound from '../../NotFound/NotFound'

const RegisterMainRouter = props => (
  <Switch>
    <Route
      exact path="/login/register"
      component={TierMain}
    />
    
    <Route
      path="/login/register/pay"
      component={StripeMain}
    />
    
    <Route
      path="/login/register/registery"
      component={RegisteryMain}
    />
    
    <Route
      path="/login/ty"
      component={CheckoutMain}
    />

    <Route
      component={NotFound}
    />
  </Switch>
)


export default RegisterMainRouter
