import { api } from '../config'

export default {
  login(data) {
    const url = '/auth/login'
    return api.post(url, data)
  },

  resetPassword(data) {
    const url = '/auth/reset-password'
    return api.post(url, data)
  },

  createPassword(data) {
    const url = `/auth/create-password`
    return api.post(url, data)
  },

  claimAccount(data) {
    const url = '/auth/claim-account'
    return api.post(url, data)
  },
  verifyEmail(data) {
    const url = '/communitys/verify'
    return api.post(url, data)
  },
  communitySignup(data) {
    const url = '/auth/community'
    return api.post(url, data)
  },
  init () {
    const url = '/communitys/init'
    return api.get(url)
  },
  register(data) {
    const url = '/auth/signup'
    return api.post(url, data)
  },
  wallet() {
    return api.get('http://localhost:9111/get_mvr_wallet')
  }
}
