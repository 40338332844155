import React, { Component } from 'react';
import { connect } from 'react-redux'

import {
   lengthTypeOptions,
   genderTypeOptions,
   sizeTypeOptions, 
   productTypeOptions
  } from '../../../../lib'

import {
  Selector,
  Button,
  Header,
  Body,
  Input,
  Form,
  FileUpload,
  Icon,
} from '../../../common'

// Actions
import {
  clearPhotos,
  removePhoto,
  uploadPhoto,
  // getGender,
  // getLength,
  // getSize
} from '../../../../actions/main'

import * as cookies from 'js-cookie'

import {
  createProduct
} from '../../../../actions/products'

import styles from './AddProduct.css'
import { s3photos } from '../../../../lib/s3';

class AddMember extends Component {
  constructor(props) {
    super(props)
    this.state = {
      import: [],
      product: '',
      cost: 0.00,
      length: '',
      size: '',
      color: '',
      gender: '',
      type: 'Wholesale',
      photo: [],
      barcode: '',
      quantity: 0,
      note: '',
      link: ''
    }
  }

  componentDidMount() {
    this.props.clearPhotos()
    // this.props.getGender()
    // this.props.getLength()
    // this.props.getSize()
  }

  componentWillUnmount() {
    this.props.clearPhotos()
  }

  onSubmit(e) {
    e.preventDefault()
    if (this.formIsInvalid) {
      return
    }
    const {
      product,
      cost,
      length,
      size,
      color,
      gender,
      type,
      photo,
      barcode,
      quantity,
      note
    } = this.state
    const me = cookies.get('me') && JSON.parse(cookies.get('me')) ? JSON.parse(cookies.get('me')) : undefined 
    if (photo.length > 0) {
      s3photos(photo[0], product)
      .then(l => {
        const data = {
          company: me.company,
          product,
          cost: Number(cost),
          // length,
          // size,
          // color,
          // gender,
          type,
          barcode,
          quantity: Number(quantity),
          note,
          photo: l
        }
        this.props.createProduct(data)
      })
    } else {
      const data = {
        company: me.company,
        product,
        cost: Number(cost),
        // length,
        // size,
        // color,
        // gender,
        type,
        barcode,
        quantity: Number(quantity),
        note,
        photo
      }
      this.props.createProduct(data)
    }
  }

  get formIsInvalid() {
    const {
      product,
      // length,
      // size,
      // color,
      // gender,
      barcode,
      quantity
      // note
    } = this.state

    return !(
      product
      // &&length
      // && size
      // && color
      // && gender
      && barcode
      && quantity
      || this.state.import.length > 0
      // && note
    )
  }

  renderPhotoItem(photo) {
    return (
      <div
        key={photo.id}
        className="photo"
      >
        <span className="photo">
          {photo.name}
        </span>
        <Icon
          small
          button
          cancel
          onClick={() => this.props.removePhoto(photo)}
        />
      </div>
    )
  }

  renderSelect() {
    const {
      type
    } = this.state

    return (
      <Selector
        options={productTypeOptions}
        selected={type}
        onSelect={type => this.setState({ type })}
      />
    )
  }

  render() {
    const {
      product,
      cost,
      length,
      size,
      color,
      gender,
      photo,
      barcode,
      quantity,
      note
    } = this.state

    const {
      genders,
      lengths,
      sizes,
      photos,
      loading,
      progress,
    } = this.props

    // const sizeOptions = [
    //   {
    //     value: '',
    //     display: 'Select Shirt Size...'
    //   },
    //   ...sizeTypeOptions,
    // ]

    // const lengthOptions = [
    //   {
    //     value: '',
    //     display: 'Select Length...'
    //   },
    //   ...lengthTypeOptions,
    // ]

    // const genderOptions = [
    //   {
    //     value: '',
    //     display: 'Select Type...'
    //   },
    //   ...genderTypeOptions,
    // ]

    return (
      <div className={styles.body}>
        <Header>
          <h3>Add a New Product</h3>
        </Header>
        <Body className={styles.body}>
          <Form
            className="form"
            onSubmit={this.onSubmit.bind(this)}
          >
          {
            // <label>
            //   Select Length:
            // </label>
            // <Selector
            //   wide
            //   selected={length}
            //   // options={lengthOptions}
            //   onSelect={length => this.setState({ length })}
            // />

            // <br />

            // <label>
            //   Shirt Size:
            // </label>
            // <Selector
            //   wide
            //   selected={size}
            //   // options={sizeOptions}
            //   onSelect={size => this.setState({ size })}
            // />
    }
            <br />

            <label>
              Product:
            </label>
            <Input
              value={product}
              onChange={product => this.setState({ product })}
            />

            <br />
            
          <label>
            Cost:
          </label>
          <Input
            type="number"
            value={cost}
            onChange={cost => this.setState({ cost })}
          />
          
          <br />
            
          <label>
            Type:
          </label>
          {this.renderSelect()}
          <br />

          <label>
              Photo:
            </label>
            
            <div className={styles.photoList}>
            {
              this.state.photo.length ? (
              this.state.photo.map(this.renderPhotoItem.bind(this))
              ) : (
                <span>
                  None
                </span>
                )
              }
              </div>
              <FileUpload
                id="add-photo"
                wide
                large
                confirm
                title="PHOTOS"
                accept={'image/jpeg,image/png'}
                inactive={loading}
                onUpload={photo => {var a=this.state.photo.slice();a.push(photo);this.setState({photo:a})}}
                progress={progress}
              />
                <br />
  
                <label>
                Barcode:
                </label>
                <Input
                  placeholder="..."
                  value={barcode}
                  onChange={barcode => this.setState({ barcode })}
                />
                
                <br />
                
                <label>
                Quantity:
                </label>
                <Input
                  type="number"
                  value={quantity}
                  onChange={quantity => this.setState({ quantity })}
                />
                
                <br />
                
                <label>
                  Notes:
                </label>
                <Input
                  placeholder="Add a note..."
                  value={note}
                  onChange={note => this.setState({ note })}
                />

                <br />

                <div className="buttons">
              <Button
                large
                cancel
                link="/dashboard"
                title="CANCEL"
              />
              <Button
                large
                type="submit"
                title="CREATE"
                inactive={this.formIsInvalid}
                onClick={this.onSubmit.bind(this)}
              />
            </div>
          </Form>
        </Body>
      </div>
    );
  }
}

const mapStateToProps = ({ products, auth }) => {
  const {
    // gendersDisplay,
    // sizesDisplay,
    // lengthsDisplay,
    photos,
    progress,
    loading,
  } = products

  return {
    // genders: gendersDisplay,
    // sizes: sizesDisplay,
    // lengths: lengthsDisplay,
    photos,
    progress,
    loading,
  }
}

const actions = {
  clearPhotos,
  removePhoto,
  createProduct,
  uploadPhoto,
  // getGender,
  // getSize,
  // getLength
}

export default connect(mapStateToProps, actions)(AddMember)
