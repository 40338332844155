import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";
// import setAuthToken from "./utils/setAuthToken";

// import { setCurrentUser, logoutUser } from "../actions/authActions";
import AddAccount from "./AddAccount/AddAccount";
import AccountsView from "./AccountsView/AccountsView";
import AccountView from "./AccountView/MemberView";

import NotFound from '../../NotFound/NotFound'

import "./BankPage.css";

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  // setAuthToken(token);
  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  // Set user and isAuthenticated
  // store.dispatch(setCurrentUser(decoded));
  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    // store.dispatch(logoutUser());

    // Redirect to login
    window.location.href = "./login";
  }
}

const BankPageRouter = ({ props, onViewModal, payment }) => (
  <Switch>
    <Route
      exact
      path="/bank"
      component={AccountsView}
    />

    <Route
      exact
      path="/bank/add"
      render={() => <AddAccount
        props={props}
        onViewModal={onViewModal}
        payment={payment}
      />
      }
    />

    <Route
      exact
      path="/bank/:id"
      render={() => <AccountView
        props={props}
      />
      }
    />

    <Route
      component={NotFound}
    />
  </Switch>
)
export default BankPageRouter;
