// React
import React, { Component } from 'react';

// Redux
import { connect } from 'react-redux'

// jwt
import * as jwt from 'jsonwebtoken'

import {
  login,
  emailChanged,
  passwordChanged,
} from '../../../actions/auth'

// Components
import {
  ErrorMessage,
  FloatBox,
  Link,
  Links,
  Button,
  Input,
  Form,
  Span
} from '../../common'

// Styles
import styles from './LoginMain.css'


import MuvorLogo from '../../../../assets/images/MuvorLogo.png';
import style from 'react-awesome-modal/lib/style';

class LoginMain extends Component {
  handleSubmit(e) {
    e.preventDefault()
    const { email, password } = this.props
    const data = {
      email,
      password: jwt.sign(password, 'M_^09XW4kUU!}}+', { algorithm: 'HS512' })
    }
    this.props.login(data)
  }

  // goHome(e) {
  //   preventDefault(e);
    
  // }

  render() {
    
    const {
      error,
      email,
      password,
      emailChanged,
      passwordChanged,
      loading,
    } = this.props

    return (
      <div className={styles.container}>
        <FloatBox className={styles.box}>
            <span className={styles.title}>
                <a href="/login"> 
                  <img src={MuvorLogo}></img>
                </a>
            </span>
          <Form
            className={styles.body}
            onSubmit={this.handleSubmit.bind(this)}>
            <Input
              wide
              id="email"
              label="Email"
              name="email"
              placeholder=""
              value={email}
              onChange={emailChanged}
            />
            <Input
              wide
              id="password"
              label="Password"
              name="password"
              placeholder=""
              value={password}
              type="password"
              onChange={passwordChanged}
            />
            <div className={styles.separate}></div>
            <Button
              className="button"
              type="submit"
              title="Login"
              inactive={loading}
            />
            <Button
              confirm
              className="button"
              link="/login/register"
              title="Register"
              inactive={loading}
            />
            <Button 
              danger
              className="button"
              link="login/claim-account"
              title="Claim Account"
            />
            <Button 
              cancel
              className="button" 
              link="login/reset-password"
              title="Recover Password"
            />
            <Button 
              verify
              className="button" 
              link="community"
              title="Muvor Community"
            />
            <ErrorMessage error={error}/>
          </Form>
        </FloatBox>
      </div>
    );
  }
}

const mapStateToProps = ({auth}) => {
  const {
    email,
    password,
    error,
    loading,
  } = auth
  return {
    email,
    password,
    error,
    loading,
  }
}

const actions = {
  login,
  emailChanged,
  passwordChanged,
}

export default connect(mapStateToProps, actions)(LoginMain)
