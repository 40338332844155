import { cashierTypes } from '../actions/types'

const {
  CREATE_DRAWER,
  CREATE_DRAWER_FAILURE,
  CREATE_DRAWER_SUCCESS,
  CREATE_TRANSACTION,
  CREATE_TRANSACTION_SUCCESS,
  CREATE_TRANSACTION_FAILURE,
  SET_DRAWER,
  GET_DRAWER,
  GET_DRAWER_FAILURE,
  GET_DRAWER_SUCCESS,
  PATCH_DRAWER,
  PATCH_DRAWER_FAILURE,
  PATCH_DRAWER_SUCCESS,
  CREATE_NOTE,
  CREATE_NOTE_FAILURE,
  CREATE_NOTE_SUCCESS,
  CHECKOUT,
  CHECKOUT_SUCCESS,
  CHECKOUT_FAILURE,
  CLEAR_ERRORS,
  CLEAR_STATE,
} = cashierTypes

const INITIAL_STATE = {
  drawer: [],
  receipt: null,
  change: null,
  error: '',
  loading: false,
}

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action
  switch (type) {
    case CREATE_DRAWER:
      return {
        ...state,
        loading: true,
      }
    case CREATE_DRAWER_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case CREATE_DRAWER_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      }
    case CREATE_TRANSACTION:
      return {
        ...state,
        loading: true,
      }
    case CREATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        change: payload,
        loading: false,
      }
    case CREATE_TRANSACTION_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      }
    case SET_DRAWER:
      return {
        ...state,
        drawer: payload,
      }
    case GET_DRAWER:
      return {
        ...state,
        loading: true,
      }
    case GET_DRAWER_SUCCESS:
      return {
        ...state,
        drawer: payload,
        loading: false,
      }
    case GET_DRAWER_FAILURE:
      return {
        ...state,
        drawer: null,
        error: payload,
        loading: false,
      }
    case PATCH_DRAWER:
      return {
        ...state,
        loading: true,
      }
    case PATCH_DRAWER_SUCCESS:
      return {
        ...state,
        drawer: payload,
        loading: false,
      }
    case PATCH_DRAWER_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      }
    case CHECKOUT:
      return {
        ...state,
        loading: true,
      }
    case CHECKOUT_SUCCESS:
      return {
        ...state,
        receipt: payload,
        loading: false,
      }
    case CHECKOUT_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      }
    case CREATE_NOTE:
      return {
        ...state,
        loading: true,
      }
    case CREATE_NOTE_FAILURE:
      return {
        ...state,
        drawer: payload,
        loading: false,
      }
    case CREATE_NOTE_SUCCESS:
      return {
        ...state,
        error: payload,
        loading: false,
      }
    case CLEAR_STATE:
      return {
        ...INITIAL_STATE,
      }
    case CLEAR_ERRORS:
      return {
        ...state,
        error: '',
        loading: false,
      }
    default:
      return state
  }
}
