export const GET_PRODUCTS = 'product_get_products'
export const GET_PRODUCTS_SUCCESS = 'product_get_products_success'
export const GET_PRODUCTS_FAILURE = 'product_get_products_failure'

export const GET_GENDER = 'product_get_gender'
export const GET_GENDER_SUCCESS = 'product_get_gender_success'
export const GET_GENDER_FAILURE = 'product_get_gender_failure'

export const GET_LENGTH = 'product_get_length'
export const GET_LENGTH_SUCCESS = 'product_get_length_success'
export const GET_LENGTH_FAILURE = 'product_get_length_failure'

export const GET_SIZE = 'product_get_size'
export const GET_SIZE_SUCCESS = 'product_get_size_success'
export const GET_SIZE_FAILURE = 'product_get_size_failure'

export const CREATE_PRODUCT = 'product_create_product'
export const CREATE_PRODUCT_SUCCESS = 'product_create_product_success'
export const CREATE_PRODUCT_FAILURE = 'product_create_product_failure'

export const ADD_QUANTITIES = 'add_quantities_product'
export const ADD_QUANTITIES_SUCCESS = 'add_quantities_product_success'
export const ADD_QUANTITIES_FAILURE = 'add_quantities_product_failure'

export const SUB_QUANTITIES = 'sub_quantities_product'
export const SUB_QUANTITIES_SUCCESS = 'sub_quantities_product_success'
export const SUB_QUANTITIES_FAILURE = 'sub_quantities_product_failure'

export const PATCH_PRODUCT = 'patch_product'
export const PATCH_PRODUCT_SUCCESS = 'patch_product_success'
export const PATCH_PRODUCT_FAILURE = 'patch_product_failure'

export const DELETE_PRODUCT = 'delete_product'
export const DELETE_PRODUCT_SUCCESS = 'delete_product_success'
export const DELETE_PRODUCT_FAILURE = 'delete_product_failure'

export const SET_PRODUCTS_EXPENSES = 'set_products_expenses'
export const FILTER_PRODUCTS = 'filter_products'
export const FILTER_SUCCESS = 'filter_success'
export const FILTER_FAILURE = 'filter_failure'
