import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import FontAwesome from 'react-fontawesome'
import * as cookies from 'js-cookie'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import cn from 'classnames'

import { Icon, Button, Button2, Overlay } from '../../common'

import { logout, getSelf } from '../../../actions/auth'
import { hasAuthTokenAsync } from '../../../lib';

import React, { Component } from 'react';

import Muvor from '../../../../assets/images/MuvorWithout.png';
import './SideNav.css'
import '@trendmicro/react-sidenav/dist/react-sidenav.css';



class SideNavi extends Component {
    constructor(props, ...rest) {
        super(props, ...rest);
        this.state = {
            dropdown: false,
        };
    }

    componentDidMount() {
      hasAuthTokenAsync()
        .then(() => {
          this.props.getSelf()
        })
        .catch(console.log)
    }

    handleClick = () => {
        this.setState(state => {
          return {
            dropdown: !state.dropdown,
          };
        });
      };

    renderDropdown() {
      const { dropdown } = this.state
      const { isAdmin, me } = this.props
      const { name, role } = me
      const string = <span style={{ color: "#cd7f32" }}>Bronze</span>
      return (
        <div>
        {isAdmin && (
        <div
          className={cn("containter", {show: dropdown})}
          onClick={() => this.setState({dropdown: !dropdown})}
        ></div>)}
        Welcome to
        {/* Dropdown */}
        <div className="options"
          onClick={() => this.props.website()}>
          <span className="brand">
            <h3>
            Muvor
            </h3>
            <img style={{marginTop: 5, marginLeft: 5, marginRight: 5, width:25, height:12.5}} src={Muvor}/>
            <h3>
            {string}
            </h3>
          </span>
        </div> 
        <span className="name">{name} - {role}</span>
          <div className="option">
            <NavLink to="/bank">
              Bank
            </NavLink>
          </div>          
          <div className="option">
            <NavLink to="/import">
              Import
            </NavLink>
          </div>
          <div className="option">
            <NavLink to="/calculator/simple">
              Calculator
            </NavLink>
          </div>
          <div className="option">
            <NavLink to="/chats/home">
              Messager
            </NavLink>
          </div>
          <div className="option">
            <NavLink to="/writer">
              Documents
            </NavLink>
          </div>
          <div className="option">
            <NavLink to="/editor">
              Editor
            </NavLink>
          </div>
          <div className="option">
            <NavLink to="/reports/stats">
              Stats
            </NavLink>
          </div>
          <div className="option">
            <NavLink to="/reports/timesheet/timesheets">
              Timesheet
            </NavLink>
          </div>
          <div className="option">
            <NavLink to="/reports/invoice">
              Reports
            </NavLink>
          </div>
          <div className="option">
            <NavLink to="/profile/me">
              Settings
            </NavLink>
          </div>
          { // me.plan==='standard' && (
            me.role==='Admin' ? (
            <div className="option">
              <NavLink to="/hr/admin">
                HR
              </NavLink>
            </div> 
            ) : me.role==='HR' ? (
              <div className="option">
                <NavLink to="/hr/admin">
                  HR
                </NavLink>
              </div>
            ) : []
          // )
          }
          <div
            className="option"
            onClick={() => this.props.logout()}
          >
            <span>
              Log Out
            </span>
          </div>
        </div>   
      ) 
    }

    render() {
        const { onClickAway } = this.props
        const { showDropdown } = this.state
        return (
            <div>
                <Button2 onClick={this.handleClick}>
                    <FontAwesome name="sort-up" />
                        <br />
                    <FontAwesome name="sort-down" />
                </Button2>
                {this.state.dropdown && (
                    <div className="dropdown">
                        {this.renderDropdown()}
                    </div>
                  )}
                <Overlay
                  active={showDropdown}
                  onClick={() => this.setState({showDropdown: false})}
                />
            </div>
        );
    }
}

const mapStateToProps = ({ auth }) => {
    const {
      me,
      isAdmin,
      loading
    } = auth
  
    return {
      me,
      isAdmin,
      loading
    }
  }
  
  const actions = {
    getSelf,
    logout,
  }
  
  export default connect(mapStateToProps, actions)(SideNavi)