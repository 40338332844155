import React, { Component } from 'react';
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import * as cookies from 'js-cookie';

import qs from 'query-string'
import cn from 'classnames'

import { getDateObject } from '../../../../lib'

import { Icon, Button2, Overlay } from '../../../common'

import {
  getVisits,
} from '../../../../actions/main'

import {
  getSelf
} from '../../../../actions/auth'

import SideNavi from '../../../shared/SideNav/SideNav'


// Helpers
import { hasAuthTokenAsync } from '../../../../lib'

import styles from './NavBar.css'
import TopNav from '../../../Messager/TopNav/TopNav';

class NavBar extends Component {
  constructor(props, ...rest) {
    super(props, ...rest);
    this.state = {
        dropdown: false,
    };
}

  componentDidMount() {
    hasAuthTokenAsync()
      .then(() => {
        this.props.getSelf()
      })
      .catch(console.log)
  }

  callAgendaActions() {
    this.props.getVisits()
  }

  handleClick = () => {
    this.setState(state => {
      return {
        dropdown: !state.dropdown,
      };
    });
  };

  renderDropdown() {
    const { dropdown } = this.state
    const { isAdmin } = this.props
    return (
      <div className={cn(styles.secondary, {show: dropdown})}
           onClick={() => this.setState({dropdown: !dropdown})}>
        <div className={styles.navBar}>
          {isAdmin && (
            <NavLink
              to="/supervisors">
                Supervisors
            </NavLink>
          )}

          {isAdmin && (
            <NavLink
              to="/employees">
                Employees
            </NavLink>
          )}

          {isAdmin && (
            <NavLink
              to="/projects">
                Projects
            </NavLink>
          )}

          {isAdmin && (
            <NavLink
              to="/products/out">
                Orders
            </NavLink>
          )}

        </div>
      </div>
    )}

  get agendaLink() {
    const { month, year } = getDateObject()
    const pathname = '/agenda'
    const search = qs.stringify({ month, year })
    return {
      pathname,
      search,
    }
  }

  get dashboardLink() {
    const pathname = '/dashboard'
    const view = 'day'
    const search = qs.stringify({ view })
    return {
      pathname,
      search,
    }
  }

  render() {
    const me = cookies.get('me') && JSON.parse(cookies.get('me')) ? JSON.parse(cookies.get('me')) : undefined 
    const { showDropdown } = this.state

    if (!me) {
      return (
        <div className={styles.navBar} >
          <TopNav />
        </div>
      )
    }

    return (
      <div className={styles.container}>
        <div className={styles.navBar}>
          <NavLink
            to={this.dashboardLink}>
            <b>
              Dashboard
            </b>
          </NavLink>

          <NavLink
            to="/contacts">
            <b>
              Contacts
            </b>
          </NavLink>

          <NavLink
            to="/products">
            <b>
              Products
            </b>
          </NavLink>

          <NavLink
            to="/team">
            <b>
              Team
            </b>  
          </NavLink>
          
          <NavLink
            to={this.agendaLink}
            onClick={() => this.callAgendaActions()}>
          <b>
            Agenda
          </b>
          </NavLink>

        </div>

        <div className={styles.dropbar} onClick={this.handleClick}>
          {this.state.dropdown && (
            <div className={styles.secondary}>
                {this.renderDropdown()}
            </div>
          )}
          <Overlay
            active={showDropdown}
            onClick={() => this.setState({showDropdown: false})}
            />
        </div>  
        <SideNavi/>
      </div> 
    )
  }
}

const mapStateToProps = ({router, auth}) => {
  const {
    isAdmin
  } = auth

  return {
    isAdmin,
    router,
  }
}

const actions = {
  // getVisits,
  getSelf
}

export default connect(mapStateToProps, actions)(NavBar)
