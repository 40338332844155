import React from 'react'
import { connect } from 'react-redux'
import { 
    // Editor, 
    EditorState, 
    RichUtils,
    AtomicBlockUtils,
    convertFromRaw, 
    convertToRaw
 } from 'draft-js';
import Editor from "draft-js-plugins-editor"
import addLinkPlugin from "./plugins/addLinkPlugin/addLinkPlugin";
import createHighlightPlugin from './plugins/highlightPlugin/highlightPlugin'
import { mediaBlockRenderer } from "./entities/mediaBlockRenderer";
import { 
    BlockStyleToolbar, 
    getBlockStyle
} from "./blockstyles/BlockStyleToolbar";
import { loadNote } from '../../../actions/editor'

const highlightPlugin = createHighlightPlugin();

class EditorPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        editorState: EditorState.createEmpty()
        }
        this.plugins = [addLinkPlugin, highlightPlugin];
    }

    componentDidMount() {
        if (this.props.note === null) {
            this.setState({
                displayedNote: "new",
                editorState: EditorState.createEmpty()
            })
        } else {
            this.setState({
                displayedNote: this.props.note /*.id*/,
                // editorState: EditorState.createWithContent(convertFromRaw(JSON.parse(JSON.stringify(this.props.note /*.content*/))))
            })
        }
    }
       
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.note == null && !!this.props.note) {
            loadNote()
            this.setState({
                displayedNote: this.note.id,
                editorState: EditorState.createWithContent(convertFromRaw(JSON.parse(this.props.note.content)))
            })
        }
    }
       
    onChange = (editorState) => {
        this.setState({ editorState: editorState });
    };
       
    submitEditor = () => {
        let contentState = this.state.editorState.getCurrentContent()
        if (this.state.displayedNote == "new") {
            let note = {content: convertToRaw(contentState)}
            note["content"] = JSON.stringify(note.content)
            this.props.createNote(note.content)
        } else {
            let note = {content: convertToRaw(contentState)}
            note["content"] = JSON.stringify(note.content)
            this.props.updateNote(this.state.displayedNote, note.content)
        }
    }

    onChange = editorState => {
      this.setState({
        editorState
      });
    };
  
    onAddLink = () => {
      const editorState = this.state.editorState;
      const selection = editorState.getSelection();
      const link = window.prompt("Paste the link -");
      if (!link) {
        this.onChange(RichUtils.toggleLink(editorState, selection, null));
        return "handled";
      }
      const content = editorState.getCurrentContent();
      const contentWithEntity = content.createEntity("LINK", "MUTABLE", {
        url: link
      });
      const newEditorState = EditorState.push(
        editorState,
        contentWithEntity,
        "create-entity"
      );
      const entityKey = contentWithEntity.getLastCreatedEntityKey();
      this.onChange(RichUtils.toggleLink(newEditorState, selection, entityKey));
      return "handled";
    };
  
    handleKeyCommand = command => {
      const newState = RichUtils.handleKeyCommand(
        this.state.editorState,
        command
      );
      if (newState) {
        this.onChange(newState);
        return "handled";
      }
      return "not-handled";
    };

    onURLChange = e => this.setState({ urlValue: e.target.value });

    focus = () => this.refs.editor.focus();
  
    onAddImage = e => {
      e.preventDefault();
      const editorState = this.state.editorState;
      const urlValue = window.prompt("Paste Image Link");
      const contentState = editorState.getCurrentContent();
      const contentStateWithEntity = contentState.createEntity(
        "image",
        "IMMUTABLE",
        { src: urlValue }
      );
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const newEditorState = EditorState.set(
        editorState,
        { currentContent: contentStateWithEntity },
        "create-entity"
      );
      this.setState(
        {
          editorState: AtomicBlockUtils.insertAtomicBlock(
            newEditorState,
            entityKey,
            " "
          )
        },
        () => {
          setTimeout(() => this.focus(), 0);
        }
      );
    };
  
    onUnderlineClick = () => {
      this.onChange(
        RichUtils.toggleInlineStyle(this.state.editorState, "UNDERLINE")
      );
    };
  
    onBoldClick = () => {
      this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, "BOLD"));
    };
  
    onItalicClick = () => {
      this.onChange(
        RichUtils.toggleInlineStyle(this.state.editorState, "ITALIC")
      );
    };
  
    onStrikeThroughClick = () => {
      this.onChange(
        RichUtils.toggleInlineStyle(this.state.editorState, "STRIKETHROUGH")
      );
    };

    onHighlight = () => {
        this.onChange(
          RichUtils.toggleInlineStyle(this.state.editorState, "HIGHLIGHT")
        );
    };
  
    render() {
      return (
        <div className="editorContainer">
            <div className="toolbar">
                <BlockStyleToolbar
                    editorState={this.state.editorState}
                    onToggle={this.toggleBlockType}
                />
                <button
                    className="inline styleButton"
                    id="underline"
                    onClick={this.onUnderlineClick}
                > U </button>
        
                <button
                    className="inline styleButton"
                    id="bold"
                    onClick={this.onBoldClick}
                > B </button>
        
                <button
                    className="inline styleButton"
                    id="italic"
                    onClick={this.onItalicClick}
                > I </button>
                <button
                    className="inline styleButton strikethrough"
                    onClick={this.onStrikeThroughClick}
                > abc </button>

                <button className="highlight" onClick={this.onHighlight}>
                    <span style={{ background: "yellow", padding: "0.3em" }}></span>
                </button>
        
                <button id="link_url" onClick={this.onAddLink} className="add-link">
                    <i className="material-icons">attach_file</i>
                </button>
                <button className="inline styleButton" onClick={this.onAddImage}>
                    <i
                        className="material-icons"
                        style={{
                            fontSize: "16px",
                            textAlign: "center",
                            padding: "0px",
                            margin: "0px"
                        }}
                    > image </i>
                </button>
            </div>   
  
            <div className="editors">
                <Editor
                    blockRendererFn={mediaBlockRenderer}
                    editorState={this.state.editorState}
                    handleKeyCommand={this.handleKeyCommand}
                    onChange={this.onChange}
                    plugins={this.plugins}
                    ref="editor"
                />
            </div>
        </div>
      );
    }
  }

const mapStateToProps = ({auth}) => {
    const {

    } = auth
    return {
    }
  }
  
  const actions = {

  }
  
  export default connect(mapStateToProps, actions)(EditorPage)