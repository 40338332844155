import React, { Component } from 'react';
import { connect } from 'react-redux'

// Components
import {
  Header,
  Button,
  Selector,
  SummaryItem,
  Table,
  Span,
} from '../../../../../common'

import {
  getTimeline
} from '../../../../../../actions/community'

import styles from './TimelineTab.css'

class TimelineTab extends Component {
  constructor(props) {
    super(props);
    this.state = { 

    }
  }

  componentDidMount() {
    this.props.getTimeline()
  }

  onClick(e) {
    e.preventDefault()
    const data = {
      name: this.state.name,
      price: this.state.price,
      payout: this.state.payout
    }
    this.props.createTimeline(data)
  }

  renderTableHead() {
    return (
      <thead className={styles.head}>
        <tr>
          <th>
            Timeline
          </th>
        </tr>
      </thead>
    )
  }

  renderTableBody() {
    const {
      timeline
    } = this.props

    if (this.props.me) {
      if (!timeline.length > 0) {
        return (
          <tbody>
            <tr>
              <td colSpan="9" className="empty">
                <Button times onClick={this.onClick.bind(this)}>
                  Add to timeline
                </Button>
              </td>
            </tr>
          </tbody>
        )
      } else {
        return (
          <tbody>
              {timeline.map(this.renderTableRow.bind(this))}
              <Button times onClick={this.onClick.bind(this)}>
                Add to timeline
              </Button>
          </tbody>
        )
      }
    } else if (!this.props.me) {
      if (!timeline.length > 0) {
        return (
          <tbody>
            <tr>
              <td className="empty">
                Hasn't Muved!
              </td>
            </tr>
          </tbody>
        )
      } else {
        return (
          <tbody>
            {timeline.map(this.renderTableRow.bind(this))}
          </tbody>
        )
      }
    }
  }

  renderTableRow(employee) {    
    return (
      <tr className={styles.map}>
        <td>
            {employee.name}
        </td>

        <td className={styles.email}>
          {employee.email}
        </td>

        <td>
          <Span icon="phone">
            {employee.phone || 'None'}
          </Span>
        </td>

        <td>
          {employee.address}
        </td>

        <td className={styles.select}>
          <Selector
            table
            value={employee.role}
            onSelect={r => this.setState({ role: r })}
            options={this.roles}
            className={styles.select}
          />
        </td>
        
        <td className={styles.select}>
          <Selector
            table
            value={employee.title}
            onSelect={t => this.setState({ title: t })}
            options={this.titles}
            className={styles.select}
          />
        </td>

        <td className="bold">
          {employee.supervisor}
        </td>
        <td className={styles.detailsCell}>
          <Button
            title="DETAILS"
            link={`/employees/${employee._id}`}
            onClick={() => this.props.setEmployee(employee)}
          />
        </td>
      </tr>
    )
  }

  render() { 
    return ( 
      <Table className={styles.app}>
        {this.renderTableHead()}
        {this.renderTableBody()}
      </Table>
    );
  }
}

const mapStateToProps = ({ community }) => {
  const {
    timeline,
  } = community

  return {
    timeline
  }
}

const actions = {
  getTimeline
}

export default connect(mapStateToProps, actions)(TimelineTab);
