// Router
import { push } from 'react-router-redux'

// Types
import { companyTypes } from './types'

// Services
import { companys, auth } from '../services'

const {
  CREATE_COMPANY,
  CREATE_COMPANY_FAILURE,
  CREATE_COMPANY_SUCCESS,
  SET_COMPANY,
  GET_COMPANY,
  GET_COMPANY_FAILURE,
  GET_COMPANY_SUCCESS,
  PATCH_COMPANY,
  PATCH_COMPANY_FAILURE,
  PATCH_COMPANY_SUCCESS,
  CLEAR_ERRORS,
  CLEAR_STATE,
} = companyTypes


export const createCompany = payload => dispatch => {
  dispatch({
    type: CREATE_COMPANY,
  })
  auth.register(payload)
    .then(res => onCreateCompanySuccess({dispatch, res}))
    .catch(err => onCreateCompanyFailure({dispatch, err}))
}

function onCreateCompanySuccess({dispatch, res}) {
  console.log('createCompany success');
  dispatch({
    type: CREATE_COMPANY_SUCCESS,
  })
  window.alert('Company Created!')
  dispatch(push('/login'))
}

function onCreateCompanyFailure({dispatch, err}) {
  console.log('createCompany failure', err);
  const payload = err.message || 'CreateCompany Failed'
  window.alert(payload)
  dispatch({
    type: CREATE_COMPANY_FAILURE,
    payload,
  })
}

export const setCompany = payload => {
  return {
    type: SET_COMPANY,
    payload,
  }
}

export const getCompanyById = companyId => (dispatch, getState) => {
  // check if a company has been set yet
  const { company } = getState().companys
  if (company && company.id !== companyId) {
    // clear it if it is not the same one
    console.log('clearing state');
    dispatch({
      type: CLEAR_STATE,
    })
  }

  dispatch({
    type: GET_COMPANY,
  })
  companys.getById(companyId)
    .then(res => onGetCompanySuccess({dispatch, res}))
    .catch(err => onGetCompanyFailure({dispatch, err}))
}

function onGetCompanySuccess({dispatch, res}) {
  console.log('getCompany success');
  dispatch({
    type: GET_COMPANY_SUCCESS,
    payload: res.company,
  })
}

function onGetCompanyFailure({dispatch, err}) {
  console.log('getCompany failure', err);
  const payload = err.message || 'GetCompany Failed'
  window.alert(payload)
  dispatch({
    type: GET_COMPANY_FAILURE,
    payload,
  })
  dispatch(push('/login'))
}

export const patchCompanyById = (id, update) => (dispatch, getState) => {
  dispatch({
    type: PATCH_COMPANY,
  })
  companys.patchById(id, update)
    .then(res => onPatchCompanySuccess({dispatch, res}))
    .catch(err => onPatchCompanyFailure({dispatch, err}))
}

function onPatchCompanySuccess({dispatch, res}) {
  console.log('patchCompany success');
  dispatch({
    type: PATCH_COMPANY_SUCCESS,
    payload: res.company,
  })
}

function onPatchCompanyFailure({dispatch, err}) {
  console.log('patchCompany failure', err)
  const payload = err.message || 'PatchCompany Failed'
  window.alert(payload)
  dispatch({
    type: PATCH_COMPANY_FAILURE,
    payload,
  })
}

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  }
}

export const clearState = () => {
  return {
    type: CLEAR_STATE,
  }
}
