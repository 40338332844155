// Router
import { push } from 'react-router-redux'

// Types
import { cashierTypes } from './types'

// Services
import { cashier } from '../services'

const {
  CREATE_DRAWER,
  CREATE_DRAWER_FAILURE,
  CREATE_DRAWER_SUCCESS,
  CREATE_TRANSACTION,
  CREATE_TRANSACTION_SUCCESS,
  CREATE_TRANSACTION_FAILURE,
  SET_DRAWER,
  GET_DRAWER,
  GET_DRAWER_FAILURE,
  GET_DRAWER_SUCCESS,
  PATCH_DRAWER,
  PATCH_DRAWER_FAILURE,
  PATCH_DRAWER_SUCCESS,
  CREATE_NOTE,
  CREATE_NOTE_FAILURE,
  CREATE_NOTE_SUCCESS,
  CHECKOUT,
  CHECKOUT_SUCCESS,
  CHECKOUT_FAILURE,
  CLEAR_ERRORS,
  CLEAR_STATE,
} = cashierTypes

const u = sessionStorage.getItem('my-community-data') ? JSON.parse(sessionStorage.getItem('my-community-data')) : ''

export const createDrawer = payload => dispatch => {
  dispatch({
    type: CREATE_DRAWER,
  })
  cashier.post(payload)
    .then(res => onCreateDrawerSuccess({dispatch, res}))
    .catch(err => onCreateDrawerFailure({dispatch, err}))
}

function onCreateDrawerSuccess({dispatch, res}) {
  dispatch({
    type: CREATE_DRAWER_SUCCESS,
  })
  dispatch(push('/drawers'))
}

function onCreateDrawerFailure({dispatch, err}) {
  const payload = err.message || 'CreateDrawer Failed'
  window.alert(payload)
  dispatch({
    type: CREATE_DRAWER_FAILURE,
    payload,
  })
}

export const createTransaction = payload => dispatch => {
  dispatch({
    type: CREATE_TRANSACTION,
  })
  cashier.post(payload, u._id)
    .then(res => onCreateTransactionSuccess({dispatch, res}))
    .catch(err => onCreateTransactionFailure({dispatch, err}))
}

function onCreateTransactionSuccess({dispatch, res}) {
  console.log(res.data)
  dispatch({
    type: CREATE_TRANSACTION_SUCCESS,
    payload: res.data
  })
}

function onCreateTransactionFailure({dispatch, err}) {
  const payload = err.message || 'CreateTransaction Failed'
  window.alert(payload)
  dispatch({
    type: CREATE_TRANSACTION_FAILURE,
    payload,
  })
}

export const setDrawer = payload => {
  return {
    type: SET_DRAWER,
    payload,
  }
}

export const getDrawerById = drawerId => (dispatch, getState) => {
  // check if a drawer has been set yet
//   const { drawer } = getState().drawer
//   if (drawer && drawer.id !== drawerId) {
//     // clear it if it is not the same one
//     dispatch({
//       type: CLEAR_STATE,
//     })
//   }

  dispatch({
    type: GET_DRAWER,
  })
  cashier.getById(drawerId)
    .then(res => onGetDrawerSuccess({dispatch, res}))
    .catch(err => onGetDrawerFailure({dispatch, err}))
}

function onGetDrawerSuccess({dispatch, res}) {
  dispatch({
    type: GET_DRAWER_SUCCESS,
    payload: res.data,
  })
}

function onGetDrawerFailure({dispatch, err}) {
  const payload = err.message || 'GetDrawer Failed'
  window.alert(payload)
  dispatch({
    type: GET_DRAWER_FAILURE,
    payload,
  })
  dispatch(push(window.location.pathname))
}

export const patchDrawerById = (id, update) => (dispatch, getState) => {
  dispatch({
    type: PATCH_DRAWER,
  })
  cashier.patchById(id, update)
    .then(res => onPatchDrawerSuccess({dispatch, res}))
    .catch(err => onPatchDrawerFailure({dispatch, err}))
}

function onPatchDrawerSuccess({dispatch, res}) {
  dispatch({
    type: PATCH_DRAWER_SUCCESS,
    payload: res.drawer,
  })
}

function onPatchDrawerFailure({dispatch, err}) {
  const payload = err.message || 'PatchDrawer Failed'
  window.alert(payload)
  dispatch({
    type: PATCH_DRAWER_FAILURE,
    payload,
  })
}

export const createDrawerNote = data => (dispatch, getState) => {
  dispatch({
    type: CREATE_NOTE,
  })

  const {
    drawerId,
    text,
  } = data

  cashier.postNoteById(drawerId, { text })
    .then(res => onCreateDrawerNoteSuccess({dispatch, getState, res}))
    .catch(err => onCreateDrawerNoteFailure({dispatch, err}))
}

function onCreateDrawerNoteSuccess({dispatch, getState, res}) {
  const { note } = res

  // update the visits array on the drawer
  const { drawer } = getState().drawers
  const notes = [note, ...drawer.notes]
  drawer.notes = notes

  dispatch({
    type: CREATE_NOTE_SUCCESS,
    payload: { ...drawer },
  })
}

function onCreateDrawerNoteFailure({dispatch, err}) {
  dispatch({
    type: CREATE_NOTE_FAILURE,
    payload: err.message || 'CreateDrawerNote Failed',
  })
}

export const checkout = data => dispatch => {
  dispatch({
    type: CHECKOUT,
  })

  cashier.checkout(data)
    .then(res => onCheckoutSuccess({dispatch, res}))
    .catch(err => onCheckoutFailure({dispatch, err}))
}

function onCheckoutSuccess({dispatch, res}) {
  sessionStorage.setItem('receipt', JSON.stringify(res.data))
  window.location.reload()
  dispatch({
    type: CHECKOUT_SUCCESS,
    payload: res.data,
  })
}

function onCheckoutFailure({dispatch, err}) {
  dispatch({
    type: CHECKOUT_FAILURE,
    payload: err.message || 'checkout Failed',
  })
}

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  }
}

export const clearState = () => {
  return {
    type: CLEAR_STATE,
  }
}