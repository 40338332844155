import { api } from '../config'

export default {
  getAll(cid,uid) {
    const url = `${cid}/projects/${uid}`
    return api.get(url)
  },

  post(data) {
    const url = '/projects'
    return api.post(url, data)
  },

  getById(id) {
    const url = `/projects/${id}`
    return api.get(url)
  },

  patchById(id, update) {
    const url = `/projects/${id}`
    return api.patch(url, update)
  },

  deleteById(id) {
    const url = `/projects/${id}`
    return api.delete(url, id)
  }
}
