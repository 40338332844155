// src/ComingSoonPage.js
import React, { Component } from 'react';
import './ComingSoon.css';
import { Container, Typography } from '@mui/material';
import WakuuDark from '../../../assets/images/WakuuDark.png'

class ComingSoonPage extends Component {
  render() {
    return (
      <div className="coming-soon-wrapper">
        <Container>
          <div className="logo-container">
            <img src={WakuuDark} alt="Logo" className="logo" />
          </div>
          <div className="coming-soon-container">
            <h4>
                Coming Soon!
            </h4>
            <subtitle>
                Stay tuned for our latest updates.
            </subtitle>
            {/* <Typography variant="body2" align="center">
                Release Date: [Add your date here]
            </Typography> */}
          </div>
        </Container>
      </div>
    );
  }
}

export default ComingSoonPage;
