// COMMUNITY FLOW
export const VERIFY_EMAIL = 'verify_email'
export const VERIFY_SUCCESS = 'verify_success'
export const VERIFY_FAILURE = 'verify_failure'

export const INIT_COMMUNITYS =   'init_communitys'
export const INIT_SUCCESS = 'init_success'
export const INIT_FAILURE = 'init_failure'

export const ADD_TO_CART = 'add_to_cart'  
export const ADD_TO_CART_SUCCESS = 'add_to_cart_success'
export const ADD_TO_CART_FAILURE = 'add_to_cart_failure' 

export const GET_CART = 'get_cart'
export const GET_CART_SUCCESS = 'get_cart_success'
export const GET_CART_FAILURE = 'get_cart_failure'

export const REMOVE_FROM_CART = 'remove_from_cart'  
export const REMOVE_FROM_CART_SUCCESS = 'remove_from_cart_success'
export const REMOVE_FROM_CART_FAILURE = 'remove_from_cart_failure' 

export const CREATE_GUEST = 'create_guest'
export const CREATE_GUEST_SUCCESS = 'create_guest_success'
export const CREATE_GUEST_FAILURE = 'create_guest_failure'

export const GET_GUEST = 'get_guest'
export const GET_GUEST_SUCCESS = 'get_guest_success'
export const GET_GUEST_FAILURE = 'get_guest_failure'

export const REMOVE_GUEST = 'remove_guest'
export const REMOVE_GUEST_SUCCESS = 'remove_guest_success'
export const REMOVE_GUEST_FAILURE = 'remove_guest_failure'

export const CREATE_COMMUNITY_MEMBER = 'community_create_member'
export const CREATE_COMMUNITY_MEMBER_SUCCESS = 'community_create_member_success'
export const CREATE_COMMUNITY_MEMBER_FAILURE = 'community_create_member_failure'

export const PATCH_COMMUNITY_MEMBER = 'community_patch_member'
export const PATCH_COMMUNITY_MEMBER_SUCCESS = 'community_patch_member_success'
export const PATCH_COMMUNITY_MEMBER_FAILURE = 'community_patch_member_failure'

export const GET_COMMUNITY = 'get_community'
export const GET_COMMUNITY_SUCCESS = 'get_community_success'
export const GET_COMMUNITY_FAILURE = 'get_community_failure'

export const SET_COMMUNITY_MEMBER = 'community_set_member'
export const GET_COMMUNITY_MEMBER = 'community_get_member'
export const GET_COMMUNITY_MEMBER_SUCCESS = 'community_get_member_success'
export const GET_COMMUNITY_MEMBER_FAILURE = 'community_get_member_failure'

export const GET_OFFERINGS = 'community_get_offerings'
export const GET_OFFERINGS_SUCCESS = 'community_get_offerings_success'
export const GET_OFFERINGS_FAILURE = 'community_get_offerings_failure'

export const CREATE_OFFERING = 'community_create_offering'
export const CREATE_OFFERING_SUCCESS = 'community_create_offering_success'
export const CREATE_OFFERING_FAILURE = 'community_create_offering_failure'

export const SET_OFFERING = 'community_set_offering'
export const GET_OFFERING = 'community_get_offering'
export const GET_OFFERING_SUCCESS = 'community_get_offering_success'
export const GET_OFFERING_FAILURE = 'community_get_offering_failure'

export const PATCH_OFFERING = 'community_patch_offering'
export const PATCH_OFFERING_SUCCESS = 'community_patch_offering_success'
export const PATCH_OFFERING_FAILURE = 'community_patch_offering_failure'

export const DELETE_OFFERING = 'community_delete_offering'
export const DELETE_OFFERING_SUCCESS = 'community_delete_offering_success'
export const DELETE_OFFERING_FAILURE = 'community_delete_offering_failure'

export const GET_PORTFOLIOS = 'community_get_portfolios'
export const GET_PORTFOLIOS_SUCCESS = 'community_get_portfolios_success'
export const GET_PORTFOLIOS_FAILURE = 'community_get_portfolios_failure'

export const CREATE_PORTFOLIO = 'community_create_portfolio'
export const CREATE_PORTFOLIO_SUCCESS = 'community_create_portfolio_success'
export const CREATE_PORTFOLIO_FAILURE = 'community_create_portfolio_failure'

export const SET_PORTFOLIO = 'community_set_portfolio'
export const GET_PORTFOLIO = 'community_get_portfolio'
export const GET_PORTFOLIO_SUCCESS = 'community_get_portfolio_success'
export const GET_PORTFOLIO_FAILURE = 'community_get_portfolio_failure'

export const PATCH_PORTFOLIO = 'community_patch_portfolio'
export const PATCH_PORTFOLIO_SUCCESS = 'community_patch_portfolio_success'
export const PATCH_PORTFOLIO_FAILURE = 'community_patch_portfolio_failure'

export const DELETE_PORTFOLIO = 'community_delete_portfolio'
export const DELETE_PORTFOLIO_SUCCESS = 'community_delete_portfolio_success'
export const DELETE_PORTFOLIO_FAILURE = 'community_delete_portfolio_failure'

export const GET_TIMELINES = 'community_get_timelines'
export const GET_TIMELINES_SUCCESS = 'community_get_timelines_success'
export const GET_TIMELINES_FAILURE = 'community_get_timelines_failure'

export const CREATE_TIMELINE = 'community_create_timeline'
export const CREATE_TIMELINE_SUCCESS = 'community_create_timeline_success'
export const CREATE_TIMELINE_FAILURE = 'community_create_timeline_failure'

export const SET_TIMELINE = 'community_set_timeline'
export const GET_TIMELINE = 'community_get_timeline'
export const GET_TIMELINE_SUCCESS = 'community_get_timeline_success'
export const GET_TIMELINE_FAILURE = 'community_get_timeline_failure'

export const PATCH_TIMELINE = 'community_patch_timeline'
export const PATCH_TIMELINE_SUCCESS = 'community_patch_timeline_success'
export const PATCH_TIMELINE_FAILURE = 'community_patch_timeline_failure'

export const DELETE_TIMELINE = 'community_delete_timeline'
export const DELETE_TIMELINE_SUCCESS = 'community_delete_timeline_success'
export const DELETE_TIMELINE_FAILURE = 'community_delete_timeline_failure'

export const GET_ANNOUNCEMENTS = 'community_get_announcements'
export const GET_ANNOUNCEMENTS_SUCCESS = 'community_get_announcements_success'
export const GET_ANNOUNCEMENTS_FAILURE = 'community_get_announcements_failure'

export const CREATE_ANNOUNCEMENT = 'community_create_announcement'
export const CREATE_ANNOUNCEMENT_SUCCESS = 'community_create_announcement_success'
export const CREATE_ANNOUNCEMENT_FAILURE = 'community_create_announcement_failure'

export const SET_ANNOUNCEMENT = 'community_set_announcement'
export const GET_ANNOUNCEMENT = 'community_get_announcement'
export const GET_ANNOUNCEMENT_SUCCESS = 'community_get_announcement_success'
export const GET_ANNOUNCEMENT_FAILURE = 'community_get_announcement_failure'

export const PATCH_ANNOUNCEMENT = 'community_patch_announcement'
export const PATCH_ANNOUNCEMENT_SUCCESS = 'community_patch_announcement_success'
export const PATCH_ANNOUNCEMENT_FAILURE = 'community_patch_announcement_failure'

export const DELETE_ANNOUNCEMENT = 'community_delete_announcement'
export const DELETE_ANNOUNCEMENT_SUCCESS = 'community_delete_announcement_success'
export const DELETE_ANNOUNCEMENT_FAILURE = 'community_delete_announcement_failure'

export const GET_COMMENTS = 'community_get_comments'
export const GET_COMMENTS_SUCCESS = 'community_get_timelines_success'
export const GET_COMMENTS_FAILURE = 'community_get_timelines_failure'

export const CREATE_COMMENT = 'community_create_comment'
export const CREATE_COMMENT_SUCCESS = 'community_create_comment_success'
export const CREATE_COMMENT_FAILURE = 'community_create_comment_failure'

export const SET_COMMENT = 'community_set_comment'
export const GET_COMMENT = 'community_get_comment'
export const GET_COMMENT_SUCCESS = 'community_get_comment_success'
export const GET_COMMENT_FAILURE = 'community_get_comment_failure'

export const PATCH_COMMENT = 'community_patch_comment'
export const PATCH_COMMENT_SUCCESS = 'community_patch_comment_success'
export const PATCH_COMMENT_FAILURE = 'community_patch_comment_failure'

export const DELETE_COMMENT = 'community_delete_comment'
export const DELETE_COMMENT_SUCCESS = 'community_delete_comment_success'
export const DELETE_COMMENT_FAILURE = 'community_delete_comment_failure'

export const GET_REPLYS = 'community_get_replys'
export const GET_REPLYS_SUCCESS = 'community_get_replys_success'
export const GET_REPLYS_FAILURE = 'community_get_replys_failure'

export const CREATE_REPLY = 'community_create_reply'
export const CREATE_REPLY_SUCCESS = 'community_create_reply_success'
export const CREATE_REPLY_FAILURE = 'community_create_reply_failure'

export const SET_REPLY = 'community_set_reply'
export const GET_REPLY = 'community_get_reply'
export const GET_REPLY_SUCCESS = 'community_get_reply_success'
export const GET_REPLY_FAILURE = 'community_get_reply_failure'

export const PATCH_REPLY = 'community_patch_reply'
export const PATCH_REPLY_SUCCESS = 'community_patch_reply_success'
export const PATCH_REPLY_FAILURE = 'community_patch_reply_failure'

export const DELETE_REPLY = 'community_delete_reply'
export const DELETE_REPLY_SUCCESS = 'community_delete_reply_success'
export const DELETE_REPLY_FAILURE = 'community_delete_reply_failure'

export const CLEAR_STATE = 'community_clear_state'
export const CLEAR_ERRORS = 'community_clear_errors'
