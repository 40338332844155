import stream from './ws/stream';

// Cookies
import * as cookies from 'js-cookie'

// socket.io
import io from 'socket.io-client'

//random text generate method
function randomTextGenerator() {
  var text = "";
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < 5; i++) text += possible.charAt(Math.floor(Math.random() * possible.length));
  return text;
}

const guest_id = randomTextGenerator(7)

const getClient = socketURL => {
  const me = cookies.get('me') && JSON.parse(cookies.get('me')) ? JSON.parse(cookies.get('me')) : undefined;
  let client
  if (typeof EventSource === 'function' && me !== undefined) {
    const id = me.company
    const uid = me.id
    const url = `${id}/company/${uid}`
    // client = new EventSource(`http://127.0.0.1:9000/api/${url}`)
    client = new EventSource(`http://192.168.0.199:9000/api/${url}`)
    // client = new EventSource(`https://api.muvor.com/api/${url}`)
  } else if (typeof EventSource === 'function' && me === undefined) {
    // Guest access
    //TODO: guest features
    // client = new EventSource(`http://127.0.0.1:9000/api/${guest_id}`)
    client = new EventSource(`http://192.168.0.199:9000/api/${guest_id}`)
    // client = new EventSource(`https://api.muvor.com/api/${guest_id}`)
  }
  return client;
}

const getSocket = () => {
  const me = cookies.get('me') && JSON.parse(cookies.get('me')) ? JSON.parse(cookies.get('me')) : undefined 
  let client
  if (typeof WebSocket === 'function' && me !== undefined) {
    const id = me.company
    const uid = me.id
    const url = `${uid}/company/${id}`
    // client = io(`ws://127.0.0.1:9000/chat/${url}`)
    client = io(`ws://192.168.0.199:9999`, { withCredentials: true, path: "/bridge" })
    // client = io(`https://api.muvor.com`, { withCredentials: true, path: "/bridge" });
  } else if (typeof WebSocket === 'function' && me === undefined) {
    // Guest access
    //TODO: guest features
    // client = io(`ws://127.0.0.1:9000`)
    client = io(`ws://192.168.0.199:9999`, { withCredentials: true, path: "/bridge" })
    // client = io(`https://api.muvor.com`, { withCredentials: true, path: "/bridge" });
  }
  return client;
}

let instance;
export const init = (clientURL) => {
  instance =  getClient(clientURL);
  return instance;
};

export const sock = () => getSocket();
export const getInstance = () => instance;
export const getStream = () => sock();

export default {
  init,
  getInstance,
  getStream,
  sock
};
