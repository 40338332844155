import React, { Component } from "react";
import ChatPage from "./ChatPage";
import { chat } from "../VideoPage/VideoPage/socket";

const chatSocket = chat;
const NEW_MESSAGE_EVENT = "new-message-event";

class useChatRoom extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      people: {
        name: "vic",
        name: "brad",
        name: "wade",
        name: "logan"
      },
      rooms: {
        room: 1,
        room: 2,
        room: 3,
        room: 4
      },
      messages: [],
      currentUser: undefined
    }
  }

  componentDidMount() {
    chatSocket.on('connect', () => console.log('tower-0 connected!'))
    chatSocket.on(NEW_MESSAGE_EVENT, (messages) => {
      console.log(messages)
      let thread = []
      messages.map(message => {
        const incomingMessage = {
          ...message,
          isOwner: message.senderId === chatSocket.id,
        };
        thread.push(incomingMessage)
      })
      this.setState({ messages: thread })
    });

    return () => {
      chatSocket.disconnect();
    };
  }

  render() { 
    return (
      <ChatPage 
        user={this.state.currentUser}
        people={this.state.people}
        rooms={this.state.rooms}
        messages={this.state.messages} 
        socket={chatSocket}
      />
    )
  };
};

export default useChatRoom
 
