import React, { Component } from 'react';
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import * as cookies from 'js-cookie';
import Spinner from 'react-spinner'

import qs from 'query-string'
import cn from 'classnames'

import { getDateObject } from '../../../../lib'

import { Icon, Button2, Overlay } from '../../../common'

import {
  getVisits,
} from '../../../../actions/main'

import {
  getSelf
} from '../../../../actions/auth'

import SideNavi from '../../../shared/SideNav/SideNav'


// Helpers
import { hasAuthTokenAsync } from '../../../../lib'

import styles from './NavBar.css'
import TitleBar from '../TitleBar/TitleBar';

class NavBar extends Component {
  constructor(props, ...rest) {
    super(props, ...rest);
    this.state = {
        dropdown: false,
    };
}

  componentDidMount() {
    hasAuthTokenAsync()
      .then(() => {
        this.props.getSelf()
      })
      .catch(console.log)
  }

  callAgendaActions() {
    this.props.getVisits()
  }

  handleClick = () => {
    this.setState(state => {
      return {
        dropdown: !state.dropdown,
      };
    });
  };

  get dashboardLink() {
    const pathname = '/dashboard'
    const view = 'day'
    const search = qs.stringify({ view })
    return {
      pathname,
      search,
    }
  }

  render() {
    const { loading, me } = this.props
    return (
      <div>
    {
        // <div className="title">
        //   <NavLink to="/">
        //     <img className="titiles" src={require('../../../../../assets/images/MuvorLogo.png')}></img>
        //     </NavLink>
        //   <Spinner className={cn({loading})} />
        // </div>
    }
        <div className={styles.container}>
          <div className={styles.navBar}>
            <NavLink
              to={this.dashboardLink}>
              <b>
                Recco
              </b>
            </NavLink>
            
            <NavLink
              to="/chats/home">
            <b>
              Chat
            </b>
            </NavLink>

            <NavLink
              to="/chats/video">
              <b>
                Meeting
              </b>
            </NavLink>
            
          </div>

          {me ? <SideNavi /> : <TitleBar />}
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({router, auth}) => {
  const {
    isAdmin,
    loading,
    me
  } = auth


  return {
    isAdmin,
    router,
    loading,
    me
  }
}

const actions = {
  // getVisits,
  getSelf
}

export default connect(mapStateToProps, actions)(NavBar)
