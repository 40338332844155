import React from 'react';
import {
  Route,
  Switch,
  Redirect
} from 'react-router-dom'

import Role from "../Role/Role";
import RoleForm from "../Role/RoleForm/RoleForm";
import Position from "../Position/Position";
import Department from "../Department/Department";
import Country from "../Country/Country";
import State from "../State/State";
import City from "../City/City";
import Company from "../Company/Company";
import Salary from "../Salary/Salary";
import LeaveApplicationHR from "./LeaveApplicationHR/LeaveApplicationHR";

// Components
import NotFound from '../../../NotFound/NotFound'
import AgendaPage from './AgendaPage/AgendaPage';

const HrDashboardRouter = props => (
  <Switch>
    <Route
        path="/hr/dashboard/agenda"
        // exact
        component={AgendaPage}
    />
    <Route
        path="/hr/dashboard/salary"
        // exact
        component={Salary}
    />
    <Route
        path="/hr/dashboard/company"
        // exact
        component={Company}
    />
    <Route path="/hr/dashboard/role" component={Role} />
    {/* <Route path="/hr/dashboard/role/form" exact component={RoleFormF} /> */}
    <Route
        path="/hr/dashboard/position"
        exact
        component={Position}
    />
    <Route
        path="/hr/dashboard/department"
        exact
        component={Department}
    />
    <Route
        path="/hr/dashboard/country"
        exact
        component={Country}
    />
    <Route
        path="/hr/dashboard/state"
        exact
        component={State}
    />
    <Route
        path="/hr/dashboard/city"
        exact
        component={City}
    />
    <Route
        path="/hr/dashboard/leave-application-hr"
        exact
        component={LeaveApplicationHR}
    />
        {/* <Route
        path="/hr/dashboard/portal-master"
        exact
        component={HRPortalF}
    /> */}
        {/* <Route
        path="/hr/dashboard/project-bid"
        exact
        component={HRProjectBidF}
    /> */}
    {/* <Route
        exact
        path="/hr"
        render={() => <Redirect to="hr/employee" />}
    /> */}
    <Route
      component={NotFound}
    />
  </Switch>
)

export default HrDashboardRouter
