import React, { Component } from 'react';
import { connect } from 'react-redux'

import {
  Icon,
  FileUpload,
  Button,
  Header,
  Body,
  Input,
  Form,
  FormModal,
} from '../../../common'

// Actions
import {
  clearPhotos,
  removePhoto,
  uploadPhoto
} from '../../../../actions/main'

import {
  createContact,
} from '../../../../actions/contacts'

import { hasAuthTokenAsync } from '../../../../lib'

import * as cookies from 'js-cookie';

import styles from './ContactModal.css'
import Page from '../../../shared/Map/Page';

class ContactModal extends Component {
  constructor(props) {
    const me = cookies.get('me') && JSON.parse(cookies.get('me')) ? JSON.parse(cookies.get('me')) : undefined 
    super(props)
    this.state = {
      cardId: me.id,
      firstName: '',
      lastName: '',
      email: '',
      position: '',
      phone: '',
      address: '',
      photo: [],
      primaryInsurance: '',
      secondaryInsurance: '',
      description: '',
      link: '',
      docs: [],
    }
  }

  componentDidMount() {
    hasAuthTokenAsync()
    .then(() => {
      this.props.clearPhotos()
    })
    .catch(console.error)
    }

    componentWillUnmount() {
      this.props.clearPhotos()
    }

  upload(type) {
    const formData = new FormData();
    type.map(t => {
      formData.append('file', t)
    })
    console.log(formData)
    return formData
  }

  onSubmit(e) {
    const me = JSON.parse(cookies.get('me'))
    e.preventDefault()
    const {
      cardId,
      firstName,
      lastName,
      email,
      position,
      phone,
      address,
      photo,
      docs,
      description,
      link,
      primaryInsurance,
      secondaryInsurance,
    } = this.state

    const data = {
      company: me.company,
      cardId,
      firstName,
      lastName,
      email,
      phone,
      address,
      photo,
      docs,
      description,
      link,
      primaryInsurance,
      secondaryInsurance
    }
    console.log(data)
    this.props.createContact(data)
  }

  // addPhoto(file) {
  //   if (file) {
  //     this.props.uploadPhoto(file)
  //   }
  // }

  renderPhotoItem(photo) {
    return (
      <div
        key={photo.id}
        className="photo"
      >
        <span className="photo">
          {photo.name}
        </span>
        <Icon
          small
          button
          cancel
          onClick={() => this.props.removePhoto(photo)}
        />
      </div>
    )
  }

  render() {
    const me = JSON.parse(cookies.get('me'))

    if (this.state.photo != null) {

    }

    const {
      loading,
      progress,
      photos
    } = this.props

    const {
      cardId,
      firstName,
      lastName,
      email,
      position,
      phone,
      address,
      photo,
      docs,
      description,
      link,
      primaryInsurance,
      secondaryInsurance
    } = this.state

    const invalid = !firstName || !lastName

    return (
      <div>
        <Body className={styles.body}>
          <Form
            className="form"
            onSubmit={this.onSubmit.bind(this)}
          >
            <Page />
            <label>
              Card ID:
            </label>
            <Input
              placeholder={me.id}
              value={cardId}
              onChange={cardId => this.setState({ cardId })}
            />

            <br />

            <label>
              Contact Name:
            </label>
            <Input
              placeholder="First Name"
              value={firstName}
              onChange={firstName => this.setState({ firstName })}
            />
            <Input
              placeholder="Last Name"
              value={lastName}
              onChange={lastName => this.setState({ lastName })}
            />

            <br />

          <label>
            Email:
          </label>
          <Input
            placeholder="Email"
            value={email}
            onChange={email => this.setState({ email })}
          />

          <br />

            <label>
              Phone:
            </label>
            <Input
              placeholder="Phone Number"
              value={phone}
              onChange={phone => this.setState({ phone })}
            />

            <br />

            <label>
              Address:
            </label>
            <Input
              placeholder="Address"
              value={address}
              onChange={address => this.setState({ address })}
            />

            <br />

            <label>
              Photo:
            </label>
            
            <div className={styles.photoList}>
            {
              this.state.photo.length ? (
              this.state.photo.map(this.renderPhotoItem.bind(this))
              ) : (
                <span>
                  None
                </span>
                )
              }
              </div>
              <FileUpload
                id="add-photo"
                wide
                large
                confirm
                title="PHOTOS"
                accept={'image/jpeg,image/png'}
                inactive={!loading}
                onUpload={photo => {var a=this.state.photo.slice();a.push(photo);this.setState({photo:a})}}
                progress={progress}
              />
              
              <br />

              <label>
                Notes:
              </label>
              <Input
                placeholder="Any Notes?"
                value={description}
                onChange={description => this.setState({ description })}
              />

              <br />
              <label>
                Link:
              </label>
              <Input
                placeholder="https://clientsite.xyz"
                value={link}
                onChange={link => this.setState({ link })}
              />
              <br />

            <label>
              Document:
            </label>
            
            <div className={styles.docsList}>
              {
                this.state.docs.length ? (
                this.state.docs.map(this.renderPhotoItem.bind(this))
                ) : (
                  <span>
                  None
                  </span>
                )
              }
              </div>
              
              <FileUpload
                id="add-docs"
                wide
                large
                confirm
                title="DOCUMENTS"
                accept={'application/pdf,text/plain,text/csv,application/word,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/epub+zip,application/json,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheet.sheet'}
                inactive={!loading}
                onUpload={docs => {var a=this.state.docs.slice();a.push(docs);this.setState({docs:a})}}
                progress={progress}
              />
              
              <br />

            <div className="buttons">
              <Button
                large
                cancel
                type="button"
                title="CANCEL"
                link="/contacts"
              />
              <Button
                large
                type="submit"
                title="CREATE CONTACT"
                inactive={invalid}
              />
            </div>
            </Form>
          </Body>
      </div>
    );
  }
}

const mapStateToProps = ({main}) => {
  const {
    photos,
    loading,
    contacts,
  } = main

  return {
    photos,
    loading,
    contacts,
  }
}

const actions = {
  createContact,
  clearPhotos,
  removePhoto,
  // uploadPhoto
}

export default connect(mapStateToProps, actions)(ContactModal)
