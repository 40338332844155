import React, { Component } from 'react';
import { hasAuthTokenAsync } from '../../../../lib';

import {
  Span,
  Input,
  Selector
} from '../../../common'

import {
  ContactBox,
} from '../../../shared'


class ContactInfo extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      supervisor: JSON.parse(localStorage.getItem('supervisor')),
      name: '',
      phone: 0,
      team: [],
      fax: 0,
      address: '',
      specialty: ''
     }
  }

  render() { 
    const {
      className, 
      editing, 
      onChange, 
      state
    } = this.props
    const {
      supervisor
    } = this.state

    console.log(supervisor)
    const {
      name,
      phone,
      team,
      fax,
      address,
    } = supervisor
    const {
      firstName,
      lastName,
      group,
      specialty,
    } = state
  
    const teamOptions = [
      {
        key: 0,
        value: '',
        display: 'view...',
      },
      ...team.map(t => ({
        key: t,
        value: t,
        display: t,
      })) || 'No group set...',
    ]
    return (
      <div className={className}>
        <div className="name">
          {editing ? (
            <div className="name">
              Name:
              <Input
                placeholder={name}
                value={this.state.name}
                onChange={name => this.setState({ name })}
              />
            </div>
          ) : (
            <div>
              Name: {name}
            </div>
          )}
    
          <div>
            Groups:
            <Span
              editing={editing}
              placeholder={team}
              value={this.state.team}
              onChange={team => onChange({ team })}
            >
              <Selector
                options={teamOptions}
                value={state}
                onSelect={state => this.setState({ state })}
              />
            </Span>
          </div>
          <div>
            Specialty:
            <Span
              editing={editing}
              placeholder="Specialty"
              value={this.state.specialty}
              onChange={specialty => onChange({ specialty })}
            >
              {supervisor.specialty || 'No specialty set...'}
            </Span>
          </div>
        </div>
    
        <ContactBox
          editing={editing}
          phone={phone}
          fax={fax}
          address={address}
          state={state}
          onChange={onChange}
        />
      </div>
    )
  }
}

export default ContactInfo;
