import React, { Component } from 'react';
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import cn from 'classnames'

import FontAwesome from 'react-fontawesome'

// Components
import {
  Overlay,
} from '../../../common'

import { logout } from '../../../../actions/auth'

import styles from './TitleBar.css'

class TitleBar extends Component {
  constructor(props) {
    super(props)
    this.state = this.initialState
  }

  get initialState() {
    return {
      showDropdown: false,
    }
  }

  renderDropdown() {
    const nameDisplay = "Welcome"
    const { showDropdown } = this.state
    const string = <span style={{ color: "#cd7f32" }}>Guest</span>
    return (
      <div
        className={cn("options", {show: showDropdown})}
        onClick={() => this.setState({showDropdown: !showDropdown})}
      >
        {/* Clickable Section */}
        <span>{nameDisplay}</span>
        <FontAwesome name="caret-down" />

        {/* Dropdown */}
        <div className={cn("dropdown", {show: showDropdown})}>
          <div className="options">
            <a href="https://www.muvor.com">
              Home
            </a>
          </div>
          <div className="options">
            <NavLink to="/">
              Login
            </NavLink>
          </div>
          <div className="options">
            <NavLink to="/chats/home">
              Messager
            </NavLink>
          </div>
          <div className="options">
            <NavLink to="/support">
              Support
            </NavLink>
          </div>
          <div className="options">
            <h5>
              <span>
                <FontAwesome name="star" />
                Muvor - {string}
              </span>
            </h5>
          </div>    
        </div>
      </div>
    )
  }

  render() {
    const { showDropdown } = this.state
    return (
      <div className={styles.titleBar}>
        {/* Dropdown */}
        {this.renderDropdown()}

        {/* Dropdown Overlay */}
        <Overlay
          active={showDropdown}
          onClick={() => this.setState({showDropdown: false})}
        />
      </div>
    )
  }
};

const mapStateToProps = props => {
  const {
    auth
  } = props

  const loading = auth.loading

  const { me } = auth
  const name = (me && me.name) || 'User'

  return {
    loading,
    name,
  }
}

const actions = {
  logout,
}

export default connect(mapStateToProps, actions)(TitleBar)
