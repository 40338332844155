import React, { Component } from "react";
import "./DashboardHR.css";
import { Route, Link, Redirect, Switch } from "react-router-dom";
import NavBar from "../NavBar/NavBar";
import Router from "./DashboardHR.router";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faChair,
  faBuilding,
  faUser,
  faUserTie,
  faRupeeSign,
  faFileAlt,
  faCity,
  faGlobeAmericas,
  faPlaceOfWorship,
  faArchway,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";
import HRdashboard from "../UserDashboards/HR/HRdashboard";

// function HRPortalF() {
//   return <HRPortal />;
// }
// function HRProjectBidF() {
//   return <HRProjectBid />;
// }

class DashboardHR extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      redirect: true,
      checked: true 
    }
  }
  handleChange=(checked)=> {
    console.log("switch");
    // var sidebarV = this.refs.sidebar;
    // var sidebarV = React.findDOMNode( this.refs.sidebar);
    // sidebarV.style.disply="none";
    
    if(this.state.checked==true){ 
       // document.getElementById("sidebar").setAttribute("style", "display:none")
      document.getElementById("sidebar").setAttribute("class", "display-none");
    }
    // document.getElementById("sidebar").setAttribute("style", "display:block");
    else{document.getElementById("sidebar").setAttribute("class", "display-block");}   
    this.setState({ checked });
  }

  render() {
    return (
        <div id="outer-main-div">
          <div id="outer-nav">
            {/* <NavBar loginInfo={this.props.data} /> */}
            <NavBar loginInfo={this.props.data} checked={this.state.checked} handleChange={this.handleChange} onLogout={this.props.onLogout}/>

          </div>

          <div id="main-non-nav">
            <div id="sidebar">
              <div id="sidebar-top-content" />
              <div id="main-title">
                <FontAwesomeIcon icon={faUserTie} className="sidebar-icon" />
                HR
              </div>
              <ul className="navbar-ul">
                <li>
                  <Link to="/hr/employee">
                    <FontAwesomeIcon icon={faUser} className="sidebar-icon" /> 
                    User 
                  </Link> 
                </li>
                <li>
                  <Link to="/hr/dashboard/agenda">
                    <FontAwesomeIcon icon={faCalendar} className="sidebar-icon" /> 
                    Agenda 
                  </Link> 
                </li>
                <li>
                  <Link to="/hr/dashboard/salary">
                    <FontAwesomeIcon icon={faRupeeSign} className="sidebar-icon" /> 
                    Salary 
                  </Link> 
                </li>
                <li>
                  <Link to="/hr/dashboard/leave-application-hr">
                    <FontAwesomeIcon icon={faFileAlt} className="sidebar-icon" /> 
                    Leave Application 
                  </Link> 
                </li>
                <li>
                  <Link to="/hr/dashboard/company">
                    <FontAwesomeIcon icon={faCity} className="sidebar-icon" /> 
                    company 
                  </Link> 
                </li>
                <li>
                  <Link to="/hr/dashboard/role">
                    <FontAwesomeIcon icon={faUsers} className="sidebar-icon" /> 
                    Role 
                  </Link> 
                </li>
                <li>
                  <Link to="/hr/dashboard/position">
                    <FontAwesomeIcon icon={faChair} className="sidebar-icon" /> 
                    Position 
                  </Link> 
                </li>
                <li>
                  <Link to="/hr/dashboard/department">
                    <FontAwesomeIcon
                      icon={faBuilding}
                      className="sidebar-icon"
                    /> 
                    Department 
                  </Link> 
                </li>
                <li>
                  <Link to="/hr/dashboard/country">
                    <FontAwesomeIcon icon={faGlobeAmericas} className="sidebar-icon" /> 
                    Country 
                  </Link> 
                </li>
                <li>
                  <Link to="/hr/dashboard/state">
                    <FontAwesomeIcon icon={faPlaceOfWorship} className="sidebar-icon" /> 
                    State 
                  </Link> 
                </li>
                <li>
                  <Link to="/hr/dashboard/city">
                    <FontAwesomeIcon icon={faArchway} className="sidebar-icon" /> 
                    City 
                  </Link> 
                </li>
                <li>
                 
                </li>
                {/* <li> <a href=""><FontAwesomeIcon icon={faChair} className="sidebar-icon"/> Position</a>   </li> */}
                {/* <li> <a href=""><FontAwesomeIcon icon={faBuilding} className="sidebar-icon"/> Department</a>   </li> */}
                {/* <li> <a href=""><FontAwesomeIcon icon={faDollarSign} className="sidebar-icon"/> Project Bidding</a>   </li> */}
                {/* <li> <a href=""><FontAwesomeIcon icon={faTasks} className="sidebar-icon"/> Portal Master</a>   </li> */}
              </ul>
            </div>
            {/* <div id="sidebar-top-content" /> */}
            <div id="main-area">
              <div id="sidebar-top-content" />
              { window.location=='http://localhost:3000/hr/dashboard' ? <HRdashboard /> : <Router /> }
            </div>
          </div>
        </div>
    );
  }
}
 
export default DashboardHR;
