import { timesheetTypes } from '../actions/types'

const {   
  CREATE_TIMESHEET,
  CREATE_TIMESHEET_FAILURE,
  CREATE_TIMESHEET_SUCCESS,
  GET,
  LIST,
  SET_TIMESHEET,
  GET_TIMESHEET,
  GET_TIMESHEET_FAILURE,
  GET_TIMESHEET_SUCCESS,
  // PATCH_TIMESHEET,
  // PATCH_TIMESHEET_FAILURE,
  // PATCH_TIMESHEET_SUCCESS,
  // CREATE_NOTE,
  // CREATE_NOTE_FAILURE,
  // CREATE_NOTE_SUCCESS,
  // CLEAR_ERRORS,
  // CLEAR_STATE
 } = timesheetTypes

const INITIAL_STATE = {
  timesheet: [],
  timesheetDisplay: [],
  error: '',
  loading: false,
}

export default (state = INITIAL_STATE, timesheet) => {
  const {type, payload} = timesheet
  switch (type) {
    case CREATE_TIMESHEET:
      return {
        ...state,
        loading: true,
      }
    case CREATE_TIMESHEET_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case CREATE_TIMESHEET_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      }
    // case SET_TIMESHEET:
    //   return {
    //     ...state,
    //     timesheet: payload,
    //   }
    case GET:
      return {
        ...state,
        timesheetDisplay: payload,
        loading: false,
      }
    case SET_TIMESHEET:
      return {
        ...state,
        timesheet: payload,
      }
    case GET_TIMESHEET:
      return {
        ...state,
        loading: true,
      }
    case GET_TIMESHEET_SUCCESS:
      return {
        ...state,
        timesheetDisplay: payload,
        loading: false,
      }
    case GET_TIMESHEET_FAILURE:
      return {
        ...state,
        timesheet: null,
        error: payload,
        loading: false,
      }
    // case PATCH_TIMESHEET:
    //   return {
    //     ...state,
    //     loading: true,
    //   }
    // case PATCH_TIMESHEET_SUCCESS:
    //   return {
    //     ...state,
    //     timesheet: payload,
    //     loading: false,
    //   }
    // case PATCH_TIMESHEET_FAILURE:
    //   return {
    //     ...state,
    //     error: payload,
    //     loading: false,
    //   }
    // case CREATE_NOTE:
    //   return {
    //     ...state,
    //     loading: true,
    //   }
    // case CREATE_NOTE_FAILURE:
    //   return {
    //     ...state,
    //     timesheet: payload,
    //     loading: false,
    //   }
    // case CREATE_NOTE_SUCCESS:
    //   return {
    //     ...state,
    //     error: payload,
    //     loading: false,
    //   }
    // case CLEAR_STATE:
    //   return {
    //     ...INITIAL_STATE,
    //   }
    // case CLEAR_ERRORS:
    //   return {
    //     ...state,
    //     error: '',
    //     loading: false,
    //   }
    default:
      return state
  }
}
