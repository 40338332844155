import React from 'react';
import {
  Route,
  Switch,
  Redirect
} from 'react-router-dom'

// Components
import DashboardAdmin from "./HrDashboard/admin/DashboardAdmin";
import DashboardHR from "./HrDashboard/hr/DashboardHR";
import DashboardEmployee from "./HrDashboard/Employee/DashboardEmployee";
import NotFound from '../NotFound/NotFound'


const HrDashboardRouter = props => (
  <Switch>
    <Route
      path="/hr/admin"
      component={DashboardAdmin}
    />

    <Route
      path="/hr/dashboard"
      component={DashboardHR}
    />

    <Route
      path="/hr/employee"
      component={DashboardEmployee}
    />  

    <Route
      component={NotFound}
    />
  </Switch>
)

export default HrDashboardRouter
