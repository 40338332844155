import React, { Component } from 'react';
import { connect } from 'react-redux'

import { roleTypeOptions, hasAuthTokenAsync } from '../../../../lib'

import {
  unformatDate,
} from '../../../../lib/dateHelper'

import {
  Selector,
  Button,
  Header,
  Input,
  Body,
  Form,
} from '../../../common'

// Actions
import {
  getContacts,
} from '../../../../actions/contacts'

import {
  createEmployee,
} from '../../../../actions/employees'

import styles from './AddEmpolyee.css'

class AddEmployee extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cardId: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      address: '',
      position: '',
      title: '',
      contact: '',
      primaryInsurance: '',
      secondaryInsurance: ''
  }
}

  componentDidMount() {
    hasAuthTokenAsync()
    .then(() => {
        this.props.getContacts()
      })
      .catch(console.log)
  }

  get initialState() {
    return {
      cardId: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      address: '',
      position: '',
      contact: '',
      primaryInsurance: '',
      secondaryInsurance: ''
    }
  }

  get invalid() {
    const {
      name,
      email,
      phone,
      // contact
    } = this.state

    return !(
      name
      && email
      && phone
      // && contact
    )
  }

  onSubmit(e) {
    e.preventDefault()
    if (this.invalid) {
      return
    }

    const company = sessionStorage.getItem("company") ? JSON.parse(sessionStorage.getItem("company")) : {}
    const sub = company[0].stripe_id

    const {
      name,
      email,
      phone,
      address,
      position,
      contact,
      primaryInsurance,
      secondaryInsurance
    } = this.state

    const data = {
      sub,
      name,
      email,
      phone,
      address,
      position,
      contact,
      primaryInsurance,
      secondaryInsurance
    }
    this.props.createEmployee(data)
  }

  render() {
    const {
      contacts,
    } = this.props

    const {
      cardId,
      name,
      email,
      phone,
      address,
      position,
      contact,
      primaryInsurance,
      secondaryInsurance
    } = this.state

    const contactOptions = [
      {
        key: '',
        value: '',
        display: 'Select Contact',
      },
      // ...contacts.map(contact => ({
      //   key: contact.email,
      //   value: contact.email,
      //   display: contact.email,
      // })),
    ]

    return (
      <div className={styles.body}>
        <Header>
          <h3>Add a New Employee</h3>
        </Header>
        <Body className={styles.body}>
          <Form
            className="form"
            onSubmit={this.onSubmit.bind(this)}
          >
            <br />

            <label>
              Employee Name:
            </label>
            <Input
              placeholder="First Last"
              value={name}
              onChange={name => this.setState({ name })}
            />

            <br />

            <label>
              Email:
            </label>
          <Input
            placeholder="Email"
            value={email}
            onChange={email => this.setState({ email })}
          />

          <br />

            <label>
              Phone Number:
            </label>
            <Input
              placeholder="Phone Number"
              value={phone}
              onChange={phone => this.setState({ phone })}
            />

            <br />

            
            <label>
            Address:
            </label>
            <Input
            placeholder="Address"
            value={address}
            onChange={address => this.setState({ address })}
            />
            
            <br />

            <label>
              Position:
            </label>
          <Input
            placeholder="What do you do?"
            value={position}
            onChange={position => this.setState({ position })}
          />

          <br />
            
          <label>
          Contact:
          </label>
            <Selector
              options={contactOptions}
              selected={contact}
              onSelect={contact => this.setState({ contact })}
            />

            <br />

            <label>
              Insurance:
            </label>            
            <Input
            placeholder="Primary Insurance"
            value={primaryInsurance}
            onChange={primaryInsurance => this.setState({ primaryInsurance })}
            />

            <label>
              Secondary Insurance:
            </label>
            <Input
            placeholder="Secondary Insurance"
            value={secondaryInsurance}
            onChange={secondaryInsurance => this.setState({ secondaryInsurance })}
            />

            <br />

            <div className="buttons">
              <Button
                large
                cancel
                title="CANCEL"
                link="/employees"
              />
              <Button
                large
                type="submit"
                title="CREATE EMPLOYEE"
                inactive={this.invalid}
                onClick={this.onSubmit.bind(this)}
              />
            </div>
          </Form>
        </Body>
      </div>
    );
  }
}

const mapStateToProps = ({ main }) => {
  const {
    loading,
    contacts,
  } = main

  return {
    loading,
    contacts,
  }
}

const actions = {
  getContacts,
  createEmployee,
}

export default connect(mapStateToProps, actions)(AddEmployee)