import Axios from 'axios';

// Router
import { push } from 'react-router-redux'

// Services
import { timesheets } from '../services'

import { timesheetTypes } from './types'

import * as cookies from 'js-cookie';

const {
  CREATE_TIMESHEET,
  CREATE_TIMESHEET_SUCCESS,
  CREATE_TIMESHEET_FAILURE,
  GET_TIMESHEET,
  GET_TIMESHEET_SUCCESS,
  GET_TIMESHEET_FAILURE,
  LIST,
  GET,
  SET_TIMESHEET
} = timesheetTypes


const me = cookies.get('me') ? JSON.parse(cookies.get('me')) : {}
const { id, company } = me
const uid=id,cid=company;
const apiUrl = '/api/users/all/timesheets';

const url = timesheetId => {
  let url = apiUrl;
  if (timesheetId) {
    url += `/${timesheetId}`;
  }

  return url;
};

export const listTimesheets = () => {
  return dispatch => {
    return Axios.get(url())
      .then(response => {
        dispatch(list(response.data));
        console.log('Timesheets retrieved.');
      })
      .catch(error => {
        console.log('Error attempting to retrieve timesheets.');
      });
  };
};

export const getTimesheet = () => dispatch => {
  dispatch({
    type: GET_TIMESHEET,
  })
  timesheets.getAll(cid,uid)
    .then(res => onGetTimesheetSuccess({dispatch, res}))
    .catch(err => onGetTimesheetFailure({dispatch, err}))
}

function onGetTimesheetSuccess({dispatch, res}) {
  console.log('getTime success');
  dispatch({
    type: GET_TIMESHEET_SUCCESS,
    payload: res.data,
  })
}

function onGetTimesheetFailure({dispatch, err}) {
  console.log('getTime failure', err);
  const payload = err.message || 'GetTime Failed'
  window.alert(payload)
  dispatch({
    type: GET_TIMESHEET_FAILURE,
    payload,
  })
  dispatch(push('/timesheets'))
}

export const updateTimesheet = timesheet => {
  return dispatch => {
    return Axios.put(url(timesheet._id), timesheet)
      .then(response => {
        dispatch(get(response.data));
        console.log(`Timesheet : ${timesheet._id}, updated.`);
        return true;
      })
      .catch(err => {
        console.log('There was an error updating timesheet.');
      });
  };
};

export const removeTimesheet = timesheet => {
  return dispatch => {
    timesheet.deleted = true;

    return Axios.put(url(timesheet._id), timesheet)
      .then(response => {
        dispatch(get(response.data));
        console.log(`Timesheet : ${response.data._id}, was deleted.`);
        return true;
      })
      .catch(err => {
        console.log('Error attempting to delete timesheet.');
      });
  };
};

export const restoreTimesheet = timesheet => {
  return dispatch => {
    timesheet.deleted = false;

    return Axios.put(url(timesheet._id), timesheet)
      .then(response => {
        dispatch(get(response.data));
        console.log(`Timesheet : ${response.data._id}, was restored.`);
        return true;
      })
      .catch(err => {
        console.log('Error attempting to restore timesheet.');
      });
  };
};

export const createTimesheet = data => (dispatch, getState) => {
  dispatch({
    type: CREATE_TIMESHEET,
  })
  timesheets.post(data)
    .then(res => onCreateTimesheetSuccess({dispatch, getState, res}))
    .catch(err => onCreateTimesheetFailure({dispatch, err}))
}

function onCreateTimesheetSuccess({dispatch, getState, res}) {
  console.log('createTimesheet success');
  const { timesheets } = getState()
  dispatch({
    type: CREATE_TIMESHEET_SUCCESS,
    payload: [res, timesheets]
  })
  dispatch(push('/reports/timesheet/timesheets'))
}

function onCreateTimesheetFailure({dispatch, err}) {
  console.log('createTimesheet failure', err);
  const payload = err.message || 'CreateTimesheet Failed'
  window.alert(payload)
  dispatch({
    type: CREATE_TIMESHEET_FAILURE,
    payload,
  })
}

export const list = timesheets => {
  return {
    type: LIST,
    timesheets: timesheets
  };
};

export const get = timesheet => {
  return {
    type: GET,
    timesheet: timesheet
  };
};

export const setTimesheet = payload => {
  sessionStorage.setItem('select_timesheet', JSON.stringify(payload))
  return {
    type: SET_TIMESHEET,
    payload
  }
}


export const assignTagToTimeEntryInput = (uid, tagName, tagColor) => {
  //assign tag to entry
//   promise.then(() => {
//     dispatch( timesheetTypes.TIME_ENTRY_INPUT__ASSIGN_TAG, {payload: {
//       tagId
//     }})
//   })
//   .catch(() => {
//     dispatch(actionFailed( timesheetTypes.TIME_ENTRY_INPUT__ASSIGN_TAG))  
//   })
// }

// dispatch(actionStart( timesheetTypes.TIME_ENTRY_INPUT__ASSIGN_TAG))

// assignProjectTime(dispatch, uid, tagName, tagColor, assignFn)
}

export const assignTagIdToTimeEntryInput = (uid, tagId) => {
// return dispatch => {
//   dispatch(timesheetTypes.TIME_ENTRY_INPUT__ASSIGN_TAG_ID, {payload: {
//     tagId
//   }})

//   const promise = ref('timeEntryInputs/' + uid).update({tag: tagId})

//   promise
//   .then(() => {      
//     dispatch(timesheetTypes.TIME_ENTRY_INPUT__ASSIGN_TAG_ID, {payload: {
//       tagId
//     }})
//   })
//   .catch(() => {
//     dispatch(timesheetTypes.TIME_ENTRY_INPUT__ASSIGN_TAG_ID)  
//   })

//   return promise
// }
}

export const assignTagToTimeEntry = (uid, entryId, tagName, tagColor) => {
return dispatch => {
dispatch
.then(() => {
  dispatch(timesheetTypes.TIME_ENTRIES__ASSIGN_TAG, {payload: {
    // tagId,
    entryId
  }})
})
.catch(() => {
  dispatch(timesheetTypes.TIME_ENTRIES__ASSIGN_TAG)
})

dispatch(timesheetTypes.TIME_ENTRIES__ASSIGN_TAG)

// assignProjectTime(dispatch, uid, tagName, tagColor, assignFn)
}
}

export const assignTagIdToTimeEntry = (uid, entryId, tagId) => {
return dispatch => {
dispatch(timesheetTypes.TIME_ENTRIES__ASSIGN_TAG_ID)
.then(() => {      
    dispatch(timesheetTypes.TIME_ENTRIES__ASSIGN_TAG_ID, {payload: {
      tagId,
      entryId
    }})
})
.catch(() => {
  dispatch(timesheetTypes.TIME_ENTRIES__ASSIGN_TAG_ID)  
})

return dispatch
}
}


// FIXME: This is for live timer 

// export const updateText = (uid, text) => {
//   return function(dispatch) {
//     dispatch(actionStart( timesheetTypes.TIME_ENTRY_INPUT__CHANGE_TEXT, {payload: {
//         text
//       }}))
//     timesheets.updateText(uid, text)
//     .then((data) => {
//       dispatch(actionSuccess( timesheetTypes.TIME_ENTRY_INPUT__CHANGE_TEXT, {payload: {
//         text
//       }}))
//     })
//     .catch(() => {
//       dispatch(actionFailed( timesheetTypes.TIME_ENTRY_INPUT__CHANGE_TEXT))  
//     })
//   }
// }

// export const updateTime = (uid, date) => {
//   return function(dispatch) {
//     dispatch(actionStart( timesheetTypes.TIME_ENTRY_INPUT__CHANGE_START_TIME, {'payload': {
//       startTime: date
//     }}))

//     timesheets.updateTime(uid, date)
//     .then((data) => {
//       dispatch(actionSuccess( timesheetTypes.TIME_ENTRY_INPUT__CHANGE_START_TIME, {payload: {
//         startTime: date
//       }}))
//     })
//     .catch(() => {
//       dispatch(actionFailed( timesheetTypes.TIME_ENTRY_INPUT__CHANGE_START_TIME))  
//     })
//   }
// }

// export const stop = (uid, text, date, tagId) => {
//   return function(dispatch) {
//     dispatch(actionStart( timesheetTypes.TIME_ENTRY_INPUT__STOP))
    
//     const now = new Date()
//     const data = {
//       text: text,
//       startTime: date,
//       endTime: now.getTime(),
//       tag: tagId
//     }

//     //create complete time entry
//     timesheets.timeStop(uid, data)
//     //delete current tracking entry
//     .then(() => {
//       timesheets.deleteById()
//       .then(function(){
//         dispatch(actionSuccess( timesheetTypes.TIME_ENTRY_INPUT__STOP))
//         dispatch(fetchList(uid))
//       })
//       .catch(function(){
//         dispatch(actionFailed( timesheetTypes.TIME_ENTRY_INPUT__STOP))
//       })
//     })
//     .catch(function(){
//       dispatch(actionFailed( timesheetTypes.TIME_ENTRY_INPUT__STOP))
//     })
//   }
// }

// //Pull tracking entry from server
// export const pull = (uid) => (dispatch, getState) => {
//   // check if a action has been set yet
//   // const { action } = getState().actions
//   // if (action && action.id !== actionId) {
//   //   // clear it if it is not the same one
//   //   console.log('clearing state');
//   //   dispatch({
//   //     type: timesheetTypes.CLEAR_STATE,
//   //   })
//   // }
//   dispatch({
//     type: timesheetTypes.TIME_ENTRY_INPUT__PULL
//   })
  
//   timesheets.getById(uid)
//   .then(res => onPullSuccess({dispatch, res}))
//   .catch(err => onPullFailure({dispatch, err}))
// }

// function onPullSuccess({dispatch, res}) {
//   console.log('getAction success');
//   const payload = {
//     text: res.text,
//     startTime: res.startTime
//   }
//   if (res.tag) {
//     payload.tagId = res.tag
//   }
//   dispatch({
//     type: timesheetTypes.TIME_ENTRY_INPUT__PULL,
//     payload
//   })
// }

// function onPullFailure({dispatch, err}) {
//   console.log('getAction failure', err);
//   const payload = err.message || 'GetAction Failed'
//   window.alert(payload)
//   dispatch({
//     type: timesheetTypes.TIME_ENTRY_INPUT__PULL,
//     payload: null,
//   })
//   dispatch(push('/reports/timesheet'))
// }

// export const start = (uid, text, date, tagId) => {
//   return function(dispatch) {
//     dispatch(actionStart( timesheetTypes.TIME_ENTRY_INPUT__START, {payload: {
//         text,
//         startTime: date,
//         tagId
//       }}))
    
//     const entryData = {
//       text,
//       startTime: date,
//     }
    
//     if (tagId) {
//       entryData.tag = tagId
//     }
    
//     timesheets.timeStart(uid, entryData)
//     .then((data) => {

//       if (tagId) {
//         dispatch(actionSuccess( timesheetTypes.TIME_ENTRY_INPUT__START, {payload: {
//           text,
//           startTime: date,
//           tagId
//         }}))
//       } else {
//         dispatch(actionSuccess( timesheetTypes.TIME_ENTRY_INPUT__START, {payload: {
//           text,
//           startTime: date
//         }}))
//       }

//     })
//     .catch(() => {
//       dispatch(actionFailed( timesheetTypes.TIME_ENTRY_INPUT__START))  
//     })
//   }
// }

// export const remove = (uid) => {
//   return function(dispatch) {
//     dispatch(actionStart( timesheetTypes.TIME_ENTRY_INPUT__REMOVE))
    
//     timesheets.deleteById(uid)
//     .then(function(){
//       dispatch(actionSuccess( timesheetTypes.TIME_ENTRY_INPUT__REMOVE))
//     })
//     .catch(function(){
//       dispatch(actionFailed( timesheetTypes.TIME_ENTRY_INPUT__REMOVE))
//     })
//   }
// }
