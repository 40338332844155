import React, { Component } from 'react';
import PricingChartContainer from './PricingChartContainer';
import logo from '../../assets/MuvorWithout.png';
import './Pricing.css';

class Pricing extends Component {
  render() {
    return (
      <div className="app">
        <div className="app-header">
          <a href="https://muvor.com"> 
            <img src={logo} className="app-logo" alt="logo" />
          </a>
        </div>
        <h3 className="strong">Welcome to Muvor</h3>
        <div className="component">
          <PricingChartContainer />
        </div>
      </div>
    );
  }
}

export default Pricing;
