import React, { Component } from 'react';
import { connect } from 'react-redux'
import * as cookies from 'js-cookie';

import { hasAuthTokenAsync } from '../../../../lib'

import {
  Selector,
  Button,
  Header,
  Body,
  Input,
  Form,
} from '../../../common'

// Actions
import {
  getReps,
  getEmployees
} from '../../../../actions/main'

import {
  getTeams
} from '../../../../actions/team'

import {
  getProjects
} from '../../../../actions/projects'

import {
  createSupervisor,
} from '../../../../actions/supervisors'

import styles from './AddSupervisor.css'

class AddSupervisor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      firstName: '',
      lastName: '',
      username: '',
      specialty: '',
      project: '',
      group: '',
      rep: '',
      me: cookies.get('me') && JSON.parse(cookies.get('me')) ? JSON.parse(cookies.get('me')) : undefined 
    }
  }

  componentDidMount() {
    hasAuthTokenAsync()
      .then(() => {
        this.props.getReps()
        this.props.getEmployees()
        this.props.getTeams()
        this.props.getProjects()
      })
      .catch(console.log)
  }

  onSubmit(e) {
    e.preventDefault()
    const {
      me,
      specialty,
      project,
      group,
      rep,
    } = this.state
    const data = {
      company: me.company,
      specialty,
      rep,
      project,
      group
    }
    this.props.createSupervisor(data)
  }

  render() {
    const {
      reps,
      projects,
      teams,
      employees
    } = this.props
    const {
      specialty,
      project,
      group,
      rep,
      me
    } = this.state

    const invalid = !rep

    const repOptions = [
      {
        key: '',
        value: '',
        display: 'Select Employee',
      },
      ...employees.map(e => ({
        key: e._id,
        value: e._id,
        display: e.name,
      })),
    ]
    console.log(teams)
    const teamOptions = [
      {
        key: '',
        value: '',
        display: 'Select a Team',
      },
      ...teams.map(t => ({
        key: t._id,
        value: t._id,
        display: t.name,
      })),
    ]
    
    const projectOptions = [
      {
        key: '',
        value: '',
        display: 'unassigned',
      },
      // ...projects.map(p => ({
      //   key: p._id,
      //   value: p._id,
      //   display: p.name,
      // })),
    ]
    return (
      <div>
        <Header>
          <h3>Add a New Supervisor</h3>
        </Header>
        <Body className={styles.body}>
          <Form
            className="form"
            onSubmit={this.onSubmit.bind(this)}
          >
          <br />

            <label>
              Employee
            </label>
            <Selector
              wide
              selected={rep}
              options={repOptions}
              onSelect={rep => this.setState({ rep })}
            />

            <br />

            <label>
              Team
            </label>
            <Selector
              wide
              selected={group}
              options={teamOptions}
              onSelect={group => this.setState({ group })}
            />
            
            <br />
{
            // <label>
            //   Project
            // </label>
            // <Selector
            //   wide
            //   selected={project}
            //   options={projectOptions}
            //   onSelect={project => this.setState({ project })}
            // />

            // <br />
  }
            <label>
              Notes:
            </label>
            <Input
              placeholder="notes..."
              value={specialty}
              onChange={specialty => this.setState({ specialty })}
            />

            <br />

            <div className="buttons">
              <Button
                large
                cancel
                type="button"
                title="CANCEL"
                link="/supervisors"
              />
              <Button
                large
                type="submit"
                title="CREATE SUPERVISOR"
                inactive={invalid}
              />
            </div>
          </Form>
        </Body>
      </div>
    );
  }
}

const mapStateToProps = ({ main, team, projects }) => {
  const {
    loading,
    reps,
    employees
  } = main

  const {
    teams
  } = team

  const {
    projectsDisplay
  } = projects


  return {
    loading,
    reps,
    employees,
    teams,
    projects: projectsDisplay
  }
}

const actions = {
  getReps,
  getEmployees,
  getTeams,
  getProjects,
  createSupervisor,
}

export default connect(mapStateToProps, actions)(AddSupervisor)
