import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom'

// Components
import InvoicePage from './InvoicePage/components/Invoice'
import Timesheet from './TimesheetPage/Timesheet'
import NotFound from '../../NotFound/NotFound'


const ReportsPageRouter = props => (
  <Switch>
    <Route
      path="/reports/invoice"
      component={InvoicePage}
    />
    
    <Route
      path="/reports/timesheet"
      component={Timesheet}
    />

    <Route
      component={NotFound}
    />
  </Switch>
)


export default ReportsPageRouter
