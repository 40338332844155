import React, { Component } from 'react';
import Router from './DashboardPage.Router'

import styles from './DashboardPage.css'

class DashboardPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedRepId: '',
      visitModal: null,
      rep: null,
      day: null,
      sideBarFlipped: false,
    }
  }
  render() {
    return (
      <div className={styles.app}>
        <Router />
      </div>
    );
  }
}

export default DashboardPage;
