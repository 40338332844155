import React, { Component } from "react";
import "./ScientificCalculator.css";
import ErrorBoundary from "./component/ErrorBoundaryComponent";
import Calculator from "./component/Calculator";

class ScientificCalculator extends Component {
  render() {
    return (
      <div className="App container">
          <Calculator />
      </div>
    );
  }
}

export default ScientificCalculator;
