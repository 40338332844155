/* eslint-disable no-undef */
/* eslint-disable no-new-object */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import parse from 'html-react-parser';

import {  
  getAccounts,
  getAccountById,
  createAccount,
  fundAccount
} from '../../../actions/accounts';
import { SwitchTable } from '..';
import { 
  Button, 
  FormModal, 
  Input, 
  Selector, 
  Span 
} from '../../common';
import {
  formatCurrencyOptions
} from '../../../lib';
import styles from './accountsModal.css';

class AccountsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentMethod: [],
      change: 0.00,
      price: 0.00,
      payment: 0.00,
      type: 'cash',
      card: 0,
      exp: '',
      cvc: 0,
      country: 'US',
      currency: 'usd',
      accountHolder: '',
      accountType: 'individual',
      routingNumber: '',
      accountNumber: '',
      accountName: '',
      amount: 0.00,
      description: ''
    }
    this.rgx = /card_([0-9a-z]+)/ || /pm_([0-9a-z]+)/ || /src_([0-9a-z]+)/ || /tok_([0-9a-z]+)/
  }

  get options() {
    return [
      {
        value: '',
        display: 'Select',
      },
      {
        value: 'card',
        display: 'Add New Card',
      },
      {
        value: 'bank',
        display: 'Add New Bank Account',
      },
    ]
  }

  get inactive() {
    const {
      type,
      card,
      exp,
      cvc,
      country,
      currency,
      accountHolder,
      accountType,
      routingNumber,
      accountNumber
    } = this.state

    return type === 'card' && !(
      card
      &&exp
      &&cvc
    ) || type === 'bank' && !(
      country
      &&currency
      &&accountHolder
      &&accountType
      &&routingNumber
      &&accountNumber
    )
  }

  componentDidMount() {
    this.props.getAccountById()
  }

  submit(e) {
    e.preventDefault()
    const {
      type,
      card,
      exp,
      cvc,
      country,
      currency,
      accountHolder,
      accountType,
      routingNumber,
      accountNumber,
      accountName,
      description,
      amount,
    } = this.state
    const {
      me
    } = this.props
    const {
      contact,
      account,
      mvrxt,
    } = me
    if (type==='card') {
      const data = {
        accountName,
        type,
        paymentMethod: {
          number: card,
          exp_month: exp.split('/')[0],
          exp_year: exp.split('/')[1],
          cvc
        }
      }
      this.props.createAccount(data)
      this.props.onClickAway()
    }
    if (type==='bank') {
      const data = {
        accountName,
        type,
        paymentMethod: {
          country,
          currency,
          accountHolder,
          accountType,
          routingNumber,
          accountNumber
        }
      }
      this.props.createAccount(data)
      this.props.onClickAway()
    }
    if (this.rgx.test(type)) {
      const data = {
        amount,
        account,
        description,
        source: type,
        contact,
        mvrxt
      }
      this.props.fundAccount(data)
    }
  }

  renderNewCard() {
    const {
      card,
      exp,
      cvc,
      accountName
    } = this.state
    const {
      onClickAway
    } = this.props
    return (
      <div>
        <label>
          Account Name:
        </label>
        <Input
          placeholder="Card 1"
          value={accountName}
          onChange={accountName => this.setState({ accountName })}
        />
        <br />

        <label>
          Card details
        </label>
        <Input
          placeholder="XXXX XXXX XXXX XXXX"
          value={card}
          onChange={card => this.setState({ card })}
          maxlength="16"
        />

        <br />
        <label>
          Expiration date
        </label>
        <Input
          placeholder="MM/YYYY"
          value={exp}
          onChange={exp => this.setState({ exp })}
          maxlength="7"
        />

        <br />
        <label>
          CVC
        </label>
        <Input
          placeholder="123"
          value={cvc}
          onChange={cvc => this.setState({ cvc })}
          maxlength="3"
        />
        <br />
        <div className="buttons">
          <Button
            large
            cancel
            type="button"
            title="Cancel"
            onClick={onClickAway}
          />
          <Button
            large
            title="Add Card"
            inactive={this.inactive}
            onClick={this.submit.bind(this)}
          />
          <br />
        </div>
      </div> 
    )
  }

  renderNewBank() {
    const {
      onClickAway
    } = this.props
    const {
      country,
      currency,
      accountHolder,
      accountType,
      routingNumber,
      accountNumber,
      accountName
    } = this.state
    return (
      <div>
        <label>
          Account Name:
        </label>
        <Input
          placeholder="Bank Account 1"
          value={accountName}
          onChange={accountName => this.setState({ accountName })}
        />
        <br />
        <label>
          Account Holder Name:
        </label>
        <Input
          placeholder="Full Name"
          value={accountHolder}
          onChange={accountHolder => this.setState({ accountHolder })}
        />

        <br />

        <label>
          Routing Number:
        </label>
        <Input
          placeholder="9 digits"
          value={routingNumber}
          onChange={routingNumber => this.setState({ routingNumber })}
          maxlength="9"
        />

        <br />
        <label>
          Account Number:
        </label>
        <Input
          placeholder="account #"
          value={accountNumber}
          onChange={accountNumber => this.setState({ accountNumber })}
          maxlength="20"
        />
        <br />
        <div className="buttons">
          <Button
            large
            cancel
            type="button"
            title="Cancel"
            onClick={onClickAway}
          />
          <Button
            large
            title="Add Bank"
            inactive={this.inactive}
            onClick={this.submit.bind(this)}
          />
          <br />
        </div>
      </div>
    )
  }

  renderReceipt() {
    const {
      receipt,
      onClickAway
    } = this.props
    const {
      account,
      details,
      items,
      description,
      amount,
    } = receipt
    const price = formatCurrencyOptions(amount)
    return (
      <div>
        <h3>{account}</h3>
        <p>{description}</p>
        <Span>{items}</Span>
        <Span>{details}</Span>
        <h4>{price}</h4>
        <br />
        <div className="buttons">
          <Button
            large
            cancel
            type="button"
            title="Close"
            onClick={onClickAway}
          />
        </div>
      </div>
    )
  }

  renderOptions() {
    const {
      type
    } = this.state
    const {
      receipt
    } = this.props
    console.log(type)
    const options = this.props.account.map(a => this.options.concat(a.paymentMethod))[0]
    return (
      <div>
        <Selector
          wide
          value={this.state.type}
          onSelect={i => this.setState({ type: i })}
          options={options ? options : this.options}
        />

        {type==='card' && this.renderNewCard()}
        {type==='bank' && this.renderNewBank()}
        {this.rgx.test(type) && this.renderFundingForm()}
        {!!receipt && this.renderReceipt()}
      </div>
    )
  }

  renderFundingForm() {
    const {
      onClickAway
    } = this.props
    const {
      amount
    } = this.state
    // const funds = formatCurrencyOptions(toString(amount))
    // console.log(funds)
    return (
      <div>
        <br />
        <label>
          Amount
        </label>
        <Input
          placeholder="123"
          type="number"
          value={amount}
          onChange={amount => this.setState({ amount })}
        />
        <br />
        <p>+6% Gas</p>
        <br />
        <div className="buttons">
          <Button
            large
            cancel
            type="button"
            title="Cancel"
            onClick={onClickAway}
          />
          <Button
            large
            title="Add Funds"
            inactive={this.inactive}
            onClick={this.submit.bind(this)}
          />
          <br />
        </div>
      </div>
    )
  }

  render() {
    const { onClickAway, content } = this.props
    return (
      <FormModal
        onClickAway={onClickAway}
        visible={!!content}
        className={styles.modal}
      >
        {this.props.isme && this.renderOptions()}
      </FormModal>
    )
  }
}

const mapStateToProps = ({ accounts }) => {
  const {
    accountsDisplay,
    account,
    receipt
  } = accounts

  return {
    accounts: accountsDisplay,
    account,
    receipt
  }
}

const actions = {
  getAccounts,
  getAccountById,
  createAccount,
  fundAccount
}

export default connect(mapStateToProps, actions)(AccountsModal);
