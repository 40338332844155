import { api } from '../config'

export default {
  getSignedUrl() {
    const url =  '/photo'
    return api.get(url)
  },
  getAll() {
    const url = '/server'
    return api.get(url)
  },

  post(type, data) {
    const url = '/import'
    const datum = {
      type,
      data
    }
    return api.post(url, datum)
  }
}
