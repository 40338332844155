import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'

import styles from './CalcNav.css'

class CalcNav extends Component {
    constructor(props, ...rest) {
        super(props, ...rest);
        this.state = {};
    }

    render() {
        return (
        <div className={styles.calcNav}>

        <NavLink
          to="/calculator/simple">
          <b>
            Simple
          </b>
        </NavLink>


        <NavLink
          to="/calculator/scientific">
          <b>
            Scientific
          </b>  
        </NavLink>

    {
        //     isAdmin && (
        //   <NavLink
        //     to="/team">
        //    Team
        //   </NavLink>
        // )
    }
      </div>
        );
    }
}

CalcNav.propTypes = {};

export default CalcNav;