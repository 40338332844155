import React, { Component } from 'react';
import { connect } from 'react-redux'
import validator from 'validator'

import {
  Selector,
  Button,
  Header,
  Body,
  Input,
  Form,
  FormModal,
  FileUpload,
  Icon,
} from '../../../common'

// Actions
import {
  createTeamMember,
} from '../../../../actions/team'
import { Import } from '../../../../actions/editor';


import styles from './ImportModal.css'
import { s3documents } from '../../../../lib/s3';

function generateAccessCode(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  // result = result.match(/.{1,5}/g).join('-')
  return result;
}

class ImportModal extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      import: [],
      type: ''
     }
  }

  get inactive() {
    const rep = this.state.import > 0 ? this.state.import : 0
    return !rep 
  }

  get options() {
    return [
      {
        value: '',
        display: 'Select',
      },
      {
        value: 'contacts',
        display: 'Contacts',
      },
      {
        value: 'products',
        display: 'Products',
      },
      {
        value: 'employees',
        display: 'Employees',
      },
      {
        value: 'expenses',
        display: 'Expenses',
      },
      {
        value: 'photos',
        display: 'Photos',
      },
      {
        value: 'timesheet',
        display: 'Timesheet',
      },
      {
        value: 'calendar',
        display: 'Calendar',
      },
      {
        value: 'documents',
        display: 'Documents',
      },
      {
        value: 'reports',
        display: 'Reports',
      },
      {
        value: 'sales',
        display: 'Sales',
      },
    ]
  }

  onSubmit(e) {
    e.preventDefault()
    const {
      type
    } = this.state
    const name = generateAccessCode(10)
    this.state.import.map(i => {
      s3documents(i, name)
      .then(link => {
        console.log(link)
        const data = {
          name,
          type,
          file: i.type
        }
        this.props.Import(data, link)
      })
    })
  }
  
  renderPhotoItem(photo) {
    return (
      <div
        key={photo._id}
        className="photo"
      >
        <span className="photo">
          {photo.name}
        </span>
        <Icon
          small
          button
          cancel
          onClick={() => this.props.removePhoto(photo)}
        />
      </div>
    )
  }

  render() {
    const {
      content,
      onClickAway,
      loading,
      progress,
      onSubmit,
      type
    } = this.props

    this.state.import.map(i => {console.log(i)})

    return (
      <FormModal
        onClickAway={onClickAway}
        visible={!!content}
        className={styles.modal}
        onSubmit={this.onSubmit.bind(this)}
      >

      <Selector
        value={this.state.type}
        onSelect={i => this.setState({ type: i })}
        options={this.options}
      />

        <div className={styles.photoList}>
        {
          this.state.import.length ? (
          this.state.import.map(this.renderPhotoItem.bind(this))
          ) : (
            <span>
              None
            </span>
            )
          }
          </div>
          <FileUpload
            id="add-import"
            large
            confirm
            title="Select Files"
            accept={'text/plain,text/csv,application/word,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/json,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheet.sheet'}
            inactive={loading}
            onUpload={i => {var a=this.state.import.slice();a.push(i);this.setState({import:a})}}
            progress={progress}
          />
          <div className="buttons">
            <Button
              large
              cancel
              type="button"
              title="Cancel"
              onClick={onClickAway}
            />
            <Button
              large
              type="submit"
              title="Import"
            />
          </div>
        </FormModal>
    )
  }
}
 
const mapStateToProps = ({ main }) => {
  const {
    loading,
    progress
  } = main

  return {
    loading,
    progress
  }
}

const actions = {
  Import
}

export default connect(mapStateToProps, actions)(ImportModal)
