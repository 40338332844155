import React, { Component } from 'react';
import styles from '../Home.css';

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {  }
  }
  render() { 
    return ( 
      <div id='about' className={styles.app}>
        <div className='about-text'>
          <h2>About Us</h2>
          <h5><strong>Universal Business Consensus</strong></h5>
          <p>{this.props.data ? this.props.data.paragraph : 'loading...'}</p>
        </div>
        <h3>Why Choose Us?</h3>
        <div className='list-style'>
          <div className="why">
            <ul>
              {this.props.data
                ? this.props.data.Why.map((d, i) => (
                    <li key={`${d}-${i}`}>{d}</li>
                  ))
                : 'loading'}
            </ul>
          </div>
          <div className="why2">
            <ul>
              {this.props.data
                ? this.props.data.Why2.map((d, i) => (
                    <li key={`${d}-${i}`}> {d}</li>
                  ))
                : 'loading'}
            </ul>
          </div>
        </div>
      </div>
     );
  }
}
 
export default About;