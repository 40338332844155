import { FloatBox, Link, Input, Button } from '../../../common'
import { UTILS }  from '../../../../lib'
import { stripes } from '../../../../services'

import { getPlan } from '../../../../actions/stripe'

import React, {Component} from 'react';
import { connect } from 'react-redux'

import {loadStripe} from '@stripe/stripe-js';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe,
  StripeProvider,
  Elements
} from 'react-stripe-elements';

import * as cookies from 'js-cookie'
import * as jwt from 'jsonwebtoken'
import axios from 'axios'

// Styles
import styles from './StripeMain.css'

// const stripePromise = loadStripe();
class _SplitFieldsForm extends Component {
  state = {
    errorMessage: '',
    cardDetails: {
      complete: false
    },
    selectedPlan: [],
    quantity: 1,
    load: false

  };

  componentDidMount() {
    sessionStorage.getItem('quantity') === undefined 
    || sessionStorage.getItem('quantity') === null 
    || sessionStorage.getItem('quantity') === 0 
    && sessionStorage.setItem('quantity', '1')
  }

  onChange = (quantity) => {
    if ( quantity > 0) {
      this.setState({ quantity })
      sessionStorage.setItem('quantity', `${quantity}`)
    }
  }

  handleChange = (e) => {
    console.log(this.state.cardDetails)
    console.log(e)
    this.setState({
      cardDetails: {
        ...this.state.cardDetails,
        [e.elementType]: e.complete ? e.value : '',
        [e.brand]: e.complete
      },
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { 
      quantity, 
    } = this.state
    const {
      selectedPlan
    } = this.props
    this.setState({ load: true })
    if (this.props.stripe) {
      this.props.stripe.createToken()
      .then(token => { 
        const data = {
          amount: selectedPlan.plan.amount_decimal,
          items: selectedPlan.plan.id,
          source: token.id,
          quantity: parseInt(sessionStorage.getItem('quantity')),
          token
        }
        stripes.charge(data)
         .then(res => {
          const { data } = res
          // var receiptUrl = subscription.items
          // var receiptUrl = 'some url'
          // console.log(data)
          sessionStorage.setItem('stripe_id', JSON.stringify(res))
          // document.getElementsByClassName(checkoutForm).styles.display = 'none'
          // const regex = /https?:\/\/(?:.*\.)*(.+\..+?)\//
          // const root = window.location.pathname.replace(regex, '\\$&')
          this.setState({ load: false })
          window.location.pathname = '/login/register/registery'
          window.alert(`Payment Successful! \n\n No. of users: ${parseInt(sessionStorage.getItem('quantity'))} \n\n Click next to continue`)
        }).catch((err) => {window.alert(`${err}: FAILED. \n\n No. of users: ${parseInt(sessionStorage.getItem('quantity'))} \n\n Click next to continue`);window.location.reload();})
      }).catch((err) => {window.alert(`${err}: Payment Declined. \n\n No. of users: ${parseInt(sessionStorage.getItem('quantity'))} \n\n Click next to continue`);window.location.reload();})
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };

  handleError = ({error}) => {
    if (error) {
      this.setState({errorMessage: error.message});
    }
  };

  get invalid() {
    const {
      loading
    } = this.props
    const {
      cardDetails,
      load
    } = this.state

    return !(
    !loading
    && cardDetails.undefined
    && !load
    )
  }
  
  render() {
    return (
      // sessionStorage.getItem('stripe_id') ?
        <div className={styles.checkoutForm}>
          <form>
            <label>
              Card details
              <CardNumberElement onChange={this.handleChange} />
            </label>
            <label>
              Expiration date
              <CardExpiryElement onChange={this.handleChange} />
            </label>
            <label>
              CVC
              <CardCVCElement onChange={this.handleChange} />
            </label>
          </form>
          <div className={styles.ua}>
            <h5>Users: </h5><Input className={styles.users} type='number' value={this.state.quantity} placeholder='1' onChange={this.onChange}/>
            <h5>Amount: {UTILS.formatUSD(this.props.selectedPlan.plan.amount * this.state.quantity)}</h5>
          </div>
          <div className={styles.split}>
            <Button
              className={styles.b1}
              title="PREV"
              link={'/login/register'}
            />
          <Button
              className={styles.b2}
              title="SUBSCRIBE"
              onClick={this.handleSubmit}
              inactive={this.invalid}
              // link={'/login/register/registery'}
            />
          </div>
        </div>
    //     :
    //     <div className={styles.checkoutForm}>
    //       <h2>Payment Successful!</h2>
    //       <a>View Receipt</a>
    //       <Link to="/">Home</Link>
    //     </div>
    );
  }
}

const SplitFieldsForm = injectStripe(_SplitFieldsForm);

class StripeMain extends Component {
  constructor(props){
    super(props)
    this.state = {
      quantity: 1,
      load: false
    }
  }

  componentDidMount() {
    sessionStorage.getItem('quantity') === undefined 
    || sessionStorage.getItem('quantity') === null 
    || sessionStorage.getItem('quantity') === 0 
    && sessionStorage.setItem('quantity', '1')
  }

  onChange = (quantity) => {
    if ( quantity > 0) {
      this.setState({ quantity })
      sessionStorage.setItem('quantity', `${quantity}`)
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { 
      quantity, 
    } = this.state
    const {
      selectedPlan
    } = this.props
    this.setState({ load: true })
    if (this.props.stripe) {
      this.props.stripe.createToken()
      .then(token => { 
        const data = {
          amount: selectedPlan.plan.amount_decimal,
          items: selectedPlan.plan.id,
          source: token.id,
          quantity: parseInt(sessionStorage.getItem('quantity')),
          token
        }
        stripes.charge(data)
         .then(res => {
          const { data } = res
          // var receiptUrl = subscription.items
          // var receiptUrl = 'some url'
          console.log(data)
          cookies.set('stripe_id', res)
          // document.getElementsByClassName(checkoutForm).styles.display = 'none'
          // const regex = /https?:\/\/(?:.*\.)*(.+\..+?)\//
          // const root = window.location.pathname.replace(regex, '\\$&')
          this.setState({ load: false })
          window.location.pathname = '/login/register/registery'
          window.alert(`Payment Successful! \n\n No. of users: ${parseInt(sessionStorage.getItem('quantity'))} \n\n Click next to continue`)
        })
      }).catch((err) => {window.alert(`${err}: Payment Declined. \n\n No. of users: ${parseInt(sessionStorage.getItem('quantity'))} \n\n Click next to continue`)})
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };
  
  render() {
    const { history } = this.props
    let plan = sessionStorage.getItem('plan')
    const selectedPlan = jwt.verify(plan, 'W3<34!!_M_^09_H4Q!7%!', { algorithm: 'HS512' })
    return (
      <div className={styles.container}>
        <FloatBox className={styles.fb}>
        {
          // <StripeProvider apiKey="pk_test_zg1kvZwcfPwJc35UXpGr6xMy00Rpleq9Ll">
        }
          <StripeProvider apiKey="pk_live_HVSDLMk8WKyXJpbT1GTTKefb00YvpebVqb"> 
            <Elements>
              <SplitFieldsForm selectedPlan={selectedPlan} history={history} handleResult={this.props.handleResult} />
            </Elements>
          </StripeProvider>
        </FloatBox>
      </div>
    );
  }
}

const mapStateToProps = ({ stripe }) => {
  const {
    stripes,
    loading,
    stripeDisplay,
    selectedPlan,
    error
  } = stripe

  return {
    stripes,
    loading,
    stripe: stripeDisplay,
    selectedPlan,
    error
  }
}

const actions = {
  getPlan
}

export default connect(mapStateToProps, actions)(StripeMain)