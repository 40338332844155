import React, { Component } from 'react';
import { connect } from 'react-redux'
import {
  AsYouType,
  parseNumber,
  formatNumber,
} from '../../../../lib/phoneHelper'

import {
  unformatDate,
} from '../../../../lib/dateHelper'

import { hasAuthTokenAsync } from '../../../../lib'

// Components
import {
  SwitchTable,
  AddressModal,
  AddressLine,
} from '../../../shared'

import {
  Input,
  Icon,
  Header,
  Body,
  Span,
} from '../../../common'

import NotesTab from './Tabs/NotesTab'
import ProductsTab from './Tabs/ProductsTab'
import InsuranceTab from './Tabs/InsuranceTab'

// Actions
import {
  getContactById,
  patchContactById,
  deleteContactById
} from '../../../../actions/contacts'

import styles from './ContactView.css'

class ContactView extends Component {
  constructor(props) {
    super(props)
    this.contact = sessionStorage.getItem('select_contact') ? JSON.parse(sessionStorage.getItem('select_contact')) : null
    this.tabOptions = [
      {
        value: 'contacts',
        display: 'Products',
        renderComponent: () => this.renderProductsTab(),
      },
      // {
      //   value: 'insurance',
      //   display: 'Medical Insurance',
      //   renderComponent: () => this.renderInsuranceTab(),
      // },
      // {
      //   value: 'notes',
      //   display: 'More Info/Notes',
      //   renderComponent: () => this.renderNotesTab(),
      // },
    ]

    this.state = {
      tab: this.tabOptions[0],
      ...this.initialState,
    }
  }

  get initialState() {
    const { warning } = this.props
    const {
      rep,
      name,
      email,
      link,
      position,
      phone,
      address,
      photo,
      description,
      primaryInsurance,
      secondaryInsurance
    } = this.contact

    const contactInfoState = {
      rep,
      name,
      link,
      email,
      position,
      phone,
      address,
      photo,
      description,
      primaryInsurance,
      secondaryInsurance
    }

    const notesState = {
      warning,
    }

    const insuranceState = {
      primaryInsurance: primaryInsurance || {},
      secondaryInsurance: secondaryInsurance || {},
    }
    
    const addressState = {
      address,
      addressModal: null,
    }


    return {
      editing: false,
      ...contactInfoState,
      ...notesState,
      ...insuranceState,
      ...addressState,
    }
  }

  componentDidMount() {
    console.log(this.props)
    hasAuthTokenAsync()
      .then(() => {
        // const { contactId } = this.props.match.params
        // this.props.getContactById(contactId)
      })
      .catch(console.log)
  }

  setEditState(editing) {
    this.setState({ ...this.initialState, editing })
  }

  closeModal() {
    this.setState({
      noteModal: null,
      addressModal: null,
    })
  }

  handlePhoneChange(val) {
    if (val.length > 20) {
      return
    }

    const phone = new AsYouType('US').input(val)

    this.setState({ phone })
  }

  insuranceHasFields(data) {
    return (
      data.plan
      || data.bin
      || data.planId
      || data.pcn
      || data.type
    )
  }

  save() {
    const id = this.contact._id
    const company = this.contact.company
    const {
      rep,
      name,
      link,
      email,
      position,
      phone,
      address,
      photo,
      description,
      primaryInsurance,
      secondaryInsurance
    } = this.state


    const update = {
      id,
      company,
      rep,
      name,
      link,
      email,
      position,
      phone,
      address,
      photo,
      description,
      primaryInsurance,
      secondaryInsurance
    }

    if (this.insuranceHasFields(primaryInsurance)) {
      update.primaryInsurance = primaryInsurance
    }

    if (this.insuranceHasFields(secondaryInsurance)) {
      update.secondaryInsurance = secondaryInsurance
    }

    this.props.patchContactById(id, update)

    this.setEditState(false)
  }

  delete() {
    const message = `Are you sure you want to delete this contact?`
    const confirmed = window.confirm(message)
    if (!confirmed) {
      return
    }
    const id = this.contact._id
    this.props.deleteContactById(id)
  }

  renderContactInfo() {
    const {
      editing
    } = this.state
    const {
      rep,
      name,
      link,
      email,
      phone,
      address,
      description,
      primaryInsurance,
      secondaryInsurance
    } = this.contact

    return (
      <div className={styles.contactInfo}>
        <div>
          <Span
            editing={editing}
            type="text"
            placeholder={rep}
            value={this.state.rep}
            onChange={rep => this.setState({ rep })}
             >
            {rep || 'No ID set...'}
          </Span>
        </div>
        <div className={styles.names}>
        {editing ? (
          <div className="name">
            Name:
            <Input
              placeholder="Name"
              value={this.state.name}
              onChange={name => this.setState({ name })}
            />
          </div>
        ) : (
          <div>
            Name: {name}
          </div>
        )}
        <div>
        {editing ? (
          <div className="email">
          Email:
            <Input
              placeholder="Email Address"
              value={this.state.email}
              onChange={email => this.setState({ email })}
              />
          </div>
          ) : (
            <div>
              Email: {email}
            </div>
          )}
          </div>
          <div className={styles.space}></div>
          <div className={styles.rests}>
            <div>
            Phone Number:
            <Span
              editing={editing}
              value={this.state.phone}
              placeholder={phone}
              onChange={this.handlePhoneChange.bind(this)}
            >
              {phone || 'No Phone...'}
            </Span>
          </div>
          <div>
          Address:
          <AddressLine
            editing={editing}
            state={this.state}
            address={this.state.address}
            onChange={newState => this.setState(newState)}
          />
        </div>
        {editing ? (
            <div className="position">
            Description:
              <Input
                placeholder="Position..."
                value={this.state.description}
                onChange={description => this.setState({ description })}
                />
            </div>
            ) : (
              <div>
                Description: {description}
              </div>
            )}
          <div className="link">
            Link:
            <Span
              editing={editing}
              type="text"
              value={this.state.link}
              placeholder={link}
              onChange={link => this.setState({ link })}
              >{link}</Span>
          </div>
        </div>
        <div className="insurance">
          <Span
            type="text"
            label="Primary Insurance"
            editing={editing}
            value={this.state.primaryInsurance}
            placeholder="Primary Insurance"
            onChange={primaryInsurance => this.setState({ primaryInsurance })}
          >
            {primaryInsurance || 'None'}
          </Span>
          <Span
            label="Secondary Insurance"
            type="text"
            editing={editing}
            value={this.state.secondaryInsurance}
            onChange={secondaryInsurance => this.setState({ secondaryInsurance })}
          >
            {secondaryInsurance || 'None'}
          </Span>
        </div>
      </div>
    </div>
    )
  }

  renderSwitchTable() {
    const { tab } = this.state
    return (
      <SwitchTable
        tabs={this.tabOptions}
        selected={tab}
        onClick={tab => this.setState({ tab })}
      />
    )
  }

  renderProductsTab() {
    return (
      <ProductsTab
        className={styles.contactsTab}
      />
    )
  }

  renderInsuranceTab() {
    return (
      <InsuranceTab
        className={styles.insuranceTab}
        state={this.state}
        contact={this.props.contact}
        setState={this.setState.bind(this)}
      />
    )
  }

  renderNotesTab() {
    return (
      <NotesTab
        className={styles.notesTab}
        state={this.state}
        contact={this.props.contact}
        setState={this.setState.bind(this)}
        onCreateNote={this.props.createNote}
        onCloseModal={() => this.closeModal()}
      />
    )
  }

  render() {
    const { name } = this.contact
    if (!name) {
      return null
    }

    const {
      editing,
      addressModal,
    } = this.state

    return (
      <div>
        <Header className={styles.header}>
          <h2>
            {name}
            {!editing ? (
              <div>
                <Icon
                  edit
                  onClick={() => this.setEditState(true)}
                />
                <Icon
                  cancel
                  onClick={() => this.delete()}
                />
              </div>
            ) : (
              <div>
                <Icon
                  cancel
                  onClick={() => this.setEditState(false)}
                />
                <Icon
                  save
                  onClick={() => this.save()}
                />
              </div>
            )}
          </h2>
        </Header>
        <Body className={styles.body}>

          {this.renderContactInfo()}

          <div className="switch-buffer" />

          {this.renderSwitchTable()}
        </Body>

        {/* Address Modal */}
        <AddressModal
          content={addressModal}
          onSubmit={address => this.setState({ address })}
          onClickAway={() => this.closeModal()}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ auth, contacts }) => {
  const {
    contact,
    error,
  } = contacts

  const {
    isAdmin,
  } = auth

  return {
    contact: contact || {},
    error,
    isAdmin,
  }
}

const actions = {
  patchContactById,
  getContactById,
  deleteContactById
}

export default connect(mapStateToProps, actions)(ContactView);
