import React, { Component } from 'react';
import { connect } from 'react-redux'

// Components
import {
  Table,
} from '../../../common'

import ProductRow from './ProductRow/ProductRow'

import { hasAuthTokenAsync } from '../../../../lib'

import styles from './ProductTable.css'

import * as cookies from 'js-cookie';

class ProductTable extends Component {

  renderTableHead() {
    return (
      <thead>
        <tr>{
          // <th>
          //   Length
          // </th>
          // <th>
          //   Size
          // </th>
          // <th>
          //   Color
          // </th>
          // <th>
          //   Gender
          // </th>
        }
          <th/>
          <th>
            Product
          </th>
          <th>
            Cost
          </th>
          <th>
            Barcode
          </th>
          <th>
            Quantity
          </th>
          <th>
            Notes
          </th>
          <th/>
        </tr>
      </thead>
    )
  }

  renderTableBody() {
    const {
      products
    } = this.props
    // .filter(
    //   product => {
    //     return product.searchType.indexOf(this.state.search) !== -1
    //   }
    // )
    if (!Array.isArray(products)) {
      return (
        <tbody>
          <tr>
            <td colSpan="9" className="empty">
              No Receipts Found
            </td>
          </tr>
        </tbody>
      )
    } else {
      return (
        <tbody>
          {products.map(p => (
            <ProductRow
              key={p._id}
              product={p}
              // checked={checkedAssignments.includes(assignment.id)}
            />
          ))}
        </tbody>
      )
    }
  }

  render() {
    return (
      <Table className={styles.table}>
        {this.renderTableHead()}
        {this.renderTableBody()}
      </Table>
    )
  }
}

const mapStateToProps = ({ main }) => {
  const {
    assignments
  } = main

  return {
    assignments
  }
}

const actions = {
}

export default connect(mapStateToProps, actions)(ProductTable)
