import React, { Component } from 'react';

class ChartNotes extends Component {

  chartNotes;

  componentDidMount() {
    this.chartNotes.querySelectorAll(".expandable").forEach((note) => {
      note.dataset.initialHeight = `${note.offsetHeight}`;
      note.setAttribute('style', `flex: 0 0 ${note.offsetHeight}px`);

      note.addEventListener('click', () => {
        note.classList.toggle('active');
        const nextHeight = note.classList.contains('active') ? note.scrollHeight : note.dataset.initialHeight;
        note.setAttribute('style', `flex: 0 0 ${nextHeight}px`);
      })
    })
  }

  render() {
    return (
      <div className="reverse strong bg-dark lr-outset" ref={ref => this.chartNotes = ref}>
          <div className="d-flex align-items-start flex-column bd-highlight mb-3">
            <div className="mb-auto p-2 bd-highlight">
              All plans include:
            </div>
            <div className="p-2 my-auto bd-highlight align-content-center">
              Yearly Plans Price Match in MuvorX!
            </div>
          </div>
          <div className="flex tb-padding-2">
            <div className="flex-item">
              <ul className="flex-column">
                <li className="expandable">
                  <div className="expandable-base">
                    <span>15 Day Free Trial!</span>
                    <span className="fa fa-question-circle expansion-icon"></span>
                  </div>
                  <div className="expansion">  
                    <p>Gain complete access to our complete platform for 15 days without charge!</p>
                  </div>
                </li>
              </ul>
              <ul className="flex-column">
                <li className="expandable">
                  <div className="expandable-base">
                    <span>Business Analysis using D3 Data Visualization</span>
                    <span className="fa fa-question-circle expansion-icon"></span>
                  </div>
                  <div className="expansion">  
                    <p>We use some the best premium graphing and visualization tools available to help you to optimize your business.</p>
                    <p>See <a href="#" title="D3 Graphs" target="blank"> full list of available graphs</a>.</p>
                  </div>
                </li>
              </ul>
              <ul className="flex-column">
                <li className="expandable">
                  <div className="expandable-base">
                    <span>Timesheets, Invoicing, Payroll, and Resource Management</span>
                    <span className="fa fa-question-circle expansion-icon"></span>
                  </div>
                  <div className="expansion">  
                    <p>Expenses</p>
                    <p>Reporting</p>
                    <p>Agendas</p>
                  </div>
                </li>
              </ul>
              <ul>
                <li>Integrated Point of Sale system</li>
                <li>Import/Export support</li>
                <li>World-Class Security</li>
              </ul>
            </div>
            <div className="flex-item">
              <ul className="flex-column">
                <li className="expandable">
                  <div className="expandable-base">
                    <span>Monthly system and extension updates</span>
                    <span className="fa fa-question-circle expansion-icon"></span>
                  </div>
                  <div className="expansion">  
                    <p>As long as you're on a plan or have active hours, we'll update your website technology to the latest versions.</p>
                  </div>
                </li>
              </ul>
              <ul className="flex-column">
                <li className="expandable">
                  <div className="expandable-base">
                    <span>Weekly database and image backups</span>
                    <span className="fa fa-question-circle expansion-icon"></span>
                  </div>
                  <div className="expansion">  
                    <p>Your files and images are safely backed up in case you need to recover an older image or web page.</p>
                  </div>
                </li>
              </ul>
              <ul className="flex-column">
                <li className="expandable">
                  <div className="expandable-base">
                    <span>Multi-platform browser testing</span>
                    <span className="fa fa-question-circle expansion-icon"></span>
                  </div>
                  <div className="expansion">  
                    <p>On every project, we test the top 90% of browsers and versions currently being used. These include the latest versions of Chrome, Safari, Firefox, Explorer and Opera.</p>
                  </div>
                </li>
              </ul>
              <ul className="flex-column">
                <li className="expandable">
                  <div className="expandable-base">
                    <span>24/7 system monitoring</span>
                    <span className="fa fa-question-circle expansion-icon"></span>
                  </div>
                  <div className="expansion">  
                    <p>We have an automated system that watches your website for unusual behavior or down-time. We're alerted immediately and can respond quickly if something goes wrong.</p>
                  </div>
                </li>
              </ul>
              <ul className="flex-column">
                <li>Full Access to the Muvor Exchange</li>
              </ul>
            </div>
          </div>
        </div>
    );
  }
}

export default ChartNotes;
