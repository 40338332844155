import React, { Component } from 'react';
import { connect } from 'react-redux'
import * as cookies from 'js-cookie';

// Helpers
import { formatCurrencyOptions, hasAuthTokenAsync } from '../../../../../lib'

// Components
import {
  SummaryItem,
  Table,
  Span,
  Selector,
} from '../../../../common'

import { getSupervisors } from '../../../../../actions/supervisors';

import Scatter from '../../../../../graphs/scatter';
import BarChart from '../../../../../graphs/bar';

import styles from './EmployeeDashboard.css'

class EmployeeDashboard extends Component {

  renderCard({title, content}) {
    return (
      <SummaryItem
        className="card"
        key={title}
        title={title}
        content={content}
      />
    )
  }

  componentDidMount() {
    hasAuthTokenAsync()
    .then(() => {
    })
  }

  renderCards() {
    const totalCost = 0
    const dayProfit = 0
    const dayRevenue = 0
    const cardData = [
      { 
        title: '$MVRX',
        content: 0 
      },
      { 
        title: 'Revenue',
        content: dayRevenue 
      },
      { 
        title: 'Profit',
        content: dayProfit, 
      },
      { 
        title: 'Cost',
        content: totalCost, 
      }
    ]

    return (
      <div className="cards">
        {cardData.map(this.renderCard.bind(this))}
      </div>
    )
  }

  renderRepRow(rep) {
    return (
      <tr key={rep.id}>
        <td>
          <Span
            link={`/team/${rep.id}`}
            icon='user-circle'
          >
            {rep.name}
          </Span>
        </td>
        <td>
          {rep.bonus ? rep.bonus.toLocaleString(...formatCurrencyOptions) : rep.coin}
        </td>
      </tr>
    )
  }

  renderRepScorecard() {
    const { reps } = this.props
    return (
      <div className="card-container">
        <h2>
          Rep Scorecard
        </h2>
        <Table
          className={styles.repsTable}
          borderless
        >
          <thead>
            <tr>
              <th>
                Rep
              </th>
              <th>
                Bonus
              </th>
            </tr>
          </thead>
          <tbody>
            {// reps.map(this.renderRepRow.bind(this))
            }
          </tbody>
        </Table>
      </div>
    )
  }

  renderSupervisorRow(supervisor) {
    return (
      <tr key={supervisor.id}>
        <td>
          <Span icon='user-circle' />
          <div className="supervisor">
            <Span
              className="name"
              link={`/supervisors/${supervisor.id}`}
            >
              {supervisor.name}
            </Span>
            {
            <Span className="group">
              <Selector
                table
                options={supervisor.team.map(t => t) || 'No Group Found'}
              />
            </Span>
            }
          </div>
        </td>
      </tr>
    )
  }

  renderUnassignedSupervisors() {
    // const supervisors = this.props.supervisors.filter(el => !el.repId)
    return (
      <div className="card-container">
        <h2>
          Unassigned Supervisors
        </h2>
        <Table
          className={styles.supervisorsTable}
          borderless
        >
          <thead>
            <tr>
              <th>
                Supervisor
              </th>
            </tr>
          </thead>
          <tbody>
            {// supervisors.map(this.renderSupervisorRow.bind(this))
            }
          </tbody>
        </Table>
      </div>
    )
  }

  render() {
    return (
      <div className={styles.body}>
        <div className="graphs">
          {// <BarChart data={[5,10,1,3]} size={[500,500]} />
            <Scatter />
          }
        </div>
        {this.renderCards()}

        <div className="cards">
          {this.renderRepScorecard()}
          {this.renderUnassignedSupervisors()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ main, supervisors, auth }) => {
  const {
  } = main
  const {
  } = supervisors
  const {
    me
  } = auth

  return {
  }
}

const actions = {
}

export default connect(mapStateToProps, actions)(EmployeeDashboard);
