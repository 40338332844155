// TEAM FLOW
export const CREATE_EMPLOYEE = 'create_employee'
export const CREATE_EMPLOYEE_SUCCESS = 'create_employee_success'
export const CREATE_EMPLOYEE_FAILURE = 'create_employee_failure'

export const SET_EMPLOYEE = 'set_employee'

export const GET_EMPLOYEE = 'get_employee'
export const GET_EMPLOYEE_SUCCESS = 'get_employee_success'
export const GET_EMPLOYEE_FAILURE = 'get_employee_failure'

export const PATCH_EMPLOYEE = 'patch_employee'
export const PATCH_EMPLOYEE_SUCCESS = 'patch_employee_success'
export const PATCH_EMPLOYEE_FAILURE = 'patch_employee_failure'

export const DELETE_EMPLOYEE = 'delete_employee'
export const DELETE_EMPLOYEE_SUCCESS = 'delete_employee_success'
export const DELETE_EMPLOYEE_FAILURE = 'delete_employee_failure'

export const CREATE_NOTE = 'create_employee_note'
export const CREATE_NOTE_SUCCESS = 'create_employee_note_success'
export const CREATE_NOTE_FAILURE = 'create_employee_note_failure'

export const CLEAR_ERRORS = 'employee_clear_errors'
export const CLEAR_STATE = 'employee_clear_state'

export const LIST = 'LIST_EMPLOYEES';
export const GET = 'GET_EMPLOYEE';
